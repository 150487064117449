import { UnisunUtils } from '@unisun/utils';

export class College {
    CollegeID: string;
    Name: string;
    StateID: string;
    State: string;
    /**
     * Constructor
     *
     * @param country
     */
    constructor(country) {
        {
            this.CollegeID = country.CollegeID; //|| UnisunUtils.generateGUID();
            this.Name = country.Name || '';
            this.State = country.State || '';
            this.StateID = country.StateID || '';
        }
    }
}
