<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="promotionalvideoForm">

            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex class="p-4">

                    <input name="Title" [(ngModel)]="promotionalvideo.Title" formControlName="Title" placeholder="Title"
                        matInput required>
                </mat-form-field>
                <mat-form-field fxFlex class="p-4">

                    <input name="QueueID" [(ngModel)]="promotionalvideo.QueueID" formControlName="QueueID" placeholder="QueueID"
                        matInput required type="number">
                </mat-form-field>
                <mat-slide-toggle class="p-4" formControlName="IsActive" [checked]="promotionalvideo.IsActive"
                    [(ngModel)]="promotionalvideo.IsActive">
                    Is Active
                </mat-slide-toggle>

            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <textarea name="Description" rows="4" cols="40" [(ngModel)]="promotionalvideo.Description"
                        formControlName="Description" placeholder="Description" matInput required></textarea>
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <textarea name="Url" rows="4" cols="40" [(ngModel)]="promotionalvideo.Url" formControlName="Url"
                        placeholder="Url" matInput required></textarea>
                </mat-form-field>
            </div>
            <div class="">
                <mat-label class="label">Video Resolutions</mat-label>
                    <table class="width-100">
                      <tr  *ngFor="let videoResolution of  promotionalvideo.VideoResolutionCollection;index as i;">
                         <td>
                            <mat-form-field >
         <mat-select name="ResolutionType"  [(ngModel)]="videoResolution.ResolutionType" [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let _resolution of resolutions" [value]="_resolution.resolution">
                                  {{_resolution.resolution}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                         </td>
                         <td>
                            <mat-form-field fxFlex>
                                <textarea name="vrUrl"  placeholder="Url"
                                 matInput required
                                    [(ngModel)]="videoResolution.Url" [ngModelOptions]="{standalone: true}"></textarea>
                            </mat-form-field>
                         </td>
                         <td style="width:100px;">
                            <span mat-menu-item aria-label="remove" (click)="deleteResolutions(i)">
                                <mat-icon>delete</mat-icon>
                            </span>
                         </td>
                        </tr>
                    </table>
                    <div class="width-100">
                        <span mat-menu-item aria-label="edit" (click)="addResolution()"
                                        matTooltip="Add Transcription"  class="text-right">
                                        <mat-icon>add</mat-icon>
                                    </span>
                    </div>
               </div>      
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(promotionalvideo)"
            class="save-button mat-accent-bg" [disabled]="promotionalvideoForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',promotionalvideo])"
            class="save-button mat-accent-bg" [disabled]="promotionalvideoForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',promotionalvideo])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>