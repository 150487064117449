<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Level's</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <div class="p-24 mat-white-bg">
            <div fxLayout="column" fxLayoutAlign="center">

                <div class="filters" fxLayout="column" fxLayoutAlign="center center"
                    fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

                    <button mat-raised-button class="save-button mat-accent-bg"  aria-label="ADD"
                        (click)="addExam()">
                        ADD
                    </button>
                </div>


            </div>
     
        </div>



        <mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort
            matSortActive="LevelID" matSortDirection="asc">

            <!-- LevelID Column -->
            <ng-container matColumnDef="LevelID">
                <mat-header-cell *matHeaderCellDef mat-sort-header>LevelID</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p class="text-truncate font-weight-300">{{row.LevelID}}</p>
                </mat-cell>
            </ng-container>
            <!-- LevelName Column -->
            <ng-container matColumnDef="LevelName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>LevelName</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p class="text-truncate font-weight-300">{{row.LevelName}}</p>
                </mat-cell>
            </ng-container>
            <!-- QuestionSetName Column -->
            <ng-container matColumnDef="QuestionSetName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>QuestionSetName</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p class="text-truncate font-weight-300">{{row.QuestionSetName}}</p>
                </mat-cell>
            </ng-container>
            <!-- Buttons Column -->
            <ng-container matColumnDef="buttons">
                <mat-header-cell *matHeaderCellDef class="cell-center">Action</mat-header-cell>
                <mat-cell *matCellDef="let row" class="cell-center">
                    <span mat-menu-item aria-label="edit" (click)="editExam(row)">
                        <mat-icon>edit</mat-icon>
                    </span>
               
                    <span mat-menu-item aria-label="remove" (click)="deleteExam(row)">
                        <mat-icon>delete</mat-icon>
                    </span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="course"
                [@animate]="{value:'*',params:{y:'100%'}}">
            </mat-row>
        </mat-table>
        <mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
            (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions">
        </mat-paginator>
    </div>
</div>