<div class="navbar-top mat-accent-bg">

    <div class="logo">
        <img class="logo-icon" [src]="_env.appLogo">
        <!-- <span class="logo-text secondary-text">ADrPlexus</span> -->
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" unisunPerfectScrollbar [unisunPerfectScrollbarOptions]="{suppressScrollX: true}">

    <!-- <div class="user mat-accent-bg" fxLayout="column" *ngIf="user">

        <div class="h3 username">{{user.UserName}}</div>
        <div class="h5 email hint-text mt-8">{{user.Email}}</div>
        <div class="avatar-container" [ngClass]="unisunConfig.layout.navbar.background">
            <img class="avatar" src="assets/images/logos/logo.png">
        </div>

    </div> -->

    <div class="navbar-content">
        <unisun-navigation layout="vertical"></unisun-navigation>
    </div>

</div>