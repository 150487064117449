import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';



import { TrandingVideo } from './tranding-video.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TrandingVideoService implements Resolve<any>
{
    onTrandingVideoChanged: BehaviorSubject<any>;

    trandingvideo: TrandingVideo[];
    user: any;
    selectedTrandingVideo: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onTrandingVideoChanged = new BehaviorSubject([]);
    }


        // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getTrandingVideoForGrid(gridFilter)
            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
        });
    }


    /**
     * Get trandingvideo
     *
     * @returns {Promise<any>}
     */
    getTrandingVideo(): Promise<TrandingVideo[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/localization/get-live-intract`)
                .subscribe((response: any) => {

                    this.trandingvideo = response;;
                    this.onTrandingVideoChanged.next(this.trandingvideo);
                    resolve(this.trandingvideo);
                }, reject);
        }
        );
    }

    /**
     * Get trandingvideo
     *
     * @returns {Promise<any>}
     */
    getTrandingVideoForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/localization/get-grid-video-live-intract`, { ..._gridFilter });
    }

    /**
     * Update trandingvideo
     *
     * @param trandingvideo
     * @returns {Promise<any>}
     */
    createTrandingVideo(trandingvideo: TrandingVideo): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/localization/create-video-live-intract/`, { ...trandingvideo })
                .subscribe(response => {
                    if (response) {
                        this.onTrandingVideoChanged.next(this.trandingvideo);
                        this.openSnackBar("Successfully added.", "Close");
                    }
                    else{
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Update trandingvideo
     *
     * @param trandingvideo
     * @returns {Promise<any>}
     */
    updateTrandingVideo(trandingvideo: TrandingVideo): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/localization/update-video-live-intract/`, { ...trandingvideo })
                .subscribe(response => {
                    if (response) {
                        this.onTrandingVideoChanged.next(this.trandingvideo);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else{
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete trandingvideo
     *
     * @param trandingvideo
     */
    deleteTrandingVideo(trandingvideo): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.delete(`${environment.apiURL}/localization/delete-video-live-intract/` + trandingvideo.PromoID,{})
                .subscribe(response => {
                    if (response) {
                        this.onTrandingVideoChanged.next(this.trandingvideo);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else{
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }
}
