export class User {
    UserID: number;
    UserName: string;
    Password: string;
    Email: string;
    State: string;
    Country: string;
    College: string;
    Mobile: string;
    CategoryOfStudy: string;
    DateOfBirth: any;
    DateOfRegistration: string;
    IsVerified: boolean;
    Role: string;
    CategoryID: string;
    StateID: string;
    CountryID: string;
    CollegeID: string;
    RoleID: number;
    IsActive: boolean;
    UniqueID: string;
}