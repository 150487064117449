<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">User Permission</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <div class="content mat-white-bg p-24">
            <div fxLayout="column" fxLayoutAlign="center">

                <div class="filters" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                    fxLayoutAlign.gt-xs="space-between center">

                    <mat-form-field class="user-search">
                        <input matInput placeholder="User" aria-label="Exam" [matAutocomplete]="auto" width="500px"
                            (keydown)="getUserBySearch()" required [formControl]="userSearch">
                        <mat-autocomplete #auto="matAutocomplete" id="examsearch" panelWidth="500px"
                            (optionSelected)='selectedUser($event.option.value)'>
                            <mat-option *ngFor="let user of users" [value]="user.Email">
                                <span>{{user.UserName}}</span> |
                                <small>Email: {{user.Email}}</small>
                            </mat-option>
                        </mat-autocomplete>

                    </mat-form-field>
                    <button mat-raised-button class="save-button mat-accent-bg" aria-label="ADD" (click)="addUser()">
                        ADD
                    </button>
                    <button class="m-4" mat-raised-button aria-label="Download" (click)="GetDownloadReport()">
                        <mat-icon class="m-4">cloud_download</mat-icon> Export
                    </button>
                </div>

            </div>
        </div>



        <mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort
            matSortActive="Name" matSortDirection="asc">

            <!-- UserName Column -->
            <ng-container matColumnDef="UserName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>User Name</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p class="text-truncate font-weight-300">{{row.UserName}}</p>
                </mat-cell>
            </ng-container>
            <!-- Email Column -->
            <ng-container matColumnDef="Email">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p class="text-truncate font-weight-300">{{row.Email}}</p>
                </mat-cell>
            </ng-container>
            <!-- Mobile Column -->
            <ng-container matColumnDef="Mobile">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p class="text-truncate font-weight-300">{{row.Mobile}}</p>
                </mat-cell>
            </ng-container>
            <!-- Buttons Column -->
            <ng-container matColumnDef="buttons">
                <mat-header-cell *matHeaderCellDef class="cell-center">Action</mat-header-cell>
                <mat-cell *matCellDef="let row" class="cell-center">
                    <span mat-menu-item aria-label="remove" (click)="deleteARFilterUserPermission(row)">
                        <mat-icon>delete</mat-icon>
                    </span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="course"
                [@animate]="{value:'*',params:{y:'100%'}}">
            </mat-row>
        </mat-table>
        <mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
            (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions">
        </mat-paginator>
    </div>
</div>