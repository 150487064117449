import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";

import { FormBuilder, FormControl, FormGroup,FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA , MatDialogRef } from '@angular/material/dialog';

import { Integration, Tag as tagModel} from 'app/main/admin/common/subject/common.model';


@Component({
    selector: 'horizontalIntegration',
    templateUrl: './addIntegration.component.html',
    styleUrls: ['./addIntegration.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class  AddIntegrationComponent implements OnInit {
    action: string;
    integrationtype:any;
    integratrion: Integration;
    integrationForm: FormGroup;
    dialogTitle: string;
    ckeConfig: any;
    choiceType: any;
    scoreType: any;

    /**
     * Constructor
     *
     * @param {MatDialogRef<AddSubjectComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */

    constructor(
        public matDialogRef: MatDialogRef<AddIntegrationComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        var self =this;
        // Set the defaults
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
       };

     this.action = _data.action;
     this.integrationtype=_data.integrationtype;
    if (this.action === 'edit') {
        if(this.integrationtype==0){
            this.dialogTitle = 'Edit Horizontal Intirgration';
            this.integratrion = _data.integration;
        }
        else{
            this.dialogTitle = 'Edit Vertical Intirgration';
            this.integratrion = _data.integration;
        }
     
    }
    
    else if(this.action === 'new') {
        if(this.integrationtype==0){
            this.dialogTitle = 'New Horizontal Intirgration';
            this.integratrion = _data.integration;
        }
        else{
            this.dialogTitle = 'New Vertical Intirgration';
            this.integratrion = _data.integration;
        }
    }
    else{

    }
    self.integrationForm = self.createintegrationForm();
  }

    

 // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create exam form
     *
     * @returns {FormGroup}
     */

    createintegrationForm(): FormGroup {
        return this._formBuilder.group({
            Name: [this.integratrion.Title],
            IsActive: [this.integratrion.IsActive],
            OrderNo:[this.integratrion.OrderNo]
           
        });
    }

    ngOnInit(): void {
    }   

}
    

   
