import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Hippo, HipposDetails } from 'app/main/admin/hippo/hippo.model';
import { State } from 'app/main/admin/state/state.model';
import { CommonService } from '../../common/subject/common.service';

@Component({
    selector: 'hippo-detail-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HipposDetailsFormDialogComponent {
    ckeConfig: any;
    action: string;
    hippo: HipposDetails;
    hippoForm: FormGroup;
    dialogTitle: string;    
    subjects:any;  

    states: State[];
    /**
     * Constructor
     *
     * @param {MatDialogRef<HipposDetailsFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<HipposDetailsFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _commonService: CommonService
    ) {
        var self = this
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };
        this._commonService.getSubject().then(function (response) {

            self.subjects = response.Data;
            console.log(response)
        });  

        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Hippo';
            this.hippo = _data.hippo;
        }
        else {
            this.dialogTitle = 'New Hippo';
            this.hippo = new HipposDetails({});
        }

        this.hippoForm = this.createHippoForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create hippo form
     *
     * @returns {FormGroup}
     */
    createHippoForm(): FormGroup {
        return this._formBuilder.group({
            Title: [this.hippo.Title],
            SubTitle: [this.hippo.SubTitle],
            Content: [this.hippo.Content],
            NoteContent: [this.hippo.NoteContent],
            Subject: [this.hippo.Subject]
        });
    }
}
