<mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort matSortActive="Name"
    matSortDirection="desc">

    <!-- Title Column -->
    <ng-container matColumnDef="SubjectName">
        <mat-header-cell *matHeaderCellDef >SubjectName</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <p class="text-truncate font-weight-300" >{{row.SubjectName}}</p>
        </mat-cell>
    </ng-container>
    <!-- IsActive Column -->
    <ng-container matColumnDef="IsActive">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="mat-yellow-bg text-boxed m-0 text-truncate font-weight-300">{{row.IsActive}}</p>
        </mat-cell>
    </ng-container>
    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef class="j-c cell-center">Action</mat-header-cell>
        <mat-cell *matCellDef="let row" class="j-c cell-center">
            <span mat-menu-item aria-label="edit" (click)="editsubject(row)">
                <mat-icon>edit</mat-icon>
            </span>
            <span mat-menu-item aria-label="remove" (click)="deleteSubject(row)">
                <mat-icon>delete</mat-icon>
            </span>
          
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Topic">
        <mat-header-cell *matHeaderCellDef class="j-c cell-center">Topic</mat-header-cell>
        <mat-cell *matCellDef="let row" class="j-c cell-center"> 
            <span mat-menu-item arial-label="add" (click)="topic(row)">
                <mat-icon >add</mat-icon><strong >{{row.TopicsCount}}</strong> 
             </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="horizontalintegration">
        <mat-header-cell *matHeaderCellDef class="j-c cell-center">Horizontalintigration</mat-header-cell>
        <mat-cell *matCellDef="let row" class="j-c cell-center">
           
            <span mat-menu-item arial-label="add" [routerLink]="['/admin/collection/qbanktype/'+row.QbankTypeID+'/subjects/'+row.SubjectID+'/horizontal-integration']">
               <!-- <mat-icon (click)="horizontalintegration(row)">add</mat-icon> -->
               <mat-icon>add</mat-icon><strong >{{row.HorizontalIntegrationCount}}</strong> 
            </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="verticalintegration">
        <mat-header-cell *matHeaderCellDef class="j-c cell-center">Verticalintigration</mat-header-cell>
        <mat-cell *matCellDef="let row" class="j-c cell-center">
            <span mat-menu-item arial-label="add" [routerLink]="['/admin/collection/qbanktype/'+row.QbankTypeID+'/subjects/'+row.SubjectID+'/vertical-integration']">
               <mat-icon>add</mat-icon><strong >{{row.VerticalIntegrationCount}}</strong> 
            </span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="exam" [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>
<mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
    (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>