import { User } from './../_models/user';
import { Injectable, NgZone } from '@angular/core';

import 'rxjs/add/operator/map';

import { navigation } from 'app/navigation/navigation';
import { UnisunNavigationService } from '@unisun/components/navigation/navigation.service';
import { Router } from '@angular/router';
import { DataGuardService } from 'app/_guards';

@Injectable()
export class HelperService {
  constructor(
    private _unisunNavigationService: UnisunNavigationService,
    private _dataGuardService: DataGuardService,
    private _router: Router,
    private ngZone: NgZone
  ) { }


  public getTimeStamp(): number {
    let now: Date = new Date();
    return now.getTime();
  }

  public getSecondsAsDigitalClock(inputSeconds: number) {
    const secNum = parseInt(inputSeconds.toString(), 10); // don't forget the second param
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum - (hours * 3600)) / 60);
    const seconds = secNum - (hours * 3600) - (minutes * 60);
    let hoursString = '';
    let minutesString = '';
    let secondsString = '';
    hoursString = (hours < 10) ? '0' + hours : hours.toString();
    minutesString = (minutes < 10) ? '0' + minutes : minutes.toString();
    secondsString = (seconds < 10) ? '0' + seconds : seconds.toString();
    return hoursString + ':' + minutesString + ':' + secondsString;
  }

  private navigation: any;
  public userNavigation(user: User, redirect: boolean = true, isSocialLogin = false) {
    var self = this;

    var isVerify = self._dataGuardService.getCookieData("OTP");
    if (Boolean(JSON.parse(isVerify))) {

      let menu: any;

      // Google Login -- Static declaration
      if (isSocialLogin) {
        if (user.Role == 'Super Admin') {
          user.RoleID = 3;
        }
        else if (user.Role == 'Admin') {
          user.RoleID = 2;
        }
        else {
          user.RoleID = 1;
        }
      }

      if (user.RoleID == 1 || user.RoleID == 0) {
        menu = navigation.filter(r => r.id == 'applications',r => r.id != 'QuestionCreator');
      }
      else if (user.RoleID == 2) {
        menu = navigation.filter(r => r.id == 'instructor',r => r.id != 'QuestionCreator');
      }
      else if (user.RoleID == 3) {
        menu = navigation.filter(r => r.id != 'QuestionCreator');
      }
      else if (user.RoleID == 4) {
        menu = navigation.filter(r => r.id == 'QuestionCreator');
      }
      // Get default navigation
      this.navigation = menu;

      // Register the navigation to the service
      this._unisunNavigationService.register('main', this.navigation);

      // Set the main navigation as our current navigation
      this._unisunNavigationService.setCurrentNavigation('main');
      this.ngZone.run(() => {
        if (redirect) {
          if (user.RoleID == 1 || user.RoleID == 0) {
            self._router.navigate(['apps/academy/courses']);
          }
          if (user.RoleID == 4) {
            self._router.navigate(['admin/qbank/analytics']);
          }
          else {
            self._router.navigate(['/admin/dashboards/analytics']);
          }
        }
      });
    } else {
      let userData = this.getUserDetail();

      if (userData) {
        self.ngZone.run(() => {
          self._router.navigate(['security/auth/otp']);
        });
      }
      else {
        self.ngZone.run(() => {
          self._router.navigate(['security/auth/login']);
        });
      }

    }

  }

  public getUserDetail(): User {
    var user: User;

    // User Data if already logged in 
    var _user = this._dataGuardService.getCookieData('lms-user');

    if (_user) {
      user = JSON.parse(_user);
    }

    return user;
  }
}