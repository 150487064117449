<div id="QuestionBugReport" class="page-layout simple left-sidebar inner-sidebar inner-scroll">

    <!-- HEADER -->
    <div class="header mat-accent-bg p-16 p-sm-24 mat-elevation-z6" fxLayout="column" fxLayoutAlign="start start"
        fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

        <!-- APP TITLE -->
        <div fxLayout="row" fxLayoutAlign="start center">


            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">account_box
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    REPORT DOWNLOAD/UPLOAD
                </span>
            </div>

        </div>
        <!-- / APP TITLE -->

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">



        <!-- CENTER -->
        <div class="center p-24 pb-56" unisunPerfectScrollbar>

            <!-- CONTENT -->
          <div class="content mat-white-bg mat-elevation-z4">
                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>backup</mat-icon>&nbsp;Download Report
                        </ng-template>
                        <div class="tab-content p-24" fusePerfectScrollbar>
                            <report-download></report-download>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>assignment</mat-icon>&nbsp;Upload Result
                        </ng-template>
                        <div class="tab-content p-24" fusePerfectScrollbar>
                            <rank-upload></rank-upload>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>assignment</mat-icon>&nbsp;View Result
                        </ng-template>
                        <div class="tab-content p-24" fusePerfectScrollbar>
                            <rank-view></rank-view>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>  
            <!-- / CONTENT -->

        </div>
        <!-- / CENTER -->

    </div>
    <!-- / CONTENT-->

</div>