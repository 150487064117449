import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';


import { UnisunDemoContentComponent } from './demo-content/demo-content.component';
import { UnisunDemoSidebarComponent } from './demo-sidebar/demo-sidebar.component';

@NgModule({
    declarations: [
        UnisunDemoContentComponent,
        UnisunDemoSidebarComponent
    ],
    imports     : [
        RouterModule,

        MatDividerModule,
        MatListModule
    ],
    exports     : [
        UnisunDemoContentComponent,
        UnisunDemoSidebarComponent
    ]
})
export class UnisunDemoModule
{
}
