import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { unisun_CONFIG } from '@unisun/services/config.service';

@NgModule()
export class UnisunModule
{
    constructor(@Optional() @SkipSelf() parentModule: UnisunModule)
    {
        if ( parentModule )
        {
            throw new Error('UnisunModule is already loaded. Import it in the AppModule only!');
        }
    }

    static forRoot(config): ModuleWithProviders<UnisunModule>
    {
        return {
            ngModule : UnisunModule,
            providers: [
                {
                    provide : unisun_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
