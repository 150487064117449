import { UnisunUtils } from '@unisun/utils';

export class Video {
    VideoID: number = 0;
    Title: string;
    RewardsPoint: number;
    IsActive: boolean;
    /**
     * Constructor
     *
     * @param video
     */
    constructor(video) {
        {
            this.VideoID = video.VideoID || 0;
            this.RewardsPoint = video.RewardsPoint || 0;
            this.Title = video.Title || '';
            this.IsActive = video.IsActive || false
        }
    }
}


export class VideoResolution{
    VideoDetailID:number=0;
    ResolutionID : number=0;
    ResolutionType : string="";
    Url :string =""
}
export class VideoDetails {
    VideoID: number = 0;
    VideoDetailID: number = 0;
    QueueID: number;
    Subject: string;
    Topic:string;
    Category:string;
    Url: string;
    Content: string;
    Title: string;
    VideoLength: string;
    Price: number;
    MaxViewPermission: number;
    VideoType: number;
    NotesLink: string;
    VideoResolutionCollection:VideoResolution[];
    /**
     * Constructor
     *
     * @param VideoDetails
     */
    constructor(value) {
        {
            this.VideoID = value.VideoID || 0;
            this.Title = value.Title || '';
            this.QueueID = value.QueueID || 0;
            this.Subject = value.Subject || '';
            this.Topic = value.Topic || '';
            this.Url = value.Url || '';
            this.Content = value.Content || '';
            this.NotesLink = value.NotesLink || '';
            this.VideoLength = value.VideoLength || '';
            this.Price = value.Price || '';
            this.MaxViewPermission = value.MaxViewPermission || '';
            this.VideoType = value.VideoType || 0;
            this.VideoResolutionCollection=value.VideoResolution||null;
        }
    }
}

export class Transcription {
    VideoDetailID: number;
    TranscriptionID: number;
    Topic: string;
    TrimStart: string;
    TrimEnd: string;
    VideoLength: string;

    /* Constructor
     *
     * @param Transcription
     */
    constructor(value) {
        {
            this.VideoDetailID = value.VideoDetailID || 0;
            this.TranscriptionID = value.TranscriptionID || 0;
            this.Topic = value.Topic || '';
            this.TrimStart = value.TrimStart || '';
            this.TrimEnd = value.TrimEnd || '';
            this.VideoLength = value.VideoLength || '';
        }
    }

}