<!-- SIDEBAR CONTENT -->
<div class="content" unisunPerfectScrollbar [@animate]="{value:'*',params:{y:'50px'}}">

    <div class="nav">
        <div class="nav-subheader">TAGS</div>

        <div class="nav-item" *ngFor="let tag of tags">
            <a class="nav-link" matRipple [routerLink]="'/apps/todo/tag/' + tag.handle"
               [routerLinkActive]="['active', 'mat-accent-bg']">
                <mat-icon class="nav-link-icon" [ngStyle]="{'color':tag.color}">label</mat-icon>
                <span>{{tag}}</span>
            </a>
        </div>

    </div>

</div>
<!-- / SIDEBAR CONTENT -->
