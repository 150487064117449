<mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort matSortActive="Hippo"
    matSortDirection="asc">

    <!-- Name Column -->
    <ng-container matColumnDef="Title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Title}}</p>
        </mat-cell>
    </ng-container>

    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef class="cell-center">Action</mat-header-cell>
        <mat-cell *matCellDef="let row" class="cell-center">
            <span mat-menu-item aria-label="edit" (click)="editHippo(row)">
                <mat-icon>edit</mat-icon> Edit
            </span>
            <span mat-menu-item aria-label="remove" (click)="deleteHippo(row)">
                <mat-icon>delete</mat-icon> Remove
            </span>
            <span mat-menu-item aria-label="edit" (click)="editCollectionHippo(row)">
                <mat-icon>add</mat-icon> Add Collection
            </span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="hippo" [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>
<mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
    (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>