import { Type } from '@angular/compiler';
import { AppRankUpdateRequest, UserRankList, Report } from './../../report/report.model';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { unisunAnimations } from '@unisun/animations';

import { ReportService } from 'app/main/admin/report/report.service';
import { ActivatedRoute } from '@angular/router';
import { SitePreference } from 'app/config';
import { CourseService } from '../../course/course.service';
import { TestSeriesService } from '../../test-series/test-series.service';
import { Exam } from '../../exam/exam.model';
import { XlsxToJsonService } from 'app/_services';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'rank-upload',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RankUpoadComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;

    Type: number;
    TypeID: number;
    ExamID: number;
    StartDate: any;
    EndDate: any;

    title: string;

    TypeList: any;
    ExamList: Array<Exam>;


    report: any;
    rankList: Array<UserRankList>;
    @Input() files: File[] = [];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ReportService} _reportService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _reportService: ReportService,
        public _matDialog: MatDialog,
        private _route: ActivatedRoute,
        private _courseService: CourseService,
        private _testseriesService: TestSeriesService,
        private sanitizer: DomSanitizer) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit() {

    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        var self = this;

        self._route.params.subscribe(function (parram) {
            self.title = parram.type;
            self.Type = parram.type == 'course' ? 1 : 2;

            if (self.Type == 1) {
                self._courseService.getCourse().subscribe(function (data) {
                    self.TypeList = data;
                });
            }
            else if (self.Type == 2) {
                self._testseriesService.getTestSeries().subscribe(function (data) {
                    self.TypeList = data;
                });
            }
        });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    OnChangeType(TypeID: number) {
        var self = this;
        self.ExamList = [];
        self.ExamID = 0;

        if (self.Type == 1) {
            self._courseService.getCourseById(TypeID).subscribe(function (data) {
                self.ExamList = data.ExamID;
            });
        }
        else if (self.Type == 2) {
            self._testseriesService.getTestSeriesById(TypeID).subscribe(function (data) {
                self.ExamList = data.ExamID;
            });
        }
    }

    OnChangeExam() {

    }

    @Input() multiple: any;
    @Input() accept: any;
    @Input() chooseLabel = 'Choose RankSheet'
    @Input() uploadLabel = 'Upload'
    @Input() cancelLabel = 'Cancel'
    @Input() dataUriPrefix: any;
    @Input() deleteButtonLabel: any;
    @Input() deleteButtonIcon = 'close'

    @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;

    inputFileName: string;


    onClick(event) {
        if (this.fileUpload)
            this.fileUpload.nativeElement.click()
    }

    onInput(event) {

    }

    onFileSelected(event) {
        let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        console.log('event::::::', event)
        for (let i = 0; i < files.length; i++) {
            let file = files[i];


            if (this.validate(file)) {

                file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));

                if (!this.isMultiple()) {
                    this.files = []
                }
                this.files.push(files[i]);

                this.handleFile(event);
            }

        }
    }
    public result: any;
    private xlsxToJsonService: XlsxToJsonService = new XlsxToJsonService();
    removeFile(event, file) {
        let ix
        if (this.files && -1 !== (ix = this.files.indexOf(file))) {
            this.files.splice(ix, 1)
            this.clearInputElement()
        }
    }

    validate(file: File) {
        for (const f of this.files) {
            if (f.name === file.name
                && f.lastModified === file.lastModified
                && f.size === f.size
                && f.type === f.type
            ) {
                return false
            }
        }
        return true
    }

    clearInputElement() {
        this.fileUpload.nativeElement.value = ''
    }


    isMultiple(): boolean {
        return this.multiple
    }

    handleFile(event) {
        var self = this;
        let file = event.target.files[0];
        try {
            this.xlsxToJsonService.processFileToJson({}, file).subscribe(data => {
                if (data.sheets.data) {
                    self.rankList = [];
                    data.sheets.data.forEach((row: UserRankList) => {
                        let _request: UserRankList =
                        {
                            Type: self.Type,
                            TypeID: self.TypeID,
                            ExamID: self.ExamID,
                            Title: row.Title,
                            SubTitle: row.SubTitle,
                            CenterName: row.CenterName,
                            RegisterNo: row.RegisterNo,
                            UserName: row.UserName,
                            Email: row.Email,
                            Mobile: row.Mobile,
                            Correct: row.Correct,
                            Wrong: row.Wrong,
                            ActualCorrect: row.ActualCorrect,
                            ActualWrong: row.ActualWrong,
                            Blank: row.Blank,
                            Percentage: row.ActualScore,
                            NoOfQuestions: row.NoOfQuestions,
                            TotalScore: row.TotalScore,
                            SubmittedOn: row.SubmittedOn,
                            ActualScore: row.ActualScore,
                            CenterRank: row.CenterRank,
                            AllIndiaRank: row.AllIndiaRank,
                            NoOfStudents: row.NoOfStudents,
                            Remark: row.Remark,
                            ScoreType: row.ScoreType
                        };
                        self.rankList.push(_request);
                    });
                }
            });
        }
        catch (e) {
            console.log(e);
        }
        finally {
        }

    }


    OnBulkUpdateRank() {
        var self = this;
        let appRankUpdateRequest: AppRankUpdateRequest = {
            Type: self.Type,
            TypeID: self.TypeID,
            ExamID: self.ExamID,
            UserRankList: this.rankList
        };

        self._reportService.updateUserRankByExam(appRankUpdateRequest).subscribe(() => {
            self._reportService.openSnackBar("Successfully added", "close");
            this.rankList = [];
            this.removeFile(event, this.files[0]);
        });


    }
}