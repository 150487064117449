import { UnisunUtils } from '@unisun/utils';

export class QuestionSet {
    QuestionSetID: number = 0;
    Title: string;
    Description: string;
    IsActive: boolean;
    /**
     * Constructor
     *
     * @param qbank
     */
    constructor(qbank) {
        {
            this.QuestionSetID = qbank.QuestionSetsID || 0;
            this.Title = qbank.Title || '';
            this.Description = qbank.Description || '';
            this.IsActive = qbank.IsActive || false
        }
    }
}

export class QuestionDetail {
    QuestionSetID: number;
    QuestionDetailID: number;
    Hints: string;
    Mark: number;
    Content: string;
    QuestionTitle: string;
    Negative: number;
    QueueID: number;
    Answer: string;
    IsBookmarked: boolean;
    Title: string;
    /**
     * Constructor
     *
     * @param QuestionDetail
     */
    constructor(value) {
        {
            this.QuestionSetID = value.QuestionSetID || 0;
            this.QuestionTitle = value.QuestionTitle || '';
            this.QueueID = value.QueueID || '';
            this.Mark = value.Mark || '';
            this.Negative = value.Negative || '';
            this.Hints = value.Hints || '';
            this.Answer = value.Answer || '';
        }
    }
}


export class ExcelDataQuestionField {
    Question: string;
    Marks: number;
    Negative: number;
    Answer: string;
    Correct: string;
    Explanation: string;
    Hints: string;
}
 