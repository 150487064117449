import { UnisunUtils } from '@unisun/utils';

export class QuestionSet {
    QuestionSetID: number = 0;
    Title: string;
    Description: string;
    IsActive: boolean;
    /**
     * Constructor
     *
     * @param qbank
     */
    constructor(qbank) {
        {
            this.QuestionSetID = qbank.QuestionSetsID || 0;
            this.Title = qbank.Title || '';
            this.Description = qbank.Description || '';
            this.IsActive = qbank.IsActive || false
        }
    }
}

export class QuestionDetail {
    QuestionSetID: number;
    QuestionDetailID: number;
    QueueID: number;
    Subject: string;
    Chapter: string;
    Mark: number;
    Content: string;
    QuestionTitle: string;
    Negative: number;
    QuestionType: number;
    QuestionBankID: string;
    Choices: Array<Choice>;
    AdditionalChoices: Array<AdditionalChoice>;
    QuestionTypeList: Array<QuestionType>;

    IsBookmarked: boolean;
    Title: string;
    /**
     * Constructor
     *
     * @param QuestionDetail
     */
    constructor(value) {
        {
            this.QuestionSetID = value.QuestionSetID || 0;
            this.QuestionTitle = value.QuestionTitle || '';
            this.QueueID = value.QueueID || '';
            this.Subject = value.Subject || '';
            this.Mark = value.Mark || '';
            this.Negative = value.Negative || '';
            this.QuestionType = value.QuestionType || 0;
            this.QuestionBankID = value.QuestionBankID || '';
            this.Choices = value.Choices || new Array<Choice>();
            this.AdditionalChoices = value.AdditionalChoices || new Array<AdditionalChoice>();
            this.QuestionTypeList = value.QuestionTypeList || new Array<QuestionType>();
        }
    }
}

export class Choice {
    AutoId: string;
    QuestionSetID: number;
    QuestionDetailID: number;
    ChoiceID: number;
    ChoiceText: string;
    IsCorrect: boolean;
    QueueID: number;
    ValidAdditionalValue: string;

    IsChecked: boolean;
    MatchedValue: string;

    /**
     * Constructor
     *
     * @param Choice
     */
    constructor(value) {
        {
            this.AutoId = value.AutoId || UnisunUtils.generateGUID();
            this.QuestionSetID = value.QuestionSetID || 0;
            this.QuestionDetailID = value.QuestionDetailID || 0;
            this.ChoiceID = value.ChoiceID || 0;
            this.QueueID = value.QueueID || '';
            this.ChoiceText = value.ChoiceText || '';
            this.IsCorrect = value.IsCorrect || false;
            this.ValidAdditionalValue = value.ValidAdditionalValue || '';
            this.IsChecked = value.IsChecked || false;

        }

    }

}

export class AdditionalChoice {
    AutoId: string;
    QueueID: number;
    QuestionSetID: number;
    ChoiceID: number;
    AdditionalChoiceID: number;
    ChoiceText: string;

    /**
     * Constructor
     *
     * @param AdditionalChoice
     */
    constructor(value) {
        {
            this.AutoId = value.AutoId || UnisunUtils.generateGUID();
            this.QuestionSetID = value.QuestionSetID || 0;
            this.ChoiceID = value.ChoiceID || 0;
            this.AdditionalChoiceID = value.AdditionalChoiceID || 0;
            this.ChoiceText = value.ChoiceText || '';

        }

    }

}

export class QuestionType {
    Key: number;
    Value: string;
}

export class ExcelDataQuestionField {
    Question: string;
    Marks: number;
    Negative: number;
    Subject: string;
    Choices: string;
    Collection: string;
    Correct: string;
    QuestionBankID: string;
    Explanation: string;
    Pattern: any;
    Chapter: string;
}

export class TodoExcelField {
    Name: string;
    Subject: string;
    TodoID: number;
    Chapter: string;
}