import { CountryService } from './../country/country.service';
import { Country } from './../country/country.model';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunSidebarService } from '@unisun/components/sidebar/sidebar.service';

import { StateService } from 'app/main/admin/state/state.service';
import { StateFormDialogComponent } from 'app/main/admin/state/state-form/form.component';

@Component({
    selector: 'state',
    templateUrl: './state.component.html',
    styleUrls: ['./state.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class StateComponent implements OnInit, OnDestroy {
    dialogRef: any;
    hasSelectedState: boolean;
    searchInput: FormControl;

    country: Country[];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {StateService} _stateService
     * @param {UnisunSidebarService} _unisunSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _stateService: StateService,
        private _countryService: CountryService,
        private _unisunSidebarService: UnisunSidebarService,
        private _matDialog: MatDialog
    ) {
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._stateService.onStateChanged.next(searchText);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * New state
     */
    newState(): void {
        if (this.country == null) {
            this._countryService.getCountry().then(country => {
                this.country = country;
                this.dialogRef = this._matDialog.open(StateFormDialogComponent, {
                    panelClass: 'state-form-dialog',
                    data: {
                        action: 'new',
                        country: this.country
                    }
                });
                this.newStateCallback();
            });
        }
        else {
            this.dialogRef = this._matDialog.open(StateFormDialogComponent, {
                panelClass: 'state-form-dialog',
                data: {
                    action: 'new',
                    country: this.country
                }
            });
            this.newStateCallback();
        }


    }

    newStateCallback() {
        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }

                this._stateService.createState(response.getRawValue());
            });
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._unisunSidebarService.getSidebar(name).toggleOpen();
    }
}
