
<!-- / SIDE PANEL -->

<div id="main">

    <!-- TOOLBAR: Above fixed -->
    <ng-container *ngIf="unisunConfig.layout.toolbar.position === 'above-fixed'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above fixed -->

    <div id="container-1" class="container" unisunPerfectScrollbar
         [unisunPerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">

        <!-- TOOLBAR: Above static -->
        <ng-container *ngIf="unisunConfig.layout.toolbar.position === 'above-static'">
            <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        </ng-container>
        <!-- / TOOLBAR: Above static -->

        <div id="container-2" class="container">

            <!-- NAVBAR: Left -->
            <ng-container *ngIf="unisunConfig.layout.navbar.position === 'left'">
                <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
            </ng-container>
            <!-- / NAVBAR: Left -->

            <div id="container-3" class="container">

                <!-- TOOLBAR: Below -->
                <ng-container *ngIf="unisunConfig.layout.toolbar.position === 'below'">
                    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
                </ng-container>
                <!-- / TOOLBAR: Below -->

                <!-- CONTENT -->
                <content></content>
                <!-- / CONTENT -->

                <!-- FOOTER: Below -->
                <ng-container *ngIf="unisunConfig.layout.footer.position === 'below'">
                    <ng-container *ngTemplateOutlet="footer"></ng-container>
                </ng-container>
                <!-- / FOOTER: Below -->

            </div>

            <!-- NAVBAR: Right -->
            <ng-container *ngIf="unisunConfig.layout.navbar.position === 'right'">
                <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
            </ng-container>
            <!-- / NAVBAR: Right -->

        </div>

        <!-- FOOTER: Above static -->
        <ng-container *ngIf="unisunConfig.layout.footer.position === 'above-static'">
            <ng-container *ngTemplateOutlet="footer"></ng-container>
        </ng-container>
        <!-- FOOTER: Above static -->

    </div>

    <!-- FOOTER: Above fixed -->
    <ng-container *ngIf="unisunConfig.layout.footer.position === 'above-fixed'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- FOOTER: Above fixed -->

</div>


<!-- QUICK PANEL -->
<unisun-sidebar name="quickPanel" position="right" class="quick-panel">
    <quick-panel></quick-panel>
</unisun-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
    <toolbar *ngIf="!unisunConfig.layout.toolbar.hidden"
             [ngClass]="[unisunConfig.layout.toolbar.position, unisunConfig.layout.toolbar.background]"></toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
    <footer *ngIf="!unisunConfig.layout.footer.hidden"
            [ngClass]="[unisunConfig.layout.footer.position, unisunConfig.layout.footer.background]"></footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
    <unisun-sidebar name="navbar" class="navbar-unisun-sidebar"
                  [folded]="unisunConfig.layout.navbar.folded"
                  lockedOpen="gt-md"
                  *ngIf="!unisunConfig.layout.navbar.hidden">
        <navbar [variant]="unisunConfig.layout.navbar.variant"
                class="left-navbar" [ngClass]="unisunConfig.layout.navbar.background"></navbar>
    </unisun-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
    <unisun-sidebar name="navbar" position="right" class="navbar-unisun-sidebar"
                  [folded]="unisunConfig.layout.navbar.folded"
                  lockedOpen="gt-md"
                  *ngIf="!unisunConfig.layout.navbar.hidden">
        <navbar [variant]="unisunConfig.layout.navbar.variant"
                class="right-navbar" [ngClass]="unisunConfig.layout.navbar.background"></navbar>
    </unisun-sidebar>
</ng-template>
<!-- / RIGHT NAVBAR -->