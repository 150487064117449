import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { UnisunUtils } from '@unisun/utils';

import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { GridFilter, ReportGridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuestionBugReport } from 'app/_models/QuestionBugReport';

@Injectable()
export class QuestionBugReportService implements Resolve<any>
{
    onQuestionBugReportChanged: BehaviorSubject<any>;

    QuestionBugReport: QuestionBugReport[];
    user: any;
    selectedQuestionBugReport: string[] = [];

    searchText: string;
    filterBy: string;
    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onQuestionBugReportChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterQuestionBugReportSnapshot} QuestionBugReport
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: ReportGridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: '',
                StartDate: 0,
                EndDate: 0,
                Type: 0,
                TypeID: 0,
                ExamID: 0
            };
            Promise.all([
                this.getQuestionBugReportForGrid(gridFilter)
            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get QuestionBugReport
     *
     * @returns {Promise<any>}
     */
    getQuestionBugReportForGrid(_gridFilter: ReportGridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/report/get-grid-question-bug-report`, { ..._gridFilter });
    }

}
