import { UserRankStatistics } from './../../../_models/UserRankStatistics';
import { QuestionBugReport } from './../../../_models/QuestionBugReport';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AppQuestionsRequest, AppQuestionsResponse, AppUserQuestionSubmit, AppUserExamSubmit, UserExamActivity, UserTestResult, AppReportRequest, AppTestReportAnalysis, QuestionDetailMockTest } from './game-view.model';
import { RankingFilter } from 'app/_models/UserRankStatistics';

@Injectable()
export class GameViewService implements Resolve<any>
{
    onCourseChanged: BehaviorSubject<any>;
    questions: Array<QuestionDetailMockTest> = [];
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) {
        // Set the defaults
        this.onCourseChanged = new BehaviorSubject({});
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                //this.getCourse(route.params.courseId)
            ]).then(
                () => {
                    resolve(null);
                },
                reject
            );
        });
    }

    /**
     *get Questions
     *
     * @param AppQuestionsRequest
     * @returns {Promise<AppQuestionsResponse>}
     */
    getQuestions(request: AppQuestionsRequest): Promise<AppQuestionsResponse> {
        var self = this;
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/api/user/get-questions/`, { ...request })
                .subscribe((response: any) => {
                    self.questions = response.Data;
                    resolve(response.Data);
                }, reject);
        });
    }

    /**
     *get anwser sheet Questions
     *
     * @param AppQuestionsRequest
     * @returns {Promise<AppQuestionsResponse>}
     */
    getAnswerSheetQuestions(request: AppQuestionsRequest): Promise<AppQuestionsResponse> {
        var self = this;
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/api/user/get-answersheet-questions/`, { ...request })
                .subscribe((response: any) => {
                    self.questions = response.Data;
                    resolve(response.Data);
                }, reject);
        });
    }

    /**
     *get Submit Questions Analysis
     *
     * @param AppQuestionsRequest
     * @returns {Promise<AppQuestionsResponse>}
     */
    getSubmitQuestionsAnalysis(request: AppQuestionsRequest): Promise<AppQuestionsResponse> {
        var self = this;
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/api/user/get-questions-pre-analysis/`, { ...request })
                .subscribe((response: any) => {
                    self.questions = response.Data;
                    resolve(response.Data);
                }, reject);
        });
    }

    /**
     * User Submit Question
     *
     * @param questionsetId
     * @returns {Promise<any>}
     */
    submitQuestion(request: AppUserQuestionSubmit): Promise<AppQuestionsResponse> {
        var self = this;
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/api/user/submit-question/`, { ...request })
                .subscribe((response: any) => {
                    self.questions = response.Data;
                    resolve(response.Data);
                }, reject);
        });
    }

    /**
     * User Submit Exam
     *
     * @param AppUserExamSubmit
     * @returns {Promise<any>}
     */
    submitExam(request: AppUserExamSubmit): Promise<UserTestResult> {
        var self = this;
        return new Promise((resolve, reject) => {
            this._httpClient.post<UserTestResult>(`${environment.apiURL}/api/user/submit-exam/`, { ...request })
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }

    /**
     * get User Mini Analysis
     *
     * @param AppReportRequest
     * @returns {Promise<UserTestResult>}
     */
    getUserMiniAnalysis(request: AppReportRequest): Promise<AppTestReportAnalysis> {
        var self = this;
        return new Promise((resolve, reject) => {
            this._httpClient.post<AppTestReportAnalysis>(`${environment.apiURL}/api/user/get-mini-analysis-report/`, { ...request })
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }

    /**
     * get User Detail Analysis
     *
     * @param AppReportRequest
     * @returns {Promise<UserTestResult>}
     */
    getUserDetailAnalysis(request: AppReportRequest): Promise<AppTestReportAnalysis> {
        var self = this;
        return new Promise((resolve, reject) => {
            this._httpClient.post<AppTestReportAnalysis>(`${environment.apiURL}/api/user/get-detail-analysis-report/`, { ...request })
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }

    /**
     *Set Questions Bookmark
     *
     * @param QuestionDetailMockTest
     * @returns {Promise<boolean>}
     */
    SetQuestionsBookmark(request: any): Promise<AppQuestionsResponse> {
        var self = this;

        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/api/user/create-question-bookmark/`, { ...request })
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }

    /**
     *Remove Questions Bookmark
     *
     * @param QuestionDetailMockTest
     * @returns {Promise<boolean>}
     */
    RemoveQuestionsBookmark(request: any): Promise<AppQuestionsResponse> {
        var self = this;

        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/api/user/delete-question-bookmark/`, { ...request })
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }

    /**
     *Report To Question Bug
     *
     * @param QuestionBugReport
     * @returns {Promise<boolean>}
     */
    ReportToQuestionBug(request: QuestionBugReport): Promise<boolean> {
        var self = this;

        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/api/user/create-question-bug-report/`, { ...request })
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }

    /**
     * Get Ranking Analysis Report
     *
     * @param AppReportRequest
     * @returns {Promise<UserTestResult>}
     */
    GetRankingAnalysisReportAsync(request: RankingFilter): Promise<UserRankStatistics> {
        var self = this;
        return new Promise((resolve, reject) => {
            this._httpClient.post<UserRankStatistics>(`${environment.apiURL}/api/user/get-ranking-analysis-report/`, { ...request })
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }
}
