import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


import { HipposDetails } from 'app/main/admin/hippo/hippo.model';
import { environment } from 'environments/environment';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class HipposDetailsService implements Resolve<any>
{
    onHipposDetailsChanged: BehaviorSubject<any>;

    hippo: HipposDetails[];
    user: any;
    selectedHipposDetails: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onHipposDetailsChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterHipposDetailsSnapshot} hippo
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, hippo: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                //this.getHipposDetailsForGrid(gridFilter)
            ]).then(
                ([]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get hippo by Id
     *
     * @returns {Promise<any>}
     */
    getHipposDetailsById(id): Promise<HipposDetails[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/fundamental/get-hippo-by-id/` + id)
                .subscribe((response: any) => {

                    this.hippo = response;

                    this.onHipposDetailsChanged.next(this.hippo);
                    resolve(this.hippo);
                }, reject);
        }
        );
    }
    /**
     * Get hippo
     *
     * @returns {Promise<any>}
     */
    getHipposDetails(): Promise<HipposDetails[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/fundamental/get-hippos-details`)
                .subscribe((response: any) => {

                    this.hippo = response;

                    this.onHipposDetailsChanged.next(this.hippo);
                    resolve(this.hippo);
                }, reject);
        }
        );
    }

    /**
     * Get hippo
     *
     * @returns {Promise<any>}
     */
    getHipposDetailsForGrid(_gridFilter: GridFilter, id: number): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/fundamental/get-grid-hippos-details/` + id, { ..._gridFilter });
    }

    /**
     * Update hippo
     *
     * @param hippo
     * @returns {Promise<any>}
     */
    createHipposDetails(hippo: HipposDetails): Promise<any> {
        return new Promise((resolve) => {

            this._httpClient.post(`${environment.apiURL}/fundamental/create-hippos-details/`, { ...hippo })
                .subscribe(response => {
                    if (response) {
                        this.onHipposDetailsChanged.next(this.hippo);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                    resolve(response);
                });
        });
    }

    /**
     * Update hippo
     *
     * @param hippo
     * @returns {Promise<any>}
     */
    updateHipposDetails(hippo: HipposDetails): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/fundamental/update-hippos-details/`, { ...hippo })
                .subscribe(response => {
                    if (response) {
                        this.onHipposDetailsChanged.next(this.hippo);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete hippo
     *
     * @param hippo
     */
    deleteHipposDetails(hippo): Promise<any> {
        return new Promise(() => {

            this._httpClient.delete(`${environment.apiURL}/fundamental/delete-hippos-details/` + hippo.HipposDetailID, {})
                .subscribe(response => {
                    if (response) {
                        this.onHipposDetailsChanged.next(this.hippo);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

}
