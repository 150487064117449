import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { unisunAnimations } from '@unisun/animations';

import { AnalyticsDashboardService } from 'app/main/admin/dashboard/analytics/analytics.service';
import { ReportService } from '../../report/report.service';

@Component({
    selector: 'analytics-dashboard',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class AnalyticsDashboardComponent implements OnInit {
    Overview: any;
    /**
     * Constructor
     *
     * @param {AnalyticsDashboardService} _analyticsDashboardService
     */
    constructor(
        private _analyticsDashboardService: AnalyticsDashboardService,
        private _reportService: ReportService,
    ) {
        // Register the custom chart.js plugin
        this.Courses = [
            { name: 'Regular Course 2019', code: '1' },
            { name: 'Regular Course 2020', code: '2' },
            { name: 'STS 2019', code: '3' },
            { name: 'STS 2020', code: '4' },
            { name: 'Dr. Arun Kumar Course', code: '5' },
            { name: 'JIPMER League 1.0', code: '6' },
            { name: 'JIPMER League 2.0', code: '7' },
            { name: 'Leaderboard Sep 2020 1.0', code: '8' },
            { name: 'Leaderboard Sep 2020 2.0', code: '9' },
            { name: 'Leaderboard Sep 2020 3.0', code: '10' }
        ];
        this.CourseDetails = [
            { code: '1', detail: [{ videos: 38, qbank: 38, hippos: 38, notes: 38, todo: 38, users: 38, livevideos: 38 }] },
            { code: '2', detail: [{ videos: 39, qbank: 39, hippos: 39, notes: 39, todo: 39, users: 39, livevideos: 39 }] },
            { code: '3', detail: [{ videos: 40, qbank: 40, hippos: 40, notes: 40, todo: 40, users: 40, livevideos: 40 }] },
            { code: '4', detail: [{ videos: 41, qbank: 41, hippos: 41, notes: 41, todo: 41, users: 40, livevideos: 40 }] },
            { code: '5', detail: [{ videos: 42, qbank: 42, hippos: 42, notes: 42, todo: 42, users: 40, livevideos: 40 }] },
            { code: '6', detail: [{ videos: 43, qbank: 43, hippos: 43, notes: 43, todo: 43, users: 40, livevideos: 40 }] },
            { code: '7', detail: [{ videos: 44, qbank: 44, hippos: 44, notes: 44, todo: 44, users: 40, livevideos: 40 }] },
            { code: '8', detail: [{ videos: 45, qbank: 45, hippos: 45, notes: 45, todo: 45, users: 40, livevideos: 40 }] },
            { code: '9', detail: [{ videos: 46, qbank: 46, hippos: 46, notes: 46, todo: 46, users: 40, livevideos: 40 }] },
            { code: '10', detail: [{ videos: 47, qbank: 47, hippos: 47, notes: 47, todo: 47, users: 40, livevideos: 40 }] },
        ]
        this.CourseDetail = this.CourseDetails.filter(function (c) { return c.code == '2' })[0];
        //console.log("CourseDetail:" + this.CourseDetail.detail[0].videos);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the widgets from the service
        this._reportService.getDashboardOverviewAnalysis()
            .subscribe((response: any) => {
                this.Overview = response.Data;
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    Courses: any[];
    selectCourse: string;
    CourseDetails: any;
    CourseDetail: any;
    brr: any;
    public CourseByUsers = [
        { "id": "1", "name": "Regular Course", "users": "55" },
        { "id": "2", "name": "STS", "users": "44" },
        { "id": "3", "name": "Dr. Arun Kumar Course", "users": "33" },
        { "id": "4", "name": "JIPMER League 1.0", "users": "22" },
    ]

    courseOnChange(e) {
   
        this.CourseDetail = this.CourseDetails[e.value.code];
        


    }
}

