import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Note, NoteDetails } from 'app/main/admin/note/note.model';
import { State } from 'app/main/admin/state/state.model';

@Component({
    selector: 'note-detail-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NotesDetailsFormDialogComponent {
    ckeConfig: any;
    action: string;
    note: NoteDetails;
    noteForm: FormGroup;
    dialogTitle: string;

    states: State[];
    /**
     * Constructor
     *
     * @param {MatDialogRef<NotesDetailsFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<NotesDetailsFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Note';
            this.note = _data.note;
        }
        else {
            this.dialogTitle = 'New Note';
            this.note = new NoteDetails({});
        }

        this.noteForm = this.createNoteForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create note form
     *
     * @returns {FormGroup}
     */
    createNoteForm(): FormGroup {
        return this._formBuilder.group({
            Title: [this.note.Title],
            SubTitle: [this.note.SubTitle],
            Content: [this.note.Content],
            Subject: [this.note.Subject]
        });
    }
}
