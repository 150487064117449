import { DataGuardService } from 'app/_guards/data.guard';
import { User } from 'app/_models';
import { Injectable } from '@angular/core';
import { navigation } from '../navigation/navigation';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, ActivatedRoute } from '@angular/router';
import { HelperService } from 'app/_helpers/helper.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
    private userData: User;
    isUserAuthenticated = false;
    private otp: any;
    filteredNavigation: any[];
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private _dataGuardService: DataGuardService,
        private _helperservice: HelperService
    ) {
        this.userData = JSON.parse(this._dataGuardService.getCookieData('lms-user'));
        this.otp = this._dataGuardService.getCookieData("OTP")
        if (this.userData.RoleID == 4) {
            navigation
        }

    }




    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.userData && Boolean(JSON.parse(this.otp)) || this.router.url.indexOf('chart-my-career/report') > 0) {
            // logged in so return true
            return true;
        } else {
            this._dataGuardService.removeCookieData('lms-user');
            this._dataGuardService.removeCookieData('OTP');
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['security/auth/login']);
        return false;

    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

       
        if (this.userData && Boolean(JSON.parse(this.otp))) {
            return true;
        } else {
            this._dataGuardService.removeCookieData('lms-user');
            this._dataGuardService.removeCookieData('OTP');
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['security/auth/login']);
        return false;
    }

  

}