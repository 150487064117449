import { take } from 'rxjs/operators';
import { VideoDetails } from 'app/main/admin/video/video.model';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { VirtualLearnVideos } from 'app/main/admin/virtual-learn/book.model';

@Component({
    selector: 'Video-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class VLPageVideoFormDialogComponent {
    ckeConfig: any;
    action: string;
    video: VirtualLearnVideos;
    videoForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<VLTranscriptionFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<VLPageVideoFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Video';
            this.video = _data.video;
        }
        else {
            this.dialogTitle = 'New Video';
            this.video = new VirtualLearnVideos({});
        }

        this.videoForm = this.createVideoForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create video form
     *
     * @returns {FormGroup}
     */
    createVideoForm(): FormGroup {
        return this._formBuilder.group({
            BookID: [this.video.BookID],
            PageID: [this.video.PageID],
            QueueID: [this.video.QueueID],
            VideoLength: [this.video.VideoLength],
            Title: [this.video.Title],
            Url: [this.video.Url],
            VideoDetailID: [this.video.VideoDetailID],
            VideoType: [this.video.VideoType],
        });
    }
}
