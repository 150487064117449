<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="assetForm">
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input type="number" name="ValidUpto" [(ngModel)]="asset.Plan.ValidUpto" formControlName="ValidUpto"
                        placeholder="Valid Upto(Months)" matInput required>
                </mat-form-field>
                <mat-slide-toggle class="p-4" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="asset.Plan.IsActive">
                    Active
                </mat-slide-toggle>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input type="number" name="ActualPrice" [(ngModel)]="asset.Plan.ActualPrice" formControlName="ActualPrice"
                        placeholder="Actual Price" matInput required>
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input type="number" name="Price" [(ngModel)]="asset.Plan.Price" formControlName="Price" placeholder="Price"
                        matInput required>
                </mat-form-field>
            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(asset)"
            class="save-button mat-accent-bg" [disabled]="assetForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',asset])"
            class="save-button mat-accent-bg" [disabled]="assetForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',asset])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>