import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";

import { FormBuilder, FormControl, FormGroup, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CKEDITOR_CONFIG } from "app/main/admin/comman-ckeditor-config";

import { Subject as subjectModel, Module as ModuleModel } from 'app/main/admin/common/subject/common.model';
import { Qbanktype } from "app/main/admin/common_Module/Model/commonModel";
import { CommonService } from "app/main/admin/common_Module/service/common.service";


@Component({
    selector: 'addSubject',
    templateUrl: './addSubject.component.html',
    styleUrls: ['./addSubject.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AddSubjectComponent implements OnInit {
    action: string;
    subject: subjectModel;
    qbanktype: Array<Qbanktype> = [];
    subjectForm: FormGroup;
    dialogTitle: string;
    ckeConfig: any;
    choiceType: any;
    scoreType: any;
    _Modules: Array<ModuleModel>;
    BackgroundUrl: any;
    /**
     * Constructor
     *
     * @param {MatDialogRef<AddSubjectComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */

    constructor(
        public matDialogRef: MatDialogRef<AddSubjectComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private commanservice: CommonService
    ) {
        this.ckeConfig = CKEDITOR_CONFIG;
        var self = this;
        // Set the defaults
        self.commanservice.getQbankType().subscribe((response: any) => {
            self.qbanktype = response.Data;
        })

        this.action = _data.action;
        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Subject';
            this.subject = _data.subject;
            this._Modules = _data.modules;

        }
        else {
            this.dialogTitle = 'New Subject';
            this.subject = _data.subject;
            this._Modules = _data.modules;
        }
        self.subjectForm = self.createSubjectForm();


    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create exam form
     *
     * @returns {FormGroup}
     */

    createSubjectForm(): FormGroup {
        return this._formBuilder.group({
            SubjectName: [this.subject.SubjectName],
            IsActive: [this.subject.IsActive],
            Description: [this.subject.Description],
            OrderNo: [this.subject.OrderNo],
            QBankType: [this.subject.QbankTypeID],
            Modules: this.buildModules(),

        });
    }

    buildModules() {
        const arr = this._Modules.map(module => {
            if (this.subject.Modules) {
                var isSelected = false;
                this.subject.Modules.forEach(_module => {
                    if (module.ModuleID == _module.ModuleID) {
                        isSelected = true;
                    }

                });
                return this._formBuilder.control(isSelected);
            }
            else {
                return this._formBuilder.control(false);
            }
        });
        return this._formBuilder.array(arr);
    }

    onselectFile(e: any) {

        const file = e.target.files[0];
        if (file) {
            const validImageTypes = ['image/jpeg', 'image/png']; // Valid image MIME types
            const fileType = file.type;
            if (!validImageTypes.includes(fileType)) {
                alert("Invalid image type. Please select a JPEG or PNG file.");
                //   this.plan.PostImage = '';
                //   this.tenantForm.get('logo').setValue('');
                return;
            }

            const fileSizeKB = file.size / 1024; // Convert file size to KB
            if (fileSizeKB > 700) {
                alert("File size exceeds the maximum limit of 700KB.");
                //   this.plan.PostImage = '';
                //   this.tenantForm.get('logo').setValue('');
                return;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event: any) => {
                    const base64String = event.target.result.split(',')[1];
                
                    this.BackgroundUrl = base64String;
                    console.log(this.BackgroundUrl);
                    if(this.BackgroundUrl.length>0){
                        this.subject.BackgroundUrl=this.BackgroundUrl;
                    }
                };
            }
        }
    }
    ngOnInit(): void {



        // sandeep kumar sahoo 02/01/2023 get module end

    }

    get Modules() {
        return this.subjectForm.get('Modules') as FormArray;;
    };
    // onChecked(obj: any, isChecked: boolean){


    //   }


}



