import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Video } from 'app/main/admin/video/video.model';
import { State } from 'app/main/admin/state/state.model';

@Component({
    selector     : 'video-form-dialog',
    templateUrl  : './form.component.html',
    styleUrls    : ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class VideoFormDialogComponent
{
    action: string;
    video: Video;
    videoForm: FormGroup;
    dialogTitle: string;

    states: State[];
    /**
     * Constructor
     *
     * @param {MatDialogRef<VideoFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<VideoFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.action = _data.action;
        this.states = _data.states;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Edit Video';
            this.video = _data.video;
        }
        else
        {
            this.dialogTitle = 'New Video';
            this.video = new Video({});
        }

        this.videoForm = this.createVideoForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create video form
     *
     * @returns {FormGroup}
     */
    createVideoForm(): FormGroup
    {
        return this._formBuilder.group({
            VideoID      : [this.video.VideoID],
            RewardsPoint      : [this.video.RewardsPoint],
            Title    : [this.video.Title],
            IsActive    : [this.video.IsActive]
        });
    }
}
