<div class="p-12 p-sm-24 " fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">


    <div class="mr-sm-48">
        <input matInput [matDatepicker]="startDatePicker" [(ngModel)]="StartDate" placeholder="Start Date" name="start">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
    </div>

    <div class="mr-sm-48">
        <input matInput [matDatepicker]="endDatePicker" [(ngModel)]="EndDate" placeholder="End Date" name="EndDate">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
    </div>

    <mat-label class="mat-yellow-bg m-4 text-boxed">{{title.toUpperCase()}}</mat-label>
    <mat-select placeholder="Select Course" *ngIf="title=='course'" [(ngModel)]="TypeID"
        (selectionChange)="OnChangeType(TypeID)" class="m-0" floatLabel="always">
        <mat-option *ngFor="let c of TypeList" [value]="c.CourseID">{{c.Title}}</mat-option>
    </mat-select>
    <mat-select placeholder="Select TestSeries" *ngIf="title=='testseries'" [(ngModel)]="TypeID"
        (selectionChange)="OnChangeType(TypeID)" class="m-0" floatLabel="always">
        <mat-option *ngFor="let c of TypeList" [value]="c.TestSeriesID">{{c.Title}}</mat-option>
    </mat-select>
    <mat-label class="mat-yellow-bg m-4 text-boxed">Exam</mat-label>
    <mat-select placeholder="Select Exam" [(ngModel)]="ExamID" class="m-0" floatLabel="always"
        (selectionChange)="OnChangeExam()">
        <mat-option *ngFor="let c of ExamList" [value]="c.ExamID">{{c.Title}}</mat-option>
    </mat-select>

</div>

<div class="p-12" *ngIf="TypeID">
    <div fxLayout="row" fxLayoutAlign="end end">
        <button class="m-4" mat-raised-button aria-label="Download" (click)="GetDownloadReport()">
            <mat-icon class="m-4">cloud_download</mat-icon>Download
        </button>
    </div>
</div>