
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Tag, Topic, Subject, Qbanktype, QbankcmbCode, CompetenecyLevel, LevelQuestion, Integration } from '../Model/commonModel';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    private _subjects: BehaviorSubject<Subject[] | null> = new BehaviorSubject(null);
    private _topics: BehaviorSubject<Topic[] | null> = new BehaviorSubject(null);
    private _tags: BehaviorSubject<Tag[] | null> = new BehaviorSubject(null);
    private qbanktype: BehaviorSubject<Qbanktype[] | null> = new BehaviorSubject(null);
    private qbankcmbcode: BehaviorSubject<QbankcmbCode[] | null> = new BehaviorSubject(null);
    private _competenecyLevel: BehaviorSubject<CompetenecyLevel[] | null> = new BehaviorSubject(null);
    private _levelofquestion: BehaviorSubject<LevelQuestion[] | null> = new BehaviorSubject(null);
    private _integration: BehaviorSubject<Integration[] | null> = new BehaviorSubject(null);
    /**
    * Constructor
    */
    constructor(private _httpClient: HttpClient,
        @Inject(LOCALE_ID) public locale: string) {

    }

    get _subjects$(): Observable<Subject[]> {
        return this._subjects.asObservable();
    }
    get _topics$(): Observable<Topic[]> {
        return this._topics.asObservable();
    }

    get _tags$(): Observable<Tag[]> {
        return this._tags.asObservable();
    }
    get _qbanktype$(): Observable<Qbanktype[]> {
        return this.qbanktype.asObservable();
    }
    get _qbankcmbcode$(): Observable<QbankcmbCode[]> {
        return this.qbankcmbcode.asObservable();
    }
    get _competenecyLevel$(): Observable<CompetenecyLevel[]> {
        return this._competenecyLevel.asObservable();
    }
    get _levelofquestion$(): Observable<LevelQuestion[]> {
        return this._levelofquestion.asObservable();
    }
    get _integration$(): Observable<Integration[]> {
        return this._integration.asObservable();
    }
//getsubjects qbank
    getSubjects(modulename: string): Observable<Subject[]> {
        return this._httpClient.get<Subject[]>(`${environment.apiURL}/adr/get-subjects/${modulename}`).pipe(
            tap((response: any) => {
                this._subjects.next(response);
            })
        );
    }
    //
    getSubjectsbyQbanktypeid(modulename: string,QBANKtypeID:number,userid:number): Observable<Subject[]> {
        return this._httpClient.get<Subject[]>(`${environment.apiURL}/adr/get-subjects/${modulename}/${QBANKtypeID}/${userid}`).pipe(
            tap((response: any) => {
                this._subjects.next(response);
            })
        );
    }
    //topic
    getTopics(topicID: number, modulename: string): Observable<Topic[]> {
        return this._httpClient.get<Topic[]>(`${environment.apiURL}/adr/get-topics/${topicID}/${modulename}`)
            .pipe(
                tap((response: any) => {
                    var data = response.Data;

                    this._topics.next(data);


                })
            );
    }
//subject
    getsubjectTopics(subjectId): Promise<Topic[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/adr/get-topics/${subjectId}/Custom QBank`)
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }
//tags
    getTags(modulename: string): Observable<Tag[]> {
        return this._httpClient.get<Tag[]>(`${environment.apiURL}/adr/get-tags/${modulename}`).pipe(
            tap((response: any) => {
                this._tags.next(response);


            })
        );
    }
    //Qbank type
    getQbankType(): Observable<Qbanktype[]> {
        return this._httpClient.get<Qbanktype[]>(`${environment.apiURL}/adr/get-qbantypes`).pipe(
            tap((response: any) => {
                this.qbanktype.next(response.Data);
                // console.log(response);


            })
        );
    }
    //Qbank type cmvb code
    getQbankCmbCode(Topicid:number): Observable<QbankcmbCode[]> {
        return this._httpClient.get<QbankcmbCode[]>(`${environment.apiURL}/adr/get-qbankcbmecodes/${Topicid}`).pipe(
            tap((response: any) => {
                this.qbankcmbcode.next(response.Data);
                // console.log(response);


            })
        );
    }

//CompetenecyLevel
getCompetenecylevel(): Observable<CompetenecyLevel[]> {
    return this._httpClient.get<CompetenecyLevel[]>(`${environment.apiURL}/adr/get-qbanklevels`).pipe(
        tap((response: any) => {
            this._competenecyLevel.next(response.Data);
            // console.log(response);


        })
    );
}
//LevelOfQuestion
getLevelOfQuestion(): Observable<LevelQuestion[]> {
    return this._httpClient.get<LevelQuestion[]>(`${environment.apiURL}/adr/get-qbanklevelquestions`).pipe(
        tap((response: any) => {
            this._levelofquestion.next(response.Data);



        })
    );
}
//Horizontal and vertical Integration
getIntegration(integrationtype:number,subjectid:number): Observable<Integration[]> {
    return this._httpClient.get<Integration[]>(`${environment.apiURL}/adr/get-qbank-integration/${integrationtype}/${subjectid}`).pipe(
        tap((response: any) => {
            this._integration.next(response.Data);
       


        })
    );
}

}
