import { User } from 'app/_models/index';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { UnisunUtils } from '@unisun/utils';

import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ARFilterUserPermission } from './user-permission.model';

@Injectable()
export class ARFilterUserPermissionService implements Resolve<any>
{
    onARFilterUserPermissionChanged: BehaviorSubject<any>;

    user: User[];

    selectedARFilterUserPermission: string[] = [];

    searchText: string;
    filterBy: string;
    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onARFilterUserPermissionChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} user
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, user: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
        });
    }


    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getARFilterUserPermissionForGrid(_gridFilter: GridFilter, courseId: any): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/virtual-learn/ar-filter/exam/get-grid-ar-filter-users/` + courseId, { ..._gridFilter });
    }

    /**
     * Update user
     *
     * @param user
     * @returns {Promise<any>}
     */
    createARFilterUserPermission(user: any): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/virtual-learn/ar-filter/exam/map-user/`, { ...user })
                .subscribe(response => {
                    if (response) {
                        this.onARFilterUserPermissionChanged.next(this.user);
                        this.openSnackBar("Successfully added.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }


    /**
     * Delete user
     *
     * @param user
     */
    deleteARFilterUserPermission(user): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/virtual-learn/ar-filter/exam/map-user/`, { ...user })
                .subscribe(response => {
                    if (response) {
                        this.onARFilterUserPermissionChanged.next(this.user);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

}
