import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';

import { UserManagementComponent } from 'app/main/admin/users/users.component';
import { UserManagementService } from 'app/main/admin/users/users.service';
import { UserManagementListComponent } from 'app/main/admin/users/users-list/list.component';
import { UserManagementFormDialogComponent } from 'app/main/admin/users/users-form/form.component';
import { CountryService } from '../country/country.service';
import { StudentCategoryService } from '../student-category/student-category.service';
import { StateService } from '../state/state.service';
import { CollegeService } from '../college/college.service';
import { AuthService } from 'app/main/authentication/auth.service';
import { MomentModule } from 'ngx-moment';
import { UserManagementCourseDialogComponent } from './users-course-mapped/form.component';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { AcademyCoursesService } from 'app/main/apps/academy/courses.service';

const routes: Routes = [
    {
        path     : 'collections/users',
        component: UserManagementComponent,
        resolve  : {
            contact: UserManagementService
        }
    }
];

@NgModule({
    declarations   : [
        UserManagementComponent,
        UserManagementListComponent,
        UserManagementFormDialogComponent,
        UserManagementCourseDialogComponent
    ],
    imports        : [
        RouterModule.forChild(routes),
        MomentModule.forRoot({ relativeTimeThresholdOptions: { 'm': 59 } }), 

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatListModule,
        MatDividerModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule
    ],
    providers      : [
        UserManagementService,
        CountryService,
        StudentCategoryService,
        StateService,
        CollegeService,
        AuthService,
        AcademyCoursesService
    ],
    entryComponents: [
        UserManagementFormDialogComponent
    ]
})
export class UserManagementModule
{
}
