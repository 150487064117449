import { UnisunUtils } from '@unisun/utils';

export class Todo {
    TodoID: number = 0;
    RewardsPoint: number = 0;
    Title: string;
    IsActive: boolean;
    /**
     * Constructor
     *
     * @param todo
     */
    constructor(value) {
        {
            this.TodoID = value.TodoID || 0;
            this.RewardsPoint = value.RewardsPoint || 0;
            this.Title = value.Title || '';
            this.IsActive = value.IsActive || false
        }
    }
}

export class TodoDetails {
    TodoID: number = 0;
    TodoDetailID: number = 0;
    Title: string;
    QueueID: number;
    Subject: string;
    Chapter: string;
    Note: string;
    IsStarred: boolean;
    IsImportent: boolean;

    /**
     * Constructor
     *
     * @param TodoDetails
     */
    constructor(value) {
        {
            this.TodoID = value.TodoID || 0;
            this.TodoDetailID = value.TodoDetailID || 0;
            this.Title = value.Title || '';
            this.QueueID = value.QueueID || 0;
            this.Subject = value.Subject || '';
            this.Note = value.Note || '';
            this.IsStarred = value.IsStarred || false;
            this.IsImportent = value.IsImportent || false;
            this.Chapter = value.Chapter;
        }
    }
}