import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';

import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';
import { CKEditorModule } from 'ng2-ckeditor';

import { TodoComponent } from 'app/main/admin/todo/todo.component';
import { TodoService } from 'app/main/admin/todo/todo.service';
import { TodoListComponent } from 'app/main/admin/todo/todo-list/list.component';
import { TodoFormDialogComponent } from 'app/main/admin/todo/todo-form/form.component';
import { TodosDetailsFormDialogComponent } from './todo-detail-form/form.component';
import { TodosDetailsComponent } from './todo-detail/list.component';
import { TodosDetailsService } from './todo-detail.service';

const routes: Routes = [
    {
        path: 'collections/todo',
        component: TodoComponent,
        resolve: {
            service: TodoService
        }
    },
    {
        path: 'collections/todo/:id',
        component: TodosDetailsComponent,
        resolve: {
            service: TodosDetailsService
        }
    }
];

@NgModule({
    declarations: [
        TodoComponent,
        TodoListComponent,
        TodoFormDialogComponent,
        TodosDetailsFormDialogComponent,
        TodosDetailsComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSlideToggleModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule,

        CKEditorModule
    ],
    providers: [
        TodoService,
        TodosDetailsService
    ],
    entryComponents: [
        TodoFormDialogComponent,
        TodosDetailsFormDialogComponent
    ]
})
export class TodosModule {
}
