import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { TodosDetailsService } from 'app/main/admin/todo/todo-detail.service';
import { Todo, TodoDetails } from '../todo.model';
import { GridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute } from '@angular/router';
import { SitePreference } from 'app/config';

import { TodosDetailsFormDialogComponent } from '../todo-detail-form/form.component';
import { DataGuardService } from 'app/_guards/data.guard';
import { XlsxToJsonService } from 'app/_services';
import { DomSanitizer } from '@angular/platform-browser';
import { TodoExcelField } from '../../qbank/qbank.model';

@Component({
    selector: 'todo-detail',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class TodosDetailsComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;
    TodosID: number;
    todo: any;
    paginationData: any;
    todos: any[];

    dataSource: TodosDetailsDataSource;
    displayedColumns = ['QueueID', 'Title', 'Subject', 'buttons'];
    selectedTodosDetails: any[];
    checkboxes: {};
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;


    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    hasSelectedTodo: boolean;
    searchInput: FormControl;

    // Private

    /**
     * Constructor
     *
     * @param {TodosDetailsService} _todoService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _todoService: TodosDetailsService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _dataGuardService: DataGuardService,
        private sanitizer: DomSanitizer
    ) {
        var self = this;
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.route.params.subscribe(function (parram) {

            self.TodosID = self._dataGuardService.valueDecryption(parram.id);;
        });
    }

    ngAfterViewInit() {

    }

    loadPage() {
        this._todoService.onTodosDetailsChanged.next(this.todo);
    }


    getNext(event: PageEvent) {
        this._todoService.onTodosDetailsChanged.next(this.todo);

    }


    onSortData(sort: Sort) {

        this._todoService.onTodosDetailsChanged.next(this.todo);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.dataSource = new TodosDetailsDataSource(this._todoService, this.TodosID);

        this._todoService.onTodosDetailsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.todo = search;
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == null ? "QueueID" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc'
                };
                this.dataSource.loadData(gridFilter);
            });

        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._todoService.onTodosDetailsChanged.next(searchText);
            });
    }

    /**
    * New todo
    */
    newTodoDetail(): void {

        this.dialogRef = this._matDialog.open(TodosDetailsFormDialogComponent, {
            panelClass: 'todo-detail-form-dialog',
            data: {
                action: 'new'
            }
        });
        this.newTodoDetailCallback();

    }

    newTodoDetailCallback() {
        this.dialogRef.afterClosed()
            .subscribe((response: TodoDetails) => {
                if (!response) {
                    return;
                }
                response.TodoID = this.TodosID;
                this._todoService.createTodosDetails(response);
            });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Edit todo
     *
     * @param todo
     */
    editTodosDetails(todo): void {

        this.dialogRef = this._matDialog.open(TodosDetailsFormDialogComponent, {
            panelClass: 'todo-detail-form-dialog',
            data: {
                todo: todo,
                action: 'edit'
            }
        });
        this.editTodosDetailsCallback(todo);

    }

    editTodosDetailsCallback(todo: TodoDetails) {
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: TodoDetails = response[1];
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':

                        this._todoService.updateTodosDetails(formData);

                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deleteTodosDetails(todo);

                        break;
                }
            });
    }

    /**
     * Delete TodosDetails
     */
    deleteTodosDetails(todo): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._todoService.deleteTodosDetails(todo);
            }
            this.confirmDialogRef = null;
        });

    }

    @Input() mode
    @Input() names
    @Input() url
    @Input() method
    @Input() multiple
    @Input() disabled
    @Input() accept
    @Input() maxFileSize
    @Input() auto = true
    @Input() withCredentials
    @Input() invalidFileSizeMessageSummary
    @Input() invalidFileSizeMessageDetail
    @Input() invalidFileTypeMessageSummary
    @Input() invalidFileTypeMessageDetail
    @Input() previewWidth
    @Input() chooseLabel = 'Choose'
    @Input() uploadLabel = 'Upload'
    @Input() cancelLabel = 'Cance'
    @Input() customUpload
    @Input() showUploadButton
    @Input() showCancelButton


    @Input() dataUriPrefix
    @Input() deleteButtonLabel
    @Input() deleteButtonIcon = 'close'
    @Input() showUploadInfo

    /**
     *
     */


    @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef

    inputFileName: string

    @Input() files: File[] = []


    onClick(event) {
        if (this.fileUpload)
            this.fileUpload.nativeElement.click()
    }

    onInput(event) {

    }

    onFileSelected(event) {
        let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        console.log('event::::::', event)
        for (let i = 0; i < files.length; i++) {
            let file = files[i];


            if (this.validate(file)) {

                file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));

                if (!this.isMultiple()) {
                    this.files = []
                }
                this.files.push(files[i]);

                this.handleFile(event);
            }

        }
    }

    public result: any;
    private xlsxToJsonService: XlsxToJsonService = new XlsxToJsonService();
    removeFile(event, file) {
        let ix
        if (this.files && -1 !== (ix = this.files.indexOf(file))) {
            this.files.splice(ix, 1)
            this.clearInputElement()
        }
    }

    validate(file: File) {
        for (const f of this.files) {
            if (f.name === file.name
                && f.lastModified === file.lastModified
                && f.size === f.size
                && f.type === f.type
            ) {
                return false
            }
        }
        return true
    }

    clearInputElement() {
        this.fileUpload.nativeElement.value = ''
    }


    isMultiple(): boolean {
        return this.multiple
    }

    handleFile(event) {
        var self = this;
        let file = event.target.files[0];
        try {
            this.xlsxToJsonService.processFileToJson({}, file).subscribe(data => {
                this.result = data['sheets'].Sheet1;
                self.todos = [];
                self.result.forEach((row: TodoExcelField) => {
                    let r = {
                        TodoID: self.TodosID,
                        Title: row.Name,
                        Subject: row.Subject,
                        Chapter : row.Chapter
                    };
                    self.todos.push(r);
                });
            });
        }
        catch (e) {
            console.log(e);
        }
        finally {
        }

    }



    OnBulkUpdate() {
        var self = this;
        let request = self._todoService.BulkUpload(this.todos, this.TodosID);
        request.then(value => {
            self._todoService.openSnackBar("Successfully added", "close");
            self.files = [];
            self.todos = [];

        });
        request.catch(error => {
            self._todoService.openSnackBar("Oops!! " + error, "close");
        });
    }

}

export class TodosDetailsDataSource extends DataSource<TodoDetails>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<TodoDetails>;
    /**
     * Constructor
     *
     * @param {TodosDetailsService} _todoService
     */
    constructor(
        private _todoService: TodosDetailsService,
        private _id: number
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._todoService.onTodosDetailsChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) {
        var self = this;

        this._todoService.getTodosDetailsForGrid(gridFilter, this._id)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);


                self.paginationData = {
                    count: response.Count || 0,
                    pageNumber: response.CurrentFilter == undefined ? 0 : response.CurrentFilter.PageNumber
                };


            });
    }
}
