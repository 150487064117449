import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { UserManagementService } from 'app/main/admin/users/users.service';
import { UserManagementFormDialogComponent } from 'app/main/admin/users/users-form/form.component';
import { UserManagement } from '../users.model';
import { GridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute } from '@angular/router';
import { SitePreference } from 'app/config';
import { AuthService } from 'app/main/authentication/auth.service';
import { User } from 'app/_models';
import { ExcelExportService } from 'app/_services/excel.export.service';
import { UserManagementCourseDialogComponent } from '../users-course-mapped/form.component';

@Component({
    selector: 'usermanagement-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class UserManagementListComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;

    usermanagement: any;

    paginationData: any;

    dataSource: UserManagementDataSource;
    displayedColumns = ['UserName', 'Email', 'Mobile', 'IsActive', 'State', 'College', 'CreatedOn', 'buttons'];
    selectedUserManagement: any[];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;


    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UserManagementService} _usermanagementService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _usermanagementService: UserManagementService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _authService: AuthService,
        private _exportExcelService: ExcelExportService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit() {

    }

    loadPage() {
        this._usermanagementService.onUserManagementChanged.next(this.usermanagement);
    }


    getNext(event: PageEvent) {
        this._usermanagementService.onUserManagementChanged.next(this.usermanagement);

    }


    onSortData(sort: Sort) {

        this._usermanagementService.onUserManagementChanged.next(this.usermanagement);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.dataSource = new UserManagementDataSource(this._usermanagementService);

        this._usermanagementService.onUserManagementChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.usermanagement = search;
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == null ? "UserManagement" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc'
                };
                this.dataSource.loadData(gridFilter);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
        * Edit User
        */
    editUser(user: User): void {
        var self = this;
        this.dialogRef = this._matDialog.open(UserManagementFormDialogComponent, {
            panelClass: 'user-form-dialog',
            data: {
                action: 'edit',
                user: user
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: User) => {
                if (!response) {
                    return;
                }

                this._authService.updateUser(response).then(function (data) {
                    self._usermanagementService.onUserManagementChanged.next('');
                });
            });
    }

    /**
   * Courses by  User
   */
    courseByUser(user: User): void {
        var self = this;
        this.dialogRef = this._matDialog.open(UserManagementCourseDialogComponent, {
            panelClass: 'user-course-dialog',
            data: {
                action: 'view',
                user: user
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: User) => {
                if (!response) {
                    return;
                }
 
            });
    }

}

export class UserManagementDataSource extends DataSource<UserManagement>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<UserManagement>;
    /**
     * Constructor
     *
     * @param {UserManagementService} _usermanagementService
     */
    constructor(
        private _usermanagementService: UserManagementService
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._usermanagementService.onUserManagementChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) {
        var self = this;

        this._usermanagementService.getUserManagementForGrid(gridFilter)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);

                self.paginationData = {
                    count: response.Count,
                    pageNumber: response.CurrentFilter.PageNumber
                };


            });
    }
}
