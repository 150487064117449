import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PromotionalVideo } from '../promotional-video.model';
import{VideoResolution} from '../../video/video.model';

@Component({
    selector     : 'promotional-video-form-dialog',
    templateUrl  : './form.component.html',
    styleUrls    : ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class PromotionalVideoFormDialogComponent
{
    action: string;
    promotionalvideo: PromotionalVideo;
    promotionalvideoForm: FormGroup;
    dialogTitle: string;
    resolutions:any[]=[{resolution:"360",disabled:false},
    {resolution:"540",disabled:false},
    {resolution:"720",disabled:false},
    {resolution:"480",disabled:false}];
    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<PromotionalVideoFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.action = _data.action;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Edit Video';
            this.promotionalvideo = _data.promotionalvideo;
        }
        else
        {
            this.dialogTitle = 'New Video';
            this.promotionalvideo = new PromotionalVideo({});
        }

        this.promotionalvideoForm = this.createPromotionalVideoForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create promotional-video form
     *
     * @returns {FormGroup}
     */
    createPromotionalVideoForm(): FormGroup
    {
        this.manageResolutions();
        return this._formBuilder.group({
            PromoID      : [this.promotionalvideo.PromoID],
            QueueID      : [this.promotionalvideo.QueueID],
            Title    : [this.promotionalvideo.Title],
            Description    : [this.promotionalvideo.Description] ,
            Url    : [this.promotionalvideo.Url], 
            IsActive    : [this.promotionalvideo.IsActive],
            VideoResolutionCollection:[this.promotionalvideo.VideoResolutionCollection]
        });
    }

    manageResolutions():void{
        if(this.promotionalvideo.VideoResolutionCollection!=null){
            this.promotionalvideo.VideoResolutionCollection.forEach(_resolutionObj => {
                this.resolutions.forEach(_resolution => {
                    if(_resolutionObj.ResolutionType==_resolution.resolution){
                        _resolution.disabled=true;
                          return;
                    }
                })
            });
        }
        else
        {
            this.promotionalvideo.VideoResolutionCollection=[];
        }
    }
    deleteResolutions(index:number):void{
        if (index > -1) { // only splice array when item is found
            this.promotionalvideo.VideoResolutionCollection.splice(index, 1); // 2nd parameter means remove one item only
          }
          this.manageResolutions();
    }
    addResolution():void{
        if(this.promotionalvideo.VideoResolutionCollection==null){
            this.promotionalvideo.VideoResolutionCollection=[];
        }
            var obj=new VideoResolution();
           this.promotionalvideo.VideoResolutionCollection.push(obj);
    }
}
