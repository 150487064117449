import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { unisunAnimations } from '@unisun/animations';
import { UnisunSidebarService } from '@unisun/components/sidebar/sidebar.service';
import { AddSubjectComponent } from './addSubject/addSubject.component';
import { CommonService } from './common.service';
import { Subject as subjectModel,Module as ModuleModel} from './common.model';
import { ActivatedRoute } from '@angular/router';
import { DataGuardService } from 'app/_guards';



 @Component({
   selector: 'subject',
   templateUrl: './subject.component.html',
   styleUrls: ['./subject.component.scss'],
   encapsulation: ViewEncapsulation.None,
   animations: unisunAnimations
 })

export class SubjectComponent implements OnInit {
    Modules:Array<ModuleModel>;
    dialogRef:any;
    searchInput: FormControl;
    Qbanktypeid:number;

     // Private
     private _unsubscribeAll: Subject<any>;


    /**
     * Constructor
     *
     
     * @param {UnisunSidebarService} _unisunSidebarService
     * @param {MatDialog} _matDialog
     */

    constructor(
        private _unisunSidebarService: UnisunSidebarService,
        private _matDialog: MatDialog,
        private _commonService: CommonService,
        private route: ActivatedRoute,
      
        private _dataGuardService: DataGuardService) {
            // Set the defaults
            var self=this;
            this.searchInput = new FormControl('');
            // Set the private defaults
            this._unsubscribeAll = new Subject();
         
            // if(self.Qbanktypeid==null){
            //     self.Qbanktypeid=0;
                
            // }
        }

        /**
     * On init
     */

    ngOnInit(): void {
        
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._commonService.onSubjectChanged.next(searchText);
            });
        
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    addSubject(): void {
         var self =this;
        var subject = new subjectModel({});
         subject.QbankTypeID=this.Qbanktypeid;
         subject.SubjectID=0;
        // sandeep kumar sahoo 02/01/2023 get module start 
         this._commonService.getModules().then(modules => {
           self.Modules=modules;
           self.dialogRef = self._matDialog.open(AddSubjectComponent, {
            panelClass: 'subject-form-dialog',
            disableClose: true,
            data: {
                subject: subject,
                modules:modules,
                action: 'new'
            }
        });
         self.newSubjectCallback();
         });
     }

    newSubjectCallback() {
        
        this.dialogRef.afterClosed()
            .subscribe(result => {
                if (!result) {
                    return;
                }
                var subject;
                if(result) {
                   subject=result.subject;
                   let modules=[];
                   var i=0;
                   result.Modules.forEach(module => {
                    if(module==true){
                        modules.push(this.Modules[i]);
                    }
                    i++;
                   });
                    subject.Modules=modules;
                    console.log(subject);
                     this._commonService.createsubject(subject);
                   
               }
                
                
              
            });
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._unisunSidebarService.getSidebar(name).toggleOpen();
    }

    
    
}