<div id="myranking" class="page-layout carded left-sidebar inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg mat-accent-bg"></div>
    <!-- / TOP BACKGROUND -->
    <unisun-sidebar class="sidebar" style="top: 136px;height: 500px;" name="carded-left-sidebar-2" position="left"
        lockedOpen="gt-md" unisunPerfectScrollbar>
        <div class="p-24 grey-200-bg" style="top: 136px;height: 800px;" unisunPerfectScrollbar>
            <form [formGroup]="notificationForm">
                <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline">
                        <mat-label>Message Type</mat-label>
                        <mat-select required placeholder="Message Type" [(ngModel)]="notification.MessageType" style="width:150px"
                            class="m-0" (selectionChange)="OnChangeMessageType($event.value)" formControlName="MessageType" floatLabel="always">
                            <mat-option value="1">Push notification</mat-option>
                            <mat-option value="2">Email</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline">
                        <mat-label>Sender Type"</mat-label>
                        <mat-select required placeholder="Sender Type" [(ngModel)]="notification.SenderType" style="width:150px"
                            class="m-0" (selectionChange)="OnChangeRecieverType($event.value)" floatLabel="always" formControlName="SenderType">
                            <mat-option value="1">General</mat-option>
                            <mat-option value="2">Specific</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div *ngIf="SenderType==2 && title=='course'" class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline">
                        <mat-label>Course</mat-label>
                        <mat-select required placeholder="Course" [(ngModel)]="notification.TypeID" formControlName="TypeID" style="width:150px" class="m-0"
                           floatLabel="float">
                            <mat-option *ngFor="let c of TypeList" [value]="c.CourseID">{{c.Title}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div *ngIf="SenderType==2 && title=='testseries'" class="mb-24" fxLayout="row"
                    fxLayoutAlign="start start">
                    <mat-form-field appearance="outline">
                        <mat-label>TestSeries</mat-label>
                        <mat-select required placeholder="TestSeries" *ngIf="title=='testseries'" [(ngModel)]="notification.TypeID" formControlName="TypeID"
                            style="width:150px" class="m-0" floatLabel="float">
                            <mat-option *ngFor="let c of TypeList" [value]="c.TestSeriesID">{{c.Title}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </form>
        </div>

    </unisun-sidebar>

    <!-- CENTER -->
    <div class="center" unisunPerfectScrollbar>

        <!-- CONTENT HEADER -->
        <div class="header mat-accent-bg p-24" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0">Messanger</h2>

        </div>

        <!-- / CONTENT HEADER -->



        <!-- CONTENT -->
        <div class="content grey-100-bg" unisunPerfectScrollbar>

            <div class="content mat-elevation-z4" unisunPerfectScrollbar>
                <div class="content-card">
                    <div class="content p-24" unisunPerfectScrollbar>
                        <form [formGroup]="notificationForm">
                            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                                <mat-form-field fxFlex>

                                    <input name="Subject" [(ngModel)]="notification.Subject" formControlName="Subject"
                                        placeholder="Subject" matInput required>
                                </mat-form-field>
                            </div>
                            <div class="mb-24 " fxLayout="row"  fxLayoutAlign="start start" *ngIf="MessageType==2">
                                <ckeditor [config]="ckeConfig" [(ngModel)]="notification.Message" placeholder="Type your message" formControlName="Message"
                                    required>
                                </ckeditor>
                            </div>
                            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start" *ngIf="MessageType==1">
                                <mat-form-field fxFlex>
                                    <textarea name="Message" [(ngModel)]="notification.Message" rows="8" cols="40" formControlName="Message"
                                        placeholder="Message" matInput required></textarea>
                                </mat-form-field>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            <!-- / CONTENT -->
        </div>
        <!-- / CENTER -->
        <div class="footer below-fixed">
            <div class="toolbar">
                <button class="m-24" class="save-button mat-primary-bg" style="height: 40px;"
                    [disabled]="notificationForm.invalid" mat-raised-button
                    aria-label="Download" (click)="createMessager()">
                    SENT NOW
                </button>
            </div>
        </div>
    </div>
</div>