<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="qbankForm">
            <!-- <div fxFlexFill fxLayout="row" fxLayout.gt-xs="row">
                <mat-form-field class="mr-sm-24" fxFlex>
                    <input name="QueueID" formControlName="QueueID" placeholder="Queue ID" matInput required
                        [(ngModel)]="qbank.QueueID">
                </mat-form-field>
            </div> -->
            <div fxFlexFill fxLayout="row" fxLayout.gt-xs="row">
                <mat-form-field class="mr-sm-24" fxFlex>
                    <input name="ChoiceText" formControlName="ChoiceText" placeholder="Choice Text" matInput required
                        [(ngModel)]="qbank.ChoiceText">
                </mat-form-field>
            </div>
            <div fxFlexFill fxLayout="row" fxLayout.gt-xs="row" *ngIf="questionType!=_commonType.QuestionType.Various">
                <mat-slide-toggle formControlName="IsCorrect" [(ngModel)]="qbank.IsCorrect">
                    Is Correct
                </mat-slide-toggle>

            </div>
            <div fxFlexFill fxLayout="row" fxLayout.gt-xs="row" *ngIf="questionType==_commonType.QuestionType.Various">
                <mat-form-field class="mr-sm-24" fxFlex>
                    <mat-select placeholder="Select Answer" [(ngModel)]="qbank.ValidAdditionalValue" required
                        formControlName="ValidAdditionalValue">
                        <mat-option *ngFor="let item of additionalChoices" [value]="item.ChoiceText">
                            {{item.ChoiceText}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(['save',qbank])"
            class="save-button mat-accent-bg" [disabled]="qbankForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['update',qbank])"
            class="save-button mat-accent-bg" [disabled]="qbankForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',qbank])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>