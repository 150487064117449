import { UnisunUtils } from '@unisun/utils';

export class Notification {
    NotificationID: string;
    NotificationType: number;
    Subject: string;
    Message: string;
    Type: number;
    TypeID: number;
    AssetType: number;
    AssetTypeID: number;
    MessageType:number;
    SenderType:number;


    /**
     * Constructor
     *
     * @param notification
     */
    constructor(notification) {
        {
            this.NotificationID = notification.NotificationID; //|| UnisunUtils.generateGUID();
            this.NotificationType = notification.NotificationType || 1;
            this.Subject = notification.Subject || '';
            this.Message = notification.Message || '';
            this.Type = notification.Type || 0;
            this.TypeID = notification.TypeID || 0;
            this.AssetType = notification.AssetType || 0;
            this.AssetTypeID = notification.AssetTypeID ||0;
            this.MessageType = notification.MessageType || 0;
            this.SenderType = notification.SenderType ||0;
        }
    }
}
