import { UnisunUtils } from '@unisun/utils';

export class Country {
    CountryID: string;
    Country: string;
    IsState: boolean;

    /**
     * Constructor
     *
     * @param country
     */
    constructor(country) {
        {
            this.CountryID = country.CountryID; //|| UnisunUtils.generateGUID();
            this.Country = country.Country || '';
            this.IsState = country.IsState || false;
        }
    }
}
