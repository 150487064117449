import { UnisunUtils } from '@unisun/utils';

export class StudentCategory {
    CategoryID: string;
    Category: string;

    /**
     * Constructor
     *
     * @param Category
     */
    constructor(category) {
        {
            this.CategoryID = category.CategoryID; //|| UnisunUtils.generateGUID();
            this.Category = category.Category || '';
        }
    }
}
