import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation , Input} from "@angular/core";
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from "../../common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DataGuardService } from "app/_guards";
import { levelofQuestions as levelofQuestionsModel } from "../../common.model";
import { GridFilter } from "app/_models/GridFilter";
import { SitePreference } from "app/config";
import { AddLevelOfQuestionComponent } from "../addlevelofQuestion/addlevelofQuestion.component";
import {Location} from '@angular/common';


@Component({
    selector: 'levelofQuestion',
    templateUrl: './levelofQuestionList.component.html',
    styleUrls: ['./levelofQuestionList.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})

export class LevelOfQuestionComponent implements OnInit, OnDestroy  {
    @ViewChild('dialogContent', { static: true })
    levelofquestions:any;
    dialogContent: TemplateRef<any>;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;
    dialogRef:any;
    _sitePreference: any = SitePreference;
    paginationData: any;
    dataSource: levelofQuestionsDetailsDataSource;
    displayedColumns = ['Title', 'IsActive', 'buttons'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
   
    
    // Private
    private _unsubscribeAll: Subject<any>;
    searchInput: FormControl;
    constructor(
        private _commonService: CommonService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _router: Router,
        private _dataGuardService: DataGuardService,
        private _location: Location
        
    ) { 
        var self = this;
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaultsSubject
        this._unsubscribeAll = new Subject();

     }
    loadPage() {
        this._commonService.onlevelofQuestionsChanged.next(this.levelofquestions);
    }

    ngOnInit(): void {
        var self=this;
        this.dataSource = new levelofQuestionsDetailsDataSource(this._commonService);
     
        this.searchInput.valueChanges
        .pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(300),
            distinctUntilChanged()
        )
        .subscribe(searchText => {
            this._commonService.onlevelofQuestionsChanged.next(searchText);
        });
    
        
        this._commonService.onlevelofQuestionsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort?.active == null ? "LevelID" : "LevelID" ,
                    SortOrder: this.sort?.direction == 'desc' ? this.sort.direction : 'desc',
                  };
                this.dataSource.loadData(gridFilter);

            });
     }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    getNext(event: PageEvent) {
        this._commonService.onlevelofQuestionsChanged.next(this.levelofquestions);

    }

    onSortData(sort: Sort) {

        this._commonService.onlevelofQuestionsChanged.next(this.levelofquestions);
    }

    addSubTopic() :void {
        var self=this;
        var levelofquestions = new levelofQuestionsModel({});
        this.dialogRef = this._matDialog.open(AddLevelOfQuestionComponent, {
            panelClass: 'addSubTopic',
            disableClose: true,
            data: {
                action: 'new',
                levelofquestions:levelofquestions,
            }     
        });        
        
        this.levelofquestionsCrudOp(levelofquestions);
         

    }

    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Edit topic
     *
     * @param subject
     */

    editlevelofQuestions(levelofquestions): void {
       var self = this;
        this._commonService.getLevelofQuestionById(levelofquestions.LevelID).then(response=>{
             self.dialogRef = self._matDialog.open(AddLevelOfQuestionComponent, {
                    panelClass: 'levelofquestions-form-dialog',
                    disableClose: true,
                    data: {
                        levelofquestions: response,
                        action: 'edit'
                    }
                });
                self.levelofquestionsCrudOp(levelofquestions);
                });
    }
        
    levelofquestionsCrudOp(levelofquestions: levelofQuestionsModel) {
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }                
                console.log(response);
                console.log("response");
                const actionType: string = response.actionType;
                const _levelofquestions: levelofQuestionsModel = response.levelofquestions;  
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'add':
                        if(_levelofquestions) {
                           this._commonService.createLevelofQuestion(_levelofquestions);
                        }
                         
                         break;

                    case 'update':

                    if(_levelofquestions) {
                        this._commonService.updateLevelofQuestion(_levelofquestions);
                      }
                      break;


                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deleteLevelofQuestion(levelofquestions);

                        break;
                }
            });
                  

    }



    /**
     * Delete Topic
     */
    deleteLevelofQuestion(levelofquestions): void {
        console.log(levelofquestions);
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._commonService.deleteLevelofQuestion(levelofquestions);
            }
            this.confirmDialogRef = null;
        });

    }


    
}

export class levelofQuestionsDetailsDataSource extends DataSource<levelofQuestionsModel>
{

    private loadingTopic = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingTopic.asObservable();
    public data: MatTableDataSource<levelofQuestionsModel>;
    
   
    
    /**
     * Constructor
     *
     * @param {TopicService} _topicService
     */
    constructor(
        private _commonService: CommonService
 
    ) {
        super();
    }
    

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._commonService.onSubtopicChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) { 
        var self = this;

        this._commonService.getLevelofQuestionForGrid(gridFilter)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingTopic.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);
                self.paginationData = {
                    count: response.Count,
                    pageNumber: response.CurrentFilter.PageNumber
                };


            });
    }
}