import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select'
import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';
import { CKEditorModule } from 'ng2-ckeditor';

import { ARFilterQuestionSetComponent } from './qbank.component';
import { ARFilterQuestionSetService } from './qbank.service';
import { ARFilterQuestionSetListComponent } from './qbank-list/list.component';
import { ARFilterQuestionSetFormDialogComponent } from './qbank-form/form.component';
import { ARFilterQuestionDetailFormDialogComponent } from './qbank-detail-form/form.component';
import { ARFilterQuestionDetailComponent } from './qbank-detail/list.component';
import { ARFilterQuestionDetailService } from './qbank-detail.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';

const routes: Routes = [
    {
        path: 'collections/virtual-learn/ar-filter/qbank',
        component: ARFilterQuestionSetComponent,
        resolve: {
            service: ARFilterQuestionSetService
        }
    },
    {
        path: 'collections/virtual-learn/ar-filter/qbank/:id',
        component: ARFilterQuestionDetailComponent,
        resolve: {
            service: ARFilterQuestionDetailService
        }
    }
];

@NgModule({
    declarations: [
        ARFilterQuestionSetComponent,
        ARFilterQuestionSetListComponent,
        ARFilterQuestionSetFormDialogComponent,
        ARFilterQuestionDetailFormDialogComponent,
        ARFilterQuestionDetailComponent,
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatListModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule,

        CKEditorModule
    ],
    providers: [
        ARFilterQuestionSetService,
        ARFilterQuestionDetailService
    ],
    entryComponents: [
        ARFilterQuestionSetFormDialogComponent,
        ARFilterQuestionDetailFormDialogComponent,
    ]
})
export class ARFilterQuestionSetModule {
}
