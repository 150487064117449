import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";

import { FormBuilder, FormControl, FormGroup,FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Tag as tagModel, Module as ModuleModel} from 'app/main/admin/common/subject/common.model';


@Component({
    selector: 'addTag',
    templateUrl: './addTag.component.html',
    styleUrls: ['./addTag.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class  AddTagComponent implements OnInit {
    action: string;
    Tag: tagModel;
    tagForm: FormGroup;
    dialogTitle: string;
    ckeConfig: any;
    choiceType: any;
    scoreType: any;
    _Modules:Array<ModuleModel>;

    /**
     * Constructor
     *
     * @param {MatDialogRef<AddSubjectComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */

    constructor(
        public matDialogRef: MatDialogRef<AddTagComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        var self =this;
        // Set the defaults
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
       };

     this.action = _data.action;

    if (this.action === 'edit') {
        this.dialogTitle = 'Edit Tag';
        this.Tag = _data.tag;
        this._Modules=_data.modules;
    }
    else {
        this.dialogTitle = 'New Tag';
        this.Tag = _data.tag;
        this._Modules=_data.modules;
    }
    self.tagForm = self.createTagForm();
  }

    

 // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create exam form
     *
     * @returns {FormGroup}
     */

    createTagForm(): FormGroup {
        return this._formBuilder.group({
            Name: [this.Tag.Name],
            IsActive: [this.Tag.IsActive],
            OrderNo:[this.Tag.OrderNo],
            Modules:this.buildModules(),
           
        });
    }

    buildModules() {
        const arr = this._Modules.map(module => {
         if(this.Tag.Modules){
            var isSelected=false;
            this.Tag.Modules.forEach(_module => {
                if(module.ModuleID==_module.ModuleID){
                    isSelected=true;
                }
                
            });
            return this._formBuilder.control(isSelected);
         }
         else{
           return this._formBuilder.control(false);
         }
        });
        return this._formBuilder.array(arr);
      }
      
    ngOnInit(): void {
    }

    get Modules() {
        return this.tagForm.get('Modules') as FormArray;;
      };

}
    

   
