<mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort matSortActive="Todo"
    matSortDirection="asc">

    <!-- Title Column -->
    <ng-container matColumnDef="Title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Title}}</p>
        </mat-cell>
    </ng-container>
    <!-- RewardsPoint Column -->
    <ng-container matColumnDef="RewardsPoint">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Rewards Point</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.RewardsPoint}}</p>
        </mat-cell>
    </ng-container>
    <!-- RewardsPoint Column -->
    <ng-container matColumnDef="IsActive">
        <mat-header-cell *matHeaderCellDef mat-sort-header>IsActive</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.IsActive}}</p>
        </mat-cell>
    </ng-container>
    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef class="cell-center">Action</mat-header-cell>
        <mat-cell *matCellDef="let row" class="cell-center">
            <span mat-menu-item aria-label="edit" (click)="editTodo(row)" title="Edit">
                <mat-icon>edit</mat-icon>
            </span>
            <span mat-menu-item aria-label="remove" (click)="deleteTodo(row)" title="Remove">
                <mat-icon>delete</mat-icon>
            </span>
            <span mat-menu-item aria-label="edit" (click)="editCollectionTodo(row)" title="Add Collection">
                <mat-icon>add</mat-icon>
            </span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="todo" [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>
<mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
    (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>