import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { UnisunDirectivesModule } from '@unisun/directives/directives';
import { UnisunSidebarModule } from '@unisun/components/sidebar/sidebar.module';
import { UnisunMaterialColorPickerModule } from '@unisun/components/material-color-picker/material-color-picker.module';

import { UnisunThemeOptionsComponent } from '@unisun/components/theme-options/theme-options.component';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
    declarations: [
        UnisunThemeOptionsComponent
    ],
    imports     : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,

        UnisunDirectivesModule,
        UnisunMaterialColorPickerModule,
        UnisunSidebarModule
    ],
    exports     : [
        UnisunThemeOptionsComponent
    ]
})
export class UnisunThemeOptionsModule
{
}
