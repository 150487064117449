import { NgModule } from '@angular/core';

import { UnisunHighlightComponent } from '@unisun/components/highlight/highlight.component';

@NgModule({
    declarations: [
        UnisunHighlightComponent
    ],
    exports: [
        UnisunHighlightComponent
    ],
})
export class UnisunHighlightModule
{
}
