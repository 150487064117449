import { UnisunUtils } from '@unisun/utils';

export class Report {
    UserName:string;
    Email:string;
    Mobile:string;
    College:string;
    State:string;
    
    Title: string;
    TrnExamResultID: number;
    TrnExamActID: number;
    Correct: number;
    Wrong: number;
    ActualCorrect: number;
    ActualScore: number;
    ActualWrong: number;
    Risk: number;
    MarkedReview: number;
    NotAnswered: number;
    NotVisited: number;
    Answered: number;
    NoOfQuestions: number;
    TotalScore: number;
    Percentage: number;
    RiskPercentage: number;
    RiskScore: number;
    SubmittedOn: number;
    Rank: number;
    TimeSpent: string;
    TimeLeft: string;
}