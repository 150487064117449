<div id="myranking" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg mat-accent-bg"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center" unisunPerfectScrollbar>

        <!-- CONTENT HEADER -->
        <div class="header mat-accent-bg p-24" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0">PGi Analysis</h2>
            <div class="content-card">
                <div class="toolbar px-24 py-8">
                    <div class="p-12 p-sm-24" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-xs="row"
                        fxLayoutAlign.gt-xs="space-between center">

                        <mat-label class="mat-yellow-bg m-4 text-boxed">{{title.toUpperCase()}}</mat-label>
                        <mat-select placeholder="Select Course" *ngIf="title=='course'" [(ngModel)]="TypeID"
                            style="width:150px" class="m-0" (selectionChange)="OnChangeType(TypeID)"
                            floatLabel="always">
                            <mat-option *ngFor="let c of TypeList" [value]="c.CourseID">{{c.Title}}</mat-option>
                        </mat-select>
                        <mat-select placeholder="Select TestSeries" *ngIf="title=='testseries'" [(ngModel)]="TypeID"
                            style="width:150px" class="m-0" (selectionChange)="OnChangeType(TypeID)"
                            floatLabel="always">
                            <mat-option *ngFor="let c of TypeList" [value]="c.TestSeriesID">{{c.Title}}</mat-option>
                        </mat-select>
                        <mat-label class="mat-yellow-bg m-4 text-boxed">Exam</mat-label>
                        <mat-select placeholder="Select Exam" [(ngModel)]="ExamID" class="m-0" floatLabel="always"
                            style="width:150px" (selectionChange)="onExamChange(TypeID)">
                            <mat-option *ngFor="let c of ExamList" [value]="c.ExamID">{{c.Title}}</mat-option>
                        </mat-select>

                    </div>
                </div>

            </div>
        </div>
        <!-- / CONTENT HEADER -->


        <!-- CONTENT -->
        <div class="content grey-100-bg" unisunPerfectScrollbar>

          
                <mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort
                    matSortActive="Name" matSortDirection="asc">

                    <!-- UserName Column -->
                    <ng-container matColumnDef="UserName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>User Name</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <p class="text-truncate font-weight-300">{{row.UserName}}</p>
                        </mat-cell>
                    </ng-container>
                    <!-- Email Column -->
                    <ng-container matColumnDef="Email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <p class="text-truncate font-weight-300">{{row.Email}}</p>
                        </mat-cell>
                    </ng-container>
                    <!-- Mobile Column -->
                    <ng-container matColumnDef="Mobile">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <p class="text-truncate font-weight-300">{{row.Mobile}}</p>
                        </mat-cell>
                    </ng-container>
                    <!-- Buttons Column -->
                    <ng-container matColumnDef="buttons">
                        <mat-header-cell *matHeaderCellDef class="cell-center">Action</mat-header-cell>
                        <mat-cell *matCellDef="let row" class="cell-center" >
                            <span mat-menu-item aria-label="list" (click)="OnViewResult(row)">
                                <mat-icon>list</mat-icon>
                            </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" class="course"
                        [@animate]="{value:'*',params:{y:'100%'}}">
                    </mat-row>
                </mat-table>
                <mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
                    (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions">
                </mat-paginator>
          
        </div>

    </div>