import { LiveVideo } from './../live-video/video.model';
import { Video } from './../video/video.model';
import { Todo } from './../todo/todo.model';
import { Note } from './../note/note.model';
import { Hippo } from './../hippo/hippo.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


import { Course, Subscription, SubscriptionResponse } from 'app/main/admin/course/course.model';
import { environment } from 'environments/environment';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Exam } from '../exam/exam.model';

@Injectable()
export class CourseService implements Resolve<any>
{
    onCourseChanged: BehaviorSubject<any>;

    course: Course[];
    user: any;
    selectedCourse: string[] = [];

    searchText: string;
    filterBy: string;
    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onCourseChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} course
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, course: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getCourseForGrid(gridFilter)
            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get course by ID
     *
     * @returns {Promise<any>}
     */
    getCourseById(id: number): Observable<Course> {
        return this._httpClient.get<Course>(`${environment.apiURL}/course/get-course-by-id/` + id);
    }

    /**
   * Get course
   *
   * @returns {Promise<any>}
   */
    getCourse(): Observable<Course> {
        return this._httpClient.get<Course>(`${environment.apiURL}/course/get-courses`);
    }

    /**
     * Get course
     *
     * @returns {Promise<any>}
     */
    getCourseForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/course/get-grid-courses`, { ..._gridFilter });
    }

    /**
     * Update course
     *
     * @param course
     * @returns {Promise<any>}
     */
    createCourse(course: Course): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/course/create-course/`, { ...course })
                .subscribe(response => {
                    if (response) {
                        resolve(response);
                        this.onCourseChanged.next(this.course);
                        this.openSnackBar("Successfully added.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Update course
     *
     * @param course
     * @returns {Promise<any>}
     */
    updateCourse(course: Course): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/course/update-course/`, { ...course })
                .subscribe(response => {
                    resolve(response);
                    if (response) {
                        this.onCourseChanged.next(this.course);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }



    /**
     * Delete course
     *
     * @param course
     */
    deleteCourse(course): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.delete(`${environment.apiURL}/course/delete-course/` + course.CourseID, {})
                .subscribe(response => {
                    if (response) {
                        this.onCourseChanged.next(this.course);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }
    /**
     * Update course Exam
     *
     * @param course
     * @returns {Promise<any>}
     */
    mapExamByCourse(exam: Exam, courseId: number): Observable<any> {
        return this._httpClient.post(`${environment.apiURL}/course/update-course-exam/` + courseId, { ...exam });


    }

    /**
     * Update course Exam
     *
     * @param course
     * @returns {Promise<any>}
     */
    deleteExamByCourse(exam: Exam, courseId: number): Observable<any> {


        return this._httpClient.post(`${environment.apiURL}/course/delete-course-exam/` + courseId, { ...exam });


    }

    /**
    * Get Hippos
    *
    * @returns {Promise<any>}
    */
    getHippos(): Promise<Array<Hippo>> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<Array<Hippo>>(`${environment.apiURL}/fundamental/get-hippos`)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }
    /**
    * Get Notes
    *
    * @returns {Promise<any>}
    */
    getNotes(): Promise<Array<Note>> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<Array<Note>>(`${environment.apiURL}/fundamental/get-notes`)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }
    /**
    * Get Todos
    *
    * @returns {Promise<any>}
    */
    getTodos(): Promise<Array<Todo>> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<Array<Todo>>(`${environment.apiURL}/fundamental/get-todos`)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }
    /**
    * Get Videos
    *
    * @returns {Promise<any>}
    */
    getVideos(): Promise<Array<Video>> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<Array<Video>>(`${environment.apiURL}/fundamental/get-videos`)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    /**
    * Get LiveSession
    *
    * @returns {Promise<any>}
    */
   getLiveSession(): Promise<Array<LiveVideo>> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<Array<LiveVideo>>(`${environment.apiURL}/fundamental/get-live-videos`)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }
    /**
     * Get Asset Exams
     *
     * @param courseId
     * @param courseSlug
     * @returns {Promise<any>}
     */
    getAssetExams(courseId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/api/user/get-asset-collection/` + courseId + `/exams/`)
                .subscribe((response: any) => {
                    this.onCourseChanged.next(response.Data.FundamendalCollections);
                    resolve(response.Data.FundamendalCollections);
                }, reject);
        });
    }

    /**
     * Get Exams
     *
     * @param courseId
     * @param courseSlug
     * @returns {Promise<any>}
     */
    getExams(courseId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/course/get-exams-by-course/` + courseId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Update Subscription Asset
     *
     * @param Subscription
     * @returns {Promise<any>}
     */
    createAssetSubscription(request: Subscription): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post<SubscriptionResponse>(`${environment.apiURL}/course/create-subscription/`, {...request})
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Update Subscription Asset
     *
     * @param Subscription
     * @returns {Promise<any>}
     */
    updateAssetSubscription(request: Subscription): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post<SubscriptionResponse>(`${environment.apiURL}/course/update-subscription/`,{...request})
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }

    /**
     * delete Subscription Asset
     *
     * @param Subscription
     * @returns {Promise<any>}
     */
    deleteAssetSubscription(request: Subscription): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/course/delete-subscription/`,{...request})
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * get Subscription
     *
     * @param Subscription
     * @returns {Promise<any>}
     */
    getSubscription(Type: number, TypeID: number): Promise<SubscriptionResponse> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<SubscriptionResponse>(`${environment.apiURL}/course/get-subscription/` + Type + `/` + TypeID)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
