import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


import { QuestionSet, Choice, AdditionalChoice } from './qbank.model';
import { environment } from 'environments/environment';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class CMCQuestionSetService implements Resolve<any>
{
    onQuestionSetChanged: BehaviorSubject<any>;

    qbank: QuestionSet[];
    user: any;
    selectedQuestionSet: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onQuestionSetChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterQuestionSetSnapshot} qbank
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, qbank: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getQuestionSetForGrid(gridFilter)
            ]).then(
                ([]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get qbank by Id
     *
     * @returns {Promise<any>}
     */
    getQuestionSetById(id): Promise<QuestionSet[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/chart-my-career/questions/get-qbank-by-id/` + id)
                .subscribe((response: any) => {

                    this.qbank = response;

                    this.onQuestionSetChanged.next(this.qbank);
                    resolve(this.qbank);
                }, reject);
        }
        );
    }
    /**
     * Get qbank
     *
     * @returns {Promise<any>}
     */
    getQuestionSet(): Promise<QuestionSet[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/chart-my-career/questions/get-qbanks`)
                .subscribe((response: any) => {

                    this.qbank = response;

                    this.onQuestionSetChanged.next(this.qbank);
                    resolve(this.qbank);
                }, reject);
        }
        );
    }

    /**
     * Get qbank
     *
     * @returns {Promise<any>}
     */
    getQuestionSetForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/chart-my-career/questions/get-grid-qbanks/`, { ..._gridFilter });
    }

    /**
     * Update qbank
     *
     * @param qbank
     * @returns {Promise<any>}
     */
    createQuestionSet(qbank: QuestionSet): Promise<any> {
        return new Promise((resolve) => {

            this._httpClient.post(`${environment.apiURL}/chart-my-career/questions/create-qbank/`, { ...qbank })
                .subscribe(response => {
                    if (response) {
                        this.onQuestionSetChanged.next(this.qbank);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                    resolve(response);
                });
        });
    }

    /**
     * Update qbank
     *
     * @param qbank
     * @returns {Promise<any>}
     */
    updateQuestionSet(qbank: QuestionSet): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/chart-my-career/questions/update-qbank/`, { ...qbank })
                .subscribe(response => {
                    if (response) {
                        this.onQuestionSetChanged.next(this.qbank);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete qbank
     *
     * @param qbank
     */
    deleteQuestionSet(qbank): Promise<any> {
        return new Promise(() => {
            this._httpClient.delete(`${environment.apiURL}/chart-my-career/questions/delete-qbank/` + qbank.QuestionSetID, {})
                .subscribe(response => {
                    if (response) {
                        this.onQuestionSetChanged.next(this.qbank);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
    * Delete qbank Choice
    *
    * @param Choice
    */
    deleteQuestionChoice(choice: Choice): Promise<any> {
        return new Promise(() => {
            this._httpClient.delete(`${environment.apiURL}/chart-my-career/questions/delete-qbank-choice/` + choice.ChoiceID, {})
                .subscribe(response => {
                    if (response) {
                        this.onQuestionSetChanged.next(this.qbank);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
    * Delete qbank Choice
    *
    * @param Choice
    */
    deleteQuestionAdditionalChoice(choice: AdditionalChoice): Promise<any> {
        return new Promise(() => {
            this._httpClient.delete(`${environment.apiURL}/chart-my-career/questions/delete-qbank-ad-choice/` + choice.ChoiceID, {})
                .subscribe(response => {
                    if (response) {
                        this.onQuestionSetChanged.next(this.qbank);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }
}
