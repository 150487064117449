<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="virtualLearnBookForm">
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input name="name" formControlName="Title" placeholder="Title" matInput required
                        [(ngModel)]="virtualLearnBook.Title">
                </mat-form-field>
            </div>
            <!-- <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input type="text" name="Subject" formControlName="Subject" placeholder="Subject" matInput required  [(ngModel)]="virtualLearnBook.Subject">
                </mat-form-field>
            </div> -->
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="fill" fxFlex>
                    <mat-label>Subject</mat-label>
                    <mat-select matNativeControl formControlName="Subject" required [(ngModel)]="virtualLearnBook.SubjectID">
                        <mat-option disabled value="">Select any</mat-option>
                        <mat-option *ngFor="let subject of subjects" [value]="subject.SubjectID"
                            (click)="getTopic(subject.SubjectID)">{{subject.SubjectName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">

                <mat-form-field appearance="fill" fxFlex>
                    <mat-label>Topic</mat-label>
                    <mat-select matNativeControl formControlName="Topic" required [(ngModel)]="virtualLearnBook.TopicID">
                        <mat-option disabled value="">Select any</mat-option>
                        <!-- <mat-option value="test">test</mat-option> -->
                        <mat-option *ngFor="let topic of topics" [value]="topic.TopicID" >{{topic.TopicName}}</mat-option>
                    </mat-select>
                    <mat-error class="error-message mt-2 ml-1">Please select any Topics</mat-error>
                </mat-form-field>
            </div>

            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input type="text" name="Banner" formControlName="Banner" placeholder="Banner URL" matInput required
                        [(ngModel)]="virtualLearnBook.Banner">
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input type="text" name="Authors" formControlName="Authors" placeholder="Authors" matInput required
                        [(ngModel)]="virtualLearnBook.Authors">
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input type="text" name="Edition" formControlName="Edition" placeholder="Edition" matInput required
                        [(ngModel)]="virtualLearnBook.Edition">
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input type="text" name="Publisher" formControlName="Publisher" placeholder="Publisher" matInput
                        required [(ngModel)]="virtualLearnBook.Publisher">
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">

                <mat-slide-toggle formControlName="IsActive" [(ngModel)]="virtualLearnBook.IsActive">
                    Is Active
                </mat-slide-toggle>

            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(virtualLearnBook)"
            class="save-button mat-accent-bg" [disabled]="virtualLearnBookForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',virtualLearnBook])"
            class="save-button mat-accent-bg" [disabled]="virtualLearnBookForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',virtualLearnBook])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>