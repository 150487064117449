import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";

import { FormBuilder, FormControl, FormGroup,FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA , MatDialogRef } from '@angular/material/dialog';

import { QbankType as qbankTypeModel} from 'app/main/admin/common/subject/common.model';


@Component({
    selector: 'addQBankType',
    templateUrl: './addQBankType.component.html',
    styleUrls: ['./addQBankType.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class  AddQBankTypeComponent implements OnInit {
    action: string;
    QbankType: qbankTypeModel;
    QBanktypeForm: FormGroup;
    dialogTitle: string;
    ckeConfig: any;
    choiceType: any;
    scoreType: any;

    /**
     * Constructor
     *
     * @param {MatDialogRef<AddSubjectComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */

    constructor(
        public matDialogRef: MatDialogRef<AddQBankTypeComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        
        var self =this;
        // Set the defaults
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
       };

     this.action = _data.action;

    if (this.action === 'edit') {
        this.dialogTitle = 'Edit QBank Type';
        this.QbankType = _data.qbankType;
    }
    else {
        this.dialogTitle = 'New QBank Type';
        this.QbankType = _data.qbankType;
    }
    self.QBanktypeForm = self.createQBanktypeForm();
  }

    

 // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create exam form
     *
     * @returns {FormGroup}
     */

    createQBanktypeForm(): FormGroup {
        return this._formBuilder.group({
            Title: [this.QbankType.Title],
            IsActive: [this.QbankType.IsActive],
            OrderNo:[this.QbankType.OrderNo]   
        });
        
    }

    ngOnInit(): void {
    }   

}
    

   
