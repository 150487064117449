import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataGuardService } from 'app/_guards';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(
        private http: HttpClient,
        private _router: Router,
        private _dataGuardService: DataGuardService
    ) { }

    public GetToken() {

        let _urlParams: string = '';
        _urlParams = 'username=' + environment.OAuthConfiguration.ApiUserName + "&";
        _urlParams = _urlParams + 'password=' + environment.OAuthConfiguration.ApiPassword + "&";
        _urlParams = _urlParams + 'client_id=' + environment.OAuthConfiguration.ApiClientID + "&";
        _urlParams = _urlParams + 'grant_type=' + environment.OAuthConfiguration.ApiGrantType;

        return this.http.post<any>(`${environment.apiURL}/oauth2/token`, _urlParams,
            {
                headers: new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded')
                    .set('Accept', '*/*')
            })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.access_token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('Access-Token', JSON.stringify(user));
                }

                return user;
            })).subscribe();
    }

    public ClearToken() {
        // remove user from local storage to log user out
        localStorage.removeItem('Access-Token');
    }

    public logout() {
        this._dataGuardService.removeCookieData('lms-user');
        this._dataGuardService.removeCookieData('OTP');
        this._router.navigate(['security/auth/login']);
    }

    public getOTP(userID: number) {

    }
}