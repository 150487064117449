import { UnisunUtils } from '@unisun/utils';
import {VideoResolution} from '../video/video.model';
export class PromotionalVideo {
    PromoID: number;
    Title: number;
    Description: string;
    IsActive: string;
    Url: string;
    QueueID:number;
    VideoResolutionCollection:VideoResolution[];
    /**
     * Constructor
     *
     * @param promotionalvideo
     */
    constructor(promotionalvideo) {
        {
            this.PromoID = promotionalvideo.PromoID; //|| UnisunUtils.generateGUID();
            this.Title = promotionalvideo.Title || '';
            this.Description = promotionalvideo.Description || '';
            this.IsActive = promotionalvideo.Message || false;
            this.Url = promotionalvideo.Url || '';
            this.QueueID = promotionalvideo.QueueID || '';
        }
    }
}
