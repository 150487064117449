import { TranscriptionFormDialogComponent } from './transcription-form/form.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';

import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';
import { CKEditorModule } from 'ng2-ckeditor';

import { VideoComponent } from 'app/main/admin/video/video.component';
import { VideoService } from 'app/main/admin/video/video.service';
import { VideoListComponent } from 'app/main/admin/video/video-list/list.component';
import { VideoFormDialogComponent } from 'app/main/admin/video/video-form/form.component';
import { VideoDetailsFormDialogComponent } from './video-detail-form/form.component';
import { VideoDetailsComponent } from './video-detail/list.component';
import { VideoDetailsService } from './video-detail.service';
import { TranscriptionService } from './transcription.service';
import { TranscriptionComponent } from './transcription/list.component';

const routes: Routes = [
    {
        path: 'collections/video',
        component: VideoComponent,
        resolve: {
            service: VideoService
        }
    },
    {
        path: 'collections/video/:id',
        component: VideoDetailsComponent,
        resolve: {
            service: VideoDetailsService
        }
    },
    {
        path: 'collections/video/:videoId/transcription/:videoDetailID',
        component: TranscriptionComponent,
        resolve: {
            service: VideoDetailsService
        }
    }
];

@NgModule({
    declarations: [
        VideoComponent,
        VideoListComponent,
        VideoFormDialogComponent,
        VideoDetailsFormDialogComponent,
        VideoDetailsComponent,
        TranscriptionFormDialogComponent,
        TranscriptionComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatMenuModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule,

        CKEditorModule
    ],
    providers: [
        VideoService,
        VideoDetailsService,
        TranscriptionService
    ],
    entryComponents: [
        VideoFormDialogComponent,
        VideoDetailsFormDialogComponent,
        TranscriptionFormDialogComponent
    ]
})
export class MediaModule {
}
