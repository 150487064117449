import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select';
import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';

import { CourseComponent } from 'app/main/admin/course/course.component';
import { CourseService } from 'app/main/admin/course/course.service';
import { CourseListComponent } from 'app/main/admin/course/course-list/list.component';
import { CourseFormComponent } from 'app/main/admin/course/course-form/form.component';
import { CourseMainSidebarComponent } from './sidebars/main/main-sidebar.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { UserService } from 'app/_services';
import { MomentModule } from 'ngx-moment';
import { CourseUserPermissionComponent } from './user-list/list.component';
import { CourseUserPermissionService } from './course-user-permission.service';
import { AssetFormDialogComponent } from './Subcription-form/form.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { TestListComponent } from './test-list/test-list.component';

const routes: Routes = [
    {
        path: 'product/courses',
        component: CourseComponent,
        resolve: {
            contacts: CourseService
        }
    },
    {
        path: 'product/course',
        component: CourseFormComponent,
        resolve: {
            contacts: CourseService
        }
    },
    {
        path: 'product/course/:id',
        component: CourseFormComponent,
        resolve: {
            contacts: CourseService
        }
    }
];

@NgModule({
    declarations: [
        CourseComponent,
        CourseListComponent,
        CourseFormComponent,
        CourseMainSidebarComponent,
        CourseUserPermissionComponent,
        AssetFormDialogComponent,
        TestListComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MomentModule.forRoot({ relativeTimeThresholdOptions: { 'm': 59 } }), 
        
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatListModule,
        MatAutocompleteModule,
        MatTabsModule,


        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule,

        CKEditorModule
    ],
    providers: [
        CourseService,
        UserService,
        CourseUserPermissionService
    ],
    entryComponents: [
        AssetFormDialogComponent
    ]
})
export class CourseModule {
}
