<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="trandingvideoForm">

            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex class="p-4">

                    <input name="Title" [(ngModel)]="trandingvideo.Title" formControlName="Title" placeholder="Title"
                        matInput required>
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex class="p-4">

                    <input name="QueueID" [(ngModel)]="trandingvideo.QueueID" formControlName="QueueID"
                        placeholder="QueueID" matInput required type="number">
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field class="mr-sm-24" fxFlex>
                    <mat-select placeholder="Video Type" [(ngModel)]="trandingvideo.VideoType" required
                        formControlName="VideoType">
                        <mat-option value="1">Vimeo</mat-option>
                        <mat-option value="2">Zoom</mat-option>
                        <mat-option value="3">Youtube</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-slide-toggle class="p-4" formControlName="IsActive" [checked]="trandingvideo.IsActive"
                    [(ngModel)]="trandingvideo.IsActive">
                    Is Active
                </mat-slide-toggle>

            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <textarea name="Description" rows="4" cols="40" [(ngModel)]="trandingvideo.Description"
                        formControlName="Description" placeholder="Description" matInput required></textarea>
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <textarea name="Url" rows="4" cols="40" [(ngModel)]="trandingvideo.Url" formControlName="Url"
                        placeholder="Url" matInput required></textarea>
                </mat-form-field>
            </div>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(trandingvideo)"
            class="save-button mat-accent-bg" [disabled]="trandingvideoForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',trandingvideo])"
            class="save-button mat-accent-bg" [disabled]="trandingvideoForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',trandingvideo])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>