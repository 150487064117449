import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'app/_models/user';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthService implements Resolve<any>
{
    onUserChanged: BehaviorSubject<any>;

    user: any;
    selectedUser: string[] = [];

    searchText: string;
    filterBy: string;
    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onUserChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterUserSnapshot} user
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, user: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise(() => {

        });
    }
    /**
     * get user by email
     *
     * @param email
     * @returns {Promise<any>}
     */
    getUserByEmail(user: any): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/user/get-user-by-email/`, { ...user })
                .subscribe(response => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * get user by email
     *
     * @param email
     * @returns {Promise<any>}
     */
    getUserLogin(user: any): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/api/user/login/`, { ...user })
                .subscribe(response => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * create user
     *
     * @param user
     * @returns {Promise<any>}
     */
    createUser(user: User): Promise<any> {
        var self = this;
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/user/create-user/`, { ...user })
                .subscribe(response => {

                    resolve(response);
                }, reject);
        });
    }

    /**
     * Update user
     *
     * @param user
     * @returns {Promise<any>}
     */
    updateUser(user: User): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/user/update-user/`, { ...user })
                .subscribe(response => {
                    resolve(response);
                    if (response) {
                        this.onUserChanged.next(this.user);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                }, reject);
        });
    }

    /**
     * Delete user
     *
     * @param user
     */
    deleteUser(user: User): Promise<any> {
        return new Promise(() => {

            this._httpClient.delete(`${environment.apiURL}/user/delete-user/` + user.UserID, {})
                .subscribe(response => {
                    if (response) {
                        this.onUserChanged.next(this.user);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Update OTP
     *
     * @param user
     * @returns {Promise<any>}
     */
    updateOneTimePasscode(user: User): Promise<any> {
        var self = this;
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/user/confirm-otp/`, { ...user })
                .subscribe(response => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Generate OTP
     *
     * @param user
     * @returns {Promise<any>}
     */
    generateOneTimePasscode(user: User): Promise<any> {
        var self = this;
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/user/generate-otp/`, { ...user })
                .subscribe(response => {
                    resolve(response);
                }, reject);
        });
    }


    /**
         * Get user info
         *
         * @returns {Promise<any>}
         */
    getUserInfo(user: any): Promise<User> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/api/user/get-user-info/`, { ...user })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        }
        );
    }

}
export class AuthDataSource extends DataSource<User>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    /**
     * Constructor
     *
     * @param {CollegeService} _authService
     */
    constructor(
        private _authService: AuthService
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._authService.onUserChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData() {
        var self = this;

    }
}