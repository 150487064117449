<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="videoForm">

            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input name="Topic" formControlName="Topic" placeholder="Topic" matInput required
                        [(ngModel)]="video.Topic">
                </mat-form-field>
            </div>

            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>

                    <input name="TrimStart" formControlName="TrimStart" placeholder="Start" matInput required
                        [(ngModel)]="video.TrimStart">
                </mat-form-field>
            </div>


            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input name="TrimEnd" formControlName="TrimEnd" placeholder="End" matInput
                        [(ngModel)]="video.TrimEnd">
                </mat-form-field>
            </div>
            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input name="VideoLength" formControlName="VideoLength" placeholder="Length" matInput
                        [(ngModel)]="video.VideoLength">
                </mat-form-field>

            </div>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(video)"
            class="save-button mat-accent-bg" [disabled]="videoForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',video])"
            class="save-button mat-accent-bg" [disabled]="videoForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',video])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>