import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Hippo } from 'app/main/admin/hippo/hippo.model';
import { State } from 'app/main/admin/state/state.model';

@Component({
    selector     : 'hippo-form-dialog',
    templateUrl  : './form.component.html',
    styleUrls    : ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HippoFormDialogComponent
{
    action: string;
    hippo: Hippo;
    hippoForm: FormGroup;
    dialogTitle: string;

    states: State[];
    /**
     * Constructor
     *
     * @param {MatDialogRef<HippoFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<HippoFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.action = _data.action;
        this.states = _data.states;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Edit Hippo';
            this.hippo = _data.hippo;
        }
        else
        {
            this.dialogTitle = 'New Hippo';
            this.hippo = new Hippo({});
        }

        this.hippoForm = this.createHippoForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create hippo form
     *
     * @returns {FormGroup}
     */
    createHippoForm(): FormGroup
    {
        return this._formBuilder.group({
            HipposID      : [this.hippo.HipposID],
            Title    : [this.hippo.Title],
            IsActive    : [this.hippo.IsActive]
        });
    }
}
