import { CMCQuestionSetService } from '../qbank/qbank.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select';
import { MatStepperModule} from '@angular/material/stepper';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatTabsModule} from '@angular/material/tabs';

import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';

import { CMCExamComponent } from '../exam/exam.component';
import { CMCExamService } from '../exam/exam.service';
import { CMCExamListComponent } from '../exam/exam-list/list.component';
import { CMCExamFormDialogComponent } from '../exam/exam-form/form.component';
import { MomentModule } from 'ngx-moment';
import { CKEditorModule } from 'ng2-ckeditor';
import { CMCUserPermissionComponent } from './user-list/list.component';
import { CMCUserPermissionService } from './user-permission.service';
import { CMCUserAnalysisReportComponent } from '../analysis-report/list.component';
import { CMCAssetFormDialogComponent } from 'app/main/admin/chart-my-career/subcription-form/form.component';

const routes: Routes = [
    {
        path: 'product/chart-my-career',
        component: CMCExamComponent,
        resolve: {
            contacts: CMCExamService
        }
    },

    {
        path: 'product/chart-my-career/report/:ExamID/:UserID',
        component: CMCUserAnalysisReportComponent,
        resolve: {
            contacts: CMCExamService
        }
    }
];

@NgModule({
    declarations: [
        CMCExamComponent,
        CMCExamListComponent,
        CMCExamFormDialogComponent,
        CMCUserPermissionComponent,
        CMCUserAnalysisReportComponent,
        CMCAssetFormDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MomentModule.forRoot({ relativeTimeThresholdOptions: { 'm': 59 } }), 
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatTabsModule,
        
        CKEditorModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule
    ],
    providers: [
        CMCExamService,
        CMCQuestionSetService,
        CMCUserPermissionService
    ],
    entryComponents: [
        CMCExamFormDialogComponent,
        CMCUserPermissionComponent,
        CMCAssetFormDialogComponent
    ]
})
export class CMCExamModule {
}
