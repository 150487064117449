import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { ARFilterQuestionDetailService } from '../qbank-detail.service';
import { QuestionSet, QuestionDetail, ExcelDataQuestionField } from '../qbank.model';
import { GridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute } from '@angular/router';
import { SitePreference } from 'app/config';

import { ARFilterQuestionDetailFormDialogComponent } from '../qbank-detail-form/form.component';
import { DataGuardService } from 'app/_guards/data.guard';
import { XlsxToJsonService } from 'app/_services';
import { UnisunUtils } from '@unisun/utils';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'ar-filter-qbank-detail',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class ARFilterQuestionDetailComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;
    QuestionSetID: number;
    qbank: any;
    paginationData: any;


    dataSource: QuestionDetailsDataSource;
    displayedColumns = ['QueueID', 'QuestionTitle', 'buttons'];
    selectedQuestionDetails: any[];
    checkboxes: {};
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;


    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    hasSelectedQuestion: boolean;
    searchInput: FormControl;

    // Private
    /**
     * Constructor
     *
     * @param {QuestionDetailsService} _qbankService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _qbankService: ARFilterQuestionDetailService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _dataGuardService: DataGuardService,
        private sanitizer: DomSanitizer
    ) {
        var self = this;
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.route.params.subscribe(function (parram) {

            self.QuestionSetID = self._dataGuardService.valueDecryption(parram.id);;
        });
    }

    ngAfterViewInit() {

    }

    loadPage() {
        this._qbankService.onQuestionDetailChanged.next(this.qbank);
    }


    getNext(event: PageEvent) {
        this._qbankService.onQuestionDetailChanged.next(this.qbank);

    }


    onSortData(sort: Sort) {

        this._qbankService.onQuestionDetailChanged.next(this.qbank);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.dataSource = new QuestionDetailsDataSource(this._qbankService, this.QuestionSetID);

        this._qbankService.onQuestionDetailChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.qbank = search;
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == null ? "Title" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc'
                };
                this.dataSource.loadData(gridFilter);
            });

        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._qbankService.onQuestionDetailChanged.next(searchText);
            });
    }

    /**
    * New qbank
    */
    newQuestionDetail(): void {

        this.dialogRef = this._matDialog.open(ARFilterQuestionDetailFormDialogComponent, {
            panelClass: 'qbank-detail-form-dialog',
            disableClose: true,
            data: {
                action: 'new'
            }
        });
        this.newQuestionDetailCallback();

    }

    newQuestionDetailCallback() {
        this.dialogRef.afterClosed()
            .subscribe((response: QuestionDetail) => {
                if (!response) {
                    return;
                }
                response.QuestionSetID = this.QuestionSetID;
                this._qbankService.createQuestionDetail(response);
            });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Edit qbank
     *
     * @param qbank
     */
    editQuestionDetails(qbank: QuestionDetail): void {
        var self = this;
        self._qbankService.getQuestionDetailById(qbank.QuestionDetailID).subscribe(function (response) {

            self.dialogRef = self._matDialog.open(ARFilterQuestionDetailFormDialogComponent, {
                panelClass: 'qbank-detail-form-dialog',
                disableClose: true,
                data: {
                    qbank: response,
                    action: 'edit'
                }
            });
            self.editQuestionDetailsCallback(qbank);
        });
    }

    editQuestionDetailsCallback(qbank: QuestionDetail) {
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: QuestionDetail = response[1];
                formData.QuestionSetID = this.QuestionSetID;
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':

                        this._qbankService.updateQuestionDetail(formData);

                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deleteQuestionDetails(qbank);

                        break;
                }
            });
    }

    /**
     * Delete QuestionDetails
     */
    deleteQuestionDetails(qbank): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._qbankService.deleteQuestionDetail(qbank);
            }
            this.confirmDialogRef = null;
        });

    }

    @Input() mode
    @Input() names
    @Input() url
    @Input() method
    @Input() multiple
    @Input() disabled
    @Input() accept
    @Input() maxFileSize
    @Input() auto = true
    @Input() withCredentials
    @Input() invalidFileSizeMessageSummary
    @Input() invalidFileSizeMessageDetail
    @Input() invalidFileTypeMessageSummary
    @Input() invalidFileTypeMessageDetail
    @Input() previewWidth
    @Input() chooseLabel = 'Choose'
    @Input() uploadLabel = 'Upload'
    @Input() cancelLabel = 'Cance'
    @Input() customUpload
    @Input() showUploadButton
    @Input() showCancelButton


    @Input() dataUriPrefix
    @Input() deleteButtonLabel
    @Input() deleteButtonIcon = 'close'
    @Input() showUploadInfo

    /**
     *
     */


    @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef

    inputFileName: string

    @Input() files: File[] = []


    onClick(event) {
        if (this.fileUpload)
            this.fileUpload.nativeElement.click()
    }

    onInput(event) {

    }

    onFileSelected(event) {
        let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        console.log('event::::::', event)
        for (let i = 0; i < files.length; i++) {
            let file = files[i];


            if (this.validate(file)) {

                file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));

                if (!this.isMultiple()) {
                    this.files = []
                }
                this.files.push(files[i]);

                this.handleFile(event);
            }

        }
    }

    public result: any;
    private xlsxToJsonService: XlsxToJsonService = new XlsxToJsonService();
    removeFile(event, file) {
        let ix
        if (this.files && -1 !== (ix = this.files.indexOf(file))) {
            this.files.splice(ix, 1)
            this.clearInputElement()
        }
    }

    validate(file: File) {
        for (const f of this.files) {
            if (f.name === file.name
                && f.lastModified === file.lastModified
                && f.size === f.size
                && f.type === f.type
            ) {
                return false
            }
        }
        return true
    }

    clearInputElement() {
        this.fileUpload.nativeElement.value = ''
    }


    isMultiple(): boolean {
        return this.multiple
    }

    handleFile(event) {
        var self = this;
        let file = event.target.files[0];
        try {
            this.xlsxToJsonService.processFileToJson({}, file).subscribe(data => {
                this.result = data['sheets'].Sheet1;
                self.qbank = [];

                var r: QuestionDetail = new QuestionDetail({});
                var questionId = 0;
                var qPattern = "";
                self.result.forEach((row: ExcelDataQuestionField) => {

                    if (row.Question) {

                        if (r.QuestionTitle) {
                            self.qbank.push(r);
                            r = new QuestionDetail({});
                        }
                        questionId++;
                        r.QuestionTitle = row.Question;
                        r.QueueID = questionId;
                        r.Content = row.Explanation;
                        r.Mark = row.Marks;
                        r.Negative = row.Negative;
                        r.Hints = row.Hints;
                        r.Answer = row.Answer;
                        r.QuestionSetID = this.QuestionSetID;
                    }
                    

                });

                if (r.QuestionTitle) {
                    self.qbank.push(r);
                    r = new QuestionDetail({});
                }
                self.qbank = self.SortOrder(self.qbank);
            })
        }
        catch (e) {
            console.log(e);
        }
        finally {
        }

    }

    SortOrder(results: QuestionDetail[]) {
        return results.sort(function (id1, id2) {
            return id1.QueueID - id2.QueueID;
        });
    }

    OnBulkUpdateQuestion() {
        var self = this;

        let request = self._qbankService.BulkUploadQuestions(this.qbank, this.QuestionSetID);
        request.then(value => {
            self._qbankService.openSnackBar("Successfully added", "close");
            self.files = [];
            self.qbank = [];

        });
        request.catch(error => {
            self._qbankService.openSnackBar("Oops!! " + error, "close");
        });
    }
}

export class QuestionDetailsDataSource extends DataSource<QuestionDetail>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<QuestionDetail>;
    /**
     * Constructor
     *
     * @param {ARFilterQuestionDetailService} _qbankService
     */
    constructor(
        private _qbankService: ARFilterQuestionDetailService,
        private _id: number
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._qbankService.onQuestionDetailChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) {
        var self = this;

        this._qbankService.getQuestionDetailForGrid(gridFilter, this._id)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);


                self.paginationData = {
                    count: response.Count || 0,
                    pageNumber: response.CurrentFilter == undefined ? 0 : response.CurrentFilter.PageNumber
                };


            });
    }
}
