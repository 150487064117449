import { filter } from 'rxjs/operators';
import { CommonType, SitePreference } from 'app/config';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import { QuestionSet, QuestionDetail} from '../qbank.model';
import { ARFilterExamService } from '../../exam/exam.service';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';
import { ARFilterQuestionSetService } from '../qbank.service';

@Component({
    selector: 'ar-filter-qbank-detail-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ARFilterQuestionDetailFormDialogComponent {
    ckeConfig: any;
    action: string;
    qbank: QuestionDetail;
    qbankForm: FormGroup;
    dialogTitle: string;
    dialogRef: any;
    _commonType: any = CommonType;
    choiceType: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<QuestionDetailFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ARFilterQuestionDetailFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        public _matDialog: MatDialog,
        private _examService: ARFilterExamService,
        private _qbankService: ARFilterQuestionSetService
    ) {
        var self = this;

        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        this._examService.getChoiceType().then(function (response) {

            self.choiceType = response;
        });
        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Question';
            this.qbank = _data.qbank;
        }
        else {
            this.dialogTitle = 'New Question';
            this.qbank = new QuestionDetail({});
        }

        this.qbankForm = this.createQuestionSetForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create qbank form
     *
     * @returns {FormGroup}
     */
    createQuestionSetForm(): FormGroup {
        return this._formBuilder.group({
            QuestionTitle: [this.qbank.QuestionTitle],
            Mark: [this.qbank.Mark],
            Negative: [this.qbank.Negative],
            Answer: [this.qbank.Answer],
            Content: [this.qbank.Content],
            Hints: [this.qbank.Hints],
            QueueID: [this.qbank.QueueID],
        });
    }
}
