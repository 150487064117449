<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">INFO</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>


        <div class="mb-16" fxLayout="row" fxLayoutAlign="start start">
            <mat-label class="m-4 font-weight-300">Name</mat-label>
            <p class="mat-yellow-bg  text-boxed">{{QuestionBugReport.UserName}}</p>
         
            <mat-label class="m-4 font-weight-300">Email</mat-label>
            <p class="mat-yellow-bg  text-boxed">{{QuestionBugReport.Email}}</p>
        
            <mat-label class="m-4 font-weight-300">Mobile</mat-label>
            <p class="mat-yellow-bg  text-boxed">{{QuestionBugReport.Mobile}}</p>
        </div>
        <div class="mb-16" fxLayout="row" fxLayoutAlign="start start">
            <mat-label class="m-4 font-weight-300">Question Set</mat-label>
            <p class="m-4">{{QuestionBugReport.Title}}</p>
        </div>
        <div class="mb-16" fxLayout="row" fxLayoutAlign="start start">
            <mat-label class="m-8 font-weight-300">Question</mat-label>
        </div>
        <p class="m-4" [innerHTML]="QuestionBugReport.QuestionTitle"></p>
        <div class="mb-16" fxLayout="row" fxLayoutAlign="start start">
            <mat-label class="m-4 font-weight-300">Has Question Error</mat-label>
            <p class="mat-red-bg  text-boxed">{{QuestionBugReport.IsQuestionBug}}</p>
        </div>
        <div class="mb-16" fxLayout="row" fxLayoutAlign="start start">
            <mat-label class="m-4 font-weight-300">Has Option Error</mat-label>
            <p class="mat-red-bg  text-boxed">{{QuestionBugReport.IsOptionBug}}</p>
        </div>
        <div class="mb-16" fxLayout="row" fxLayoutAlign="start start">
            <mat-label class="m-4 font-weight-300">Has Explanation Error</mat-label>
            <p class="mat-red-bg  text-boxed">{{QuestionBugReport.IsExplanation}}</p>
        </div>
        <div class="mb-16" fxLayout="row" fxLayoutAlign="start start">
            <mat-label class="m-4 font-weight-300">Remarks</mat-label>
            <p class="m-4" [innerHTML]="QuestionBugReport.Remarks"></p>
        </div>
    </div>

</div>