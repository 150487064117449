<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form  class="event-form w-100-p" [formGroup]="cbmecodeForm" fxLayout="column" fxFlex>

            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

                <mat-form-field class="mb-0" fxFlex>
                    <input matInput name="Title" required formControlName="Title" [(ngModel)]="CbmeCode.Title"
                        placeholder="Title">
                </mat-form-field>
                                
            </div>
            

            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

            <mat-form-field class="mb-0" fxFlex>
                <input type="number" matInput name="Order No" required formControlName="OrderNo" [(ngModel)]="CbmeCode.OrderNo"
                    placeholder="Order No">
            </mat-form-field>

            </div>

            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">
                <ckeditor [config]="ckeConfig" placeholder="Description" [(ngModel)]="CbmeCode.Description" formControlName="Description">
                </ckeditor>
            </div>

          
            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row" class="p-0">  

                <mat-slide-toggle class="my-4" formControlName="IsActive" [(ngModel)]="CbmeCode.IsActive">
                    Is Active
                </mat-slide-toggle>

            </div> 
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button
         (click)="matDialogRef.close({actionType:'add',cbmecode: CbmeCode})"
            class="save-button mat-accent-bg" [disabled]="cbmecodeForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button 
        (click)="matDialogRef.close({actionType:'update',cbmecode: CbmeCode})"
        
   class="save-button mat-accent-bg" [disabled]="cbmecodeForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button 
        (click)="matDialogRef.close({actionType:'delete',cbmecode: CbmeCode})"
        aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>