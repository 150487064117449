<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>
                <form name="examForm" [formGroup]="examForm" class="p-24 event-form w-100-p" fxLayout="column" fxFlex>
                    <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">
                        <mat-form-field class="mb-24" fxFlex>
                            <input matInput name="LevelName" required formControlName="LevelName" [(ngModel)]="examLevel.LevelName"
                                placeholder="Level Name">
                        </mat-form-field>
                        <mat-form-field class="mb-24" fxFlex>
                            <input type="number" matInput name="LevelID" required formControlName="LevelID"
                                [(ngModel)]="examLevel.LevelID" placeholder="Level ID">
                        </mat-form-field>
                    </div>
                    <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">
                        <mat-form-field class="mr-sm-24" fxFlex>
                            <mat-select placeholder="Question Set" [(ngModel)]="examLevel.QuestionSetID" required
                                formControlName="QuestionSetID">
                                <mat-option *ngFor="let c of questionSet" [value]="c.QuestionSetID">{{c.Title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-slide-toggle class="p-4" formControlName="IsActive" [(ngModel)]="examLevel.IsActive">
                            Is Active
                        </mat-slide-toggle>
                    </div>

                </form>
            
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button mat-raised-button (click)="matDialogRef.close(['save',exam,examLevel])"
            class="save-button mat-accent-bg" [disabled]="examForm.invalid" aria-label="SAVE">
            SAVE
        </button>

    </div>
</div>