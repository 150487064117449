import { Video, VideoDetails } from './../video/video.model';
import { Todo, TodoDetails } from './../todo/todo.model';
import { Note, NoteDetails } from './../note/note.model';
import { Hippo, HipposDetails } from './../hippo/hippo.model';
import { UnisunUtils } from '@unisun/utils';
import { Exam } from '../exam/exam.model';
import { User } from 'app/_models';

export class Course {
     CourseID: number;
     Title: string;
     SubTitle: string;
     Description: string;
     Price: number;
     RewardsPoint: number;
     StartDate: string;
     EndDate: string;
     VideoID: number;
     HipposID: number;
     NoteID: number;
     TodoID: number;
     LiveSessionID: number;
     IsHide:boolean;
     IsActive: boolean;
     ExamID: Array<Exam>;
     NoOfExams: number;
     ProgressPercentage: number;
     NoOfEntrolled: number;
     NoOfQuestions: number;
     NoOfExamCompleted: number;
     TotalMinutes: number;
     TotalScore: number;

     Users: Array<User>;
     Subscription: Subscription;
     /**
      * Constructor
      *
      * @param course
      */
     constructor(course) {
          {
               this.CourseID = course.CourseID; //|| UnisunUtils.generateGUID();
               this.Title = course.Title || '';
               this.SubTitle = course.SubTitle || '';
               this.Description = course.Description || '';
               this.Price = course.Price || '';
               this.RewardsPoint = course.RewardsPoint || '';
               this.StartDate = course.StartDate || '';
               this.EndDate = course.EndDate || '';
               this.VideoID = course.VideoID || 0;
               this.HipposID = course.HipposID || 0;
               this.NoteID = course.NoteID || 0;
               this.TodoID = course.TodoID || 0;
               this.LiveSessionID = course.LiveSessionID || 0;
               this.ExamID = course.ExamID || new Array<Exam>();
               this.Users = course.Users || new Array<User>();
               this.Subscription = course.Subscription;
          }
     }
}


export class Subscription {
     SubscriptionID: number;
     SubscriptionTypeID: number;
     Type: number;
     TypeID: number;
     IsActive: boolean;
     Plans: Array<SubscriptionPlan>;
     Plan: SubscriptionPlan;
     SubscriptionType: SubscriptionType;
     IsFreeHippo: boolean;
     IsFreeTodo: boolean;
     IsFreeVideo: boolean;
     IsFreeQBank: boolean;

     constructor(asset) {

          this.IsFreeHippo = asset.IsFreeHippo || false;
          this.IsFreeTodo = asset.IsFreeTodo || false;
          this.IsFreeVideo = asset.IsFreeVideo || false;
          this.IsFreeQBank = asset.IsFreeQBank || false;

     }
}
export class SubscriptionPlan {
     SubscriptionID: number;
     SubscriptionAssetID: number;
     AssetType: number;
     SubscriptionTypeID: number;
     ValidUpto: number;
     DiscountPercentage: number;
     ActualPrice: number;
     Price: number;
     Type: number;
     TypeID: number;
     IsActive: boolean;

     constructor(asset) {
          {
               this.AssetType = asset.AssetType;
               this.SubscriptionAssetID = asset.SubscriptionAssetID || 0;
               this.AssetType = asset.AssetType || 0;
               this.SubscriptionTypeID = asset.SubscriptionTypeID || 0;
               this.ValidUpto = asset.ValidUpto || '';
               this.DiscountPercentage = asset.DiscountPercentage || 0;
               this.ActualPrice = asset.ActualPrice || '';
               this.Price = asset.Price || '';


          }
     }
}

export class SubscriptionType {
     SubscriptionTypeID: number;
     SubscriptionName: string;
     SubscriptionKey: string;
     Priority: number;

}

export class SubscriptionResponse {
     Subscriptions: Array<Subscription>;
     SubscriptionType: Array<SubscriptionType>;
}

export class ExamCollection {
     Subject: string;
     ProgressPercentage: number;
     Collections: Array<Exam>;
}

export class HippoCollection {
     Subject: string;
     ProgressPercentage: number;
     Collections: Array<HipposDetails>;
}
export class NotesCollection {
     Subject: string;
     ProgressPercentage: number;
     Collections: Array<NoteDetails>;
}
export class TodoCollection {
     Subject: string;
     ProgressPercentage: number;
     Collections: Array<TodoDetails>;
}

export class VideoCollection {
     Subject: string;
     ProgressPercentage: number;
     Collections: Array<VideoDetails>;
}
