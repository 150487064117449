<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
       
    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form  class="event-form w-100-p" [formGroup]="topicForm"  fxLayout="column" fxFlex>

            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

                <mat-form-field class="mb-0" fxFlex>
                    <input matInput required formControlName="TopicName" [(ngModel)]="topic.TopicName"
                        placeholder="Topic Name">
                </mat-form-field>
                                
            </div>

            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row" >
                <mat-slide-toggle class="my-4" formControlName="IsActive" [(ngModel)]="topic.IsActive">
                    Is Active
                </mat-slide-toggle>

             </div>
            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row" class="p-0 mt-15">
                <label for="Description">Modules</label>
           </div> 
           <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row" class="p-0">
               
               <ul class="checkbox-items modules_checkbox">
                   <li class="checkbox_module_li" *ngFor="let module of Modules.controls; let i=index">
                       <mat-checkbox [formControl]="module">{{_Modules[i].Name}}</mat-checkbox>
                       
                      </li>
               </ul>                                                               

           </div> 
         </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

      
        <button *ngIf="action !=='edit'" mat-raised-button
        (click)="matDialogRef.close({actionType:'add',topic: topic, Modules: this.topicForm.value.Modules})"
           class="save-button mat-accent-bg" [disabled]="topicForm.invalid" aria-label="SAVE">
           SAVE
       </button>

       <button *ngIf="action ==='edit'" mat-raised-button 
       (click)="matDialogRef.close({actionType:'update',topic: topic, Modules: this.topicForm.value.Modules})"
   class="save-button mat-accent-bg" [disabled]="topicForm.invalid" aria-label="SAVE">
           SAVE
       </button>

       <button *ngIf="action ==='edit'" mat-icon-button 
       (click)="matDialogRef.close({actionType:'delete',topic: topic, Modules: this.topicForm.value.Modules})"
       aria-label="Delete" matTooltip="Delete">
           <mat-icon>delete</mat-icon>
       </button>

    </div>
</div>