import { StateService } from 'app/main/admin/state/state.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunSidebarService } from '@unisun/components/sidebar/sidebar.service';

import { ARFilterQuestionSetService } from './qbank.service';
import { ARFilterQuestionSetFormDialogComponent } from './qbank-form/form.component';
import { QuestionSet } from './qbank.model';

@Component({
    selector: 'ar-filter-qbank',
    templateUrl: './qbank.component.html',
    styleUrls: ['./qbank.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class ARFilterQuestionSetComponent implements OnInit, OnDestroy {
    dialogRef: any;
    hasSelectedQuestionSet: boolean;
    searchInput: FormControl;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ARFilterQuestionSetService} _qbankService
     * @param {UnisunSidebarService} _unisunSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _qbankService: ARFilterQuestionSetService,
        private _unisunSidebarService: UnisunSidebarService,
        private _stateService: StateService,
        private _matDialog: MatDialog
    ) {
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._qbankService.onQuestionSetChanged.next(searchText);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * New qbank
     */
    newQuestionSet(): void {

        this.dialogRef = this._matDialog.open(ARFilterQuestionSetFormDialogComponent, {
            panelClass: 'qbank-form-dialog',
            data: {
                action: 'new'
            }
        });
        this.newQuestionSetCallback();

    }

    newQuestionSetCallback() {
        this.dialogRef.afterClosed()
            .subscribe((response: QuestionSet) => {
                if (!response) {
                    return;
                }

                this._qbankService.createQuestionSet(response);
            });
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._unisunSidebarService.getSidebar(name).toggleOpen();
    }
}
