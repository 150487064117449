export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'SETTINGS': 'Settings',
            'DASHBOARDS': 'Dashboards',
            'MASTER': 'Localization',
            'USERMANAGEMENT': 'Usermanagement',
            'REPORT': 'Reports',
            'EXAM_REPORT': 'Exam Activity',
            'COLLECTION': 'Collections',
            'ACADEMY': 'Course',
            'TESTSERIES': 'Test Series',
            'VIRTUAL_LEARN': 'Virtual Learn',
            'MAIL-CONFIGURATION':'Mail Configuration',
            'MYRANK':'My Rank',
            'MAIL': {
                'TITLE': 'Mail',
                'BADGE': '25'
            },
            'MAIL_NGRX': {
                'TITLE': 'Mail Ngrx',
                'BADGE': '13'
            },
            'CONTACTS': 'Contacts',
            'TODO': 'To-Do',
            'BOOKMARKS': 'Bookmarks',
        }
    }
};
