import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select';
import { MatStepperModule} from '@angular/material/stepper';
import { MatListModule} from '@angular/material/list';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatTabsModule} from '@angular/material/tabs';

import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';

import { TestSeriesComponent } from 'app/main/admin/test-series/test-series.component';
import { TestSeriesService } from 'app/main/admin/test-series/test-series.service';
import { TestSeriesListComponent } from 'app/main/admin/test-series/test-series-list/list.component';
import { TestSeriesFormComponent } from 'app/main/admin/test-series/test-series-form/form.component';
import { TestSeriesMainSidebarComponent } from './sidebars/main/main-sidebar.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { UserService } from 'app/_services';
import { MomentModule } from 'ngx-moment';
import { TestSeriesUserPermissionComponent } from './user-list/list.component';
import { TestSeriesUserPermissionService } from './test-series-user-permission.service';

const routes: Routes = [
    {
        path: 'product/test-series',
        component: TestSeriesComponent,
        resolve: {
            contacts: TestSeriesService
        }
    },
    {
        path: 'product/test-series-form',
        component: TestSeriesFormComponent,
        resolve: {
            contacts: TestSeriesService
        }
    },
    {
        path: 'product/test-series-form/:id',
        component: TestSeriesFormComponent,
        resolve: {
            contacts: TestSeriesService
        }
    }
];

@NgModule({
    declarations: [
        TestSeriesComponent,
        TestSeriesListComponent,
        TestSeriesFormComponent,
        TestSeriesMainSidebarComponent,
        TestSeriesUserPermissionComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MomentModule.forRoot({ relativeTimeThresholdOptions: { 'm': 59 } }), 
        
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatListModule,
        MatAutocompleteModule,
        MatTabsModule,


        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule,

        CKEditorModule
    ],
    providers: [
        TestSeriesService,
        UserService,
        TestSeriesUserPermissionService
    ],
    entryComponents: [

    ]
})
export class TestSeriesModule {
}
