import { UnisunConfig } from '@unisun/types';

/**
 * Default Unisun Configuration
 *
 * You can edit these options to change the default options. All these options also can be changed per component
 * basis. See `app/main/security/authentication/login/login.component.ts` constructor method to learn more
 * about changing these options per component basis.
 */

export const unisunConfig: UnisunConfig = {
    layout: {
        style: 'vertical-layout-1', //horizontal-layout-1 ,vertical-layout-1,vertical-layout-2,vertical-layout-3
        width: 'fullwidth',
        navbar: {
            background: 'mat-unisun-black-50-bg',
            folded: false,
            hidden: false,
            position: 'left',
            variant: 'vertical-style-1'
        },
        toolbar: {
            background: 'mat-white-500-bg',
            hidden: false,
            position: 'below-static'
        },
        footer: {
            background: 'mat-unisun-black-900-bg',
            hidden: true,
            position: 'below-fixed'
        },
        sidepanel: {
            hidden: false,
            position: 'right'
        }
    },
    customScrollbars: true
};

export const SitePreference = {

    PAGE: {
        GridRowViewCount: 10,
        GridRowViewPageSizeOptions: [5, 10, 50, 100, 1000]
    }

};

export const CommonType = {
    QuestionType: {
        Single: 1,
        Multiple: 2,
        Various: 3
    },
    ScoreType: {
        Regulare: 1,
        PGI: 2,
        AIIMS: 3
    },

};
