import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Transcription } from 'app/main/admin/video/video.model';

@Component({
    selector: 'transcription-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TranscriptionFormDialogComponent {
    ckeConfig: any;
    action: string;
    video: Transcription;
    videoForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<TranscriptionFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<TranscriptionFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Transcription';
            this.video = _data.video;
        }
        else {
            this.dialogTitle = 'New Transcription';
            this.video = new Transcription({});
        }

        this.videoForm = this.createVideoForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create video form
     *
     * @returns {FormGroup}
     */
    createVideoForm(): FormGroup {
        return this._formBuilder.group({
            Topic: [this.video.Topic],
            TrimStart: [this.video.TrimStart],
            TrimEnd: [this.video.TrimEnd],
            VideoLength: [this.video.VideoLength]
        });
    }
}
