import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { QuestionSet, Choice, AdditionalChoice } from 'app/main/admin/qbank/qbank.model';
import { CommonType, SitePreference } from 'app/config';

@Component({
    selector: 'cmc-qbank-ad-choice-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CMCQuestionAdChoiceFormDialogComponent {
    action: string;
    qbank: AdditionalChoice;
    qbankForm: FormGroup;
    dialogTitle: string;
    _commonType: any = CommonType;

    /**
     * Constructor
     *
     * @param {MatDialogRef<CMCQuestionAdChoiceFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<CMCQuestionAdChoiceFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        // Set the defaults
        this.action = _data.action;
        
        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Additional Choice';
            this.qbank = _data.qbank;
        }
        else {
            this.dialogTitle = 'New Additional Choice';
            this.qbank = new AdditionalChoice({});
        }

        this.qbankForm = this.createQuestionChoiceForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create qbank form
     *
     * @returns {FormGroup}
     */
    createQuestionChoiceForm(): FormGroup {
        return this._formBuilder.group({
            QuestionSetID: [this.qbank.QuestionSetID],
            ChoiceID: [this.qbank.ChoiceID],
            ChoiceText: [this.qbank.ChoiceText]
        });
    }
}
