import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


import { Todo } from 'app/main/admin/todo/todo.model';
import { environment } from 'environments/environment';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TodoService implements Resolve<any>
{
    onTodoChanged: BehaviorSubject<any>;

    todo: Todo[];
    user: any;
    selectedTodo: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onTodoChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterTodoSnapshot} todo
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, todo: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getTodoForGrid(gridFilter)
            ]).then(
                ([]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get todo by Id
     *
     * @returns {Promise<any>}
     */
    getTodoById(id): Promise<Todo[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/fundamental/get-todo-by-id/` + id)
                .subscribe((response: any) => {

                    this.todo = response;

                    this.onTodoChanged.next(this.todo);
                    resolve(this.todo);
                }, reject);
        }
        );
    }
    /**
     * Get todo
     *
     * @returns {Promise<any>}
     */
    getTodo(): Promise<Todo[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/fundamental/get-todos`)
                .subscribe((response: any) => {

                    this.todo = response;

                    this.onTodoChanged.next(this.todo);
                    resolve(this.todo);
                }, reject);
        }
        );
    }

    /**
     * Get todo
     *
     * @returns {Promise<any>}
     */
    getTodoForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/fundamental/get-grid-todos/`, { ..._gridFilter });
    }

    /**
     * Update todo
     *
     * @param todo
     * @returns {Promise<any>}
     */
    createTodo(todo: Todo): Promise<any> {
        return new Promise((resolve) => {

            this._httpClient.post(`${environment.apiURL}/fundamental/create-todo/`, { ...todo })
                .subscribe(response => {
                    if (response) {
                        this.onTodoChanged.next(this.todo);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                    resolve(response);
                });
        });
    }

    /**
     * Update todo
     *
     * @param todo
     * @returns {Promise<any>}
     */
    updateTodo(todo: Todo): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/fundamental/update-todo/`, { ...todo })
                .subscribe(response => {
                    if (response) {
                        this.onTodoChanged.next(this.todo);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete todo
     *
     * @param todo
     */
    deleteTodo(todo: Todo): Promise<any> {
        return new Promise(() => {

            this._httpClient.delete(`${environment.apiURL}/fundamental/delete-todo/` + todo.TodoID, {})
                .subscribe(response => {
                    if (response) {
                        this.onTodoChanged.next(this.todo);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

}
