import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


import { VideoDetails } from 'app/main/admin/video/video.model';
import { environment } from 'environments/environment';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class VideoDetailsService implements Resolve<any>
{
    onVideoDetailsChanged: BehaviorSubject<any>;

    video: VideoDetails[];
    user: any;
    selectedVideoDetails: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onVideoDetailsChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterVideoDetailsSnapshot} video
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, video: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                // this.getVideoDetailsForGrid(gridFilter)
            ]).then(
                ([]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get video by Id
     *
     * @returns {Promise<any>}
     */
    getVideoDetailsById(id): Promise<VideoDetails[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/fundamental/get-video-by-id/` + id)
                .subscribe((response: any) => {

                    this.video = response;

                    this.onVideoDetailsChanged.next(this.video);
                    resolve(this.video);
                }, reject);
        }
        );
    }
    /**
     * Get video
     *
     * @returns {Promise<any>}
     */
    getVideoDetails(): Promise<VideoDetails[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/fundamental/get-video-details`)
                .subscribe((response: any) => {

                    this.video = response;

                    this.onVideoDetailsChanged.next(this.video);
                    resolve(this.video);
                }, reject);
        }
        );
    }

    /**
     * Get video
     *
     * @returns {Promise<any>}
     */
    getVideoDetailsForGrid(_gridFilter: GridFilter, id: number): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/fundamental/get-grid-video-details/` + id, { ..._gridFilter });
    }

    /**
     * Update video
     *
     * @param video
     * @returns {Promise<any>}
     */
    createVideoDetails(video: VideoDetails): Promise<any> {
        return new Promise((resolve) => {

            this._httpClient.post(`${environment.apiURL}/fundamental/create-video-details/`, { ...video })
                .subscribe(response => {
                    if (response) {
                        this.onVideoDetailsChanged.next(this.video);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                    resolve(response);
                });
        });
    }

    /**
     * Update video
     *
     * @param video
     * @returns {Promise<any>}
     */
    updateVideoDetails(video: VideoDetails): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/fundamental/update-video-details/`, { ...video })
                .subscribe(response => {
                    if (response) {
                        this.onVideoDetailsChanged.next(this.video);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete video
     *
     * @param video
     */
    deleteVideoDetails(video): Promise<any> {
        return new Promise(() => {

            this._httpClient.delete(`${environment.apiURL}/fundamental/delete-video-details/` + video.VideoDetailID, {})
                .subscribe(response => {
                    if (response) {
                        this.onVideoDetailsChanged.next(this.video);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

}
