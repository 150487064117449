import { UnisunUtils } from '@unisun/utils';

export class Note {
    NoteID: number = 0;
    Title: string;
    IsActive: boolean;
    /**
     * Constructor
     *
     * @param note
     */
    constructor(value) {
        {
            this.NoteID = value.NoteID || 0;
            this.Title = value.Title || '';
            this.IsActive = value.IsActive || false
        }
    }
}

export class NoteDetails {
    NoteID: number = 0;
    NoteDetailID: number = 0;
    QueueID: number;
    Subject: string;
    SubTitle: string;
    Content: string;
    Title: string;
    /**
     * Constructor
     *
     * @param NoteDetails
     */
    constructor(value) {
        {
            this.NoteID = value.NoteID || 0;
            this.NoteDetailID = value.NoteDetailID || 0;
            this.Title = value.Title || '';
            this.QueueID = value.QueueID || 0;
            this.Subject = value.Subject || '';
            this.SubTitle = value.SubTitle || '';
            this.Content = value.Content || '';
        }
    }
}