
export class ARFilterTopicLevel {
    TrnVRExamID: number;
    LevelName: string;
    LevelID: number;
    QueueNo: string;
    QuestionSetID: number;
    ExamID: number;
    IsActive: boolean;
    /**
     * Constructor
     *
     * @param value
     */
    constructor(value) {
        {
            this.TrnVRExamID = value.TrnVRExamID || 0;
            this.LevelName = value.LevelName || ''; //|| UnisunUtils.generateGUID();
            this.LevelID = value.LevelID || '';
            this.QueueNo = value.QueueNo || 0;
            this.QuestionSetID = value.QuestionSetID || 0;
            this.ExamID = value.ExamID || 0;
            this.IsActive = value.IsActive ;
        }
    }
}