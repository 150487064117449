<div class="content mat-white-bg p-24">
    <div >

        <div class="main-div">

            <!-- <input matInput placeholder="User" aria-label="Exam" [matAutocomplete]="auto" width="500px" required> -->
            <!-- <mat-autocomplete #auto="matAutocomplete" id="examsearch" panelWidth="500px">
                <mat-option [value]="56">
                    <span>user.UserName</span> |
                    <small>Email: user.Email</small>
                </mat-option>
            </mat-autocomplete> -->

            <!-- <div class="section-1"> -->
                <form [formGroup] ="Adddata" class="section-1">
                    <mat-form-field>
                        <mat-label>Category Type</mat-label>
                        <mat-select formControlName="CategoryType">
                            <mat-option value='' disabled>select any</mat-option>
                            <mat-option value="SWT">SWT</mat-option>
                            <mat-option value="PYQ">PYQ</mat-option>
                            <mat-option value="CBME">CBME</mat-option>
                            <mat-option value="GRNT">GRNT</mat-option>
                            <mat-option value="GRNMT">GRNMT</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Exam Name</mat-label>
                        <mat-select formControlName="ExamName">
                            <mat-option value='' disabled>select any</mat-option>
                            <mat-option value="Exam1">Exam 1</mat-option>
                            <mat-option value="Exam2">Exam 2</mat-option>
                            <mat-option value="Exam3">Exam 3</mat-option>
                            <mat-option value="Exam4">Exam 4</mat-option>
                            <mat-option value="Exam5">Exam 5</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <mat-label>Custom Exam Name</mat-label>
                        <input matInput formControlName="CustomExamName" placeholder="Exam Name">
                    </mat-form-field>
                    <button mat-raised-button class="save-button mat-accent-bg" aria-label="ADD" (click)="addtest()" [disabled]="Adddata.invalid">
                        ADD
                    </button>
                </form>
            <!-- </div> -->

            

        </div>

    </div>



<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-full">

    <ng-container matColumnDef="categorytype">
      <th mat-header-cell *matHeaderCellDef> Category Type </th>
      <td mat-cell *matCellDef="let data"> {{data.CategoryType}} </td>
    </ng-container>
  
    <ng-container matColumnDef="customexamName">
      <th mat-header-cell *matHeaderCellDef> Custom Exam Name </th>
      <td mat-cell *matCellDef="let data"> {{data.CustomExamName}} </td>
    </ng-container>
  
    <ng-container matColumnDef="examname">
      <th mat-header-cell *matHeaderCellDef> Exam Name </th>
      <td mat-cell *matCellDef="let data"> {{data.ExamName}} </td>
    </ng-container>

    <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef> Delete </th>
        <td mat-cell *matCellDef="let data">
          <button mat-icon-button color="warn" (click)="deleteRow(data)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
  </table>
  