import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunSidebarService } from '@unisun/components/sidebar/sidebar.service';

import { TrandingVideo } from 'app/main/admin/live-intract-video/tranding-video.model';
import { TrandingVideoService } from 'app/main/admin/live-intract-video/tranding-video.service';
import { TrandingVideoFormDialogComponent } from 'app/main/admin/live-intract-video/form/form.component';

@Component({
    selector     : 'tranding-video',
    templateUrl  : './tranding-video.component.html',
    styleUrls    : ['./tranding-video.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : unisunAnimations
})
export class TrandingVideoComponent implements OnInit, OnDestroy
{
    dialogRef: any;
    hasSelectedTrandingVideo: boolean;
    searchInput: FormControl;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {TrandingVideoService} _tranding-videoService
     * @param {UnisunSidebarService} _unisunSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _trandingvideoService: TrandingVideoService,
        private _unisunSidebarService: UnisunSidebarService,
        private _matDialog: MatDialog
    )
    {
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._trandingvideoService.onTrandingVideoChanged.next(searchText);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * New tranding-video
     */
    newTrandingVideo(): void
    {
        this.dialogRef = this._matDialog.open(TrandingVideoFormDialogComponent, {
            panelClass: 'tranding-video-form-dialog',
            data      : {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: TrandingVideo) => {
                if ( !response )
                {
                    return;
                }

                this._trandingvideoService.createTrandingVideo(response);
            });
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._unisunSidebarService.getSidebar(name).toggleOpen();
    }
}
