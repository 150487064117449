import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { UnisunUtils } from '@unisun/utils';

import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'app/_models';

@Injectable()
export class UserService implements Resolve<any>
{
    onUserChanged: BehaviorSubject<any>;

    user: User[];
    selectedUser: string[] = [];

    searchText: string;
    filterBy: string;
    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onUserChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} user
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, user: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getUserForGrid(gridFilter)
            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<User[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/user/get-users`)
                .subscribe((response: any) => {

                    this.user = response;

                    this.onUserChanged.next(this.user);
                    resolve(this.user);
                }, reject);
        }
        );
    }
    /**
  * Get user
  *
  * @returns {Promise<any>}
  */
    getUserBySearch(search: string): Promise<User[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/user/get-users-search/` + search)
                .subscribe((response: any) => {

                    this.user = response;

                    resolve(this.user);
                }, reject);
        }
        );
    }
    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUserForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/user/get-grid-users`, { ..._gridFilter });
    }

    /**
     * Update user
     *
     * @param user
     * @returns {Promise<any>}
     */
    createUser(user: User): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/user/create-user/`, { ...user })
                .subscribe(response => {
                    if (response) {
                        this.onUserChanged.next(this.user);
                        this.openSnackBar("Successfully added.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Update user
     *
     * @param user
     * @returns {Promise<any>}
     */
    updateUser(user: User): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/user/update-user/`, { ...user })
                .subscribe(response => {
                    if (response) {
                        this.onUserChanged.next(this.user);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete user
     *
     * @param user
     */
    deleteUser(user): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.delete(`${environment.apiURL}/user/delete-user/` + user.UserID, {})
                .subscribe(response => {
                    if (response) {
                        this.onUserChanged.next(this.user);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Update user
     *
     * @param user
     * @returns {Promise<any>}
     */
    updateUserAccount(user: any): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/api/user/update-user-account/`, { ...user })
                .subscribe(response => {
                    resolve(response);
                    if (response) {
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
    * Update user
    *
    * @param user
    * @returns {Promise<any>}
    */
    updateUserAccountPassword(user: any): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/api/user/update-user-account-password/`, { ...user })
                .subscribe(response => {
                    resolve(response);
                    if (response) {
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }
    /**
         * Get user
         *
         * @returns {Promise<any>}
         */
    getUserById(userId: number): Promise<User> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/user/get-user-by-id/` + userId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        }
        );
    }

    /**
         * Send one time password user
         *
         * @returns {Promise<any>}
         */
    sendOTP(mobile: any): Promise<User> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/api/user/get-otp/` + mobile)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        }
        );
    }

    /**
         * Verify user otp password
         *
         * @returns {Promise<any>}
    */
    verifyOTP(mobile: any, passcode: string): Promise<User> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/api/user/verify-otp/` + mobile + '/' + passcode)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        }
        );
    }

    /**
    * forget password
    *
    * @returns {Promise<any>}
   */
    forgetPassword(request: any): Promise<User> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/api/user/forget-password/`, { ...request })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        }
        );
    }
}
