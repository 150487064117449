import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


import { Hippo } from 'app/main/admin/hippo/hippo.model';
import { environment } from 'environments/environment';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class HippoService implements Resolve<any>
{
    onHippoChanged: BehaviorSubject<any>;

    hippo: Hippo[];
    user: any;
    selectedHippo: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onHippoChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterHippoSnapshot} hippo
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, hippo: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getHippoForGrid(gridFilter)
            ]).then(
                ([]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get hippo by Id
     *
     * @returns {Promise<any>}
     */
    getHippoById(id): Promise<Hippo[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/fundamental/get-hippo-by-id/` + id)
                .subscribe((response: any) => {

                    this.hippo = response;

                    this.onHippoChanged.next(this.hippo);
                    resolve(this.hippo);
                }, reject);
        }
        );
    }
    /**
     * Get hippo
     *
     * @returns {Promise<any>}
     */
    getHippo(): Promise<Hippo[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/fundamental/get-hippos`)
                .subscribe((response: any) => {

                    this.hippo = response;

                    this.onHippoChanged.next(this.hippo);
                    resolve(this.hippo);
                }, reject);
        }
        );
    }

    /**
     * Get hippo
     *
     * @returns {Promise<any>}
     */
    getHippoForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/fundamental/get-grid-hippos/`, { ..._gridFilter });
    }

    /**
     * Update hippo
     *
     * @param hippo
     * @returns {Promise<any>}
     */
    createHippo(hippo: Hippo): Promise<any> {
        return new Promise((resolve) => {

            this._httpClient.post(`${environment.apiURL}/fundamental/create-hippos/`, { ...hippo })
                .subscribe(response => {
                    if (response) {
                        this.onHippoChanged.next(this.hippo);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                    resolve(response);
                });
        });
    }

    /**
     * Update hippo
     *
     * @param hippo
     * @returns {Promise<any>}
     */
    updateHippo(hippo: Hippo): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/fundamental/update-hippos/`, { ...hippo })
                .subscribe(response => {
                    if (response) {
                        this.onHippoChanged.next(this.hippo);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete hippo
     *
     * @param hippo
     */
    deleteHippo(hippo): Promise<any> {
        return new Promise(() => {

            this._httpClient.delete(`${environment.apiURL}/fundamental/delete-hippos/` + hippo.HipposID, {})
                .subscribe(response => {
                    if (response) {
                        this.onHippoChanged.next(this.hippo);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

}
