import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'app/main/admin/course/course.service';
import { TestSeriesService } from 'app/main/admin/test-series/test-series.service';
import { Exam } from 'app/main/admin/exam/exam.model';
import { RankingFilter, UserRankStatistics, PositionAnalysis } from 'app/_models/UserRankStatistics';
import { DataGuardService } from 'app/_guards';
import { HelperService } from 'app/_helpers/helper.service';
import { User } from 'app/_models/user';
import { GameViewService } from 'app/main/apps/academy/game-view.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'rank-view',
    templateUrl: './ranking.component.html',
    styleUrls: ['./ranking.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AdminRankingComponent implements OnInit {
    myrwdata: any;
    gtsanalysis: any;
    posanalysis: any;

    Type: number;
    TypeID: number;
    ExamID: number;
    Email: string;
    title: string = '';

    TypeList: any;
    ExamList: Array<Exam>;

    userData: User;
    userRankStatistics: UserRankStatistics;

    examForm: FormGroup;
    constructor(
        private _route: ActivatedRoute,
        private _courseService: CourseService,
        private _testseriesService: TestSeriesService,
        private _gameViewService: GameViewService,
        private _formBuilder: FormBuilder,
    ) {
        var self = this;
        this.examForm=this.createForm();
    }

    OnChangeType(TypeID: number) {
        var self = this;
        self.ExamList = [];
        self.userRankStatistics = null;
        if (self.Type == 1) {
            self._courseService.getCourseById(TypeID).subscribe(function (data) {
                self.ExamList = data.ExamID;
            });
        }
        else if (self.Type == 2) {
            self._testseriesService.getTestSeriesById(TypeID).subscribe(function (data) {
                self.ExamList = data.ExamID;
            });
        }
    }

    createForm(): FormGroup {
        return this._formBuilder.group({
            TypeID: [this.TypeID],
            ExamID: [this.ExamID],
            Email: [this.Email, Validators.email],
        });
    }

    fetchResult() {
        var self = this;

        var request: RankingFilter = {
            Type: self.Type,
            TypeID: self.TypeID,
            ExamID: self.ExamID,
            EmailID: self.Email
        };
        self.userRankStatistics = null;
        self._gameViewService.GetRankingAnalysisReportAsync(request).then(function (response) {
            self.userRankStatistics = response;
            self.processingChart(response);

        });
    }

    ngOnInit() {

        var self = this;

        self._route.params.subscribe(function (parram) {
            self.title = parram.type;
            self.Type = parram.type == 'course' ? 1 : 2;

            if (self.Type == 1) {
                self._courseService.getCourse().subscribe(function (data) {
                    self.TypeList = data;
                });
            }
            else if (self.Type == 2) {
                self._testseriesService.getTestSeries().subscribe(function (data) {
                    self.TypeList = data;
                });
            }
        });


    }

    processingChart(_userRankStatistics: UserRankStatistics) {
        this.myrwdata = {
            tooltip: {
                trigger: 'item',
                formatter: "{d}%",
            },
            legend: {
                align: 'left',
                data: ['Challenge the Wrong Ones in Life', 'Remember and Enjoy the Right Ones', 'Fill in the Blanks Next Time'],
                textStyle: {
                    color: '#575757',
                    fontSize: 10
                },
                itemHeight: 5,
                itemWidth: 10,
                itemGap: 2,
                orient: 'vertical',
                top: 'top',
                left: 'left',
            },
            series: [
                {
                    name: 'gts',
                    type: 'pie',
                    radius: '80%',
                    center: ['50%', '50%'],
                    selectedMode: 'single',
                    color: ['#ef5350', '#9ccc65', '#ffca28'],
                    label: {
                        normal: { formatter: '{d}%' },
                    },
                    data: [
                        { value: _userRankStatistics.Variation.Correct, name: 'Challenge the Wrong Ones in Life' },
                        { value: _userRankStatistics.Variation.Wrong, name: 'Remember and Enjoy the Right Ones' },
                        { value: _userRankStatistics.Variation.Blank, name: 'Fill in the Blanks Next Time' }
                    ]
                }
            ]
        };

        var gtanaldata = [_userRankStatistics.GTSAnalysis.LowestScore, _userRankStatistics.GTSAnalysis.AverageScore, _userRankStatistics.GTSAnalysis.HighestScore, _userRankStatistics.GTSAnalysis.YourRank];

        var links = gtanaldata.map(function (item, i) {
            return {
                source: i,
                target: i + 1
            };
        });

        this.gtsanalysis = {
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['Lowest', 'Average', 'Highest', 'You'],
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    color: ['#ef5350', '#9ccc65', '#ffca28'],
                    type: 'graph',
                    layout: 'none',
                    coordinateSystem: 'cartesian2d',
                    symbolSize: 50,
                    label: {
                        normal: {
                            show: true,
                            formatter: '{b} \n {c}',
                        }
                    },
                    edgeSymbol: ['circle', 'arrow'],
                    edgeSymbolSize: [4, 10],
                    data: gtanaldata,
                    links: links,
                    lineStyle: {
                        normal: {
                            color: '#2f4554'
                        }
                    }
                }
            ]
        };

        let _poDataXAxis = [];
        let _poDataYAxis = [];

        _userRankStatistics.PositionAnalysis.forEach(function (value: PositionAnalysis) {
            _poDataXAxis.push(value.Title);
        });

        _userRankStatistics.PositionAnalysis.forEach(function (value: PositionAnalysis) {
            _poDataYAxis.push(value.Score);
        });

        this.posanalysis = {
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                splitLine: { show: false },
                data: _poDataXAxis,
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
            },
            yAxis: {
                type: 'value'
            },
            series: [

                {
                    name: 'Rank',
                    type: 'bar',
                    stack: 'rank',
                    color: ['#42a5f5'],
                    label: {
                        normal: {
                            show: true,
                            position: 'insideBottom',
                            distance: 15,
                            align: 'left',
                            verticalAlign: 'middle',
                            rotate: 90,
                            formatter: '{b} -  {c}',
                        }
                    },
                    data: _poDataYAxis
                },
                {
                    name: 'Your Rank',
                    type: 'bar',
                    stack: 'rank',
                    color: ['#9ccc65'],
                    label: {
                        normal: {
                            show: true,
                            position: 'insideBottom',
                            distance: 15,
                            align: 'left',
                            verticalAlign: 'middle',
                            rotate: 90,
                            formatter: '{b} -  {c}',
                        }
                    },
                    data: ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', _userRankStatistics.UserRank.AllIndiaRank]
                }
            ]
        };
    }
}
