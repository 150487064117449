import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { HipposDetailsService } from 'app/main/admin/hippo/hippo-detail.service';
import { Hippo, HipposDetails } from '../hippo.model';
import { GridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute } from '@angular/router';
import { SitePreference } from 'app/config';

import { HipposDetailsFormDialogComponent } from '../hippo-detail-form/form.component';
import { DataGuardService } from 'app/_guards/data.guard';

@Component({
    selector: 'hippo-detail',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class HipposDetailsComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;
    HipposID: number;
    hippo: any;
    paginationData: any;

    dataSource: HipposDetailsDataSource;
    displayedColumns = ['QueueID', 'Title', 'buttons'];
    selectedHipposDetails: any[];
    checkboxes: {};
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;


    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    hasSelectedHippo: boolean;
    searchInput: FormControl;

    // Private

    /**
     * Constructor
     *
     * @param {HipposDetailsService} _hippoService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _hippoService: HipposDetailsService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _dataGuardService: DataGuardService
    ) {
        var self = this;
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.route.params.subscribe(function (parram) {

            self.HipposID = self._dataGuardService.valueDecryption(parram.id);;
        });
    }

    ngAfterViewInit() {

    }

    loadPage() {
        this._hippoService.onHipposDetailsChanged.next(this.hippo);
    }


    getNext(event: PageEvent) {
        this._hippoService.onHipposDetailsChanged.next(this.hippo);

    }


    onSortData(sort: Sort) {

        this._hippoService.onHipposDetailsChanged.next(this.hippo);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.dataSource = new HipposDetailsDataSource(this._hippoService, this.HipposID);

        this._hippoService.onHipposDetailsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.hippo = search;
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == null ? "Title" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc'
                };
                this.dataSource.loadData(gridFilter);
            });

        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._hippoService.onHipposDetailsChanged.next(searchText);
            });
    }

    /**
    * New hippo
    */
    newHippoDetail(): void {

        this.dialogRef = this._matDialog.open(HipposDetailsFormDialogComponent, {
            panelClass: 'hippo-detail-form-dialog',
            data: {
                action: 'new'
            }
        });
        this.newHippoDetailCallback();

    }

    newHippoDetailCallback() {
        var self = this;
        this.dialogRef.afterClosed()
            .subscribe((response: HipposDetails) => {
                if (!response) {
                    return;
                }
                response.HipposID = this.HipposID;
                this._hippoService.createHipposDetails(response);
            });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Edit hippo
     *
     * @param hippo
     */
    editHipposDetails(hippo): void {

        this.dialogRef = this._matDialog.open(HipposDetailsFormDialogComponent, {
            panelClass: 'hippo-detail-form-dialog',
            data: {
                hippo: hippo,
                action: 'edit'
            }
        });
        this.editHipposDetailsCallback(hippo);

    }

    editHipposDetailsCallback(hippo: HipposDetails) {
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: HipposDetails = response[1];
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':

                        this._hippoService.updateHipposDetails(formData);

                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deleteHipposDetails(hippo);

                        break;
                }
            });
    }

    /**
     * Delete HipposDetails
     */
    deleteHipposDetails(hippo): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._hippoService.deleteHipposDetails(hippo);
            }
            this.confirmDialogRef = null;
        });

    }

}

export class HipposDetailsDataSource extends DataSource<HipposDetails>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<HipposDetails>;
    /**
     * Constructor
     *
     * @param {HipposDetailsService} _hippoService
     */
    constructor(
        private _hippoService: HipposDetailsService,
        private _id: number
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._hippoService.onHipposDetailsChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) {
        var self = this;

        this._hippoService.getHipposDetailsForGrid(gridFilter, this._id)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);


                self.paginationData = {
                    count: response.Count || 0,
                    pageNumber: response.CurrentFilter == undefined ? 0 : response.CurrentFilter.PageNumber
                };


            });
    }
}
