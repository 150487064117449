
    <!-- PROGRESS BAR -->
    <unisun-progress-bar></unisun-progress-bar>
    <!-- / PROGRESS BAR -->

    <!-- VERTICAL LAYOUT 1 -->
    <ng-container *ngIf="unisunConfig.layout.style === 'vertical-layout-1'">
        <vertical-layout-1></vertical-layout-1>
    </ng-container>
    <!-- / VERTICAL LAYOUT 1 -->

    <!-- VERTICAL LAYOUT 2 -->
    <ng-container *ngIf="unisunConfig.layout.style === 'vertical-layout-2'">
        <vertical-layout-2></vertical-layout-2>
    </ng-container>
    <!-- / VERTICAL LAYOUT 2 -->

    <!-- VERTICAL LAYOUT 3 -->
    <ng-container *ngIf="unisunConfig.layout.style === 'vertical-layout-3'">
        <vertical-layout-3></vertical-layout-3>
    </ng-container>
    <!-- / VERTICAL LAYOUT 3 -->

    <!-- HORIZONTAL LAYOUT 1 -->
    <ng-container *ngIf="unisunConfig.layout.style === 'horizontal-layout-1'">
        <horizontal-layout-1></horizontal-layout-1>
    </ng-container>
    <!-- / HORIZONTAL LAYOUT 1 -->

    <!-- THEME OPTIONS PANEL -->
    <!-- <button mat-icon-button class="mat-primary-bg mat-elevation-z2 theme-options-button"
        [ngClass]="{'right-side-panel': unisunConfig.layout.sidepanel.position === 'right',
                    'side-panel-hidden': unisunConfig.layout.sidepanel.hidden === true}"
        (click)="toggleSidebarOpen('themeOptionsPanel')">
    <mat-icon>settings</mat-icon>
</button> -->

    <!-- <unisun-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <unisun-theme-options></unisun-theme-options>
</unisun-sidebar> -->
    <!-- / THEME OPTIONS PANEL -->
