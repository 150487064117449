import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Note } from 'app/main/admin/note/note.model';
import { State } from 'app/main/admin/state/state.model';

@Component({
    selector     : 'note-form-dialog',
    templateUrl  : './form.component.html',
    styleUrls    : ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NoteFormDialogComponent
{
    action: string;
    note: Note;
    noteForm: FormGroup;
    dialogTitle: string;

    states: State[];
    /**
     * Constructor
     *
     * @param {MatDialogRef<NoteFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<NoteFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.action = _data.action;
        this.states = _data.states;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Edit Note';
            this.note = _data.note;
        }
        else
        {
            this.dialogTitle = 'New Note';
            this.note = new Note({});
        }

        this.noteForm = this.createNoteForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create note form
     *
     * @returns {FormGroup}
     */
    createNoteForm(): FormGroup
    {
        return this._formBuilder.group({
            NotesID      : [this.note.NoteID],
            Title    : [this.note.Title],
            IsActive    : [this.note.IsActive]
        });
    }
}
