import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { UnisunNavigationComponent } from './navigation.component';
import { UnisunNavVerticalItemComponent } from './vertical/item/item.component';
import { UnisunNavVerticalCollapsableComponent } from './vertical/collapsable/collapsable.component';
import { UnisunNavVerticalGroupComponent } from './vertical/group/group.component';
import { UnisunNavHorizontalItemComponent } from './horizontal/item/item.component';
import { UnisunNavHorizontalCollapsableComponent } from './horizontal/collapsable/collapsable.component';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports     : [
        CommonModule,
        RouterModule,

        MatIconModule,
        MatRippleModule,

        TranslateModule.forChild()
    ],
    exports     : [
        UnisunNavigationComponent
    ],
    declarations: [
        UnisunNavigationComponent,
        UnisunNavVerticalGroupComponent,
        UnisunNavVerticalItemComponent,
        UnisunNavVerticalCollapsableComponent,
        UnisunNavHorizontalItemComponent,
        UnisunNavHorizontalCollapsableComponent
    ]
})
export class UnisunNavigationModule
{
}
