<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            Temp Order ID
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            <span class="mat-blue-bg m-4 text-boxed">{{paymenthistory.TempReferenceID}}</span>
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            Order ID
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            <span class="mat-blue-bg m-4 text-boxed">{{paymenthistory.OrderReferenceID}}</span>
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            Bank Reference ID
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            <span class="mat-blue-bg m-4 text-boxed">{{paymenthistory.BankReferenceID}}</span>
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            Order Date
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            {{paymenthistory.CreatedOn}}
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            Name
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            {{paymenthistory.UserName}}
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            Email
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            {{paymenthistory.Email}}
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            Mobile No
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            {{paymenthistory.Mobile}}
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            Payment Mode
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            {{paymenthistory.PaymentMethodName}}
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            Product Name
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            {{paymenthistory.ProductName}}
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            Plan
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            <span class="mat-blue-bg m-4 text-boxed">{{paymenthistory.Plan}} Months</span>
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            Status
        </div>
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start end">
            <span class="mat-blue-bg m-4 text-boxed" *ngIf="paymenthistory.Status==1">Initiated</span>
            <span class="mat-green-bg m-4 text-boxed" *ngIf="paymenthistory.Status==2">Paid</span>
            <span class="mat-yello-bg m-4 text-boxed" *ngIf="paymenthistory.Status==3">Refund</span>
            <span class="mat-red-bg m-4 text-boxed" *ngIf="paymenthistory.Status==4">Cancelled</span>
            <span class="mat-red-bg m-4 text-boxed" *ngIf="paymenthistory.Status==5">Payment Failed</span>
            <span class="mat-red-bg m-4 text-boxed" *ngIf="paymenthistory.Status==6">Refund Failed</span>
        </div>


    </div>

    <!-- <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(paymenthistory)"
            class="save-button mat-accent-bg" [disabled]="paymenthistoryForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',paymenthistory])"
            class="save-button mat-accent-bg" [disabled]="paymenthistoryForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',paymenthistory])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div> -->
</div>