import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTabsModule} from '@angular/material/tabs';
import { MatListModule} from '@angular/material/list';

import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';
import { CKEditorModule } from 'ng2-ckeditor';

import { QuestionSetComponent } from 'app/main/admin/qbank/qbank.component';
import { QuestionSetService } from 'app/main/admin/qbank/qbank.service';
import { QuestionSetListComponent } from 'app/main/admin/qbank/qbank-list/list.component';
import { QuestionSetFormDialogComponent } from 'app/main/admin/qbank/qbank-form/form.component';
import { QuestionDetailFormDialogComponent } from './qbank-detail-form/form.component';
import { QuestionDetailComponent } from './qbank-detail/list.component';
import { QuestionDetailService } from './qbank-detail.service';
import { QuestionChoiceFormDialogComponent } from './qbank-choice-form/form.component';
import { QuestionAdChoiceFormDialogComponent } from './qbank-ad-choice-form/form.component';

const routes: Routes = [
    {
        path: 'collections/qbank',
        component: QuestionSetComponent,
        resolve: {
            service: QuestionSetService
        }
    },
    {
        path: 'collections/qbank/:id',
        component: QuestionDetailComponent,
        resolve: {
            service: QuestionDetailService
        }
    }
];

@NgModule({
    declarations: [
        QuestionSetComponent,
        QuestionSetListComponent,
        QuestionSetFormDialogComponent,
        QuestionDetailFormDialogComponent,
        QuestionDetailComponent,
        QuestionChoiceFormDialogComponent,
        QuestionAdChoiceFormDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatListModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule,

        CKEditorModule
    ],
    providers: [
        QuestionSetService,
        QuestionDetailService
    ],
    entryComponents: [
        QuestionSetFormDialogComponent,
        QuestionDetailFormDialogComponent,
        QuestionChoiceFormDialogComponent,
        QuestionAdChoiceFormDialogComponent
    ]
})
export class QuestionSetModule {
}
