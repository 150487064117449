import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UnisunConfigService } from '@unisun/services/config.service';

import { UnisunSidebarService } from '@unisun/components/sidebar/sidebar.service';
import { UnisunSplashScreenService } from '@unisun/services/splash-screen.service';
import { UnisunTranslationLoaderService } from '@unisun/services/translation-loader.service';


import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { AuthenticationService } from './_services';
import { DataGuardService } from './_guards';
import { Router } from '@angular/router';
import { HelperService } from './_helpers/helper.service';
import { User } from './_models';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    unisunConfig: any;
    userData: User;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {UnisunConfigService} _UnisunConfigService
     * @param {UnisunNavigationService} _unisunNavigationService
     * @param {UnisunSidebarService} _unisunSidebarService
     * @param {UnisunSplashScreenService} _unisunSplashScreenService
     * @param {UnisunTranslationLoaderService} _unisunTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _UnisunConfigService: UnisunConfigService,

        private _unisunSidebarService: UnisunSidebarService,
        private _unisunSplashScreenService: UnisunSplashScreenService,
        private _unisunTranslationLoaderService: UnisunTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _authenticationService: AuthenticationService,
        private _dataGuardService: DataGuardService,
        private _router: Router,
        private _helperService: HelperService
    ) {
        var self = this;

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._unisunTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Token generation
        //this._authenticationService.GetToken();

        // User Data if already logged in 
        let user = this._dataGuardService.getCookieData('lms-user');

        if (user) {
            self.userData = JSON.parse(user);
            self._helperService.userNavigation(self.userData);
        }
        else {
            self._router.navigate(['/security/auth/login']);
        }

       

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._UnisunConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.unisunConfig = config;

                if (this.unisunConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._unisunSidebarService.getSidebar(key).toggleOpen();
    }
}
