import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { UnisunUtils } from '@unisun/utils';

import { Notification } from 'app/main/admin/notification/notification.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NotificationService implements Resolve<any>
{
    onNotificationChanged: BehaviorSubject<any>;

    notification: Notification[];
    user: any;
    selectedNotification: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onNotificationChanged = new BehaviorSubject([]);
    }


        // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getNotificationForGrid(gridFilter)
            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
        });
    }


    /**
     * Get notification
     *
     * @returns {Promise<any>}
     */
    getNotification(): Promise<Notification[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/localization/get-notifications`)
                .subscribe((response: any) => {

                    this.notification = response;;
                    this.onNotificationChanged.next(this.notification);
                    resolve(this.notification);
                }, reject);
        }
        );
    }

    /**
     * Get notification
     *
     * @returns {Promise<any>}
     */
    getNotificationForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/localization/get-grid-notifications`, { ..._gridFilter });
    }

    /**
     * Update notification
     *
     * @param notification
     * @returns {Promise<any>}
     */
    createNotification(notification: Notification): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/localization/create-notification/`, { ...notification })
                .subscribe(response => {
                    if (response) {
                        this.onNotificationChanged.next(this.notification);
                        this.openSnackBar("Successfully added.", "Close");
                    }
                    else{
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Update notification
     *
     * @param notification
     * @returns {Promise<any>}
     */
    updateNotification(notification: Notification): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/localization/update-notification/`, { ...notification })
                .subscribe(response => {
                    if (response) {
                        this.onNotificationChanged.next(this.notification);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else{
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete notification
     *
     * @param notification
     */
    deleteNotification(notification): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.delete(`${environment.apiURL}/localization/delete-notification/` + notification.NotificationID,{})
                .subscribe(response => {
                    if (response) {
                        this.onNotificationChanged.next(this.notification);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else{
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Update notification
     *
     * @param notification
     * @returns {Promise<any>}
     */
    createMessager(notification: Notification): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/api/user/notify/`, { ...notification })
                .subscribe(response => {
                    if (response) {
                        this.onNotificationChanged.next(this.notification);
                        this.openSnackBar("Successfully Notified.", "Close");
                    }
                    else{
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

}
