<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form name="registerForm" [formGroup]="registerForm" novalidate>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field>
                    <input matInput placeholder="Name" formControlName="name" [(ngModel)]="userModel.UserName" required>
                    <mat-error>
                        Name is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field>
                    <input matInput placeholder="Email" formControlName="email" [(ngModel)]="userModel.Email" required>
                    <mat-error *ngIf="registerForm.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field>
                    <input matInput type="number" placeholder="Mobile no" formControlName="mobile" required
                        [(ngModel)]="userModel.Mobile">
                    <mat-error>
                        Mobile number is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field>
                    <input matInput type="text" placeholder="Reg No" formControlName="Reg No" 
                        [(ngModel)]="userModel.UniqueID">
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field>
                    <mat-select placeholder="Country" formControlName="country" required
                        (selectionChange)="onChangeCountry($event)" [(ngModel)]="userModel.CountryID">
                        <mat-option *ngFor="let c of countryList" [value]="c.CountryID">{{c.Country}}</mat-option>
                    </mat-select>
                    <mat-error>
                        Country is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field>
                    <mat-select placeholder="State" formControlName="state" required
                        (selectionChange)="onChangeState($event)" [(ngModel)]="userModel.StateID">
                        <mat-option *ngFor="let c of stateList" [value]="c.StateID">{{c.Name}}</mat-option>
                    </mat-select>
                    <mat-error>
                        State is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field>
                    <mat-select  placeholder="Role" required formControlName="role" [(ngModel)]="userModel.RoleID">
                        <mat-option *ngFor="let u of this.roleOptions" [value]="u.id">{{u.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                        Role is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field>
                    <mat-select placeholder="Institute" required formControlName="institute"
                        [(ngModel)]="userModel.CollegeID">
                        <mat-option *ngFor="let c of instituteList" [value]="c.CollegeID">{{c.Name}}</mat-option>
                    </mat-select>
                    <mat-error>
                        Institute is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field>
                    <mat-select placeholder="Year of passing" required formControlName="category"
                        [(ngModel)]="userModel.CategoryID">
                        <mat-option *ngFor="let c of categoryList" [value]="c.CategoryID">{{c.Category}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Education is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field>
                    <input matInput [matDatepicker]="dateOfBirth" required placeholder="Choose your date of birth"
                        formControlName="dateOfBirth" [(ngModel)]="userModel.DateOfBirth">
                    <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfBirth></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field>
                    <input matInput type="password" placeholder="Password" [(ngModel)]="userModel.Password" required
                        formControlName="password">
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-slide-toggle class="p-4"  formControlName="IsActive" [checked]="userModel.IsActive"
                    [(ngModel)]="userModel.IsActive">
                    Is Active
                </mat-slide-toggle>
            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button mat-raised-button (click)="matDialogRef.close(userModel)" class="save-button mat-accent-bg"
            [disabled]="registerForm.invalid" aria-label="SAVE">
            SAVE
        </button>

    </div>
</div>