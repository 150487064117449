import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup,FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Topic as TopicModel,Module as ModuleModel, Subtopic as SubtopicModel  } from 'app/main/admin/common/subject/common.model'
import { CommonService } from "../../common.service";
import { State } from 'app/main/admin/state/state.model'

@Component({
    selector: 'addSubtopic',
    templateUrl: './addSubTopic.component.html',
    styleUrls: ['./addSubTopic.component.scss'],
    encapsulation: ViewEncapsulation.None
    
})

export class AddSubtopicComponent {

    ckeConfig:any;
    action: string;
    dialogTitle: string;
    topic:TopicModel;
    subtopic: SubtopicModel
    SubtopicForm: FormGroup;
    _Modules:Array<ModuleModel>;

    states:State[];
     /**
     * Constructor
     *
     * @param {MatDialogRef<AddSubtopicComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */

     constructor(
        public matDialogRef: MatDialogRef<AddSubtopicComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
     ) {
        var self =this;
        // Set the defaults
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        this.action = _data.action;
        if (this.action === 'edit') {
            this.dialogTitle = 'Edit SubTopic';
            this.subtopic = _data.subtopic;
            this._Modules=_data.modules;
        }
        else {
            this.dialogTitle = 'New SubTopic';
            this.subtopic = _data.subtopic;
            this._Modules=_data.modules;
        }

        this.SubtopicForm = this.createsubtopicForm();
     }
     

     ngOnInit(): void {
        
     }

     /**
     * Create topic form
     *
     * @returns {FormGroup}
     */
     createsubtopicForm():FormGroup{
        return this._formBuilder.group({
            SubTopicName: [this.subtopic.SubTopicName],
            IsActive:[this.subtopic.IsActive],
            SubTopicID:[this.subtopic.SubTopicID],
            OrderNo:[this.subtopic.OrderNo],
            Modules:this.buildModules(),
        })     
     
    }
    


     buildModules() {
        const arr = this._Modules.map(module => {
         if(this.subtopic.Modules){
            var isSelected=false;
            this.subtopic.Modules.forEach(_module => {
                if(module.ModuleID==_module.ModuleID){
                    isSelected=true;
                }
            });
            return this._formBuilder.control(isSelected);
         }
         else{
           return this._formBuilder.control(false);
         }
        });
        return this._formBuilder.array(arr);
      }
   
      get Modules() {
        return this.SubtopicForm.get('Modules') as FormArray;
      };
    
}