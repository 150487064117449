import { VirtualLearnPages } from './../book.model';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'virtual-learn-page-detail-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class VLPageDetailsFormDialogComponent {
    ckeConfig: any;
    action: string;
    page: VirtualLearnPages;
    pageForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<VLPageDetailsFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<VLPageDetailsFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit page';
            this.page = _data.page;
        }
        else {
            this.dialogTitle = 'New page';
            this.page = new VirtualLearnPages({});
        }

        this.pageForm = this.createpageForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create page form
     *
     * @returns {FormGroup}
     */
    createpageForm(): FormGroup {
        return this._formBuilder.group({
            PageNumber: [this.page.PageNumber],
            // PageReferenceID: [this.page.PageReferenceID],
            NoOfVideos: [this.page.NoOfVideos],
            IsActive: [this.page.IsActive]
        });
    }
}
