import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunSidebarService } from '@unisun/components/sidebar/sidebar.service';

import { Notification } from 'app/main/admin/notification/notification.model';
import { NotificationService } from 'app/main/admin/notification/notification.service';
import { NotificationFormDialogComponent } from 'app/main/admin/notification/form/form.component';

@Component({
    selector     : 'notification',
    templateUrl  : './notification.component.html',
    styleUrls    : ['./notification.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : unisunAnimations
})
export class NotificationComponent implements OnInit, OnDestroy
{
    dialogRef: any;
    hasSelectedNotification: boolean;
    searchInput: FormControl;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {NotificationService} _notificationService
     * @param {UnisunSidebarService} _unisunSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _notificationService: NotificationService,
        private _unisunSidebarService: UnisunSidebarService,
        private _matDialog: MatDialog
    )
    {
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._notificationService.onNotificationChanged.next(searchText);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * New notification
     */
    newNotification(): void
    {
        this.dialogRef = this._matDialog.open(NotificationFormDialogComponent, {
            panelClass: 'notification-form-dialog',
            data      : {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: Notification) => {
                if ( !response )
                {
                    return;
                }

                this._notificationService.createNotification(response);
            });
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._unisunSidebarService.getSidebar(name).toggleOpen();
    }
}
