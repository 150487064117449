<div id="topic" class="page-layout simple left-sidebar inner-sidebar inner-scroll">

    <!-- HEADER -->
    <div class="header mat-accent-bg p-16 p-sm-24 mat-elevation-z6" fxLayout="column"
        fxLayoutAlign="start start" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

        <!-- APP TITLE -->
        <div fxLayout="row" fxLayoutAlign="start center">
            <a  [routerLink]="['/admin/collection/subject/']" mat-raised-button id="back_btn" aria-label="back_btn" 
                [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
                <mat-icon>arrow_back</mat-icon>
             </a>
            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    account_box
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    TOPIC'S
                </span>
            </div>

        </div>
        <!-- / APP TITLE -->

        <!-- SEARCH -->
        <div class="search-input-wrapper mt-16 ml-8 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
            <label for="search" class="mr-8">
                <mat-icon>search</mat-icon>
            </label>
            <mat-form-field mat-no-float class="m-0" floatLabel="never">
                <input matInput id="search" placeholder="Search" [formControl]="searchInput">
            </mat-form-field>
        </div>
        <!-- / SEARCH -->
        <!-- ADD hippo BUTTON -->
        <button mat-raised-button id="add-hippo-button"  aria-label="add hippo" (click)="addTopic()"
            [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
            <mat-icon>add</mat-icon> ADD
        </button>
        <!-- / ADD hippo BUTTON -->
    </div>
    <!-- / HEADER -->
   
    <!-- CONTENT -->
    <div class="content">



        <!-- CENTER -->
        <div class="center p-24 pb-56" unisunPerfectScrollbar>

            <!-- CONTENT -->
            <div class="content mat-white-bg mat-elevation-z4">
                <mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort matSortActive="Name"
                matSortDirection="desc">
            
                <!-- Title Column -->
                <ng-container matColumnDef="TopicName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>TopicName</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <p class="text-truncate font-weight-300" >{{row.TopicName}}</p>
                    </mat-cell>
                </ng-container>
                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <p class="mat-yellow-bg text-boxed m-0 text-truncate font-weight-300">{{row.IsActive}}</p>
                    </mat-cell>
                </ng-container>
                <!-- Buttons Column -->
                <ng-container matColumnDef="buttons">
                    <mat-header-cell *matHeaderCellDef class="cell-center">Action</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="cell-center">
                        <span mat-menu-item aria-label="edit" (click)="edittopic(row)">
                            <mat-icon>edit</mat-icon>
                        </span>
                        <span mat-menu-item aria-label="remove" (click)="deleteTopic(row)" >
                            <mat-icon>delete</mat-icon>
                        </span>
                    </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="SubTopic">
                    <mat-header-cell *matHeaderCellDef class="cell-center">SubTopic</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="cell-center">
                        <span mat-menu-item arial-label="add" (click)="subTopic(row)">
                            <mat-icon>add</mat-icon>
                         </span>
                    </mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="CMBECode">
                    <mat-header-cell *matHeaderCellDef class="cell-center">CMBECode</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="cell-center">
                        <span mat-menu-item arial-label="add" [routerLink]="['/admin/collection/topic/cmbecode/',row.TopicID]" >
                            <mat-icon>add</mat-icon><strong >{{row.CmbeCodeCount}}</strong> 
                         </span>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="exam" [@animate]="{value:'*',params:{y:'100%'}}">
                </mat-row>
            </mat-table>
            <mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
                (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CENTER -->

    </div>
    <!-- / CONTENT-->

</div>