import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { UnisunUtils } from '@unisun/utils';

import { StudentCategory } from 'app/main/admin/student-category/student-category.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class StudentCategoryService implements Resolve<any>
{
    onStudentCategoryChanged: BehaviorSubject<any>;

    studentCategory: StudentCategory[];
    category: any;
    selectedStudentCategory: string[] = [];

    searchText: string;
    filterBy: string;
    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onStudentCategoryChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getStudentCategoryForGrid(gridFilter)
            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get college
     *
     * @returns {Promise<any>}
     */
    getStudentCategory(): Promise<StudentCategory[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/localization/get-student-categories`)
                .subscribe((response: any) => {
                    this.category = response;
                    resolve(this.category);
                }, reject);
        }
        );
    }

    /**
     * Get country
     *
     * @returns {Promise<any>}
     */
    getStudentCategoryForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/localization/get-grid-student-categories`, { ..._gridFilter });
    }

    /**
     * Update country
     *
     * @param country
     * @returns {Promise<any>}
     */
    createStudentCategory(country: StudentCategory): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/localization/create-student-category/`, { ...country })
                .subscribe(response => {
                    if (response) {
                        this.onStudentCategoryChanged.next(this.studentCategory);
                        this.openSnackBar("Successfully added.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Update country
     *
     * @param country
     * @returns {Promise<any>}
     */
    updateStudentCategory(country: StudentCategory): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/localization/update-student-category/`, { ...country })
                .subscribe(response => {
                    if (response) {
                        this.onStudentCategoryChanged.next(this.studentCategory);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete country
     *
     * @param country
     */
    deleteStudentCategory(country): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.delete(`${environment.apiURL}/localization/delete-student-category/` + country.CategoryID, {})
                .subscribe(response => {
                    if (response) {
                        this.onStudentCategoryChanged.next(this.studentCategory);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

}
