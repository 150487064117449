import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GridFilter,Integrationsgrid,SubTopicGridFilter,TopicGridFilter, subjectgrid } from 'app/_models/GridFilter';
import { Subject as subjectModel,Subtopic as subtopicModel,Comprehension as comprehensionModel,
    Comprehensionhistory, Module as ModuleModel, Topic as TopicModel,Tag as tagModel, QbankType as qbankTypeModel, 
    levelofQuestions as levelofQuestionsModel,CbmeCode as CbmeCodeModel,Comprehension, Module, SubjectDetails, Integration, QuestionConfiguration} from 'app/main/admin/common/subject/common.model';
import { Qbanktype } from '../../common_Module/Model/commonModel';
import { catchError } from 'rxjs/operators';

// import Module = require('module');
@Injectable()
export class CommonService implements Resolve<any>  {

    onSubjectChanged: BehaviorSubject<any>;
    onTopicChanged: BehaviorSubject<any>;
    onSubtopicChanged: BehaviorSubject<any>;
    onSubjectsSetChanged: BehaviorSubject<any>;
    onComprehensionChanged: BehaviorSubject<any>;
    onComprehensionHistoryChanged: BehaviorSubject<any>;
    onTagChanged: BehaviorSubject<any>;    
    onIntegrationChanged: BehaviorSubject<any>;    
    onQBankTypeChanged: BehaviorSubject<any>;
    onlevelofQuestionsChanged: BehaviorSubject<any>;    
    onCbmeCodeChanged: BehaviorSubject<any>;
    subject: subjectModel;    
    tag: tagModel;     
    integration:Integration
    qbankType: qbankTypeModel;    
    levelofQuestions: levelofQuestionsModel;    
    CbmeCode: CbmeCodeModel;
    comprehension: comprehensionModel[];
    comprehensionHistory: Comprehensionhistory[];
    subtopic: subtopicModel
    subjects: subjectModel[];    
    tags: tagModel[];   
    qbankTypes: qbankTypeModel[];
    user: any;
   
    Module:ModuleModel[];
    topic:TopicModel;
   
    searchText: string;
    filterBy: string;
    Modules:Array<ModuleModel>;
    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }

    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */

    constructor( 
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar 
    ) { 
         // Set the defaults
         this.onSubjectChanged = new BehaviorSubject([]);
         this.onTopicChanged = new BehaviorSubject([]);
         this.onSubtopicChanged = new BehaviorSubject([]);
         this.onSubjectsSetChanged = new BehaviorSubject([]);
         this.onComprehensionChanged = new BehaviorSubject([]);
         this.onComprehensionHistoryChanged = new BehaviorSubject([]);
         this.onTagChanged = new BehaviorSubject([]);
         this.onIntegrationChanged = new BehaviorSubject([]);
         this.onQBankTypeChanged = new BehaviorSubject([]);         
         this.onlevelofQuestionsChanged = new BehaviorSubject([]);
         this.onCbmeCodeChanged = new BehaviorSubject([]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} subject
     * @returns {Observable<any> | Promise<any> | any}
     */


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) :
      Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: subjectgrid = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: '',
                QbankTypeID: 0
            };
            Promise.all([
                this.getSubjectForGrid(gridFilter),
            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
           
        });
    }


    /**
     * Get subject
     *
     * @returns {Promise<any>}
     */
    getSubject():Promise<any> {
        return new Promise((resolve,reject)=> {
            this._httpClient.get(`${environment.apiURL}/adr/get-subjects`)
            .subscribe((response:any)=>{
                this.subject = response;
                resolve(this.subject);
            }, reject);
        }
        );
    }

    /**
     * Get subject by Id
     *
     * @returns {Promise<any>}
     */
    getSubjectById(id): Promise<subjectModel> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/adr/get-subjects-by-id/`+ id)
                .subscribe((response: any) => {
                this.subject = response;
                resolve(this.subject);
                }, reject);
        }
        );
    }
    /**
     * Get topic
     *
     * @returns {Promise<any>}
     */
 

     /**
     * Get topic
     *
     * @returns {Promise<any>}
     */
     getTopicDetailsForGrid(_gridFilter: TopicGridFilter): Observable<any> {
        return this._httpClient.post(`${environment.apiURL}/adr/grid-topics`, { ..._gridFilter });

    }
    
    getQbankType(): Observable<Qbanktype[]> {
        return this._httpClient.get<Qbanktype[]>(`${environment.apiURL}/adr/get-qbantypes`);
    }

    /**
     * Get subject
     *
     * @returns {Promise<any>}
     */
    getSubjectForGrid(_gridFilter: subjectgrid): Observable<any> {
         
        return this._httpClient.post(`${environment.apiURL}/adr/grid-subjects`, { ..._gridFilter });
    }
    //get subject by qbank type

//sandeep kumar sahoo 02/01/2023 update delete and add module funtions for subject start
    /**
     * Update subject
     *
     * @param subject
     * @returns {Promise<any>}
     */
    createsubject(subject: subjectModel): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/adr/create-subject/`, { ...subject })
                .subscribe(response => {
                    if (response) {
                        this.onSubjectChanged.next(this.subject);
                        this.openSnackBar("Successfully added.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Update subject
     *
     * @param subject
     * @returns {Promise<any>}
     */

    updateSubject(subject: subjectModel): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/adr/update-subject/`, { ...subject })
                .subscribe(response => {
                    if (response) {
                        this.onSubjectChanged.next(this.subject);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

     /**
     * Delete subject
     *
     * @param subject
     */

     deleteSubject(subject): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.delete(`${environment.apiURL}/adr/delete-subject/` + subject.SubjectID, {})
                .subscribe(response => {
                    if (response) {
                        this.onSubjectChanged.next(this.subject);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    } 

    /**
     * Get Modules For Subject
     *
     * @param subject
     */
        getModules():Promise<Array<ModuleModel>>{
            return new Promise((resolve, reject) => {
                this._httpClient.get(`${environment.apiURL}/adr/modules/`)
                .subscribe((response:any)=>{
                    this.Modules = response.Data;
                    resolve(this.Modules)
                }, reject);
            }
            );
        }



        //sandeep kumar sahoo 02/01/2023 update delete and add module funtions for subject start
    // topic list 

    /**
     * Update topic
     *
     * @param Topic
     * @returns {Promise<any>}
     */
    createTopic(Topic: TopicModel): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/adr/create-topic/`, { ...Topic })
                .subscribe(response => {
                    if (response) {
                        this.onTopicChanged.next(this.topic);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                });
        });
    }
    /**
     * Get topic
     *
     * @returns {Promise<any>}
     */

 
    /**
     * Get topic by Id
     *
     * @returns {Promise<any>}
     */
    getTopicById(id): Promise<TopicModel> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/adr/get-topics-by-id/`+ id)
                .subscribe((response: any) => {
                   this.topic = response;
                 resolve(this.topic);
                }, reject);
        }
        );
    }
    /**
     * Update topic
     *
     * @param Topic
     * @returns {Promise<any>}
     */

    updateTopic(topic: TopicModel): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/adr/update-topic/`, { ...topic })
                .subscribe(response => {
                    if (response) {
                        this.onTopicChanged.next(this.topic);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }



    /**
     * Delete topic
     *
     * @param Topic
     */
    deleteTopic(Topic): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.delete(`${environment.apiURL}/adr/delete-topic/` + Topic.TopicID, {})
                .subscribe(response => {
                    if (response) {
                        this.onTopicChanged.next(this.topic);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    } 

    // SubTopic's Start

    /**
     * Get topic
     *
     * @returns {Promise<any>}
     */
    getSubTopicDetailsForGrid(_gridFilter: SubTopicGridFilter): Observable<any> {
        return this._httpClient.post(`${environment.apiURL}/adr/grid-subtopics`, { ..._gridFilter });

    }
    

    
        //lovish 17/01/2023 update delete and add module funtions for subtopic start
    // subtopic list 

    /**
     * Update Subtopic
     *
     * @param Subtopic
     * @returns {Promise<any>}
     */
    createSubTopic(SubTopic: subtopicModel): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/adr/create-subtopic/`, { ...SubTopic })
                .subscribe(response => {
                    if (response) {
                        this.onSubtopicChanged.next(this.topic);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                });
        });
    }
    /**
     * Get Subtopic
     *
     * @returns {Promise<any>}
     */

 
    /**
     * Get Subtopic by Id
     *
     * @returns {Promise<any>}
     */
    
    getSubTopicById(id): Promise<subtopicModel> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/adr/get-Subtopics-by-id/`+ id)
                .subscribe((response: any) => {
                this.subtopic = response;
                  resolve(this.subtopic);
                }, reject);
        }
        );
    }
    /**
     * Update topic
     *
     * @param Topic
     * @returns {Promise<any>}
     */

    updateSubTopic(Subtopic: subtopicModel): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/adr/update-Subtopic/`, { ...Subtopic })
                .subscribe(response => {
                    if (response) {
                        this.onSubtopicChanged.next(this.subtopic);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }



    /**
     * Delete topic
     *
     * @param Topic
     */
    deleteSubTopic(SubTopic): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.delete(`${environment.apiURL}/adr/delete-Subtopic/` + SubTopic.SubTopicID, {})
                .subscribe(response => {
                    if (response) {
                        console.log("b");
                        this.onSubtopicChanged.next(this.subtopic);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        console.log("a");
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    } 

        //lovish 21/01/2023 update delete and add module funtions for tag start
    // tag list 

    /**
     * Update Tag
     *
     * @param Tag
     * @returns {Promise<any>}
     */
    createTag(tag: tagModel): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/adr/create-tag/`, { ...tag })
                .subscribe(response => {
                    if (response) {
                        this.onTagChanged.next(this.topic);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                });
        });
    }
    //Create integration
    createintegration(integration: Integration): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/adr/create-integration/`, { ...integration }).pipe(
                catchError(error => {
                    if(error.status==409){
                        this.openSnackBar(error.error, "Close");
                        return throwError(error);
                    }
                  // Handle the error here
                })
              )
                .subscribe(response => {
                    if (response) {
                        this.onIntegrationChanged.next(this.integration);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                });
        });
    }
    /**
     * Get Tag
     *
     * @returns {Promise<any>}
     */

 
    /**
     * Get Tag by Id
     *
     * @returns {Promise<any>}
     */
    
    getTagById(id): Promise<tagModel> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/adr/get-tag-by-id/`+ id)
                .subscribe((response: any) => {
                this.tag = response;
                  resolve(this.tag);
                }, reject);
        }
        );
    }
    //getintegrationbyid
    getIntegrationbyId(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/adr/get-integration-by-id/`+ id)
                .subscribe((response: any) => {
                this.tag = response;
                  resolve(this.tag);
                }, reject);
        }
        );
    }
    /**
     * Get subject
     *
     * @returns {Promise<any>}
     */
    getTagForGrid(_gridFilter: GridFilter): Observable<any> {
         
        return this._httpClient.post(`${environment.apiURL}/adr/grid-tags`, { ..._gridFilter });
    }
    getVetricalHorizontalintegration(_gridFilter: Integrationsgrid): Observable<any> {
         
        return this._httpClient.post(`${environment.apiURL}/adr/grid-integration`, { ..._gridFilter });
    }
    /**
     * Update topic
     *
     * @param Topic
     * @returns {Promise<any>}
     */

    updateTag(tag: tagModel): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/adr/update-tag/`, { ...tag })
                .subscribe(response => {
                    if (response) {
                        this.onTagChanged.next(this.tag);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

//update integration
updateintegration(integration: Integration): Promise<any> {
    return new Promise(() => {
        this._httpClient.post(`${environment.apiURL}/adr/update-integration/`, { ...integration })
            .subscribe(response => {
                if (response) {
                    this.onIntegrationChanged.next(this.integration);
                    this.openSnackBar("Successfully updated.", "Close");
                }
                else {
                    this.openSnackBar("Failed", "Close");
                }
            });
    });
}

    /**
     * Delete topic
     *
     * @param Topic
     */
    deleteTag(Tag): Promise<any> {
        return new Promise((resolve, reject) => {
           this._httpClient.delete(`${environment.apiURL}/adr/delete-tag/` + Tag.TagID, {})
                .subscribe(response => {
                    if (response) {
                        this.onTagChanged.next(this.tag);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    } 
    //
    deleteintegration(integration):Promise<any>{
        return new Promise((resolve, reject) => {
            this._httpClient.delete(`${environment.apiURL}/adr/delete-integration/` + integration.IntegrationID, {})
                 .subscribe(response => {
                     if (response) {
                         this.onIntegrationChanged.next(this.integration);
                         this.openSnackBar("Successfully removed.", "Close");
                     }
                     else {
                         this.openSnackBar("Failed", "Close");
                     }
                 });
         });
    }
    getSubjectsByEntityType(entityType:string): Promise<subjectModel[]> {
        return new Promise((resolve, reject) => {
          this._httpClient.get(`${environment.apiURL}/adr/get-subjects/${entityType}`)
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }
    getTopicsByEntityType(subjectID:number,entityType:string): Promise<TopicModel[]> {
        return new Promise((resolve, reject) => {
          this._httpClient.get(`${environment.apiURL}/adr/get-topics/${subjectID}/${entityType}`)
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }
    getSubTopicsByEntityType(topicID:number,entityType:string): Promise<subtopicModel[]> {
        return new Promise((resolve, reject) => {
          this._httpClient.get(`${environment.apiURL}/adr/get-subtopics/${topicID}/${entityType}`)
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }

    getTagsByEntityType(entityType:string): Promise<TopicModel[]> {
        return new Promise((resolve, reject) => {
          this._httpClient.get(`${environment.apiURL}/adr/get-tags/${entityType}`)
                .subscribe((response: any) => {
                    resolve(response.Data);
                }, reject);
        });
    }

    //  Qbank Type Start 

    createQbanktype(qbantypes: qbankTypeModel): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/adr/create-qbanktype/`, { ...qbantypes })
                .subscribe(response => {
                    if (response) {
                        this.onQBankTypeChanged.next(this.topic);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                });
        });
    }

    getQbankTypeById(id): Promise<qbankTypeModel> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/adr/get-qbanktype-by-id/` + id)
                .subscribe((response: any) => {
                    this.qbankType = response;
                    resolve(this.qbankType);
                }, reject);
        }
        );
    }

    getQbankTypeForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/adr/grid-qbantypes`, { ..._gridFilter });
    }

    updateQbankType(qbantypes: qbankTypeModel): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/adr/update-qbanktype/`, { ...qbantypes })
                .subscribe(response => {
                    if (response) {
                        this.onQBankTypeChanged.next(this.qbankType);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    deleteQbankType(qbantypes): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(`${environment.apiURL}/adr/delete-qbanktype/` + qbantypes.QbankTypeID, {})
                .subscribe(response => {
                    if (response) {
                        this.onQBankTypeChanged.next(this.qbankType);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    } 

    //** QBank Type End **/

     //  Level of Questions Start 

     createLevelofQuestion(levelofquestions: levelofQuestionsModel): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/adr/create-levelquestion/`, { ...levelofquestions })
                .subscribe(response => {
                    if (response) {
                        this.onlevelofQuestionsChanged.next(this.topic);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                });
        });
    }

    getLevelofQuestionById(id): Promise<levelofQuestionsModel> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/adr/get-levelquestion-by-id/` + id)
                .subscribe((response: any) => {
                    this.levelofQuestions = response;
                    resolve(this.levelofQuestions);
                }, reject);
        }
        );
    }

    getLevelofQuestionForGrid(_gridFilter: GridFilter): Observable<any> {
        return this._httpClient.post(`${environment.apiURL}/adr/grid-levelquestions`, { ..._gridFilter });
    }

    updateLevelofQuestion(levelofquestions: levelofQuestionsModel): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/adr/update-levelquestion/`, { ...levelofquestions })
                .subscribe(response => {
                    if (response) {
                        this.onlevelofQuestionsChanged.next(this.levelofQuestions);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    deleteLevelofQuestion(levelofquestions): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(`${environment.apiURL}/adr/delete-levelquestion/` + levelofquestions.LevelID, {})
                .subscribe(response => {
                    if (response) {
                        this.onlevelofQuestionsChanged.next(this.levelofQuestions);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    } 

    //** Level of Questions End **/
     //  CMBE Code Start 

     createCbmeCode(CbmeCode: CbmeCodeModel): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/adr/create-cbmecode/`, { ...CbmeCode })
                .subscribe(response => {
                    if (response) {
                        this.onCbmeCodeChanged.next(this.topic);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                });
        });
    }

    getCbmeCodeById(id): Promise<CbmeCodeModel> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/adr/get-cmbecode-by-id/` + id)
                .subscribe((response: any) => {
                    this.CbmeCode = response;
                    resolve(this.CbmeCode);
                }, reject);
        }
        );
    }

    getCbmeCodeForGrid(_gridFilter: GridFilter): Observable<any> {
        return this._httpClient.post(`${environment.apiURL}/adr/grid-cbmecodes`, { ..._gridFilter });
    }

    updateCbmeCode(CbmeCode: CbmeCodeModel): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/adr/update-cbmecode/`, { ...CbmeCode })
                .subscribe(response => {
                    if (response) {
                        this.onCbmeCodeChanged.next(this.CbmeCode);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    deleteCbmeCode(CbmeCode): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(`${environment.apiURL}/adr/delete-cbmecode/` + CbmeCode.CMBEID, {})
                .subscribe(response => {
                    if (response) {
                        this.onCbmeCodeChanged.next(this.CbmeCode);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    } 
getconfigurations():Observable<QuestionConfiguration[]>{
 return   this._httpClient.get<QuestionConfiguration[]>(`${environment.apiURL}/adr/get-configurations`,{})
}
updateconfiguration(questionConfiguration:any){
    let header = new Headers({
        'Content-Type': 'application/json'
      });
      
    return   this._httpClient.post<any>(`${environment.apiURL}/adr/update-configurations`,questionConfiguration,{
        headers:new HttpHeaders()
        .set('Content-Type','application/json')
        })
}

    //** CMBE Code End **/
}