<div class="p-12 p-sm-24 mat-elevation-z6" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">

    <mat-label class="mat-yellow-bg m-4 text-boxed">{{title.toUpperCase()}}</mat-label>
    <mat-select placeholder="Select Course" *ngIf="title=='course'" [(ngModel)]="TypeID"
        (selectionChange)="OnChangeType(TypeID)" class="m-0" floatLabel="always">
        <mat-option *ngFor="let c of TypeList" [value]="c.CourseID">{{c.Title}}</mat-option>
    </mat-select>
    <mat-select placeholder="Select TestSeries" *ngIf="title=='testseries'" [(ngModel)]="TypeID"
        (selectionChange)="OnChangeType(TypeID)" class="m-0" floatLabel="always">
        <mat-option *ngFor="let c of TypeList" [value]="c.TestSeriesID">{{c.Title}}</mat-option>
    </mat-select>
    <mat-label class="mat-yellow-bg m-4 text-boxed">Exam</mat-label>
    <mat-select placeholder="Select Exam" [(ngModel)]="ExamID" class="m-0" floatLabel="always"
        (selectionChange)="OnChangeExam(ExamID)">
        <mat-option *ngFor="let c of ExamList" [value]="c.ExamID">{{c.Title}}</mat-option>
    </mat-select>

</div>

<mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort matSortActive="Report"
    matSortDirection="asc">

    <!-- UserName Column -->
    <ng-container matColumnDef="UserName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.UserName}}</p>
        </mat-cell>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="Email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Email}}</p>
        </mat-cell>
    </ng-container>


    <!-- Mobile Column -->
    <ng-container matColumnDef="Mobile">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Mobile}}</p>
        </mat-cell>
    </ng-container>

    <!-- ActualDurationTime Column -->
    <ng-container matColumnDef="ActualDurationTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Actual(Min's)</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate">{{row.ActualDurationTime}}</p>
        </mat-cell>
    </ng-container>

    <!-- RemainingDurationTime Column -->
    <ng-container matColumnDef="RemainingDurationTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Balance(Min's)</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate ">{{row.RemainingDurationTime}}</p>
        </mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="Status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <mat-label class="mat-green-bg m-4 text-boxed" *ngIf="row.Status==1">STARTED</mat-label>
            <mat-label class="mat-yellow-bg m-4 text-boxed" *ngIf="row.Status==2">INPROGRESS</mat-label>
            <mat-label class="mat-ping-bg m-4 text-boxed" *ngIf="row.Status==3">COMPLETED</mat-label>
        </mat-cell>
    </ng-container>
    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef class="cell-center">Action</mat-header-cell>
        <mat-cell *matCellDef="let row" class="cell-center">
            <span mat-menu-item aria-label="edit" (click)="edit(row)">
                <mat-icon>edit</mat-icon>
            </span>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="report" [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>
<mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
    (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>