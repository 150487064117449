import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { UnisunNavigationModule } from '@unisun/components';
import { UnisunSharedModule } from '@unisun/shared.module';

import { NavbarVerticalStyle1Component } from 'app/layout/components/navbar/vertical/style-1/style-1.component';
import { HelperService } from 'app/_helpers/helper.service';

@NgModule({
    declarations: [
        NavbarVerticalStyle1Component
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,

        UnisunSharedModule,
        UnisunNavigationModule
    ],
    exports     : [
        NavbarVerticalStyle1Component
    ],
    providers:[
        HelperService
    ]
})
export class NavbarVerticalStyle1Module
{
}
