import { CMCExamService } from './../../chart-my-career/exam/exam.service';
import { DailyExamService } from 'app/main/admin/daily-exam/exam.service';
import { AppRankUpdateRequest } from './../report.model';
import { UserTestResult } from './../../../apps/academy/game-view.model';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { ReportService } from 'app/main/admin/report/report.service';
import { ReportFormDialogComponent } from 'app/main/admin/report/report-form/form.component';
import { Report, UserRankList } from '../report.model';
import { GridFilter, ReportGridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute } from '@angular/router';
import { SitePreference } from 'app/config';
import { Type } from '@angular/compiler';
import { CourseService } from '../../course/course.service';
import { TestSeriesService } from '../../test-series/test-series.service';
import { Exam } from '../../exam/exam.model';
import { XlsxToJsonService } from 'app/_services';
import { DomSanitizer } from '@angular/platform-browser';
import { ExcelExportService } from 'app/_services/excel.export.service';

@Component({
    selector: 'report-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class ReportListComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;

    Type: number;
    TypeID: number;
    ExamID: number;

    title: string;

    TypeList: any;
    ExamList: Array<Exam>;


    report: any;
    rankList: Array<UserRankList>;

    paginationData: any;

    dataSource: ReportDataSource;
    displayedColumns = ['UserName', 'Email', 'Mobile', 'SubmittedOn', 'Rank', 'Correct', 'Wrong', 'TotalScore', 'Percentage'];
    selectedReport: any[];
    checkboxes: {};
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;


    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ReportService} _reportService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _reportService: ReportService,
        public _matDialog: MatDialog,
        private _route: ActivatedRoute,
        private _courseService: CourseService,
        private _testseriesService: TestSeriesService,
        private _dailyExamService: DailyExamService,
        private _CMCExamService: CMCExamService,
        private sanitizer: DomSanitizer,
        private _exportExcelService: ExcelExportService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit() {

    }

    loadPage() {
        let gridFilter: ReportGridFilter = {
            PageNumber: this.paginator.pageIndex + 1,
            PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
            Search: "",
            SortBy: this.sort.active == null ? "Email" : this.sort.active,
            SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc',
            Type: this.Type,
            TypeID: this.TypeID,
            ExamID: this.ExamID,
            StartDate: 0,
            EndDate: 0
        };
        this.dataSource.loadData(gridFilter);
    }


    getNext(event: PageEvent) {
        this._reportService.onReportChanged.next(this.report);

    }


    onSortData(sort: Sort) {

        this._reportService.onReportChanged.next(this.report);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        var self = this;

        self._route.params.subscribe(function (parram) {
            self.title = parram.type;

            switch (parram.type) {
                case 'course':
                    {
                        self.Type = 1;
                        break;
                    }
                case 'testseries':
                    {
                        self.Type = 2;
                        break;
                    }
                case 'daily':
                    {
                        self.Type = 3;
                        self.TypeID = 0;
                        break;
                    }
                case 'cmc':
                    {
                        self.Type = 4;
                        self.TypeID = 0;
                        break;
                    }
            }

            if (self.Type == 1) {
                self._courseService.getCourse().subscribe(function (data) {
                    self.TypeList = data;
                });
            }
            else if (self.Type == 2) {
                self._testseriesService.getTestSeries().subscribe(function (data) {
                    self.TypeList = data;
                });
            }
            else if (self.Type == 3) {
                self._dailyExamService.getExam('').then(function (data) {
                    self.ExamList = data;
                });
            }
            else if (self.Type == 4) {
                self._CMCExamService.getExam('').then(function (data) {
                    self.ExamList = data;
                });
            }

        });

        self.dataSource = new ReportDataSource(self._reportService);

        self._reportService.onReportChanged
            .pipe(takeUntil(self._unsubscribeAll))
            .subscribe(search => {
                self.report = search;
                let gridFilter: ReportGridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == null ? "Email" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc',
                    Type: this.Type,
                    TypeID: this.TypeID,
                    ExamID: this.ExamID,
                    StartDate: 0,
                    EndDate: 0
                };
                self.dataSource.loadData(gridFilter);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    OnChangeType(TypeID: number) {
        var self = this;
        self.ExamList = [];
        self.loadPage();
        if (self.Type == 1) {
            self._courseService.getExams(TypeID).then(function (data) {
                self.ExamList = data;
            });
        }
        else if (self.Type == 2) {
            self._testseriesService.getTestSeriesById(TypeID).subscribe(function (data) {
                self.ExamList = data.ExamID;
            });
        }

    }

    OnChangeExam(ExamID: number) {
        var self = this;

        self.loadPage();
    }

    /**
 * Report download (Course, Testseries)
 */

    GetDownloadReport() {
        var self = this;

        let gridFilter: ReportGridFilter = {
            PageNumber: 1,
            PageSize: 1000000,
            Search: "",
            SortBy: "Report",
            SortOrder: 'asc',
            Type: this.Type,
            TypeID: this.TypeID,
            ExamID: this.ExamID,
            StartDate: 0,
            EndDate: 0
        };

        this._reportService.getReportDownload(gridFilter).subscribe(response => {
            self._exportExcelService.exportAsExcelFile(response.data, "exam-report-list-");

        });

    }
}

export class ReportDataSource extends DataSource<Report>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<Report>;
    /**
     * Constructor
     *
     * @param {ReportService} _reportService
     */
    constructor(
        private _reportService: ReportService
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._reportService.onReportChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: ReportGridFilter) {
        var self = this;
        if (gridFilter.ExamID > 0)
            this._reportService.getReportForGrid(gridFilter)
                .pipe(
                    catchError(() => of([])),
                    finalize(() => {
                        this.loadingSubject.next(false)
                    })
                )
                .subscribe(response => {
                    this.data = new MatTableDataSource(response.data);

                    self.paginationData = {
                        count: response.Count,
                        pageNumber: response.CurrentFilter.PageNumber
                    };


                });
    }
}
