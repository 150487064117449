import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../_services';
import { UnisunProgressBarService } from '@unisun/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DataGuardService } from 'app/_guards';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];
    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        if (this.requests.length > 0)
            this._loadingService.show();
        else
            this._loadingService.hide();
    }

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    constructor(
        private _authenticationService: AuthenticationService,
        private _loadingService: UnisunProgressBarService,
        private _matSnockbar: MatSnackBar,
        private router: Router,
        private _dataGuardService: DataGuardService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this._loadingService.show();
        return Observable.create(observer => {
            const subscription = next.handle(request)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(request);
                            observer.next(event);
                        }
                    },
                    err => {
                        if (err.status === 401 || err.status === 400) {
                            // auto logout if 401 response returned from api
                            this._authenticationService.ClearToken();
                            this._authenticationService.GetToken();
                            this._dataGuardService.removeCookieData('lms-user');
                          // not logged in so redirect to login page with the return url
                            this.router.navigate(['security/auth/login']);
                        }
                        this.removeRequest(request);
                        observer.error(err);
                        const error = err.message || err.statusText;
                        //this.openSnackBar(error, 'close');
                        return throwError(error);
                    },
                    () => {
                        this.removeRequest(request);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(request);
                subscription.unsubscribe();
            };
        });
    }
}