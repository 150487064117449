<mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort matSortActive="Name"
    matSortDirection="asc">

    <!-- Title Column -->
    <ng-container matColumnDef="Title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Title}}</p>
        </mat-cell>
    </ng-container>
    <!-- Subject Column -->
    <ng-container matColumnDef="Subject">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Subject</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Subject}}</p>
        </mat-cell>
    </ng-container>
    <!-- StartDate Column -->
    <ng-container matColumnDef="StartDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Start</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{(row.StartDate) | amDateFormat:'DD/MM/YY hh:mm A' }}</p>
        </mat-cell>
    </ng-container>
    <!-- EndDate Column -->
    <ng-container matColumnDef="EndDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>End</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{(row.EndDate) | amDateFormat:'DD/MM/YY hh:mm A'}}</p>
        </mat-cell>
    </ng-container>
    <!-- IsActive Column -->
    <ng-container matColumnDef="IsActive">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.IsActive}}</p>
        </mat-cell>
    </ng-container>
    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef class="cell-center">Action</mat-header-cell>
        <mat-cell *matCellDef="let row" class="cell-center">
            <span mat-menu-item aria-label="edit" (click)="editExam(row)">
                <mat-icon>edit</mat-icon>
            </span>
            <span mat-menu-item aria-label="remove" (click)="deleteExam(row)">
                <mat-icon>delete</mat-icon>
            </span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="exam" [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>
<mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
    (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>