import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation , Input} from "@angular/core";
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from "../../common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DataGuardService } from "app/_guards";
import { Tag as TagModel,Module as ModuleModel } from "../../common.model";
import { GridFilter } from "app/_models/GridFilter";
import { SitePreference } from "app/config";
import { AddTagComponent } from "../addTag/addTag.component";
import {Location} from '@angular/common';


@Component({
    selector: 'tagList',
    templateUrl: './tagList.component.html',
    styleUrls: ['./tagList.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})

export class TagListComponent implements OnInit, OnDestroy  {
    @ViewChild('dialogContent', { static: true })
    Modules:Array<ModuleModel>;
    tag:any;
    dialogContent: TemplateRef<any>;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;
    dialogRef:any;
    _sitePreference: any = SitePreference;
    paginationData: any;
    dataSource: TagDetailsDataSource;
    displayedColumns = ['Name', 'IsActive', 'buttons'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
   
    
    // Private
    private _unsubscribeAll: Subject<any>;
    searchInput: FormControl;
    constructor(
        private _commonService: CommonService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _router: Router,
        private _dataGuardService: DataGuardService,
        private _location: Location
        
    ) { 
        var self = this;
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaultsSubject
        this._unsubscribeAll = new Subject();

     }
    loadPage() {
        this._commonService.onTagChanged.next(this.tag);
    }

    ngOnInit(): void {
        var self=this;
        this.dataSource = new TagDetailsDataSource(this._commonService);
     
        this.searchInput.valueChanges
        .pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(300),
            distinctUntilChanged()
        )
        .subscribe(searchText => {
            this._commonService.onTagChanged.next(searchText);
        });
    
        
        this._commonService.onTagChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort?.active == null ? "TagID" : "TagID",
                    SortOrder: this.sort?.direction == 'desc' ? this.sort.direction : 'desc',
                  };
                this.dataSource.loadData(gridFilter);

            });
     }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    getNext(event: PageEvent) {
        this._commonService.onTagChanged.next(this.tag);

    }

    onSortData(sort: Sort) {

        this._commonService.onTagChanged.next(this.tag);
    }

    addSubTopic() :void {
        var self=this;
        var tag = new TagModel({});
        this._commonService.getModules().then(modules => {
        self.Modules=modules;
        this.dialogRef = this._matDialog.open(AddTagComponent, {
            panelClass: 'addSubTopic',
            disableClose: true,
            data: {
                action: 'new',
                tag:tag,
                modules:modules
            }     
        });        
        
        this.tagCrudOp(tag);
        
    });
    

    }

    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Edit topic
     *
     * @param subject
     */

    editTag(tag): void {
       var self = this;
        this._commonService.getTagById(tag.TagID).then(response=>{
           this._commonService.getModules().then(modules => {
             self.Modules=modules;
             self.dialogRef = self._matDialog.open(AddTagComponent, {
                    panelClass: 'tag-form-dialog',
                    disableClose: true,
                    data: {
                        tag: response,
                        modules:modules,
                        action: 'edit'
                    }
                });
                self.tagCrudOp(tag);
                });
       })
    }
        
    tagCrudOp(tag: TagModel) {
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }                
                const actionType: string = response.actionType;
                const _tag: TagModel = response.tag;  
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'add':
                        if(_tag) {
                           let modules=[];
                           var i=0;
                           response.Modules.forEach(module => {
                            if(module==true){
                                modules.push(this.Modules[i]);
                            }
                            i++;
                           });
                          
                           _tag.Modules=modules;
                            this._commonService.createTag(_tag);
                        }
                         
                         break;

                    case 'update':

                    if(_tag) {
                        let modules=[];
                        var i=0;
                        response.Modules.forEach(module => {
                         if(module==true){
                             modules.push(this.Modules[i]);
                         }
                         i++;
                         
                        });
                       
                        _tag.Modules=modules;
                         this._commonService.updateTag(_tag);
                      }
                      break;


                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deleteTag(tag);

                        break;
                }
                              
            });
                  

    }



    /**
     * Delete Topic
     */
    deleteTag(tag): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._commonService.deleteTag(tag);
            }
            this.confirmDialogRef = null;
        });

    }


    
}

export class TagDetailsDataSource extends DataSource<TagModel>
{

    private loadingTopic = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingTopic.asObservable();
    public data: MatTableDataSource<TagModel>;
    
   
    
    /**
     * Constructor
     *
     * @param {TopicService} _topicService
     */
    constructor(
        private _commonService: CommonService
 
    ) {
        super();
    }
    

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._commonService.onSubtopicChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) { 
        var self = this;

        this._commonService.getTagForGrid(gridFilter)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingTopic.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);
                self.paginationData = {
                    count: response.Count,
                    pageNumber: response.CurrentFilter.PageNumber
                };


            });
    }
}