import { ReportService } from 'app/main/admin/report/report.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select';
import { MatTabsModule} from '@angular/material/tabs';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatBadgeModule} from '@angular/material/badge';
import { MatDialogModule} from '@angular/material/dialog';
import { MatAutocompleteModule} from '@angular/material/autocomplete';

import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';

import { MomentModule } from 'ngx-moment';
import { PGiReportComponent } from './report.component';

import { PgiUserPermissionService } from './user-permission.service';
import { PgiUserAnalysisReportComponent } from './analysis-report/list.component';

const routes: Routes = [
    {
        path: 'report/pgi-analysis/:type',
        component: PGiReportComponent
    },
    {
        path: 'report/pgi-analysis/result/:type/:typeID/:ExamID/:UserID',
        component: PgiUserAnalysisReportComponent
    }
];

@NgModule({
    declarations: [
        PGiReportComponent,
        PgiUserAnalysisReportComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MomentModule.forRoot({ relativeTimeThresholdOptions: { 'm': 59 } }),
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatTabsModule,
        MatButtonToggleModule,
        MatBadgeModule,
        MatDialogModule,
        MatAutocompleteModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule
    ],
    providers: [
        ReportService,
        PgiUserPermissionService

    ],
    entryComponents: [

    ]
})
export class PGiReportModule {
}
