import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';
// import { CKEditorModule } from 'ng2-ckeditor';
import { CKEditorModule } from 'ckeditor4-angular';
import { SubjectComponent } from './subject.component';
import { AddSubjectComponent } from './addSubject/addSubject.component';
import { CommonService } from './common.service';
import { SubjectListComponent } from './subject-list/subjectList.component';
import { TopicComponent } from './topic/topic.component';
import { SubTopicComponent } from './subtopic/subtopic.component';
import { AddTopicComponent } from './topic/addTopic/addTopic.component';
import { AddSubtopicComponent } from './subtopic/addsubtopic/addSubTopic.component';
import {TagListComponent} from './tags/tag-list/tagList.component';
import {AddTagComponent} from './tags/addTag/addTag.component';
import {QBankTypeListComponent} from './qbanktype/QBankType-list/qbankTypeList.component';
import {AddQBankTypeComponent} from './qbanktype/addQBankType/addQBankType.component';
import {AddIntegrationComponent} from './integration/addIntegration/addIntegration.component';
import {AddLevelOfQuestionComponent} from './levelofQuestion/addlevelofQuestion/addlevelofQuestion.component';
import {LevelOfQuestionComponent} from './levelofQuestion/levelofQuestion-list/levelofQuestionList.component';
import {AddCmbeCodeComponent} from './cmbecode/addcmbecode/addcmbecode.component';
import {CmbeCodeComponent} from './cmbecode/cmbecode-list/cmbecodeList.component';
import { QuestionConfigurationComponent } from './question-configuration/question-configuration.component';
import { IntegrationListComponent } from './integration/integration-list/integration-list.component';


const routes: Routes = [
    {path:'collection/subject',
     component:SubjectComponent,
    resolve: {
        contacts:CommonService
    }
},
{ path:'collection/subject/:id',
  component:TopicComponent,
  resolve:{
    contacts:CommonService
  }
},
{ path:'collection/topic/:id',
  component:SubTopicComponent,
  resolve:{
    contacts:CommonService
  }
},
{ path:'collection/tags',
  component:TagListComponent,
  resolve:{
    contacts:CommonService
  }
},
{ path:'collection/qbanktype',
  component:QBankTypeListComponent,
  resolve:{
    contacts:CommonService
  }
},
{ path:'collection/qbanktype/:id/subjects',
  component:SubjectComponent,
  resolve:{
    contacts:CommonService
  }
},
{ path:'collection/horizontalintergration',
  component:IntegrationListComponent,
  resolve:{
    contacts:CommonService
  }
},
{ path:'collection/intergration/:id/:IntegrationType',
  component:IntegrationListComponent,
  resolve:{
    contacts:CommonService
  }
},
//for horizontal and vertical integration
{ path:'collection/qbanktype/:qbankyypeid/subjects/:subjectid/horizontal-integration',
  component:IntegrationListComponent,
  resolve:{
    contacts:CommonService
  }
},
{ path:'collection/qbanktype/:qbankyypeid/subjects/:subjectid/vertical-integration',
  component:IntegrationListComponent,
  resolve:{
    contacts:CommonService
  }
},

{ path:'collection/levelofquestion',
  component:LevelOfQuestionComponent,
  resolve:{
    contacts:CommonService
  }
},
{ path:'collection/cmbecode',
  component:CmbeCodeComponent,
  resolve:{
    contacts:CommonService
  }
},
{ path:'collection/topic/cmbecode/:Topicid',
  component:CmbeCodeComponent,
  resolve:{
    contacts:CommonService
  }
},
{ path:'collection/configuration',
  component:QuestionConfigurationComponent,
  resolve:{
    contacts:CommonService
  }
}

]


@NgModule({
    declarations:[
        SubjectComponent,
        AddSubjectComponent,
        SubjectListComponent,
        TopicComponent,
        AddTopicComponent,
        SubTopicComponent,
        AddSubtopicComponent,
        TagListComponent,
        AddTagComponent,
        QBankTypeListComponent,
        AddQBankTypeComponent,
        IntegrationListComponent,
        AddIntegrationComponent,
        AddLevelOfQuestionComponent,
        LevelOfQuestionComponent,
        AddCmbeCodeComponent,
        CmbeCodeComponent,
        QuestionConfigurationComponent
    ],

  imports:[ RouterModule.forChild(routes),
     MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSlideToggleModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule,
        

        CKEditorModule],


    providers:[ CommonService],


    entryComponents:[]
})

export class CommonModule { }