export class AcademyFakeDb {

    public static ChoiceType = [
        {
            'Key': 1,
            'Value': 'Single',
        },
        {
            'Key': 2,
            'Value': 'Multiple',
        },
        {
            'Key': 3,
            'Value': 'Various',
        }
    ];

    public static ScoreType = [
        {
            'Key': 1,
            'Value': 'REGULARE',
        },
        {
            'Key': 2,
            'Value': 'PGI',
        },
        {
            'Key': 3,
            'Value': 'AIIMS',
        }
    ];

    public static DailyExamScoreType = [
        {
            'Key': 1,
            'Value': 'REGULARE',
        }
    ];
}
