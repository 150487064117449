<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>
        <mat-tab-group mat-align-tabs="basic-info">
            <mat-tab label="Details" class="content">
                <ng-template mat-tab-label>
                    <mat-icon>info</mat-icon>&nbsp;
                    Details
                </ng-template>
                <form name="examForm" [formGroup]="examForm" class="p-24 event-form w-100-p" fxLayout="column" fxFlex>

                    <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

                        <mat-form-field class="mb-24" fxFlex>
                            <input matInput name="Title" required formControlName="Title" [(ngModel)]="exam.Title"
                                placeholder="Title">
                        </mat-form-field>
                        <mat-form-field class="mb-24" fxFlex>
                            <input type="number" matInput name="Rewards" required formControlName="Price"
                                [(ngModel)]="exam.Price" placeholder="Price">
                        </mat-form-field>
                        <mat-form-field class="mb-24" fxFlex>
                            <input matInput name="Subject" required formControlName="Subject" [(ngModel)]="exam.Subject"
                                placeholder="Subject">
                        </mat-form-field>
                        <mat-form-field class="mb-24" fxFlex>
                            <input type="number" matInput name="DurationByMinutes" required
                                formControlName="DurationByMinutes" [(ngModel)]="exam.DurationByMinutes"
                                placeholder="Duration (Mins)">
                        </mat-form-field>
                    </div>
                    <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">


                        <mat-form-field class="mb-24" fxFlex>
                            <input type="number" matInput name="Rewards" required formControlName="RewardsPoint"
                                [(ngModel)]="exam.RewardsPoint" placeholder="Rewards Point">
                        </mat-form-field>
                        <mat-form-field class="mr-sm-24" fxFlex>
                            <mat-select placeholder="Question Set" [(ngModel)]="exam.QuestionSetID" required
                                formControlName="QuestionSetID">
                                <mat-option *ngFor="let c of questionSet" [value]="c.QuestionSetID">{{c.Title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-slide-toggle class="p-4" formControlName="IsActive" [(ngModel)]="exam.IsActive">
                            Is Active
                        </mat-slide-toggle>
                    </div>

                    
                </form>
            </mat-tab>
            <!-- <mat-tab label="Subscription" class="content" *ngIf="SubscriptionResponse">
                <ng-template mat-tab-label>
                    <mat-icon>accessibility</mat-icon>&nbsp;
                    Subscription
                </ng-template>
                <mat-tab-group animationDuration="200ms" class="custom">
                    <mat-tab [label]="plan.SubscriptionType.SubscriptionName"
                        *ngFor="let plan of SubscriptionResponse.Subscriptions">

                        <div class="p-24 mat-white-bg">
                            <div class="filters " fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                                fxLayoutAlign.gt-xs="space-between center">
                               
                                <mat-slide-toggle class="p-4" [ngModelOptions]="{standalone: true}"
                                    (change)="updateSubscription(plan)" [(ngModel)]="plan.IsFreeQBank">
                                    Qbank
                                </mat-slide-toggle>
                                
                                <mat-slide-toggle class="p-4" [ngModelOptions]="{standalone: true}"
                                    (change)="updateSubscription(plan)" [(ngModel)]="plan.IsActive">
                                    Active
                                </mat-slide-toggle>
                                <button mat-raised-button class="save-button mat-accent-bg" (click)="newAsset(plan)">
                                    ADD
                                </button>

                            </div>

                            <div fxLayout="column" fxLayoutAlign="center">
                                <table class="simple clickable">
                                    <thead>
                                        <tr>
                                            <th>Plan</th>
                                            <th class="text-right">ActualPrice</th>
                                            <th class="text-right">Price</th>
                                            <th class="text-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of plan.Plans">
                                            <td>{{item.ValidUpto}} Months</td>
                                            <td class="text-right">{{item.ActualPrice}}</td>
                                            <td class="text-right">{{item.Price}}</td>
                                            <td class="text-right">
                                                <a (click)="editAsset(item,plan)">
                                                    <mat-icon>edit</mat-icon>
                                                </a>
                                                &nbsp;&nbsp;
                                                <a (click)="deleteAsset(item,plan)">
                                                    <mat-icon>delete</mat-icon>
                                                </a>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-tab> -->
        </mat-tab-group>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(exam)"
            class="save-button mat-accent-bg" [disabled]="examForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',exam])"
            class="save-button mat-accent-bg" [disabled]="examForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',exam])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>