import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";

import { FormBuilder, FormControl, FormGroup,FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA , MatDialogRef } from '@angular/material/dialog';
import { CKEDITOR_CONFIG } from "app/main/admin/comman-ckeditor-config";

import { CbmeCode as CbmeCodeModel} from 'app/main/admin/common/subject/common.model';


@Component({
    selector: 'addcmbecode',
    templateUrl: './addcmbecode.component.html',
    styleUrls: ['./addcmbecode.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class  AddCmbeCodeComponent implements OnInit {
    action: string;
    CbmeCode: CbmeCodeModel;
    cbmecodeForm: FormGroup;
    dialogTitle: string;
    ckeConfig:any;
    choiceType: any;
    scoreType: any;

    /**
     * Constructor
     *
     * @param {MatDialogRef<AddSubjectComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */

    constructor(
        public matDialogRef: MatDialogRef<AddCmbeCodeComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        this.ckeConfig = CKEDITOR_CONFIG;
        var self =this;
        // Set the defaults


     this.action = _data.action;

    if (this.action === 'edit') {
        this.dialogTitle = 'Edit CMBE Code';
        this.CbmeCode = _data.cbmecode;
    }
    else {
        this.dialogTitle = 'New CMBE Code';
        this.CbmeCode = _data.cbmecode;
    }
    self.cbmecodeForm = self.createCbmeCodeForm();
  }

    

 // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create exam form
     *
     * @returns {FormGroup}
     */

    createCbmeCodeForm(): FormGroup {
        return this._formBuilder.group({
            Title: [this.CbmeCode.Title],
            IsActive: [this.CbmeCode.IsActive],
            OrderNo:[this.CbmeCode.OrderNo],         
            Description:[this.CbmeCode.Description],                     
        });
    }

    ngOnInit(): void {
    }   

}
    

   
