import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { QuestionSet } from 'app/main/admin/qbank/qbank.model';

@Component({
    selector     : 'qbank-form-dialog',
    templateUrl  : './form.component.html',
    styleUrls    : ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CMCQuestionSetFormDialogComponent
{
    action: string;
    qbank: QuestionSet;
    qbankForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<CMCQuestionSetFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<CMCQuestionSetFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.action = _data.action;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Edit Question Set';
            this.qbank = _data.qbank;
        }
        else
        {
            this.dialogTitle = 'New Question Set';
            this.qbank = new QuestionSet({});
        }

        this.qbankForm = this.createQuestionSetForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create qbank form
     *
     * @returns {FormGroup}
     */
    createQuestionSetForm(): FormGroup
    {
        return this._formBuilder.group({
            QuestionSetsID      : [this.qbank.QuestionSetID],
            Title    : [this.qbank.Title],
            Description    : [this.qbank.Description],
            IsActive    : [this.qbank.IsActive]
        });
    }
}
