import { filter } from 'rxjs/operators';
import { AppQuestionsRequest, PGiQuestionsRequest } from '../../../apps/academy/game-view.model';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'app/main/admin/course/course.service';
import { TestSeriesService } from 'app/main/admin/test-series/test-series.service';
import { Exam } from 'app/main/admin/exam/exam.model';
import { RankingFilter, UserRankStatistics, PositionAnalysis } from 'app/_models/UserRankStatistics';
import { DataGuardService } from 'app/_guards';
import { HelperService } from 'app/_helpers/helper.service';
import { User } from 'app/_models';
import { GameViewService } from 'app/main/apps/academy/game-view.service';
import { FormControl } from '@angular/forms';
import { UserService } from 'app/_services';
import { ReportService } from '../../report/report.service';
import { PGiQuestionAnalysisReport } from 'app/_models/PGiQuestionAnalysisReport';
import { unisunAnimations } from '@unisun/animations';

@Component({
    selector: 'pgi-user-report',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class PgiUserAnalysisReportComponent implements OnInit {


    Type: number;
    TypeID: number;
    ExamID: number;
    UserID: string;
    title: string = '';

    TypeList: any;
    ExamList: Array<Exam>;

    userData: User;
    pGiQuestionAnalysisReport: PGiQuestionAnalysisReport;

    constructor(
        private _route: ActivatedRoute,
        private _courseService: CourseService,
        private _testseriesService: TestSeriesService,
        private _dataGuardService: DataGuardService,
        private _reportService: ReportService,
        private _router: Router,
        private _helperService: HelperService,
        private _userService: UserService,
    ) {
        var self = this;

       
    }



    fetchResult() {
        var self = this;

        var request: PGiQuestionsRequest = {
            Type: self.Type,
            TypeID: self.TypeID,
            ExamID: self.ExamID,
            UserID: self.UserID
        };
        self.pGiQuestionAnalysisReport = null;
        self._reportService.getQuestionsAnalysis(request).subscribe(function (response) {
            self.pGiQuestionAnalysisReport = response.Data;
        });
    }

    ngOnInit() {
        debugger

        var self = this;

        self._route.params.subscribe(function (parram) {
            self.title = parram.type;
            self.UserID = parram.UserID;
            self.TypeID = parram.typeID;
            self.ExamID = parram.ExamID;
            self.Type = parram.type == 'course' ? 1 : 2;
            self.fetchResult();
        });


    }

}
