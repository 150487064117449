 
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'app/main/admin/course/course.service';
import { TestSeriesService } from 'app/main/admin/test-series/test-series.service';
import { Exam } from 'app/main/admin/exam/exam.model';
import { DataGuardService } from 'app/_guards';
import { HelperService } from 'app/_helpers/helper.service';
import { User } from 'app/_models';
import { unisunAnimations } from '@unisun/animations';
import { CMCDownloadReport, AppCMCQuestionsRequest } from 'app/_models/CMCResult';
import { CMCExamService } from '../exam/exam.service';
import { UnisunConfigService } from '@unisun/services/config.service';

@Component({
    selector: 'cmc-user-report',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class CMCUserAnalysisReportComponent implements OnInit {


    Type: number;
    TypeID: number;
    ExamID: number;
    UserID: number;
    title: string = '';

    TypeList: any;
    ExamList: Array<Exam>;

    userData: User;
    report: CMCDownloadReport;

    constructor(
        private _route: ActivatedRoute,
        private _courseService: CourseService,
        private _testseriesService: TestSeriesService,
        private _dataGuardService: DataGuardService,
        private _router: Router,
        private _helperService: HelperService,
        private _CMCExamService: CMCExamService,
        private _UnisunConfigService: UnisunConfigService,
    ) {
        var self = this;

        // Configure the layout
        self._UnisunConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }



    fetchResult() {
        var self = this;

        var request: AppCMCQuestionsRequest = {
            Type: self.Type,
            TypeID: self.TypeID,
            ExamID: self.ExamID,
            UserID: self.UserID
        };
        self.report = null;
        self._CMCExamService.getUserExamResult(request).then(function (response) {
            self.report = response.Data;
        });
    }

    ngOnInit() {
        debugger

        var self = this;

        self._route.params.subscribe(function (parram) {
            self.UserID = Number(atob(parram.UserID));
            self.TypeID = parram.ExamID;
            self.ExamID = parram.ExamID;
            self.Type = 4;
            self.fetchResult();
        });


    }

    fnExcelReport() {
        var tab_text = "<table border='2px'><tr bgcolor='#87AFC6'>";
        var txtArea1;
        var sa;
        var j = 0;
        var tab;
        tab= document.getElementById('UserDetails'); // id of table

        for (j = 0; j < tab.rows.length; j++) {
            tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
            //tab_text=tab_text+"</tr>";
        }

        tab_text = tab_text + "</table>";
        tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
        tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
        tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) // If Internet Explorer
        {
            txtArea1.document.open("txt/html", "replace");
            txtArea1.document.write(tab_text);
            txtArea1.document.close();
            txtArea1.focus();
            sa = txtArea1.document.execCommand("SaveAs", true, "Say Thanks to Mai.xls");
        } else //other browser not tested on IE 11
            sa = window.open('data:application/vnd.ms-excel,' + encodeURIComponent(tab_text));

        return (sa);
    }
}
