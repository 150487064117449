import { VLVideoService } from './page-video.service';
import { VLPageVideoFormDialogComponent } from './page-video-form/form.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';

import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';
import { CKEditorModule } from 'ng2-ckeditor';

import { BookComponent } from './book.component';
import { BookService } from './book.service';
import { VLBookListComponent } from './book-list/list.component';
import { VLBookDetailFormDialogComponent } from './book-form/form.component';
import { VLPageDetailsFormDialogComponent } from './page-detail-form/form.component';
import { VLPageDetailsComponent } from './page-detail/list.component';
import { VLPageDetailsService } from './page-detail.service';
import { VLPageVideoComponent } from './page-video/list.component';

const routes: Routes = [
    {
        path: 'product/virtual-learn/book',
        component: BookComponent,
        resolve: {
            service: BookService
        }
    },
    {
        path: 'product/virtual-learn/book/:id',
        component: VLPageDetailsComponent,
        resolve: {
            service: VLPageDetailsService
        }
    },
    {
        path: 'product/virtual-learn/book/:BookID/video/:PageID',
        component: VLPageVideoComponent,
        resolve: {
            service: VLVideoService
        }
    }
];

@NgModule({
    declarations: [
        BookComponent,
        VLBookListComponent,
        VLBookDetailFormDialogComponent,
        VLPageDetailsFormDialogComponent,
        VLPageDetailsComponent,
        VLPageVideoFormDialogComponent,
        VLPageVideoComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatMenuModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule,

        CKEditorModule
    ],
    providers: [
        VLVideoService,
        VLPageDetailsService,
        BookService,
    ],
    entryComponents: [
        VLBookDetailFormDialogComponent,
        VLPageDetailsFormDialogComponent,
        VLPageVideoFormDialogComponent
    ]
})
export class VirtualLearnBookModule {
}
