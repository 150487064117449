    <div class="p-12 p-sm-24 mat-elevation-z6" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-xs="row"
        fxLayoutAlign.gt-xs="space-between center">
        <mat-label class="mat-yellow-bg m-4 text-boxed">{{title.toUpperCase()}}</mat-label>
        <mat-select placeholder="Select Course" *ngIf="title=='course'" [(ngModel)]="TypeID"
            (selectionChange)="OnChangeType(TypeID)" class="m-0" floatLabel="always">
            <mat-option *ngFor="let c of TypeList" [value]="c.CourseID">{{c.Title}}</mat-option>
        </mat-select>
        <mat-select placeholder="Select TestSeries" *ngIf="title=='testseries'" [(ngModel)]="TypeID"
            (selectionChange)="OnChangeType(TypeID)" class="m-0" floatLabel="always">
            <mat-option *ngFor="let c of TypeList" [value]="c.TestSeriesID">{{c.Title}}</mat-option>
        </mat-select>
        <mat-label class="mat-yellow-bg m-4 text-boxed">Exam</mat-label>
        <mat-select placeholder="Select Exam" [(ngModel)]="ExamID" class="m-0" floatLabel="always"
            (selectionChange)="OnChangeExam(ExamID)">
            <mat-option *ngFor="let c of ExamList" [value]="c.ExamID">{{c.Title}}</mat-option>
        </mat-select>
        <button *ngIf="dataSource.data" class="m-4" mat-raised-button aria-label="Download" (click)="GetDownloadReport()">
            <mat-icon class="m-4">cloud_download</mat-icon>
        </button>
    </div>
    <mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort matSortActive="Report"
        matSortDirection="asc">

        <!-- UserName Column -->
        <ng-container matColumnDef="UserName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p class="text-truncate font-weight-300">{{row.UserName}}</p>
            </mat-cell>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="Email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p class="text-truncate font-weight-300">{{row.Email}}</p>
            </mat-cell>
        </ng-container>


        <!-- Mobile Column -->
        <ng-container matColumnDef="Mobile">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p class="text-truncate font-weight-300">{{row.Mobile}}</p>
            </mat-cell>
        </ng-container>

        <!-- SubmittedOn Column -->
        <ng-container matColumnDef="SubmittedOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header>SubmittedOn</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p class="text-truncate font-weight-300">
                    {{(row.SubmittedOn | amFromUnix) | amDateFormat:'DD/MM/YYYY hh:mmA'}}</p>
            </mat-cell>
        </ng-container>

        <!-- Rank Column -->
        <ng-container matColumnDef="Rank">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Rank</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p class="text-truncate font-weight-300 mat-yellow-bg m-4 text-boxed">{{row.Rank}}</p>
            </mat-cell>
        </ng-container>

        <!-- Correct Column -->
        <ng-container matColumnDef="Correct">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Correct</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p class="text-truncate font-weight-300">{{row.Correct}}</p>
            </mat-cell>
        </ng-container>

        <!-- Wrong Column -->
        <ng-container matColumnDef="Wrong">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Wrong</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p class="text-truncate font-weight-300">{{row.Wrong}}</p>
            </mat-cell>
        </ng-container>

        <!-- TotalScore Column -->
        <ng-container matColumnDef="TotalScore">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Score</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p class="text-truncate font-weight-300">{{row.TotalScore}}</p>
            </mat-cell>
        </ng-container>

        <!-- Percentage Column -->
        <ng-container matColumnDef="Percentage">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Percentage</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p class="text-truncate font-weight-300">{{row.Percentage}}</p>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="report"
            [@animate]="{value:'*',params:{y:'100%'}}">
        </mat-row>
    </mat-table>
    <mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
        (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>