import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { unisunAnimations } from '@unisun/animations';

import { StudentAnalyticsDashboardService } from './analytics.service';
import { ReportService } from '../../report/report.service';
import { Exam } from '../../exam/exam.model';
import { CourseService } from '../../course/course.service';
import { TestSeriesService } from '../../test-series/test-series.service';

@Component({
    selector: 'student-analytics-dashboard',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class StudentAnalyticsDashboardComponent implements OnInit {
    Overview: any;
    widgets: any;
    widget1SelectedYear = '2016';
    widget5SelectedDay = 'today';
    Type: number;
    TypeID: number;
    ExamID: number;
    TypeList: any;
    ExamList: Array<Exam>;
    Underperformars: any;
    Toppers: any;
    /**
     * Constructor
     *
     * @param {StudentAnalyticsDashboardService} _studentAnalyticsDashboardService
     */
    constructor(
        private _studentAnalyticsDashboardService: StudentAnalyticsDashboardService,
        private _reportService: ReportService,
        private _courseService: CourseService,
        private _testseriesService: TestSeriesService
    ) {
        var self = this;
        self.Type = 1;
        self._courseService.getCourse().subscribe(function (data) {
            self.TypeList = data;
        });
        // Register the custom chart.js plugin

        this.CourseDetails = [
            { code: '1', detail: [{ videos: 38, qbank: 38, hippos: 38, notes: 38, todo: 38, users: 38, livevideos: 38 }] },
            { code: '2', detail: [{ videos: 39, qbank: 39, hippos: 39, notes: 39, todo: 39, users: 39, livevideos: 39 }] },
            { code: '3', detail: [{ videos: 40, qbank: 40, hippos: 40, notes: 40, todo: 40, users: 40, livevideos: 40 }] },
            { code: '4', detail: [{ videos: 41, qbank: 41, hippos: 41, notes: 41, todo: 41, users: 40, livevideos: 40 }] },
            { code: '5', detail: [{ videos: 42, qbank: 42, hippos: 42, notes: 42, todo: 42, users: 40, livevideos: 40 }] },
            { code: '6', detail: [{ videos: 43, qbank: 43, hippos: 43, notes: 43, todo: 43, users: 40, livevideos: 40 }] },
            { code: '7', detail: [{ videos: 44, qbank: 44, hippos: 44, notes: 44, todo: 44, users: 40, livevideos: 40 }] },
            { code: '8', detail: [{ videos: 45, qbank: 45, hippos: 45, notes: 45, todo: 45, users: 40, livevideos: 40 }] },
            { code: '9', detail: [{ videos: 46, qbank: 46, hippos: 46, notes: 46, todo: 46, users: 40, livevideos: 40 }] },
            { code: '10', detail: [{ videos: 47, qbank: 47, hippos: 47, notes: 47, todo: 47, users: 40, livevideos: 40 }] },
        ]
        this.CourseDetail = this.CourseDetails.filter(function (c) { return c.code == '2' })[0];
        //console.log("CourseDetail:" + this.CourseDetail.detail[0].videos);
        // Register the custom chart.js plugin
        this._registerCustomChartJSPlugin();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the widgets from the service
        // this._reportService.getDashboardOverviewAnalysis()
        //     .subscribe((response: any) => {
        //         this.Overview = response.Data;
        //     });
        this._studentAnalyticsDashboardService.getCustomWidgets();

        this.widgets = this._studentAnalyticsDashboardService.widgets;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    Courses: any[];
    selectCourse: string;
    CourseDetails: any;
    CourseDetail: any;
    brr: any;
    public CourseByUsers = [
        { "id": "1", "name": "Regular Course", "users": "55" },
        { "id": "2", "name": "STS", "users": "44" },
        { "id": "3", "name": "Dr. Arun Kumar Course", "users": "33" },
        { "id": "4", "name": "JIPMER League 1.0", "users": "22" },
    ]

    courseOnChange(e) {

        this.CourseDetail = this.CourseDetails[e.value.code];



    }

    OnChangeExam(ExamID: number) {
        var self = this;
        let request = {
            Type: self.Type,
            TypeID: self.TypeID,
            ExamID: ExamID
        }
        self._reportService.getTopperAnalysis(request).subscribe(function (response) {
            self.Toppers = response.Data.LeaderBoards;
        });

        self._reportService.getUnderperoformarAnalysis(request).subscribe(function (response) {
            self.Underperformars = response.Data.LeaderBoards;
        });
    }

    OnChangeType(TypeID: number) {
        var self = this;
        self.ExamList = [];


        self._courseService.getExams(TypeID).then(function (data) {
            self.ExamList = data;
        });


    }

    /**
     * Register a custom plugin
     */
    private _registerCustomChartJSPlugin(): void {
        (<any>window).Chart.plugins.register({
            afterDatasetsDraw: function (chart, easing) {
                // Only activate the plugin if it's made available
                // in the options
                if (
                    !chart.options.plugins.xLabelsOnTop ||
                    (chart.options.plugins.xLabelsOnTop && chart.options.plugins.xLabelsOnTop.active === false)
                ) {
                    return;
                }

                // To only draw at the end of animation, check for easing === 1
                const ctx = chart.ctx;

                chart.data.datasets.forEach(function (dataset, i) {
                    const meta = chart.getDatasetMeta(i);
                    if (!meta.hidden) {
                        meta.data.forEach(function (element, index) {

                            // Draw the text in black, with the specified font
                            ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
                            const fontSize = 13;
                            const fontStyle = 'normal';
                            const fontFamily = 'Roboto, Helvetica Neue, Arial';
                            ctx.font = (<any>window).Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                            // Just naively convert to string for now
                            const dataString = dataset.data[index].toString() + 'k';

                            // Make sure alignment settings are correct
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            const padding = 15;
                            const startY = 24;
                            const position = element.tooltipPosition();
                            ctx.fillText(dataString, position.x, startY);

                            ctx.save();

                            ctx.beginPath();
                            ctx.setLineDash([5, 3]);
                            ctx.moveTo(position.x, startY + padding);
                            ctx.lineTo(position.x, position.y - padding);
                            ctx.strokeStyle = 'rgba(255,255,255,0.12)';
                            ctx.stroke();

                            ctx.restore();
                        });
                    }
                });
            }
        });
    }
}

