import { DataGuardService } from './../../../_guards/data.guard';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { UnisunConfigService } from '@unisun/services/config.service';
import { UnisunSidebarService } from '@unisun/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { Route, Router } from '@angular/router';
import { AuthenticationService } from 'app/_services';
import { User } from 'app/_models';
import { HelperService } from 'app/_helpers/helper.service';
import { UnisunNavigationService } from '@unisun/components/navigation/navigation.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    user: User;
    
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UnisunConfigService} _UnisunConfigService
     * @param {UnisunSidebarService} _unisunSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _UnisunConfigService: UnisunConfigService,
        private _unisunSidebarService: UnisunSidebarService,
        private _translateService: TranslateService,
        private _unisunNavigationService: UnisunNavigationService,
        private _helperService: HelperService,
        private _authenticationService: AuthenticationService,
        private _router : Router
    ) {
        
        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.user = this._helperService.getUserDetail();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._UnisunConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._unisunSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout() {
        this._unisunNavigationService.unregister('main');
        this._authenticationService.logout();
    }

    profile() {
        this._router.navigate(["/security/auth/profile"]);
    }

    changePassword() {
        this._router.navigate(["/security/auth/reset-password"]);
    }
}
