import { ScoreType } from './../exam.model';
import { QuestionSetService } from './../../qbank/qbank.service';
import { Country } from './../../country/country.model';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Exam } from 'app/main/admin/exam/exam.model';
import { QuestionSet } from '../../qbank/qbank.model';
import { DailyExamService } from '../exam.service';

@Component({
    selector: 'daily-exam-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DailyExamFormDialogComponent {
    action: string;
    exam: Exam;
    examForm: FormGroup;
    dialogTitle: string;
    questionSet: Array<QuestionSet>;
    ckeConfig: any;
    choiceType: any;
    scoreType: any;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ExamFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<DailyExamFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _examService: DailyExamService
    ) {
        var self =this;
        // Set the defaults
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        this._examService.getChoiceType().then(function (response) {

            self.choiceType = response;
        });

        this.scoreType = this._examService.getScoreType().then(function (response) {

            self.scoreType = response;
        });
        
        this.action = _data.action;
        this.questionSet = _data.questionSet;
        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Exam';
            this.exam = _data.exam;
        }
        else {
            this.dialogTitle = 'New Exam';
            this.exam = new Exam({});
        }


        this.examForm = this.createExamForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create exam form
     *
     * @returns {FormGroup}
     */
    createExamForm(): FormGroup {
        return this._formBuilder.group({
            ExamID: [this.exam.ExamID],
            Title: [this.exam.Title],
            Subject: [this.exam.Subject],
            ScoreType: [this.exam.ScoreType],
            ChoiceType: [this.exam.ChoiceType],
            RewardsPoint: [this.exam.RewardsPoint],
            StartDate: [this.exam.StartDate],
            EndDate: [this.exam.EndDate],
            QuestionSetID: [this.exam.QuestionSetID],
            HasReview: [this.exam.HasReview],
            HasClear: [this.exam.HasClear],
            IsActive: [this.exam.IsActive],
            HasCalculateRisk: [this.exam.HasCalculateRisk],
            DurationByMinutes: [this.exam.DurationByMinutes],
            Instruction: [this.exam.Instruction]
        });
    }

    ngOnInit(): void {
        
        var self =this;

    }
}
