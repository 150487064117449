import { NumberValueAccessor } from '@angular/forms';
import { UnisunUtils } from '@unisun/utils';

export class Subject {
    SubjectID: number;
    QbankTypeID: number;
    SubjectName: string;
    IsActive: boolean;
    Description: string;
    OrderNo: number;
    BackgroundUrl:any;
    Modules: Array<Module> //sandeep kumar 02/01/2023 modules add start and end

    /**
     * Constructor
     *
     * @param subject
     */
    constructor(subject) {
        {
            this.SubjectID = subject.SubjectID; //|| UnisunUtils.generateGUID();
            this.SubjectName = subject.SubjectName || '';
            this.Description = subject.Description || '';
            this.Modules = subject.Modules || '';
            this.QbankTypeID = subject.QbankTypeID || 0;
        }
    }
}

export class SubjectDetails {
    SubjectID: number = 0;
    TopicID: number = 0;
    ModuleName: string;
    OrderNo: number;
    TopicName: string;
    IsDeleted: boolean;
    IsActive: boolean;
    Modules: []
    /**
 * Constructor
 *
 * @param SubjectDetails
 */
    constructor(value) {
        {
            this.SubjectID = value.SubjectID || 0;
            this.TopicID = value.TopicID || 0;
            this.ModuleName = value.ModuleName || '';
        }
    }
}
export class Subtopic {
    SubTopicID: number;
    SubTopicName: string;
    TopicID: number;
    OrderNo: number;
    IsActive: boolean;
    Modules: any;

    /**
     * Constructor
     *
     * @param subject
     */
    constructor(subtopic) {
        {
            this.TopicID = subtopic.TopicID; //|| UnisunUtils.generateGUID();
            this.SubTopicName = subtopic.SubTopicName || '';
            this.IsActive = subtopic.IsActive;

        }
    }
}

export class Comprehension {
    ComprehensionID: number;
    SubjectID: number;
    SubTopicID: number;
    Title: string;
    SubTitle: string;
    ReadingContent: string;
    ReferenceLink: string;
    MediaType: number;
    NoOfWords: number;
    PercentageOfExams?: number;
    NoOfEntrolled?: number;
    NoOfExamCompleted?: number;
    ProgressPercentage: string;
    OrderNo: number;
    NoOfQuestions: number;
    TotalScore?: number;
    TotalMinutes?: string;
    ChoiceType?: number;
    ScoreType?: number;
    Status: boolean;
    QuestionSetID: number;
    IsActive: boolean
    constructor(comprehension) {
        {
            this.ComprehensionID = comprehension.ComprehensionID; //|| UnisunUtils.generateGUID();
            this.SubjectID = comprehension.SubjectID;
            this.IsActive = comprehension.IsActive;

        }
    }



}
export class Comprehensionhistory {
    SubjectID: number;
    ComprehensionID: number;
    Strength: number;
    StartedTime: string;
    Profile: string;
    FinishedTime: string;
    Correct: number;
    CreatedOn: number;
    SubTopicID: number;
    TotalQuestions: number;
    TotalTime: number;
    TrnReadID: string;
    UpdatedOn: number;
    UserID: number;
    WPM: string;
    Wrong: number;
    Email: string;
    UserName: string;
    Mobile: string;
    constructor(Comprehensionhistory) {
        {
            this.ComprehensionID = Comprehensionhistory.ComprehensionID; //|| UnisunUtils.generateGUID();
            this.SubjectID = Comprehensionhistory.SubjectID

        }
    }



}

export class Module {
    ModuleID: number;
    Name: string;
    isSelected: boolean;
}

export class Topic {
    SubjectID: number = 0;
    TopicID: number = 0;
    OrderNo: number;
    TopicName: string;
    IsDeleted: boolean;
    IsActive: boolean;
    Modules: Array<Module>

    /**
     * Constructor
     *
     * @param topic
     */
    constructor(topic) {
        {
            this.SubjectID = topic.SubjectID || 0; //|| UnisunUtils.generateGUID();
            this.OrderNo = topic.OrderNo || 0;
            this.TopicName = topic.TopicName || '';
            this.TopicID = topic.TopicID || 0;
            this.IsDeleted = topic.IsDeleted;
            this.IsActive = topic.IsActive;
            this.Modules = topic.Modules || '';

        }
    }
}
export class Tag {
    Name: string;
    TagID: number;
    IsActive: boolean;
    OrderNo: number;
    Modules: Array<Module> //sandeep kumar 02/01/2023 modules add start and end

    /**
     * Constructor
     *
     * @param subject
     */
    constructor(tag) {
        {
            this.TagID = tag.TagID; //|| UnisunUtils.generateGUID();
            this.Name = tag.SubjectName || '';
            this.Modules = tag.Modules || '';

        }
    }
}
export class Integration {
    IntegrationID: number
    IntegrationType: number
    IsActive: boolean
    OrderNo: number
    SubjectID: number
    Title: string
    //sandeep kumar 02/01/2023 modules add start and end

    /**
     * Constructor
     *
     * @param subject
     */
    constructor(integration) {
        {
            this.IntegrationID = integration.IntegrationID;
            this.IntegrationType = integration.IntegrationType;
            this.IsActive = integration.IsActive;
            this.OrderNo = integration.OrderNo;
            this.SubjectID = integration.SubjectID;
            this.Title = integration.Title || '';;

        }
    }
}

export class TagDetails {
    Name: string;
    TagID: number;
    ModuleName: string;
    IsDeleted: boolean;
    IsActive: boolean;
    Modules: []
    /**
 * Constructor
 *
 * @param SubjectDetails
 */
    constructor(value) {
        {
            this.TagID = value.TagID || 0;
            this.ModuleName = value.ModuleName || '';
        }
    }
}
export class QbankType {
    Title: string;
    QbankTypeID: number;
    IsActive: boolean;
    OrderNo: number;

    /**
     * Constructor
     *
     * @param subject
     */
    constructor(qbanktype) {
        {
            this.QbankTypeID = qbanktype.QbankTypeID; //|| UnisunUtils.generateGUID();
            this.Title = qbanktype.Title || '';

        }
    }
}
export class levelofQuestions {
    Title: string;
    LevelID: number;
    IsActive: boolean;
    OrderNo: number;

    /**
     * Constructor
     *
     * @param subject
     */
    constructor(levelofquestions) {
        {
            this.LevelID = levelofquestions.LevelID; //|| UnisunUtils.generateGUID();
            this.Title = levelofquestions.Title || '';

        }
    }
}
export class CbmeCode {
    Title: string;
    CMBEID: number;
    IsActive: boolean;
    OrderNo: number;
    Description: string;
    TopicID: number;

    /**
     * Constructor
     *
     * @param CbmeCode
     */
    constructor(cbmecode) {
        {
            this.CMBEID = cbmecode.CMBEID; //|| UnisunUtils.generateGUID();
            this.Title = cbmecode.Title || '';
            this.Description = cbmecode.Description || '';
            this.TopicID = cbmecode.TopicID;

        }
    }

}
export class QuestionConfiguration {
    CanAccess: boolean;
    ConfigurationID: number;
    CreatedBy: string;
    CreatedOn: number;
    Email: string;
    IsActive: boolean;
    IsDeleted: boolean;
    IsHide: boolean;
    Key: string;
    Modules: []
    Name: string;
    UpdatedBy: string;
    UpdatedOn: number;
    UserID: number;
    Value: string;
    constructor(questionConfiguration) {
        this.CanAccess = questionConfiguration.CanAccess;
        this.ConfigurationID = questionConfiguration.ConfigurationID;
        this.CreatedBy = questionConfiguration.CreatedBy;
        this.CreatedOn = questionConfiguration.CreatedOn;
        this.Email = questionConfiguration.Email;
        this.IsActive = questionConfiguration.IsActive;
        this.IsDeleted = questionConfiguration.IsDeleted;
        this.IsHide = questionConfiguration.IsHide;
        this.Key = questionConfiguration.Key;
        this.Modules = questionConfiguration.Modules;
        this.Name = questionConfiguration.Name;
        this.UpdatedBy = questionConfiguration.UpdatedBy;
        this.UpdatedOn = questionConfiguration.UpdatedOn;
        this.UserID = questionConfiguration.UserID;
        this.Value = questionConfiguration.Value;
    }
}