import { UserService } from 'app/_services';

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef, Input, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { GridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute, Router } from '@angular/router';
import { SitePreference } from 'app/config';
import { CMCUserPermissionService } from '../user-permission.service';
import { CMCUserPermission } from '../user-permission.model';
import { User } from 'app/_models';
import { ExcelExportService } from 'app/_services/excel.export.service';
import { Exam } from 'app/main/admin/exam/exam.model';

@Component({
    selector: 'cmc-user-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class CMCUserPermissionComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;

    user: any;

    // @Input() courseId: string = '';
    // @Input() course: Exam;

    paginationData: any;

    dataSource: CMCUserPermissionDataSource;
    displayedColumns = ['UserName', 'Email', 'Mobile', 'buttons'];



    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UserService} _userService
     * @param {MatDialog} _matDialog
     */
    constructor(
        public matDialogRef: MatDialogRef<CMCUserPermissionComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _userService: UserService,
        private _courseUserPermissionService: CMCUserPermissionService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _router: Router,
        private _exportExcelService: ExcelExportService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.userSearch = new FormControl('');
    }

    ngAfterViewInit() {

    }

    loadPage() {
        this._courseUserPermissionService.onCMCUserPermissionChanged.next(this.user);
    }


    getNext(event: PageEvent) {
        this._courseUserPermissionService.onCMCUserPermissionChanged.next(this.user);

    }


    onSortData(sort: Sort) {

        this._courseUserPermissionService.onCMCUserPermissionChanged.next(this.user);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
debugger;
        this.dataSource = new CMCUserPermissionDataSource(this._courseUserPermissionService, this._data.exam.ExamID);

        this._courseUserPermissionService.onCMCUserPermissionChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.user = search;
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == undefined ? "CMCUserPermission" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc'
                };
                this.dataSource.loadData(gridFilter);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Delete CMCUserPermission
     */
    deleteCMCUserPermission(user): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {

                let userPermissionHistory = {
                    ModeID: 2,
                    ProductAccessType: 1,
                    ProductID: this._data.exam.ExamID,
                    UserInfo: user
                };

                this._courseUserPermissionService.deleteCMCUserPermission(userPermissionHistory);
            }
            this.confirmDialogRef = null;
        });

    }

    users: Array<User>;
    userSelectedValue: string;
    userSearch: FormControl;

    selectedUser(userID: string) {
        this.userSelectedValue = userID;
    }


    getUserBySearch() {
        var self = this;
        if (this.userSearch.value != null && this.userSearch.value.length > 1)
            this._userService.getUserBySearch(this.userSearch.value).then(function (response) {
                self.users = response;
            });
    }

    addUser() {
        let user = this.users.find(a => a.Email == this.userSelectedValue);

        let userPermissionHistory = {
            ModeID: 1,
            ProductAccessType: 1,
            ProductID: this._data.exam.ExamID,
            UserInfo: user
        };

        this._courseUserPermissionService.createCMCUserPermission(userPermissionHistory)
            .then(function () {
                this.userSelectedValue = '';
                this.userSearch.reset();

            })
    }

    removeUser(user) {

        let userPermissionHistory = {
            Mode: 2,
            ProductAccessType: 1,
            ProductID: this._data.exam.ExamID,
            UserInfo: user
        };

        this._courseUserPermissionService.createCMCUserPermission(userPermissionHistory)
            .then(function () {
                this.userSelectedValue = '';
                this.userSearch.reset();

            })
    }

    GetDownloadReport() {
        var self = this;

        let gridFilter: GridFilter = {
            PageNumber: 1,
            PageSize: 7000000,
            Search: "",
            SortBy: "UserName",
            SortOrder: 'asc'
        };

        this._courseUserPermissionService.getCMCUserPermissionForGrid(gridFilter, this._data.exam.ExamID).subscribe(response => {

            let data = [];

            response.data.forEach(element => {
                let user = {
                    CMCName: self._data.exam.Title,
                    UserName: element.UserName,
                    Email: element.Email,
                    Mobile: element.Mobile,
                    College: element.College,
                    State: element.state,
                    CategoryOfStudy :element.CategoryOfStudy,
                    RegisteredOn: new Date(element.CreatedOn * 1000),
                };
                data.push(user);
            });

            self._exportExcelService.exportAsExcelFile(data, self._data.exam.Title + "-user-list-");

        });

    }
}

export class CMCUserPermissionDataSource extends DataSource<CMCUserPermission>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<CMCUserPermission>;
    /**
     * Constructor
     *
     * @param {CMCUserPermissionService} _userService
     */
    constructor(
        private _userService: CMCUserPermissionService,
        private courseId: string
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._userService.onCMCUserPermissionChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) {
        var self = this;

        this._userService.getCMCUserPermissionForGrid(gridFilter, this.courseId)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);

                self.paginationData = {
                    count: response.Count,
                    pageNumber: response.CurrentFilter.PageNumber
                };


            });
    }
}
