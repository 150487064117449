import { NgModule } from '@angular/core';

import { UnisunIfOnDomDirective } from '@unisun/directives/unisun-if-on-dom/unisun-if-on-dom.directive';
import { UnisunInnerScrollDirective } from '@unisun/directives/unisun-inner-scroll/unisun-inner-scroll.directive';
import { UnisunPerfectScrollbarDirective } from '@unisun/directives/unisun-perfect-scrollbar/unisun-perfect-scrollbar.directive';
import { UnisunMatSidenavHelperDirective, UnisunMatSidenavTogglerDirective } from '@unisun/directives/unisun-mat-sidenav/unisun-mat-sidenav.directive';

@NgModule({
    declarations: [
        UnisunIfOnDomDirective,
        UnisunInnerScrollDirective,
        UnisunMatSidenavHelperDirective,
        UnisunMatSidenavTogglerDirective,
        UnisunPerfectScrollbarDirective
    ],
    imports     : [],
    exports     : [
        UnisunIfOnDomDirective,
        UnisunInnerScrollDirective,
        UnisunMatSidenavHelperDirective,
        UnisunMatSidenavTogglerDirective,
        UnisunPerfectScrollbarDirective
    ]
})
export class UnisunDirectivesModule
{
}
