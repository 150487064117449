import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards';
import { PageNotFoundComponent } from './main/admin/page-not-found/page-not-found.component';

const appRoutes: Routes = [


  {
    path: 'apps',
    canActivateChild: [AuthGuard],
    loadChildren: './main/apps/apps.module#AppsModule'
  },
  {
    path: 'security',
    loadChildren: './main/authentication/auth.module#AuthModule'
  },
  {
    path: 'admin',
    canActivateChild: [AuthGuard],
    loadChildren: './main/admin/admin.module#AdminModule'
  },
  {
    path: 'settings',
    canActivateChild: [AuthGuard],
    loadChildren: './main/settings/settings.module#SettingsModule'
  }
  ,
  { path: '**', pathMatch: 'full', 
  component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
