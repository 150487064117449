<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="qbankForm">
            <mat-tab-group>
                <mat-tab label="Name">
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <ckeditor [config]="ckeConfig" placeholder="Title" [(ngModel)]="qbank.QuestionTitle"
                            formControlName="QuestionTitle" required>
                        </ckeditor>
                    </div>
                </mat-tab>
                <mat-tab label="Configuration">
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field class="mr-sm-24" fxFlex>
                            <input matInput placeholder="Hints" [(ngModel)]="qbank.Hints" required
                                formControlName="Hints">
                        </mat-form-field>
                    </div>
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field class="mr-sm-24" fxFlex>
                            <input matInput placeholder="Answer" [(ngModel)]="qbank.Answer" required
                                formControlName="Answer">
                        </mat-form-field>
                    </div>
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">

                        <mat-form-field class="mr-sm-24" fxFlex>
                            <input type="number" matInput placeholder="Question ID" [(ngModel)]="qbank.QueueID" required
                                formControlName="QueueID">
                        </mat-form-field>
                    </div>
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field class="mr-sm-24" fxFlex>
                            <input type="number" matInput placeholder="Marks" [(ngModel)]="qbank.Mark" required
                                formControlName="Mark">
                        </mat-form-field>
                    </div>
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field class="mr-sm-24" fxFlex>
                            <input type="number" matInput placeholder="Negative" [(ngModel)]="qbank.Negative" required
                                formControlName="Negative">
                        </mat-form-field>
                    </div>
                     
                </mat-tab>
                
                 
                <mat-tab label="Content">
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <ckeditor [config]="ckeConfig" [(ngModel)]="qbank.Content" formControlName="Content" required>
                        </ckeditor>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(qbank)"
            class="save-button mat-accent-bg" [disabled]="!qbankForm.valid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',qbank])"
            class="save-button mat-accent-bg" [disabled]="!qbankForm.valid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',qbank])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>