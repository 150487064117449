import { UnisunUtils } from '@unisun/utils';

export class VirtualLearnBook {
    BookID: number;
    Title: string;
    Subject: string;
    Banner: string;
    Edition: string;
    Publisher: string;
    Authors: string;
    NoOfPages:number;
    IsActive: boolean;
    PageCollections:[];
    TopicID:number;
    SubjectID:number;
    /**
     * Constructor
     *
     * @param VirtualLearnBook
     */
    constructor(value) {
        {
            this.BookID = value.BookID || 0;
            this.Title = value.Title || '';
            this.Subject = value.Subject || '';
            this.Banner = value.Banner || '';
            this.Edition = value.Edition || '';
            this.Publisher = value.Publisher || '';
            this.Authors = value.Authors || '';
            this.NoOfPages = value.NoOfPages || 0
            this.IsActive = value.IsActive || false,
            this.TopicID=this.TopicID||0;
            this.SubjectID=this.SubjectID||0;
        }
    }
}
export class Subject {
    SubjectID: number;
    QbankTypeID: number;
    SubjectName: string;
    IsActive: boolean;
    Description: string;
    OrderNo: number;
   //sandeep kumar 02/01/2023 modules add start and end

    /**
     * Constructor
     *
     * @param subject
     */
    constructor(subject) {
        {
            this.SubjectID = subject.SubjectID; //|| UnisunUtils.generateGUID();
            this.SubjectName = subject.SubjectName || '';
            this.Description = subject.Description || '';
            this.QbankTypeID = subject.QbankTypeID || 0;
        }
    }
}
export class Topic {
    SubjectID: number = 0;
    TopicID: number = 0;
    OrderNo: number;
    TopicName: string;
    IsChecked: boolean;
    IsDeleted: boolean;
    IsActive: boolean;


    /**
     * Constructor
     *
     * @param topic
     */
    constructor(topic) {
        {
            this.SubjectID = topic.SubjectID || 0; //|| UnisunUtils.generateGUID();
            this.OrderNo = topic.OrderNo || 0;
            this.TopicName = topic.TopicName || '';
            this.TopicID = topic.TopicID || 0;
            this.IsDeleted = topic.IsDeleted;
            this.IsActive = topic.IsActive;
            this.IsChecked = topic.IsChecked;

        }
    }
}
export class VirtualLearnPages {
    BookID: number = 0;
    PageID: number = 0;
    PageNumber: number;
    PageReferenceID: string;
    NoOfVideos: number;
    IsActive: string;
    VideoCollections: [];
    /**
     * Constructor
     *
     * @param VideoDetails
     */
    constructor(value) {
        {
            this.BookID = value.VideoID || 0;
            this.PageID = value.PageID || '';
            this.PageNumber = value.PageNumber || 0;
            this.PageReferenceID = value.PageReferenceID || '';
            this.NoOfVideos = value.NoOfVideos || 0;
            this.IsActive = value.IsActive || false;
            this.VideoCollections = value.VideoCollections || [];
        }
    }
}

export class VirtualLearnVideos {
    BookID: number = 0;
    PageID: number = 0;
    VideoDetailID: number;
    Title: string;
    QueueID: string;
    Url: string;
    VideoLength: string;
    VideoType: number;

    /* Constructor
     *
     * @param VirtualLearnVideos
     */
    constructor(value) {
        {
            this.VideoDetailID = value.VideoDetailID || 0;
            this.BookID = value.BookID || 0;
            this.PageID = value.PageID || 0;
            this.Title = value.Title || '';
            this.QueueID = value.QueueID || '';
            this.VideoLength = value.VideoLength || '';
            this.Url = value.Url || '';
            this.VideoType = value.VideoType || 1;
        }
    }

}