import { CountryService } from './../country/country.service';
import { Country } from './../country/country.model';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunSidebarService } from '@unisun/components/sidebar/sidebar.service';

import { ExamService } from 'app/main/admin/exam/exam.service';
import { ExamFormDialogComponent } from 'app/main/admin/exam/exam-form/form.component';
import { QuestionSetService } from '../qbank/qbank.service';
import { QuestionSet } from '../qbank/qbank.model';
import { Exam } from './exam.model';
import { CommonService } from '../common/subject/common.service';

@Component({
    selector: 'exam',
    templateUrl: './exam.component.html',
    styleUrls: ['./exam.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class ExamComponent implements OnInit, OnDestroy {
    dialogRef: any;
    hasSelectedExam: boolean;
    searchInput: any='';
    subjects: any;
    topics: any=[];
    subjectid:number=0;
    topicid:number=0;

    questionSets = Array<QuestionSet>();

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ExamService} _examService
     * @param {UnisunSidebarService} _unisunSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _examService: ExamService,
        private _unisunSidebarService: UnisunSidebarService,
        private _matDialog: MatDialog,
        private _questionSetService: QuestionSetService,
        private _commonService: CommonService,
    ) {
        var self = this;
        this._commonService.getSubject().then(function (response) {

            self.subjects = response?.Data;
        });
        // Set the defaults
       

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    gettopics(SubjectID){
        this._commonService.getTopicsByEntityType(SubjectID,"Custom QBank").then((response:any)=> {
            this.topics = response;
            this.subjectid=SubjectID;
        });     
    }
    gettopicid(TopicID){
        this.topicid=TopicID
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        
    }
    Search(){
        console.log(this.searchInput)
        var request={
         search:this.searchInput,
         SubjectId:this.subjectid>0 ? this.subjectid :0,
         topicId:this.topicid>0 ? this.topicid :0,
        }
        this._examService.onExamChanged.next(request);
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * New exam
     */
    newExam(): void {
        var self = this;

        if (self.questionSets) {
            self._questionSetService.getQuestionSet().then(function (response) {
                self.questionSets = response;
                self.dialogRef = self._matDialog.open(ExamFormDialogComponent, {
                    panelClass: 'exam-form-dialog',
                    disableClose: true,
                    data: {
                        action: 'new',
                        questionSet: self.questionSets
                    }
                });
                self.newExamCallback();
            })
        }
        else {
            self.dialogRef = self._matDialog.open(ExamFormDialogComponent, {
                panelClass: 'exam-form-dialog',
                disableClose: true,
                data: {
                    action: 'new',
                    questionSet: self.questionSets
                }
            });
            self.newExamCallback();
        }

    }

    newExamCallback() {
        this.dialogRef.afterClosed()
            .subscribe((response: Exam) => {
                if (!response) {
                    return;
                }
                this._examService.createExam(response);
            });
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._unisunSidebarService.getSidebar(name).toggleOpen();
    }
}
