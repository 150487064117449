import { UserService } from 'app/_services';

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef, Input, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { GridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute, Router } from '@angular/router';
import { SitePreference } from 'app/config';
import { ARFilterTopicLevelService } from '../topic-level.service';
import { ARFilterTopicLevel } from '../topic-level.model';
import { User } from 'app/_models';
import { ExcelExportService } from 'app/_services/excel.export.service';
import { Exam } from 'app/main/admin/exam/exam.model';
import { QuestionSet } from '../../qbank/qbank.model';
import { ARFilterQuestionSetService } from '../../qbank/qbank.service';
import { ARFilterTopicLevelFormDialogComponent } from '../topic-level-form/form.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'ar-filter-topic-level-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class ARFilterTopicLevelComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;
    dialogRef: any;
    questionSet: Array<QuestionSet>;
    filtertedQuestionSet: Array<QuestionSet>;
    exam : Exam;
    // @Input() topicId: string = '';
    // @Input() topic: Exam;

    paginationData: any;

    dataSource: ARFilterTopicLevelDataSource;
    displayedColumns = ['LevelID', 'LevelName', 'QuestionSetName', 'buttons'];



    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UserService} _userService
     * @param {MatDialog} _matDialog
     */
    constructor(
        public matDialogRef: MatDialogRef<ARFilterTopicLevelComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _userService: UserService,
        private _topicTopicLevelService: ARFilterTopicLevelService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _router: Router,
        private _aRFilterQuestionSetService: ARFilterQuestionSetService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.examSearch = new FormControl('');
        var self = this;
        self.exam = this._data.exam;
        if (!self.questionSet) {
            self._aRFilterQuestionSetService.getQuestionSet().then(function (response) {
                self.questionSet = response;
            });
        }

    }

    ngAfterViewInit() {

    }

    examSearch: FormControl;
    examSelectedValue: string;

    selectedExam(examID: string) {
        this.examSelectedValue = examID;
    }


    getExamBySearch() {
        var self = this;
        if (!self.questionSet) {
            self._aRFilterQuestionSetService.getQuestionSet().then(function (response) {
                self.questionSet = response;
            });
        }
        if (this.examSearch.value != null && this.examSearch.value.length > 1) {
            self.filtertedQuestionSet = [];
            self.filtertedQuestionSet = self.questionSet.filter(a => {
                return a.Title.toLowerCase().includes(self.examSearch.value.toLowerCase())
            });
        }
    }

    addExam() {
        var self = this;

        self.dialogRef = self._matDialog.open(ARFilterTopicLevelFormDialogComponent, {
            panelClass: 'exam-user-form-dialog',
            disableClose: true,
            data: {
                exam: self.exam,
                examLevel : new ARFilterTopicLevel({}),
                action: 'new',
                questionSet: self.questionSet
            }
        });

        this.editExamCallback(self.exam,new ARFilterTopicLevel({}));
    }

    editExam(examLevel) {
        var self = this;
        
            self._aRFilterQuestionSetService.getQuestionSet().then(function (response) {
                self.questionSet = response;
           
        
                self.dialogRef = self._matDialog.open(ARFilterTopicLevelFormDialogComponent, {
                    panelClass: 'exam-user-form-dialog',
                    disableClose: true,
                    data: {
                        exam: self.exam,
                        examLevel : examLevel,
                        action: 'edit',
                        questionSet: self.questionSet
                    }
                });

                self.editExamCallback(self.exam,examLevel);
        });
    }

    editExamCallback(exam,examLevel) {
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: ARFilterTopicLevel = response[2];
                formData.ExamID = this.exam.ExamID;

                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':
                        if (formData.TrnVRExamID>0)
                        {
                            this._topicTopicLevelService.updateARFilterTopicLevel(formData);
                        }
                        else
                        {
                            this._topicTopicLevelService.createARFilterTopicLevel(formData);
                        }
                        
                      
                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deleteExam(exam);

                        break;
                }
            });

    }
    /**
         * Delete Exam
         */
    deleteExam(exam): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._topicTopicLevelService.deleteARFilterTopicLevel(exam);
            }
            this.confirmDialogRef = null;
        });

    }

    loadPage() {
        this._topicTopicLevelService.onARFilterTopicLevelChanged.next(this.questionSet);
    }


    getNext(event: PageEvent) {
        this._topicTopicLevelService.onARFilterTopicLevelChanged.next(this.questionSet);

    }


    onSortData(sort: Sort) {

        this._topicTopicLevelService.onARFilterTopicLevelChanged.next(this.questionSet);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.dataSource = new ARFilterTopicLevelDataSource(this._topicTopicLevelService, this._data.exam.ExamID);

        this._topicTopicLevelService.onARFilterTopicLevelChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.questionSet = search;
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == undefined ? "LevelID" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc'
                };
                this.dataSource.loadData(gridFilter);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Delete ARFilterTopicLevel
     */
    deleteARFilterTopicLevel(user): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe((result: ARFilterTopicLevel) => {
            if (result) {

                this._topicTopicLevelService.deleteARFilterTopicLevel(result);
            }
            this.confirmDialogRef = null;
        });

    }


}

export class ARFilterTopicLevelDataSource extends DataSource<ARFilterTopicLevel>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<ARFilterTopicLevel>;
    /**
     * Constructor
     *
     * @param {ARFilterTopicLevelService} _userService
     */
    constructor(
        private _userService: ARFilterTopicLevelService,
        private topicId: string
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._userService.onARFilterTopicLevelChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) {
        var self = this;

        this._userService.getARFilterTopicLevelForGrid(gridFilter, this.topicId)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);

                self.paginationData = {
                    count: response.Count,
                    pageNumber: response.CurrentFilter.PageNumber
                };


            });
    }
}
