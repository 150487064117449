<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="qbankForm">
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input name="name" formControlName="Title" placeholder="Name" matInput required
                        [(ngModel)]="qbank.Title">
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input name="Description" formControlName="Description" placeholder="Description" matInput required
                        [(ngModel)]="qbank.Description">
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">

                <mat-slide-toggle formControlName="IsActive" [(ngModel)]="qbank.IsActive">
                    Is Active
                </mat-slide-toggle>

            </div>
        </form>

    </div>
    <div mat-dialog-footer>
        <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

            <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(qbank)"
                class="save-button mat-accent-bg" [disabled]="qbankForm.invalid" aria-label="SAVE">
                SAVE
            </button>

            <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',qbank])"
                class="save-button mat-accent-bg" [disabled]="qbankForm.invalid" aria-label="SAVE">
                SAVE
            </button>

            <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',qbank])"
                aria-label="Delete" matTooltip="Delete">
                <mat-icon>delete</mat-icon>
            </button>

        </div>

    </div>
</div>