import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from '../common.service';
import { QuestionConfiguration } from '../common.model';

@Component({
  selector: 'app-question-configuration',
  templateUrl: './question-configuration.component.html',
  styleUrls: ['./question-configuration.component.css']
})
export class QuestionConfigurationComponent implements OnInit {
  QuestionForm: FormGroup;
  Questionconf: FormArray;
  label: string;
  responseMessage = "";
  ConfigurationList: Array<QuestionConfiguration>
  constructor(private _formBuilder: FormBuilder, private _commonService: CommonService) {
    this.QuestionForm = this._formBuilder.group({
      Questionconf: this._formBuilder.array([])
    });

  }

  ngOnInit(): void {
    this._commonService.getconfigurations().subscribe((response: any) => {
      this.ConfigurationList = response.Data;
      this.Questionconf = this.QuestionForm.get('Questionconf') as FormArray;
      for (let i = 0; i < this.ConfigurationList.length; i++) {
        this.Questionconf.push(
          this._formBuilder.group({
            configurationValue: this.ConfigurationList[i].Value
          })
        )
      }

    })
  }

  onSubmit() {
    var configurationValue = this.QuestionForm.get('Questionconf').value
    var configurationlist = []
    for (let i = 0; i < configurationValue.length; i++) {
      this.ConfigurationList[i].Value = configurationValue[i].configurationValue;
      configurationlist.push(this.ConfigurationList[i])
    }


    this._commonService.updateconfiguration(JSON.stringify(configurationlist)).subscribe((response: any) => {
      if (response.Status == 200) {
        this.ConfigurationList = this.ConfigurationList;
        this.responseMessage = "configuration Update Sucessfully";
        setTimeout(() => {
          this.responseMessage = '';
        }, 1000);
      }
      else {
        this.responseMessage = "Somthing went Wrong Please Contact supporter";
      }

    })
  }
}
