import { UserService } from 'app/_services';

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { GridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute, Router } from '@angular/router';
import { SitePreference } from 'app/config';
import { TestSeriesUserPermissionService } from '../test-series-user-permission.service';
import { TestSeriesUserPermission } from '../test-series-user-permission.model';
import { User } from 'app/_models';

@Component({
    selector: 'test-series-user-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class TestSeriesUserPermissionComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;

    user: any;

    @Input() testseriesId: string = '';

    paginationData: any;

    dataSource: TestSeriesUserPermissionDataSource;
    displayedColumns = ['UserName', 'Email', 'Mobile', 'buttons'];



    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UserService} _userService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _userService: UserService,
        private _testseriesUserPermissionService: TestSeriesUserPermissionService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _router: Router
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.userSearch = new FormControl('');
    }

    ngAfterViewInit() {

    }

    loadPage() {
        this._testseriesUserPermissionService.onTestSeriesUserPermissionChanged.next(this.user);
    }


    getNext(event: PageEvent) {
        this._testseriesUserPermissionService.onTestSeriesUserPermissionChanged.next(this.user);

    }


    onSortData(sort: Sort) {

        this._testseriesUserPermissionService.onTestSeriesUserPermissionChanged.next(this.user);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.dataSource = new TestSeriesUserPermissionDataSource(this._testseriesUserPermissionService, this.testseriesId);

        this._testseriesUserPermissionService.onTestSeriesUserPermissionChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.user = search;
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == undefined ? "TestSeriesUserPermission" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc'
                };
                this.dataSource.loadData(gridFilter);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Delete TestSeriesUserPermission
     */
    deleteTestSeriesUserPermission(user): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {

                let userPermissionHistory = {
                    ModeID: 2,
                    ProductAccessType: 2,
                    ProductID: this.testseriesId,
                    UserInfo: user
                };

                this._testseriesUserPermissionService.deleteTestSeriesUserPermission(userPermissionHistory);
            }
            this.confirmDialogRef = null;
        });

    }

    users: Array<User>;
    userSelectedValue: string;
    userSearch: FormControl;

    selectedUser(userID: string) {
        this.userSelectedValue = userID;
    }


    getUserBySearch() {
        var self = this;
        if (this.userSearch.value != null && this.userSearch.value.length > 1)
            this._userService.getUserBySearch(this.userSearch.value).then(function (response) {
                self.users = response;
            });
    }

    addUser() {
        let user = this.users.find(a => a.Email == this.userSelectedValue);

        let userPermissionHistory = {
            ModeID: 1,
            ProductAccessType: 2,
            ProductID: this.testseriesId,
            UserInfo: user
        };

        this._testseriesUserPermissionService.createTestSeriesUserPermission(userPermissionHistory)
            .then(function () {
                this.userSelectedValue = '';
                this.userSearch.reset();

            })
    }

    removeUser(user) {

        let userPermissionHistory = {
            Mode: 2,
            ProductAccessType: 2,
            ProductID: this.testseriesId,
            UserInfo: user
        };

        this._testseriesUserPermissionService.createTestSeriesUserPermission(userPermissionHistory)
            .then(function () {
                this.userSelectedValue = '';
                this.userSearch.reset();

            })
    }
}

export class TestSeriesUserPermissionDataSource extends DataSource<TestSeriesUserPermission>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<TestSeriesUserPermission>;
    /**
     * Constructor
     *
     * @param {TestSeriesUserPermissionService} _userService
     */
    constructor(
        private _userService: TestSeriesUserPermissionService,
        private testseriesId: string
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._userService.onTestSeriesUserPermissionChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) {
        var self = this;
        if (self.testseriesId) {
            this._userService.getTestSeriesUserPermissionForGrid(gridFilter, this.testseriesId)
                .pipe(
                    catchError(() => of([])),
                    finalize(() => {
                        this.loadingSubject.next(false)
                    })
                )
                .subscribe(response => {
                    this.data = new MatTableDataSource(response.data);

                    self.paginationData = {
                        count: response.Count,
                        pageNumber: response.CurrentFilter.PageNumber
                    };


                });
        }
    }
}
