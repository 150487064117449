<div class="page-layout simple right-sidebar inner-scroll">

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header mat-accent-bg p-24" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md
                (click)="toggleSidebar('simple-right-sidebar-2')">
                <mat-icon>menu</mat-icon>
            </button>

            <h2 class="m-0"> DETAIL'S</h2>
            <div class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between right">

                <button *ngIf="action !=='edit'" mat-raised-button (click)="saveOrUpdate(['new',testseries])"
                    class="save-button mat-primary-bg" [disabled]="testseriesForm.invalid" aria-label="SAVE">
                    SAVE
                </button>

                <button *ngIf="action ==='edit'" mat-raised-button (click)="saveOrUpdate(['update',testseries])"
                    class="save-button mat-primary-bg" [disabled]="testseriesForm.invalid" aria-label="SAVE">
                    SAVE
                </button>
<!-- 
                <button *ngIf="action ==='edit'" mat-icon-button (click)="saveOrUpdate(['delete',testseries])"
                    aria-label="Delete" matTooltip="Delete">
                    <mat-icon>delete</mat-icon>
                </button> -->

            </div>

        </div>
        <!-- / HEADER -->

        <!-- CONTENT -->
        <div class="content mat-white-bg p-24" unisunPerfectScrollbar>
            <form name="testseriesForm" [formGroup]="testseriesForm">
                <mat-tab-group mat-align-tabs="basic-info">

                    <mat-tab label="Basic Info" class="content">
                        <ng-template mat-tab-label>
                            <mat-icon>thumb_up</mat-icon>&nbsp;
                            Basic Info
                        </ng-template>
                        <div class="p-24 mat-white-bg">
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <mat-form-field fxFlex>
                                    <input matInput name="Title" required formControlName="Title"
                                        [(ngModel)]="testseries.Title" placeholder="Title">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <mat-form-field fxFlex>
                                    <input matInput name="SubTitle" required formControlName="SubTitle"
                                        [(ngModel)]="testseries.SubTitle" placeholder="Sub Title">
                                </mat-form-field>
                            </div>
                            <label for="Description">Description *</label>
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <ckeditor #Description [config]="ckeConfig" [(ngModel)]="testseries.Description"
                                    formControlName="Description" required>
                                </ckeditor>

                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Meta" class="content">
                        <ng-template mat-tab-label>
                            <mat-icon>build</mat-icon>&nbsp;
                            Meta
                        </ng-template>
                        <div class="p-24 mat-white-bg">
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <mat-form-field class="mr-sm-24" fxFlex>
                                    <input matInput [matDatepicker]="startDatePicker" [(ngModel)]="testseries.StartDate"
                                        placeholder="Start Date" name="start" formControlName="StartDate" required>
                                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #startDatePicker></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field class="mr-sm-24" fxFlex>
                                    <input matInput [matDatepicker]="endDatePicker" [(ngModel)]="testseries.EndDate"
                                        placeholder="End Date" name="EndDate" formControlName="EndDate" required>
                                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #endDatePicker></mat-datepicker>
                                </mat-form-field>


                            </div>

                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <mat-form-field fxFlex>
                                    <input type="number" matInput name="RewardsPoint" required
                                        formControlName="RewardsPoint" [(ngModel)]="testseries.RewardsPoint"
                                        placeholder="Rewards Point">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <mat-form-field fxFlex>
                                    <input type="number" matInput name="Price" required formControlName="Price"
                                        [(ngModel)]="testseries.Price" placeholder="Price">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">
                                <mat-slide-toggle class="p-4" formControlName="IsActive" [(ngModel)]="testseries.IsActive">
                                    Is Active
                                </mat-slide-toggle>
                            </div>
                        </div>

                    </mat-tab>
                    <mat-tab label="Lesson" class="content" [disabled]="TestSeriesID==null || TestSeriesID==0">
                        <ng-template mat-tab-label>
                            <mat-icon>school</mat-icon>&nbsp;
                            Lesson
                        </ng-template>
                      
                        <div class="p-24 mat-white-bg">
                            <div fxLayout="column" fxLayoutAlign="center">

                                <div class="filters" fxLayout="column" fxLayoutAlign="center center"
                                    fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

                                    <mat-form-field class="exam-search">
                                        <input matInput placeholder="Exam" aria-label="Exam" [matAutocomplete]="auto"
                                            (keydown)="getExamBySearch($event)" required [formControl]="examSearch">
                                        <mat-autocomplete #auto="matAutocomplete" id="examsearch" panelWidth="500px"
                                            (optionSelected)='selectedExam($event.option.value)'>
                                            <mat-option *ngFor="let exam of exams" [value]="exam.Title">
                                                <span>{{exam.Title}}</span> |
                                                <small>Subject: {{exam.Subject}}</small>
                                            </mat-option>
                                        </mat-autocomplete>

                                    </mat-form-field>

                                    <button mat-raised-button class="save-button mat-accent-bg"
                                        [disabled]="examSelectedValue==null || examSelectedValue==''" aria-label="ADD"
                                        (click)="addExam()">
                                        ADD
                                    </button>
                                </div>


                            </div>
                            <div fxLayout="column" fxLayoutAlign="center">

                                <table class="simple clickable">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th class="text-right">Subject</th>
                                            <th class="text-right">Expire On</th>
                                            <th class="text-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of testseries.ExamID">
                                            <td>{{row.Title}}</td>
                                            <td class="text-right">{{row.Subject}}</td>
                                            <td class="text-right">
                                                {{(row.EndDate) | amDateFormat:'DD/MM/YYYY hh:mmA' }}
                                            </td>
                                            <td class="text-right">
                                                <a (click)="removeExam(row)">
                                                    <mat-icon>delete</mat-icon>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Users" class="content" [disabled]="TestSeriesID==null || TestSeriesID==0">
                        <ng-template mat-tab-label>
                            <mat-icon>accessibility</mat-icon>&nbsp;
                            Users
                        </ng-template>
                        <div class="p-24 mat-white-bg">
                            <div fxLayout="column" fxLayoutAlign="center">
                                <test-series-user-list [testseriesId]="TestSeriesID"></test-series-user-list>
                            </div>
                        </div>
                    </mat-tab>

                </mat-tab-group>
            </form>
        </div>
        <!-- / CONTENT -->

    </div>
    <!-- / CENTER -->

</div>