import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';
import { CKEditorModule } from 'ng2-ckeditor';

import { LiveVideoComponent } from './video.component';
import { LiveVideoService } from './video.service';
import { LiveVideoListComponent } from './video-list/list.component';
import { LiveVideoFormDialogComponent } from './video-form/form.component';
import { LiveVideoDetailsFormDialogComponent } from './video-detail-form/form.component';
import { LiveVideoDetailsComponent } from './video-detail/list.component';
import { LiveVideoDetailsService } from './video-detail.service';

const routes: Routes = [
    {
        path: 'collections/live-video',
        component: LiveVideoComponent,
        resolve: {
            service: LiveVideoService
        }
    },
    {
        path: 'collections/live-video/:id',
        component: LiveVideoDetailsComponent,
        resolve: {
            service: LiveVideoDetailsService
        }
    }
];

@NgModule({
    declarations: [
        LiveVideoComponent,
        LiveVideoListComponent,
        LiveVideoFormDialogComponent,
        LiveVideoDetailsFormDialogComponent,
        LiveVideoDetailsComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatMenuModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule,

        CKEditorModule
    ],
    providers: [
        LiveVideoService,
        LiveVideoDetailsService
    ],
    entryComponents: [
        LiveVideoFormDialogComponent,
        LiveVideoDetailsFormDialogComponent
    ]
})
export class LiveMediaModule {
}
