import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { UnisunUtils } from '@unisun/utils';

import { Exam } from 'app/main/admin/exam/exam.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class DailyQuestionService implements Resolve<any>
{
    onExamChanged: BehaviorSubject<any>;

    exam: Exam[];
    user: any;
    selectedExam: string[] = [];

    searchText: string;
    filterBy: string;
    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onExamChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} exam
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, exam: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getExamForGrid(gridFilter)
            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get exam
     *
     * @returns {Promise<any>}
     */
    getExam(search: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/daily/quiz/get-quizs/`)
                .subscribe((response: any) => {

                    this.exam = response;
                    resolve(this.exam);
                }, reject);
        }
        );
    }
    /**
     * Get exam
     *
     * @returns {Promise<any>}
     */
    getExamForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/daily/quiz/get-grid-quizs`, { ..._gridFilter });
    }

    /**
     * Update exam
     *
     * @param exam
     * @returns {Promise<any>}
     */
    createExam(exam: Exam): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/daily/quiz/create-quiz/`, { ...exam })
                .subscribe(response => {
                    if (response) {
                        this.onExamChanged.next(this.exam);
                        this.openSnackBar("Successfully added.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Update exam
     *
     * @param exam
     * @returns {Promise<any>}
     */
    updateExam(exam: Exam): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/daily/quiz/update-quiz/`, { ...exam })
                .subscribe(response => {
                    if (response) {
                        this.onExamChanged.next(this.exam);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete exam
     *
     * @param exam
     */
    deleteExam(exam): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.delete(`${environment.apiURL}/daily/quiz/delete-quiz/` + exam.ExamID, {})
                .subscribe(response => {
                    if (response) {
                        this.onExamChanged.next(this.exam);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Get Score Type
     *
     * @returns {Promise<any>}
     */
    getScoreType(): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.get('api/academy-scoretype')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get Score Type
     *
     * @returns {Promise<any>}
     */
    getChoiceType(): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.get('api/academy-daily-scoretype')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
