import { AppQuestionsRequest, PGiQuestionsRequest } from './../../apps/academy/game-view.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { UnisunUtils } from '@unisun/utils';

import { Report, AppRankUpdateRequest } from 'app/main/admin/report/report.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { GridFilter, ReportGridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ReportService implements Resolve<any>
{
    onReportChanged: BehaviorSubject<any>;

    report: Report[];
    user: any;
    selectedReport: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onReportChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([

            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get report
     *
     * @returns {Promise<any>}
     */
    getReportForGrid(_gridFilter: ReportGridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/report/get-grid-analysis-report`, { ..._gridFilter });
    }

    /**
     * Get report
     *
     * @returns {Promise<any>}
     */
    getReportDownload(_gridFilter: ReportGridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/report/get-grid-analysis-report?timestamp=` + UnisunUtils.generateGUID(), { ..._gridFilter });
    }

    /**
     * Get report
     *
     * @returns {Promise<any>}
     */
    getReportByTypeDownload(_gridFilter: ReportGridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/report/get-exam-report-by-type?timestamp=` + UnisunUtils.generateGUID(), { ..._gridFilter });
    }

    /**
     * Get report
     *
     * @returns {Promise<any>}
     */
    updateUserRankByExam(appRankUpdateRequest: AppRankUpdateRequest): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/report/update-user-rank`, { ...appRankUpdateRequest });
    }

    /**
     * Get report
     *
     * @returns {Promise<any>}
     */
    getQuestionsAnalysis(_gridFilter: PGiQuestionsRequest): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/report/get-pgi-questions-analysis`, { ..._gridFilter });
    }

    /**
  * Get report
  *
  * @returns {Promise<any>}
  */
    getDashboardOverviewAnalysis(): Observable<any> {

        return this._httpClient.get(`${environment.apiURL}/report/get-dashboard-overview-report`);
    }

    /**
   * Get Topper
   *
   * @returns {Promise<any>}
   */
    getTopperAnalysis(request): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/report/get-leaderboard`, { ...request });
    }

    /**
     * Get Underperoformar
     *
     * @returns {Promise<any>}
     */
    getUnderperoformarAnalysis(request): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/report/get-underperformar`, { ...request });
    }
}
