import { PgiUserPermission } from './user-permission.model';
import { filter, catchError, finalize, takeUntil } from 'rxjs/operators';

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'app/main/admin/course/course.service';
import { TestSeriesService } from 'app/main/admin/test-series/test-series.service';
import { Exam } from 'app/main/admin/exam/exam.model';

import { User } from 'app/_models';

import { FormControl } from '@angular/forms';
import { UserService } from 'app/_services';

import { SitePreference } from 'app/config';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { PgiUserPermissionService } from './user-permission.service';
import { DataSource } from '@angular/cdk/table';
import { GridFilter } from 'app/_models/GridFilter';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-pgi-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class PGiReportComponent implements OnInit {


    Type: number;
    TypeID: number;
    ExamID: number;
    StartDate: Date;
    EndDate: Date;
    title: string = '';

    TypeList: any;
    ExamList: Array<Exam>;
    _sitePreference: any = SitePreference;

    user: any;

    // @Input() courseId: string = '';
    // @Input() course: Exam;

    paginationData: any;
    _data: any;

    dataSource: PgiUserPermissionDataSource;
    displayedColumns = ['UserName', 'Email', 'Mobile', 'buttons'];



    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;


    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService,
        private _courseService: CourseService,
        private _testseriesService: TestSeriesService,
        private _courseUserPermissionService: PgiUserPermissionService,
    ) {
        var self = this;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.userSearch = new FormControl('');

    }

    OnChangeType(TypeID: number) {
        var self = this;
        self.ExamList = [];

        if (self.Type == 1) {
            self._courseService.getExams(TypeID).then(function (data) {
                self.ExamList = data.filter(r => r.ScoreType == 2);
            });
        }
        else if (self.Type == 2) {
            self._testseriesService.getTestSeriesById(TypeID).subscribe(function (data) {
                self.ExamList = data.ExamID;
            });
        }
    }

    onExamChange(ExamID: number) {
        this.dataSource = new PgiUserPermissionDataSource(this._courseUserPermissionService, this.TypeID, this.ExamID);

        this._courseUserPermissionService.onPgiUserPermissionChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.user = search;
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == undefined ? "UserName" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc'
                };
                this.dataSource.loadData(gridFilter);
            });
    }

    loadPage() {
        let gridFilter: GridFilter = {
            PageNumber: this.paginator.pageIndex + 1,
            PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
            Search: "",
            SortBy: this.sort.active == undefined ? "UserName" : this.sort.active,
            SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc'
        };
        this.dataSource.loadData(gridFilter);
    }


    getNext(event: PageEvent) {
        this._courseUserPermissionService.onPgiUserPermissionChanged.next(this.user);

    }


    onSortData(sort: Sort) {

        this._courseUserPermissionService.onPgiUserPermissionChanged.next(this.user);
    }


    ngOnInit() {

        var self = this;

        self._route.params.subscribe(function (parram) {
            self.title = parram.type;
            self.Type = parram.type == 'course' ? 1 : 2;

            if (self.Type == 1) {
                self._courseService.getCourse().subscribe(function (data) {
                    self.TypeList = data;
                });
            }
            else if (self.Type == 2) {
                self._testseriesService.getTestSeries().subscribe(function (data) {
                    self.TypeList = data;
                });
            }
        });
        this.dataSource = new PgiUserPermissionDataSource(this._courseUserPermissionService, this.TypeID,this.ExamID);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    users: Array<User>;
    userSelectedValue: string;
    userSearch: FormControl;

    selectedUser(userID: string) {
        this.userSelectedValue = userID;
    }


    getUserBySearch() {
        var self = this;
        if (this.userSearch.value != null && this.userSearch.value.length > 1)
            this._userService.getUserBySearch(this.userSearch.value).then(function (response) {
                self.users = response;
            });
    }

    OnViewResult(user: User) {
        this._router.navigate(['admin/report/pgi-analysis/result/' + this.title + "/" + this.TypeID + "/" + this.ExamID +"/" + user.UserID]);
    }

}
export class PgiUserPermissionDataSource extends DataSource<PgiUserPermission>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<PgiUserPermission>;
    /**
     * Constructor
     *
     * @param {PGiUserPermissionService} _userService
     */
    constructor(
        private _userService: PgiUserPermissionService,
        private courseId: number,
        private examID: number
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._userService.onPgiUserPermissionChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) {
        var self = this;

        this._userService.getPgiUserPermissionForGrid(gridFilter, this.courseId, this.examID)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);

                self.paginationData = {
                    count: response.Count,
                    pageNumber: response.CurrentFilter.PageNumber
                };


            });
    }
}