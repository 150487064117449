<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="todoForm">

            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <textarea name="Title" formControlName="Title" placeholder="Name" matInput required
                        [(ngModel)]="todo.Title"></textarea>
                </mat-form-field>
            </div>

            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>

                    <input name="Subject" formControlName="Subject" placeholder="Subject" matInput required
                        [(ngModel)]="todo.Subject">
                </mat-form-field>
            </div>
            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>

                    <input name="Chapter" formControlName="Chapter" placeholder="Chapter" matInput required
                        [(ngModel)]="todo.Chapter">
                </mat-form-field>
            </div>

            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <textarea name="Note" formControlName="Note" placeholder="Note" matInput
                        [(ngModel)]="todo.Note"></textarea>
                </mat-form-field>
            </div>
            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-slide-toggle formControlName="IsImportent" [(ngModel)]="todo.IsImportent">
                    Is Importent
                </mat-slide-toggle>

            </div>
            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-slide-toggle formControlName="IsStarred" [(ngModel)]="todo.IsStarred">
                    Is Starred
                </mat-slide-toggle>

            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(todo)"
            class="save-button mat-accent-bg" [disabled]="todoForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',todo])"
            class="save-button mat-accent-bg" [disabled]="todoForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',todo])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>