import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { UnisunPipesModule } from '@unisun/pipes/pipes.module';

import { UnisunMaterialColorPickerComponent } from '@unisun/components/material-color-picker/material-color-picker.component';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
    declarations: [
        UnisunMaterialColorPickerComponent
    ],
    imports: [
        CommonModule,

        FlexLayoutModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatRippleModule,

        UnisunPipesModule
    ],
    exports: [
        UnisunMaterialColorPickerComponent
    ],
})
export class UnisunMaterialColorPickerModule
{
}
