import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


import { Subject, Topic, VirtualLearnBook } from './book.model';
import { environment } from 'environments/environment';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable()
export class BookService implements Resolve<any>
{
    onBookChanged: BehaviorSubject<any>;

    book: VirtualLearnBook[];
    user: any;
    selectedBook: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onBookChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterBookSnapshot} book
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, book: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getBookForGrid(gridFilter)
            ]).then(
                ([]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get book by Id
     *
     * @returns {Promise<any>}
     */
    getBookById(id): Promise<VirtualLearnBook[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/virtual-learn/get-book-by-id/` + id)
                .subscribe((response: any) => {

                    this.book = response;

                    this.onBookChanged.next(this.book);
                    resolve(this.book);
                }, reject);
        }
        );
    }
    /**
     * Get book
     *
     * @returns {Promise<any>}
     */
    getBook(): Promise<VirtualLearnBook[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/virtual-learn/get-books`)
                .subscribe((response: any) => {

                    this.book = response;

                    this.onBookChanged.next(this.book);
                    resolve(this.book);
                }, reject);
        }
        );
    }

    getSubjects(): Observable<Subject[]> {
        return this._httpClient.get<Subject[]>(`${environment.apiURL}/adr/get-subjects`);
    }
    getsubjectTopics(subjectId): Observable<Topic[]> {
        
         return this._httpClient.get<Topic[]>(`${environment.apiURL}/adr/get-topics/${subjectId}/Custom QBank`,{})
               
       
    }
    /**
     * Get book
     *
     * @returns {Promise<any>}
     */
    getBookForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/virtual-learn/get-grid-books/`, { ..._gridFilter });
    }

    /**
     * Update book
     *
     * @param book
     * @returns {Promise<any>}
     */
    createBook(book: VirtualLearnBook): Promise<any> {
        return new Promise((resolve) => {

            this._httpClient.post(`${environment.apiURL}/virtual-learn/create-book/`, { ...book })
                .subscribe(response => {
                    if (response) {
                        this.onBookChanged.next(this.book);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                    resolve(response);
                });
        });
    }

    /**
     * Update book
     *
     * @param book
     * @returns {Promise<any>}
     */
    updateBook(book: VirtualLearnBook): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/virtual-learn/update-book/`, { ...book })
                .subscribe(response => {
                    if (response) {
                        this.onBookChanged.next(this.book);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete book
     *
     * @param book
     */
    deleteBook(book): Promise<any> {
        return new Promise(() => {

            this._httpClient.delete(`${environment.apiURL}/virtual-learn/delete-book/` + book.BookID, {})
                .subscribe(response => {
                    if (response) {
                        this.onBookChanged.next(this.book);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

}
