<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Assigned Courses</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <mat-list>
            <div mat-subheader>Courses</div>
            <mat-list-item *ngFor="let folder of courses">
                <mat-icon mat-list-icon>folder</mat-icon>
                <div mat-line>{{folder.Title}}</div>
                <div mat-line> Expires on: {{folder.EndDate | amDateFormat:'DD MMMM YYYY'}} </div>
            </mat-list-item>
            
        </mat-list>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">


    </div>
</div>