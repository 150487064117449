<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="reportForm">

            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <mat-icon matPrefix class="mr-12 s-20 secondary-text">account_circle</mat-icon>
                    <input name="name" formControlName="Report" placeholder="Name" matInput required>
                </mat-form-field>
            </div>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'"
                mat-raised-button
                (click)="matDialogRef.close(reportForm)"
                class="save-button mat-accent-bg"
                [disabled]="reportForm.invalid"
                aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'"
                mat-raised-button
                (click)="matDialogRef.close(['save',reportForm])"
                class="save-button mat-accent-bg"
                [disabled]="reportForm.invalid"
                aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'"
                mat-icon-button
                (click)="matDialogRef.close(['delete',reportForm])"
                aria-label="Delete"
                matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>
