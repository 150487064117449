import { AssetFormDialogComponent } from './../Subcription-form/form.component';
import { Video } from './../../video/video.model';
import { Todo } from './../../todo/todo.model';
import { Note } from './../../note/note.model';
import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


import { Course, SubscriptionPlan, Subscription, SubscriptionResponse } from 'app/main/admin/course/course.model';
import { UnisunSidebarService } from '@unisun/components/sidebar/sidebar.service';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';
import { Exam } from '../../exam/exam.model';
import { ExamService } from '../../exam/exam.service';
import { CourseService } from '../course.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataGuardService } from 'app/_guards';
import { Hippo } from '../../hippo/hippo.model';
import { LiveVideo } from '../../live-video/video.model';
import { User } from 'app/_models';

@Component({
    selector: 'course-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CourseFormComponent {
    action: string;
    course: Course;
    courseForm: FormGroup;
    dialogTitle: string;
    CourseID: number;
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;


    hippos: Array<Hippo>;
    notes: Array<Note>;
    videos: Array<Video>;
    LiveSessions: Array<LiveVideo>;
    todos: Array<Todo>;

    exams: Array<Exam>;
    examSelectedValue: string;

    examSearch: FormControl;
    ckeConfig: any;
    ExamCollections: any;

    IsHippoFree: boolean = false;
    IsVideoFree: boolean = false;
    IsQBankFree: boolean = false;
    IsTodoFree: boolean = false;

    Subscription: Subscription;
    SubscriptionResponse: SubscriptionResponse;
    userModel: User;
    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _unisunSidebarService: UnisunSidebarService,
        public _matDialog: MatDialog,
        private _examService: ExamService,
        private _courseService: CourseService,
        private _route: ActivatedRoute,
        private _dataGuardService: DataGuardService,
        private _router: Router) {
        var self = this;
        self.ExamCollections = [];
        // Set the defaults
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        this.examSearch = new FormControl('');

        this.action = 'new';
        self.course = new Course({});

        this._route.params.subscribe(function (parram) {

            self.CourseID = self._dataGuardService.valueDecryption(parram.id);

            if (self.CourseID > 0) {
                self.action = "edit";
                self.dialogTitle = 'Edit Course';
                self._courseService.getCourseById(self.CourseID).subscribe(function (response) {
                    //debugger
                    self.course = response;
                });
                self.getSubscription();
            }
            else {
                self.dialogTitle = 'New Course';
                self.course = new Course({});
                self.Subscription = new Subscription({});
                self.Subscription.Type = 1;
                self.Subscription.TypeID = self.CourseID;
                self.Subscription.Plans = new Array<SubscriptionPlan>();
            }
        });
        self.getHippos();
        self.getNotes();
        self.getTodos();
        self.getVideos();
        self.getLiveSession();
        self.GetExamCollection();
        self.courseForm = self.createCourseForm();
        // User Data if already logged in 
        let user = self._dataGuardService.getCookieData('lms-user');

        if (user) {
            self.userModel = JSON.parse(user);
        }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    GetExamCollection() {
        var self = this;
        self.ExamCollections = [];
        if (self.CourseID)
            self._courseService.getAssetExams(self.CourseID).then(function (data) {
                if (data.length > 0) {
                    data.forEach(subject => {
                        subject.Collections.forEach(chapter => {
                            
                                self.ExamCollections.push(chapter);
                         
                        });
                    });
                }
            });

    }
    /**
     * Create course form
     *
     * @returns {FormGroup}
     */
    createCourseForm(): FormGroup {
        return this._formBuilder.group({
            CourseID: [this.course.CourseID],
            Title: [this.course.Title],
            SubTitle: [this.course.SubTitle],
            Description: [this.course.Description],
            Price: [this.course.Price],
            RewardsPoint: [this.course.RewardsPoint],
            StartDate: [this.course.StartDate],
            EndDate: [this.course.EndDate],
            VideoID: [this.course.VideoID],
            HipposID: [this.course.HipposID],
            NoteID: [this.course.NoteID],
            IsActive: [this.course.IsActive],
            IsHide: [this.course.IsHide],
            TodoID: [this.course.TodoID],
            ExamID: [this.course.ExamID],
            LiveSessionID: [this.course.LiveSessionID],
            Users: [this.course.Users],
        });
    }

    /**
 * Toggle sidebar
 *
 * @param name
 */
    toggleSidebar(name): void {
        this._unisunSidebarService.getSidebar(name).toggleOpen();
    }

    filterByAssetType(assets: Array<SubscriptionPlan>, SubscriptionTypeID: number) {
        return assets.filter(a => a.SubscriptionTypeID == SubscriptionTypeID);
    }

    saveOrUpdate(courseFrom: any) {
        if (!courseFrom) {
            return;
        }
        var self = this;

        const actionType: string = courseFrom[0];
        const formData: Course = courseFrom[1];

        switch (actionType) {
            case 'new':

                self._courseService.createCourse(formData).then(function (response) {
                    self._router.navigate(['admin/product/course/' + self._dataGuardService.valueEncryption(response)]);
                });

                break;
            case 'update':

                this._courseService.updateCourse(formData);

                break;

            case 'delete':

                this._courseService.deleteCourse(formData);

                break;
        }
    }

    getHippos() {
        var self = this;

        this._courseService.getHippos().then(function (response) {
            self.hippos = response;
        });
    }

    getNotes() {
        var self = this;

        this._courseService.getNotes().then(function (response) {
            self.notes = response;
        });
    }

    getTodos() {
        var self = this;

        this._courseService.getTodos().then(function (response) {
            self.todos = response;
        });
    }

    getVideos() {
        var self = this;

        this._courseService.getVideos().then(function (response) {
            self.videos = response;
        });
    }

    getLiveSession() {
        var self = this;

        this._courseService.getLiveSession().then(function (response) {
            self.LiveSessions = response;
        });
    }

    selectedExam(examID: string) {
        this.examSelectedValue = examID;
    }


    getExamBySearch() {
        var self = this;
        if (this.examSearch.value != null && this.examSearch.value.length > 1)
            this._examService.getExam(this.examSearch.value).then(function (response) {
                self.exams = response;
            });
    }

    addExam() {
        var self = this;

        let exam = this.exams.find(a => a.Title == this.examSelectedValue);

        this._courseService.mapExamByCourse(exam, this.CourseID).subscribe(response => {
            if (response) {
                this.examSelectedValue = '';
                this.examSearch.reset();
                self.GetExamCollection();
                self._courseService.openSnackBar("Successfully updated.", "Close");
            }
            else {
                self._courseService.openSnackBar("Failed", "Close");
            }
        });
    }

    getSubscription() {
        var self = this;

        self._courseService.getSubscription(1, self.CourseID).then(response => {
            if (response) {
                self.SubscriptionResponse = response;

            }
            else {
                self.Subscription = new Subscription({});
                self.Subscription.Type = 1;
                self.Subscription.TypeID = self.CourseID;
            }
        });

    }
    removeExam(exam: Exam) {
        var self = this;

        self._courseService.deleteExamByCourse(exam, this.CourseID).subscribe(response => {
            if (response) {
                self.GetExamCollection();
                self.examSelectedValue = '';
                self.examSearch.reset();
                self._courseService.openSnackBar("Successfully updated.", "Close");
            }
            else {
                self._courseService.openSnackBar("Failed", "Close");
            }
        });
    }

    newAsset(plan: Subscription): void {

        let asset = new SubscriptionPlan({
            AssetType: 1,
            SubscriptionTypeID: plan.SubscriptionTypeID

        });

        asset.AssetType = 1;
        asset.Type = 1;
        asset.TypeID = this.CourseID;
        asset.AssetType = 1;
        asset.SubscriptionTypeID = plan.SubscriptionTypeID;
        plan.Plan = asset;

        debugger;
        this.dialogRef = this._matDialog.open(AssetFormDialogComponent, {
            panelClass: 'asset-form-dialog',
            data: {
                action: 'new',
                Asset: plan
            }
        });
        this.newAssetCallback();


    }


    newAssetCallback() {
        var self = this;

        this.dialogRef.afterClosed()
            .subscribe((response: Subscription) => {
                if (!response) {
                    return;
                }

                self._courseService.createAssetSubscription(response).then(response => {
                    if (response) {
                        self.Subscription = response;
                        self._courseService.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        self._courseService.openSnackBar("Failed", "Close");
                    }
                });
            });
    }

    updateSubscription(plan: Subscription) {
        var self = this;

        plan.Type = 1;
        plan.TypeID = self.CourseID;
        self._courseService.createAssetSubscription(plan).then(response => {
            if (response) {
                self.SubscriptionResponse = response;
                self._courseService.openSnackBar("Successfully updated.", "Close");
            }
            else {
                self._courseService.openSnackBar("Failed", "Close");
            }
        });
    }

    editAsset(asset, plan: Subscription): void {

        plan.Plan = asset;

        this.dialogRef = this._matDialog.open(AssetFormDialogComponent, {
            panelClass: 'asset-form-dialog',
            data: {
                Asset: plan,
                action: 'edit'
            }
        });
        this.editAssetCallback(asset);
    }

    editAssetCallback(plan: Subscription) {
        var self = this;
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: Subscription = response[1];
                formData.Plan.Type = 1;
                formData.Plan.TypeID = self.CourseID;
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':


                        self._courseService.createAssetSubscription(formData).then(response => {
                            if (response) {
                                self.SubscriptionResponse = response;
                                self._courseService.openSnackBar("Successfully updated.", "Close");
                            }
                            else {
                                self._courseService.openSnackBar("Failed", "Close");
                            }
                        });


                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        self._courseService.deleteAssetSubscription(formData).then(response => {
                            if (response) {
                                self.SubscriptionResponse = response;
                                self._courseService.openSnackBar("Successfully updated.", "Close");
                            }
                            else {
                                self._courseService.openSnackBar("Failed", "Close");
                            }
                        });

                        break;
                }
            });
    }

    /**
     * Delete Asset
     */
    deleteAsset(asset): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
            this.confirmDialogRef = null;
        });

    }
}