<div id="main">

    <!-- TOOLBAR: Above -->
    <ng-container *ngIf="unisunConfig.layout.toolbar.position === 'above'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above -->

    <!-- NAVBAR: Top -->
    <ng-container>
        <ng-container *ngTemplateOutlet="topNavbar"></ng-container>
    </ng-container>
    <!-- / NAVBAR: Top -->

    <!-- TOOLBAR: Below -->
    <ng-container *ngIf="unisunConfig.layout.toolbar.position === 'below'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Below -->

    <div id="container-1" class="container">

        <!-- NAVBAR: Left -->
        <ng-container>
            <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Left -->

        <div id="container-2" class="container">

            <div id="container-3" class="container" unisunPerfectScrollbar
                 [unisunPerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">

                <!-- CONTENT -->
                <content></content>
                <!-- / CONTENT -->

                <!-- FOOTER: Below -->
                <ng-container *ngIf="unisunConfig.layout.footer.position === 'above-static'">
                    <ng-container *ngTemplateOutlet="footer"></ng-container>
                </ng-container>
                <!-- / FOOTER: Below -->

            </div>

        </div>

    </div>

    <!-- FOOTER: Above -->
    <ng-container *ngIf="unisunConfig.layout.footer.position === 'above-fixed'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- FOOTER: Above -->

</div>


<!-- QUICK PANEL -->
<unisun-sidebar name="quickPanel" position="right" class="quick-panel">
    <quick-panel></quick-panel>
</unisun-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
    <toolbar *ngIf="!unisunConfig.layout.toolbar.hidden"
             [ngClass]="[unisunConfig.layout.toolbar.position, unisunConfig.layout.toolbar.background]"></toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
    <footer *ngIf="!unisunConfig.layout.footer.hidden"
            [ngClass]="[unisunConfig.layout.footer.position, unisunConfig.layout.footer.background]"></footer>
</ng-template>
<!-- / FOOTER -->

<!-- TOP NAVBAR -->
<ng-template #topNavbar>
    <navbar variant="horizontal-style-1"
            class="top-navbar" [ngClass]="unisunConfig.layout.navbar.background"
            fxHide fxShow.gt-md
            *ngIf="!unisunConfig.layout.navbar.hidden">
    </navbar>
</ng-template>
<!-- / TOP NAVBAR -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
    <unisun-sidebar name="navbar" class="navbar-unisun-sidebar"
                  [folded]="unisunConfig.layout.navbar.folded"
                  *ngIf="!unisunConfig.layout.navbar.hidden">
        <navbar [variant]="unisunConfig.layout.navbar.variant"
                class="left-navbar" [ngClass]="unisunConfig.layout.navbar.background"></navbar>
    </unisun-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->
