import { UnisunUtils } from '@unisun/utils';

export class State {
    StateID: string;
    CountryID: string;
    Country: string;
    Name: string;

    /**
     * Constructor
     *
     * @param state
     */
    constructor(state) {
        {
            this.StateID = state.StateID; //|| UnisunUtils.generateGUID();
            this.Name = state.Name || '';
            this.Country = state.Country || '';
            this.CountryID = state.CountryID || '';
        }
    }
}
