import { UnisunNavigation } from '@unisun/types';

export const navigation: UnisunNavigation[] = [
    {
        id: 'applications',
        title: 'Synapse',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'academy-course',
                title: 'Courses',
                translate: 'NAV.ACADEMY',
                type: 'item',
                icon: 'school',
                url: '/apps/academy/courses',
                roles:[2,3,1]
                
            },
            {
                id: 'academy-test-series',
                title: 'Test Series',
                translate: 'NAV.TESTSERIES',
                type: 'item',
                hidden: true,
                icon: 'assignment',
                url: '/apps/academy/testseries',
                roles:[2,3]
            },
            {
                id: 'academy-book-marks',
                title: 'Bookmarks',
                translate: 'NAV.BOOKMARKS',
                type: 'item',                
                icon: 'bookmark',
                url: '/apps/academy/bookmarkslist',
                roles:[2,3,1]
            },
            {
                id: 'academy-book-marks',
                title: 'My Rank',
                translate: 'NAV.MYRANK',
                type: 'item',
                hidden: true,
                icon: 'adjust',
                url: '/apps/academy/myranking/testseries',
                roles:[2,3]
            },
        ]
    },  
    {
        id: 'settings',
        title: 'settings',
        translate: 'NAV.SETTINGS',
        type: 'group',
        hidden: true,
        icon: 'setting',
        children: [
            {
                id: 'mail-settings',
                title: 'Mail Configuration',
                translate: 'NAV.MAIL-CONFIGURATION',
                type: 'item',
                icon: 'mail',
                url: '/settings/mail/configuration',
                roles:[2,3]
            }
        ]
    },
    {
        id: 'instructor',
        title: 'Instructor',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'analytics',
                title: 'Dashboard',
                type: 'collapsable',
                icon: 'home',
                hidden: false,
                url: '/admin/dashboards/analytics',
                roles:[2,3,1],
                children: [
                    {
                        id: 'students',
                        title: 'Students',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/dashboards/student/analytics',
                        roles:[2,3]
                    }
                ]
            },
            //by vansh garg
            {
                id: 'analytics',
                title: 'Question Analytics',
                type: 'item',
                icon: 'home',
                url: '/admin/qbank/analytics',
                roles:[2,3,4],
               
            },
            
            {
                id: '  QuestionCreator',
                title: 'Question Creator',
                type: 'collapsable',
                icon: 'home',
               children: [
                    {
                        id: 'Create',
                        title: 'Create Account',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/QuestionCreator/create',
                        roles:[2,3,4]
                    },
                    {
                        id: 'Create',
                        title: 'List of Creators',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/QuestionCreator/list',
                        roles:[2,3,4]
                    }
                ]
            },
             {
                id: 'CreateQbank',
                title: 'Q\'Bank Status',
                type: 'collapsable',
                icon: 'home',
     
                children: [
                    {
                        id: 'QuestionApproved',
                        title: 'Questions Approved',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/Question/Approved',
                        roles:[2,3]
                    },
                    {
                        id: 'QuestionPending',
                        title: 'Waiting for approval',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/Question/Pending',
                        roles:[2,3]
                    },
                    {
                        id: 'Questionrejected',
                        title: 'Questions Rejected',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/Question/rejected',
                        roles:[2,3]
                    },
                    {
                        id: 'create',
                        title: 'Create Question',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/Question/create',
                        roles:[2,3,4]
                     
                    },
                    {
                        id: 'list',
                        title: 'List of Questions',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/Question/list',
                        roles:[2,3,4]
                     
                    }
                    // {
                    //     id: 'WatingForApproval',
                    //     title: 'Wating For Approval',
                    //     type: 'item',
                    //     icon: 'check_box',
                    //     url: '/admin/Question/WatingForApproval'
                    // },
                ]
               
            },
            
//End vansh garg
          
            {
                id: 'home',
                title: 'Communication',
                translate: 'NAV.DASHBOARDS',
                type: 'collapsable',
                icon: 'dashboard',
                
                children: [
                    
                    {
                        id: 'notification',
                        title: 'Notification',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/localization/notification',
                        roles:[2,3]
                    },
                    {
                        id: 'tranding-videos',
                        title: 'Tranding Video',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/localization/tranding-video',
                        roles:[2,3]
                    },
                    {
                        id: 'live-intract-videos',
                        title: 'Live Intract Video',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/localization/live-intract-video',
                        roles:[2,3]
                    },
                    {
                        id: 'mailer',
                        title: 'Communications Centre',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/feature/messanger',
                        roles:[2,3]
                    }
                ]
            },
            {
                id: 'localization',
                title: 'Localization',
                translate: 'NAV.MASTER',
                type: 'collapsable',
                icon: 'file_copy',
                children: [
                    {
                        id: 'country',
                        title: 'Country',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/localization/country',
                        roles:[2,3]
                    },
                    {
                        id: 'state',
                        title: 'States',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/localization/state',
                        roles:[2,3]
                    },
                    {
                        id: 'college',
                        title: 'Colleges',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/localization/college',
                        roles:[2,3]
                    },
                    {
                        id: 'studentcategory',
                        title: 'Category',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/localization/student-category',
                        roles:[2,3]
                    }
                ]
            },
            {
                id: 'Configuration',
                title: 'Configuration',
                type: 'item',
                icon: 'description',
                url:'/admin/collection/configuration',
                roles:[2,3]
            },
            {
                id: 'collection',
                title: 'Collection',
                translate: 'NAV.COLLECTION',
                type: 'collapsable',
                icon: 'description',
                children: [
                    {
                        id: 'hippo',
                        title: 'Hippo',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/collections/hippo',
                        hidden: false,
                        roles:[2,3]
                    },
                    {
                        id: 'notes',
                        title: 'Notes',
                        icon: 'check_box',
                        type: 'item',
                        url: '/admin/collections/note',
                        roles:[2,3]
                    },
                    {
                        id: 'to-do',
                        title: 'To-Do',
                        translate: 'NAV.TODO',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/collections/todo',
                        hidden: false,
                        roles:[2,3]
                    },
                    {
                        id: 'video',
                        title: 'Video',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/collections/video',
                        hidden: false,
                        roles:[2,3]
                    },
                    {
                        id: 'live-video',
                        title: 'Meetings',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/collections/live-video',
                        hidden: false,
                        roles:[2,3]
                    },
                    {
                        id: 'qbank',
                        title: 'Question Set',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/collections/qbank',
                        roles:[2,3]
                    },
                    {
                        id: 'exam',
                        title: 'Exams',
                        type: 'item',
                        icon: 'check_box',
                        url: '/admin/collections/exams',
                        roles:[2,3]
                    },
                    {
                        id:'qbankType',
                        title: 'Qbank Type',
                        type: 'item',
                        icon: 'check_box',
                        url:'/admin/collection/qbanktype',
                        roles:[2,3] 
                    },
                    {
                        id:'subject',
                        title: 'Subject',
                        type: 'item',
                        icon: 'check_box',
                        url:'/admin/collection/subject',
                        roles:[2,3]
                    },
                    {
                        id:'tags',
                        title: 'Tags',
                        type: 'item',
                        icon: 'check_box',
                        url:'/admin/collection/tags',
                        roles:[2,3]
                    },
                    {
                        id:'levelofquestion',
                        title: 'Level of Question',
                        type: 'item',
                        icon: 'check_box',
                        url:'/admin/collection/levelofquestion',
                        roles:[2,3]
                    },
                ]
            },
            {
                id: 'chart-my-career',
                title: 'Chart My Career',
                type: 'collapsable',
                icon: 'assignment_ind',
                hidden: false,
                children: [
                    {
                        id: 'cmc-exams',
                        title: 'Exams',
                        type: 'item',
                        url: '/admin/product/chart-my-career',
                        roles:[2,3]
                    },{
                        id: 'cmc-qbank',
                        title: 'Question Set',
                        type: 'item',
                        url: '/admin/collections/chart-my-career/qbank',
                        roles:[2,3]
                    }
                ]
            },
            {
                id: 'virtual-learn',
                title: 'Virtual Learn',
                type: 'collapsable',
                icon: 'dashboard',
                hidden: false,
                children: [ 
                    {
                        id: 'virtual-learn',
                        title: 'Book',
                        translate: 'NAV.VIRTUAL_LEARN',
                        type: 'item',
                        icon: 'assignment',
                        url: '/admin/product/virtual-learn/book',
                        roles:[2,3]
                    },
                    {
                        id: 'virtual-learn-ar',
                        title: 'AR Filter',
                        type: 'collapsable',
                        icon: 'assignment',
                        children: [ {
                            id: 'cmc-exams',
                            title: 'Topic',
                            type: 'item',
                            url: '/admin/product/virtual-learn/ar-filter',
                            roles:[2,3]
                        },
                        {
                            id: 'cmc-qbank',
                            title: 'Question Set',
                            type: 'item',
                            url: '/admin/collections/virtual-learn/ar-filter/qbank',
                            roles:[2,3]
                        }]
                    }
                    
                ]
            },
            {
                id: 'users',
                title: 'User Management',
                translate: 'NAV.USERMANAGEMENT',
                type: 'item',
                icon: 'supervised_user_circle',
                url: '/admin/collections/users',
                roles:[2,3]
            }
            ,
            {
                id: 'question-bug-report',
                title: 'Question Reports',
                translate: 'NAV.REPORT',
                type: 'item',
                icon: 'feedback',
                url: '/admin/report/question-bug-report',
                roles:[2,3]
            },
            {
                id: 'academy',
                title: 'Courses',
                translate: 'NAV.ACADEMY',
                type: 'item',
                icon: 'school',
                url: '/admin/product/courses',
                roles:[2,3]
            },
            {
                id: 'testseries',
                title: 'Test Series',
                translate: 'NAV.TESTSERIES',
                type: 'item',
                hidden: true,
                icon: 'assignment',
                url: '/admin/product/test-series',
                roles:[2,3]
            },
            {
                id: 'daily-quiz',
                title: 'Daily Quiz',
                type: 'item',
                icon: 'record_voice_over',
                url: '/admin/product/daily-quiz',
                roles:[2,3]
            },
            {
                id: 'daily-exam',
                title: 'Daily Exams',
                type: 'item',
                icon: 'record_voice_over',
                url: '/admin/product/daily-exams',
                roles:[2,3]
            },
            {
                id: 'reports',
                title: 'Analytics Report',
                translate: 'NAV.REPORT',
                type: 'collapsable',
                icon: 'dashboard_customize',
                children: [
                    {
                        id: 'course-report',
                        title: 'Course',
                        type: 'item',
                        icon: 'school',
                        url: '/admin/collections/reports/course',
                        roles:[2,3]
                    },
                    {
                        id: 'testseries-report',
                        title: 'Testseries',
                        type: 'item',
                        hidden: true,
                        icon: 'assignment',
                        url: '/admin/collections/reports/testseries',
                        roles:[2,3]
                    },
                    {
                        id: 'daily-exam-report',
                        title: 'Daily Exam',
                        type: 'item',
                        icon: 'assignment',
                        url: '/admin/collections/reports/daily',
                        roles:[2,3]
                    },{
                        id: 'pgi-report',
                        title: 'PGI Analysis',
                        type: 'item',
                        icon: 'school',
                        url: '/admin/report/pgi-analysis/course',
                        roles:[2,3]
                    },
                    {
                        id: 'payment-history',
                        title: 'Payment History',
                        translate: 'NAV.REPORT',
                        type: 'item',
                        icon: 'play_for_work',
                        url: '/admin/report/payment-history',
                        roles:[2,3]
                    },
                ]
            },
            {
                id: 'reports',
                title: 'Report Execution',
                translate: 'NAV.REPORT',
                hidden: true,
                type: 'collapsable',
                icon: 'graphic_eq',
                children: [
                    {
                        id: 'testseries-execution-report',
                        title: 'Testseries',
                        type: 'item',
                        icon: 'assignment',
                        url: '/admin/report/rank-execution/testseries',
                        roles:[2,3]
                    }
                ]
            },
            {
                id: 'exam-reports',
                title: 'Exam Actitvity',
                translate: 'NAV.REPORT',
                type: 'collapsable',
                icon: 'dynamic_feed',
                hidden: false,
                children: [
                    {
                        id: 'exam-course-report',
                        title: 'Course',
                        type: 'item',
                        icon: 'school',
                        url: '/admin/collections/exam-activity-reports/course',
                        roles:[2,3]
                    },
                    {
                        id: 'exam-testseries-report',
                        title: 'Testseries',
                        type: 'item',
                        hidden: true,
                        icon: 'assignment',
                        url: '/admin/collections/exam-activity-reports/testseries',
                        roles:[2,3]
                    }
                ]
            },


        ]
    },
    {
        id: 'QuestionCreator',
        title: 'QuestionCreator',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps' ,
         children: [
            {
                id: 'analytics',
                title: 'Question Analytics',
                type: 'item',
                icon: 'home',
                url: '/admin/qbank/analytics',
                roles:[2,3,4]
               
            },
         {
            
            id: 'CreateQbank',
            title: 'Q\'Bank',
            type: 'collapsable',
            icon: 'home',
 
            children: [
                {
                    id: 'create',
                    title: 'Create Question',
                    type: 'item',
                    icon: 'check_box',
                    url: '/admin/Question/create',
                    roles:[2,3,4]
                 
                },
                {
                    id: 'list',
                    title: 'List of Questions',
                    type: 'item',
                    icon: 'check_box',
                    url: '/admin/Question/list',
                    roles:[2,3,4]
                 
                }
             ]
           
            }
        ]
    }
//End vansh garg
];
