import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { unisunAnimations } from '@unisun/animations';

import { ReportService } from 'app/main/admin/report/report.service';
import { ReportGridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute } from '@angular/router';
import { SitePreference } from 'app/config';
import { CourseService } from '../../course/course.service';
import { TestSeriesService } from '../../test-series/test-series.service';
import { Exam } from '../../exam/exam.model';
import { ExcelExportService } from 'app/_services/excel.export.service';
import { UserRankList } from '../../report/report.model';

@Component({
    selector: 'report-download',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ReportDownloadComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;

    Type: number;
    TypeID: number;
    ExamID: number;
    StartDate: Date;
    EndDate: Date;
    title: string;

    TypeList: any;
    ExamList: Array<Exam>;


    report: any;
    rankList: Array<UserRankList>;




    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ReportService} _reportService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _reportService: ReportService,
        public _matDialog: MatDialog,
        private _route: ActivatedRoute,
        private _courseService: CourseService,
        private _testseriesService: TestSeriesService,
        private _exportExcelService: ExcelExportService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit() {

    }

    loadPage() {

    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        var self = this;

        self._route.params.subscribe(function (parram) {
            self.title = parram.type;
            self.Type = parram.type == 'course' ? 1 : 2;

            if (self.Type == 1) {
                self._courseService.getCourse().subscribe(function (data) {
                    self.TypeList = data;
                });
            }
            else if (self.Type == 2) {
                self._testseriesService.getTestSeries().subscribe(function (data) {
                    self.TypeList = data;
                });
            }
        });


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    OnChangeType(TypeID: number) {
        var self = this;
        self.ExamList = [];
        self.loadPage();
        if (self.Type == 1) {
            self._courseService.getCourseById(TypeID).subscribe(function (data) {
                self.ExamList = data.ExamID;
            });
        }
        else if (self.Type == 2) {
            self._testseriesService.getTestSeriesById(TypeID).subscribe(function (data) {
                self.ExamList = data.ExamID;
            });
        }
    }

    OnChangeExam() {
        var self = this;

        self.loadPage();
    }

    /**
     * Report download (Course, Testseries)
     */

    GetDownloadReport() {
        var self = this;

        let gridFilter: ReportGridFilter = {
            PageNumber: 1,
            PageSize: 1000000,
            Search: "",
            SortBy: "Report",
            SortOrder: 'asc',
            Type: this.Type,
            TypeID: this.TypeID,
            ExamID: this.ExamID,
            StartDate: this.StartDate.valueOf(),
            EndDate: this.EndDate.valueOf()
        };

        this._reportService.getReportByTypeDownload(gridFilter).subscribe(response => {
            self._exportExcelService.exportAsExcelFile(response.data, "testseries-exam-report-list_");

        });

    }

}
