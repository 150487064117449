import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Country } from 'app/main/admin/country/country.model';

@Component({
    selector     : 'country-form-dialog',
    templateUrl  : './country-form.component.html',
    styleUrls    : ['./country-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CountryFormDialogComponent
{
    action: string;
    country: Country;
    countryForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<CountryFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.action = _data.action;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Edit Country';
            this.country = _data.country;
        }
        else
        {
            this.dialogTitle = 'New Country';
            this.country = new Country({});
        }

        this.countryForm = this.createCountryForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create country form
     *
     * @returns {FormGroup}
     */
    createCountryForm(): FormGroup
    {
        return this._formBuilder.group({
            CountryID      : [this.country.CountryID],
            Country    : [this.country.Country],
        });
    }
}
