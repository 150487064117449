import { Subscription,SubscriptionPlan, SubscriptionType } from 'app/main/admin/course/course.model';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
 

@Component({
    selector: 'ar-filter-asset-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ARFilterAssetFormDialogComponent {
    action: string;
    asset: Subscription;
    assetForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ARFilterAssetFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ARFilterAssetFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Asset';
            this.asset = _data.Asset;
        }
        else {
            this.dialogTitle = 'New Asset';
            this.asset = _data.Asset;
        }

        this.assetForm = this.createAssetForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create college form
     *
     * @returns {FormGroup}
     */
    createAssetForm(): FormGroup {
        return this._formBuilder.group({
            SubscriptionAssetID: [this.asset.Plan.SubscriptionAssetID],
            ValidUpto: [this.asset.Plan.ValidUpto],
            ActualPrice: [this.asset.Plan.ActualPrice],
            Price: [this.asset.Plan.Price]
        });
    }
}
