import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { PromotionalVideoService } from '../promotional-video.service';
import { PromotionalVideoFormDialogComponent } from '../form/form.component';
import { PromotionalVideo } from '../promotional-video.model';
import { GridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute } from '@angular/router';
import { SitePreference } from 'app/config';

@Component({
    selector: 'promotional-video-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class PromotionalVideoListComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', {static: true})
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;

    promotionalvideo: any;

    paginationData: any;

    dataSource: PromotionalVideoDataSource;
    displayedColumns = ['QueueID','Title','IsActive','Description','Url','buttons'];
    selectedPromotionalVideo: any[];
    checkboxes: {};
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;


    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {PromotionalVideoService} _promotional-videoService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _promotionalvideoService: PromotionalVideoService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit() {

    }

    loadPage() {
        this._promotionalvideoService.onPromotionalVideoChanged.next(this.promotionalvideo);
    }


    getNext(event: PageEvent) {
        this._promotionalvideoService.onPromotionalVideoChanged.next(this.promotionalvideo);

    }


    onSortData(sort: Sort) {

        this._promotionalvideoService.onPromotionalVideoChanged.next(this.promotionalvideo);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.dataSource = new PromotionalVideoDataSource(this._promotionalvideoService);

        this._promotionalvideoService.onPromotionalVideoChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.promotionalvideo = search;
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == null ? "CreatedOn" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc'
                };
                this.dataSource.loadData(gridFilter);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Edit promotional-video
     *
     * @param promotional-video
     */
    editPromotionalVideo(promotionalvideo): void {
        this.dialogRef = this._matDialog.open(PromotionalVideoFormDialogComponent, {
            panelClass: 'promotional-video-form-dialog',
            data: {
                promotionalvideo: promotionalvideo,
                action: 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: PromotionalVideo = response[1];
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':

                        this._promotionalvideoService.updatePromotionalVideo(formData);

                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deletePromotionalVideo(promotionalvideo);

                        break;
                }
            });
    }

    /**
     * Delete PromotionalVideo
     */
    deletePromotionalVideo(promotionalvideo): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._promotionalvideoService.deletePromotionalVideo(promotionalvideo);
            }
            this.confirmDialogRef = null;
        });

    }

}

export class PromotionalVideoDataSource extends DataSource<PromotionalVideo>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<PromotionalVideo>;
    /**
     * Constructor
     *
     * @param {PromotionalVideoService} _promotional-videoService
     */
    constructor(
        private _promotionalvideoService: PromotionalVideoService
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._promotionalvideoService.onPromotionalVideoChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) {
        var self = this;

        this._promotionalvideoService.getPromotionalVideoForGrid(gridFilter)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);

                self.paginationData = {
                    count: response.Count,
                    pageNumber: response.CurrentFilter.PageNumber
                };


            });
    }
}
