import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AcademyCoursesService } from 'app/main/apps/academy/courses.service';

import { User } from 'app/_models';
import { Subject } from 'rxjs';

@Component({
    selector: 'user-course-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UserManagementCourseDialogComponent {
    registerForm: FormGroup;
    userModel: User;
    action: any;
    dialogTitle: string;
    courses: any;

    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<UserManagementCourseDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<UserManagementCourseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _academyCoursesService: AcademyCoursesService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        var self = this;
        // Subscribe to courses
        this._academyCoursesService.getCoursesByUserID(_data.user.UserID)
            .then(courses => {
                self.courses = courses;
            });
    }


}
