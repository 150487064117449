<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form name="examForm" [formGroup]="examForm" class="event-form w-100-p" fxLayout="column" fxFlex>

            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

                <mat-form-field class="mb-24" fxFlex>
                    <input matInput name="Title" required formControlName="Title" [(ngModel)]="exam.Title"
                        placeholder="Title">
                </mat-form-field>
            </div>
            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

                <mat-form-field class="mb-24" fxFlex>
                    <input matInput name="Subject" required formControlName="Subject" [(ngModel)]="exam.Subject"
                        placeholder="Subject">
                </mat-form-field>
                <mat-form-field class="mb-24" fxFlex>
                    <input type="number" matInput name="DurationByMinutes" required formControlName="DurationByMinutes"
                        [(ngModel)]="exam.DurationByMinutes" placeholder="Duration (Mins)">
                </mat-form-field>
                <mat-form-field class="mb-24" fxFlex>
                    <input type="number" matInput name="Rewards" required formControlName="RewardsPoint"
                        [(ngModel)]="exam.RewardsPoint" placeholder="Rewards Point">
                </mat-form-field>
            </div>
            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

                <mat-form-field class="mr-sm-24" fxFlex>
                    <mat-select placeholder="Score Type"  [(ngModel)]="exam.ScoreType" required
                        formControlName="ScoreType">
                        <mat-option [value]="s.Key" *ngFor="let s of scoreType">{{s.Value}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="mr-sm-24" fxFlex>
                    <mat-select placeholder="Choice Type"  [(ngModel)]="exam.ChoiceType" required
                        formControlName="ChoiceType">
                        <mat-option [value]="s.Key" *ngFor="let s of choiceType">{{s.Value}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="mr-sm-24" fxFlex>
                    <mat-select placeholder="Question Set" [(ngModel)]="exam.QuestionSetID" required
                        formControlName="QuestionSetID">
                        <mat-option *ngFor="let c of questionSet" [value]="c.QuestionSetID">{{c.Title}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row" class="p-12">
                <mat-slide-toggle class="p-4" formControlName="HasCalculateRisk" [(ngModel)]="exam.HasCalculateRisk">
                    Has Calculate Risk
                </mat-slide-toggle>
                <mat-slide-toggle class=" p-4" formControlName="HasClear" [(ngModel)]="exam.HasClear">
                    Has Clear
                </mat-slide-toggle>
                <mat-slide-toggle class="p-4" formControlName="HasReview" [(ngModel)]="exam.HasReview">
                    Has Review
                </mat-slide-toggle>
                <mat-slide-toggle class="p-4" formControlName="IsActive" [(ngModel)]="exam.IsActive">
                    Is Active
                </mat-slide-toggle>
            </div>
            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

                <mat-form-field class="mr-sm-24" fxFlex>
                    <input matInput [matDatepicker]="startDatePicker" [(ngModel)]="exam.StartDate"
                        placeholder="Start Date" name="start" formControlName="StartDate" required>
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>

                <!-- <mat-form-field class="no-errors-spacer" fxFlex mat-no-float>
                    <input matInput placeholder="Start Time">
                </mat-form-field> -->

                <!-- </div>

            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row"> -->

                <mat-form-field class="mr-sm-24" fxFlex>
                    <input matInput [matDatepicker]="endDatePicker" [(ngModel)]="exam.EndDate" placeholder="End Date"
                        name="EndDate" formControlName="EndDate" required>
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>

                <!-- <mat-form-field class="no-errors-spacer" fxFlex mat-no-float>
                    <input matInput placeholder="End Time">
                </mat-form-field> -->

            </div>
            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                    <ckeditor [config]="ckeConfig" placeholder="Title" [(ngModel)]="exam.Instruction"
                        formControlName="Instruction">
                    </ckeditor>
                </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(exam)"
            class="save-button mat-accent-bg" [disabled]="examForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',exam])"
            class="save-button mat-accent-bg" [disabled]="examForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',exam])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>