import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';
import { Subscription, SubscriptionResponse, SubscriptionPlan } from 'app/main/admin/course/course.model';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import { QuestionSet } from '../../qbank/qbank.model';
import { ARFilterExamService } from '../exam.service';
import { ARFilterTopicLevel } from '../topic-level.model';
import { Exam } from 'app/main/admin/exam/exam.model';

@Component({
    selector: 'ar-filter-topic-level-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ARFilterTopicLevelFormDialogComponent {
    action: string;
    exam: Exam;
    examLevel: ARFilterTopicLevel;
    examForm: FormGroup;
    dialogTitle: string;
    questionSet: Array<QuestionSet>;
    ckeConfig: any;
    choiceType: any;
    scoreType: any;
    Subscription: Subscription;
    SubscriptionResponse: SubscriptionResponse;
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<ExamFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ARFilterTopicLevelFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _examService: ARFilterExamService,
        public _matDialog: MatDialog,
    ) {
        var self =this;
        // Set the defaults
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        this._examService.getChoiceType().then(function (response) {

            self.choiceType = response;
        });

        this.scoreType = this._examService.getScoreType().then(function (response) {

            self.scoreType = response;
        });
        
        this.action = _data.action;
        this.questionSet = _data.questionSet;
        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Level';
            this.exam = _data.exam;
            this.examLevel = _data.examLevel;
        }
        else {
            this.dialogTitle = 'New Level';
            this.examLevel = new ARFilterTopicLevel({});
        }


        this.examForm = this.createExamForm();
        //self.getSubscription();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create exam form
     *
     * @returns {FormGroup}
     */
    createExamForm(): FormGroup {
        return this._formBuilder.group({
            ExamID: [this.examLevel.ExamID],
            QuestionSetID: [this.examLevel.QuestionSetID],
            LevelID: [this.examLevel.LevelID],
            LevelName: [this.examLevel.LevelName],
            QueueNo: [this.examLevel.QueueNo],
            IsActive: [this.examLevel.IsActive],
 
        });
    }

    ngOnInit(): void {
        
        var self =this;
        //self.getSubscription();
    }

    
   
}
