<mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort matSortActive="BookID"
    matSortDirection="desc">

    <!-- Name Column -->
    <ng-container matColumnDef="Title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Title}}</p>
        </mat-cell>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="Subject">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Subject</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Subject}}</p>
        </mat-cell>
    </ng-container>
     <!-- NoOfPages Column -->
     <ng-container matColumnDef="NoOfPages">
        <mat-header-cell *matHeaderCellDef mat-sort-header>No Of Pages</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.NoOfPages}}</p>
        </mat-cell>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="IsActive">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.IsActive}}</p>
        </mat-cell>
    </ng-container>

    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef class="cell-center">Action</mat-header-cell>
        <mat-cell *matCellDef="let row" class="cell-center">
            <span mat-menu-item aria-label="edit" (click)="editVirtualLearnBook(row)" matTooltip="Edit">
                <mat-icon>edit</mat-icon> 
            </span>
            <span mat-menu-item aria-label="remove" (click)="deleteVirtualLearnBook(row)" matTooltip="Remove">
                <mat-icon>delete</mat-icon> 
            </span>
            <span mat-menu-item aria-label="edit" (click)="editCollectionVirtualLearnBook(row)" matTooltip="Add Pages">
                <mat-icon>add</mat-icon> 
            </span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="Book" [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>
<mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
    (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>