<div class="page-layout simple right-sidebar inner-scroll courseForm">

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header mat-accent-bg p-24" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md
                (click)="toggleSidebar('simple-right-sidebar-2')">
                <mat-icon>menu</mat-icon>
            </button>

            <h2 class="m-0">COURSE DETAIL'S</h2>
            <div class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between right">

                <button *ngIf="action !=='edit'" mat-raised-button (click)="saveOrUpdate(['new',course])"
                    class="save-button mat-primary-bg" [disabled]="courseForm.invalid" aria-label="SAVE">
                    SAVE
                </button>

                <button *ngIf="action ==='edit'" mat-raised-button (click)="saveOrUpdate(['update',course])"
                    class="save-button mat-primary-bg" [disabled]="courseForm.invalid" aria-label="SAVE">
                    SAVE
                </button>

                <!-- <button *ngIf="action ==='edit'" mat-icon-button (click)="saveOrUpdate(['delete',course])"
                    aria-label="Delete" matTooltip="Delete">
                    <mat-icon>delete</mat-icon>
                </button> -->

            </div>

        </div>
        <!-- / HEADER -->

        <!-- CONTENT -->
        <div class="content mat-white-bg p-24" unisunPerfectScrollbar>
            <form name="courseForm" [formGroup]="courseForm">
                <mat-tab-group mat-align-tabs="basic-info">

                    <mat-tab label="Basic Info" class="content">
                        <ng-template mat-tab-label>
                            <mat-icon>thumb_up</mat-icon>&nbsp;
                            Basic Info
                        </ng-template>
                        <div class="p-24 mat-white-bg">
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <mat-form-field fxFlex>
                                    <input matInput name="Title" required formControlName="Title"
                                        [(ngModel)]="course.Title" placeholder="Title">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <mat-form-field fxFlex>
                                    <input matInput name="SubTitle" required formControlName="SubTitle"
                                        [(ngModel)]="course.SubTitle" placeholder="Sub Title">
                                </mat-form-field>
                            </div>
                            <label for="Description">Description *</label>
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <ckeditor #Description [config]="ckeConfig" [(ngModel)]="course.Description"
                                    formControlName="Description" required>
                                </ckeditor>

                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Meta" class="content">
                        <ng-template mat-tab-label>
                            <mat-icon>build</mat-icon>&nbsp;
                            Meta
                        </ng-template>
                        <div class="p-24 mat-white-bg">
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <mat-form-field class="mr-sm-24" fxFlex>
                                    <input matInput [matDatepicker]="startDatePicker" [(ngModel)]="course.StartDate"
                                        placeholder="Start Date" name="start" formControlName="StartDate" required>
                                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #startDatePicker></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field class="mr-sm-24" fxFlex>
                                    <input matInput [matDatepicker]="endDatePicker" [(ngModel)]="course.EndDate"
                                        placeholder="End Date" name="EndDate" formControlName="EndDate" required>
                                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #endDatePicker></mat-datepicker>
                                </mat-form-field>


                            </div>

                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <mat-form-field fxFlex>
                                    <input type="number" matInput name="RewardsPoint" required
                                        formControlName="RewardsPoint" [(ngModel)]="course.RewardsPoint"
                                        placeholder="Rewards Point">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <mat-form-field fxFlex>
                                    <input type="number" matInput name="Price" required formControlName="Price"
                                        [(ngModel)]="course.Price" placeholder="Price">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <mat-form-field class="mr-sm-24" fxFlex>
                                    <mat-select placeholder="Hippos" [(ngModel)]="course.HipposID"
                                        formControlName="HipposID" required>
                                        <mat-option value="0">-- Select --</mat-option>
                                        <mat-option *ngFor="let hippo of hippos" [value]="hippo.HipposID">
                                            {{hippo.Title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="mr-sm-24" fxFlex>
                                    <mat-select placeholder="Notes" [(ngModel)]="course.NoteID" formControlName="NoteID"
                                        required>
                                        <mat-option value="0">-- Select --</mat-option>
                                        <mat-option *ngFor="let note of notes" [value]="note.NoteID">
                                            {{note.Title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">

                                <mat-form-field class="mr-sm-24" fxFlex>
                                    <mat-select placeholder="Todo" [(ngModel)]="course.TodoID" formControlName="TodoID"
                                        required>
                                        <mat-option value="0">-- Select --</mat-option>
                                        <mat-option *ngFor="let todo of todos" [value]="todo.TodoID">
                                            {{todo.Title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="mr-sm-24" fxFlex>
                                    <mat-select placeholder="Video" [(ngModel)]="course.VideoID"
                                        formControlName="VideoID" required>
                                        <mat-option value="0">-- Select --</mat-option>
                                        <mat-option *ngFor="let video of videos" [value]="video.VideoID">
                                            {{video.Title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="mr-sm-24" fxFlex>
                                    <mat-select placeholder="Live Session" [(ngModel)]="course.LiveSessionID"
                                        formControlName="LiveSessionID">
                                        <mat-option value="0">-- Select --</mat-option>
                                        <mat-option *ngFor="let video of LiveSessions" [value]="video.VideoID">
                                            {{video.Title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="start start">
                                <mat-slide-toggle class="p-4" formControlName="IsActive" [(ngModel)]="course.IsActive">
                                    Is Active
                                </mat-slide-toggle>
                                <mat-slide-toggle class="p-4" formControlName="IsHide" [(ngModel)]="course.IsHide">
                                    Do you want to hide default?
                                </mat-slide-toggle>
                            </div>
                        </div>

                    </mat-tab>
                    <mat-tab label="Lesson" class="content" *ngIf="CourseID!=null">
                        <ng-template mat-tab-label>
                            <mat-icon>school</mat-icon>&nbsp;
                            Lesson
                        </ng-template>

                        <div class="p-24 mat-white-bg">
                            <div fxLayout="column" fxLayoutAlign="center">

                                <div class="filters" fxLayout="column" fxLayoutAlign="center center"
                                    fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

                                    <mat-form-field class="exam-search">
                                        <input matInput placeholder="Exam" aria-label="Exam" [matAutocomplete]="auto"
                                            (keydown)="getExamBySearch()" required [formControl]="examSearch">
                                        <mat-autocomplete #auto="matAutocomplete" id="examsearch" panelWidth="500px"
                                            (optionSelected)='selectedExam($event.option.value)'>
                                            <mat-option *ngFor="let exam of exams" [value]="exam.Title">
                                                <span>{{exam.Title}}</span> |
                                                <small>Subject: {{exam.Subject}}</small>
                                            </mat-option>
                                        </mat-autocomplete>

                                    </mat-form-field>

                                    <button mat-raised-button class="save-button mat-accent-bg"
                                        [disabled]="examSelectedValue==null || examSelectedValue==''" aria-label="ADD"
                                        (click)="addExam()">
                                        ADD
                                    </button>
                                </div>


                            </div>
                            <div fxLayout="column" fxLayoutAlign="center">

                                <div *ngFor="let exam of ExamCollections">
                                    <div>
                                        <h2 class="subject">{{exam.Subject}}</h2>
                                    </div>
                                    <div>
                                        <h3 class="topic">{{exam.Chapter}}</h3>
                                    </div>
                                    <div class="flex divv">                                      
                                        
                                        <div class="div-exam" *ngFor="let lesson of exam.Collections">
                                            <h3>{{lesson.Title}}</h3>
                                            <h4 class="text-right">
                                                <span class="mat-primary-bg">{{(lesson.EndDate) | amDateFormat:'DD/MM/YYYY
                                                    hh:mmA' }}</span>
                                            </h4>
                                            <h5 class="text-right">
                                                <a (click)="removeExam(lesson)">
                                                    <mat-icon>delete</mat-icon>
                                                </a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>


                                <!-- <table class="simple clickable">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th class="text-left">Subject</th>
                                            <th class="text-right">Expire On</th>
                                            <th class="text-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let exam of ExamCollections">
                                            <td>{{exam.Title}}</td>
                                            <td class="text-right">{{exam.Subject}}</td>
                                            <td class="text-right">
                                                <span class="mat-primary-bg">{{(exam.EndDate) | amDateFormat:'DD/MM/YYYY
                                                    hh:mmA' }}</span>
                                            </td>
                                            <td class="text-right">
                                                <a (click)="removeExam(exam)">
                                                    <mat-icon>delete</mat-icon>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table> -->
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Users" class="content" *ngIf="CourseID!=null && userModel.RoleID==3">
                        <ng-template mat-tab-label>
                            <mat-icon>accessibility</mat-icon>&nbsp;
                            Users
                        </ng-template>
                        <div class="p-24 mat-white-bg">
                            <div fxLayout="column" fxLayoutAlign="center" *ngIf="CourseID">
                                <user-user-list [courseId]="CourseID" [course]="course"></user-user-list>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Subscription" class="content" *ngIf="SubscriptionResponse && CourseID!=null">
                        <ng-template mat-tab-label>
                            <mat-icon>accessibility</mat-icon>&nbsp;
                            Subscription
                        </ng-template>
                        <mat-tab-group animationDuration="200ms" class="custom">
                            <mat-tab [label]="plan.SubscriptionType.SubscriptionName"
                                *ngFor="let plan of SubscriptionResponse.Subscriptions">

                                <div class="p-24 mat-white-bg">
                                    <div class="filters " fxLayout="column" fxLayoutAlign="center center"
                                        fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
                                        <mat-slide-toggle class="p-4" [ngModelOptions]="{standalone: true}"
                                            (change)="updateSubscription(plan)" [(ngModel)]="plan.IsFreeHippo">
                                            Hippo
                                        </mat-slide-toggle>
                                        <mat-slide-toggle class="p-4" [ngModelOptions]="{standalone: true}"
                                            (change)="updateSubscription(plan)" [(ngModel)]="plan.IsFreeTodo">
                                            Todo
                                        </mat-slide-toggle>
                                        <mat-slide-toggle class="p-4" [ngModelOptions]="{standalone: true}"
                                            (change)="updateSubscription(plan)" [(ngModel)]="plan.IsFreeQBank">
                                            Qbank
                                        </mat-slide-toggle>
                                        <mat-slide-toggle class="p-4" [ngModelOptions]="{standalone: true}"
                                            (change)="updateSubscription(plan)" [(ngModel)]="plan.IsFreeVideo">
                                            Video
                                        </mat-slide-toggle>
                                        <mat-slide-toggle class="p-4" [ngModelOptions]="{standalone: true}"
                                            (change)="updateSubscription(plan)" [(ngModel)]="plan.IsActive">
                                            Active
                                        </mat-slide-toggle>
                                        <button mat-raised-button class="save-button mat-accent-bg"
                                            (click)="newAsset(plan)">
                                            ADD
                                        </button>

                                    </div>

                                    <div fxLayout="column" fxLayoutAlign="center">
                                        <table class="simple clickable">
                                            <thead>
                                                <tr>
                                                    <th>Plan</th>
                                                    <th class="text-right">ActualPrice</th>
                                                    <th class="text-right">Price</th>
                                                    <th class="text-right">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of plan.Plans">
                                                    <td>{{item.ValidUpto}} Months</td>
                                                    <td class="text-right">{{item.ActualPrice}}</td>
                                                    <td class="text-right">{{item.Price}}</td>
                                                    <td class="text-right">
                                                        <a (click)="editAsset(item,plan)">
                                                            <mat-icon>edit</mat-icon>
                                                        </a>
                                                        &nbsp;&nbsp;
                                                        <a (click)="deleteAsset(item,plan)">
                                                            <mat-icon>delete</mat-icon>
                                                        </a>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </mat-tab>
                    <mat-tab label="Testname" class="content">
                        <ng-template mat-tab-label>
                            <mat-icon>accessibility</mat-icon>&nbsp;
                            Test Name
                        </ng-template>
                        <div class="p-24 mat-white-bg">
                            <div fxLayout="column" fxLayoutAlign="center" *ngIf="CourseID">
                                <!-- <user-user-list [courseId]="CourseID" [course]="course"></user-user-list> -->
                                <app-test-list></app-test-list>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </form>
        </div>
        <!-- / CONTENT -->

    </div>
    <!-- / CENTER -->

</div>