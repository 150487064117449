<div id="myranking">
    <div class="px-24 py-8">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-xs="row"
            fxLayoutAlign.gt-xs="space-between center">
            <form name="examForm" [formGroup]="examForm" class="event-form w-100-p">
                <mat-form-field class="mb-24" fxFlex *ngIf="title=='course'">
                    <mat-select placeholder="Select {{title}}" required formControlName="TypeID" [(ngModel)]="TypeID"
                        class="m-0" (selectionChange)="OnChangeType(TypeID)" floatLabel="always">
                        <mat-option *ngFor="let c of TypeList" [value]="c.CourseID">{{c.Title}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="mb-24" fxFlex *ngIf="title=='testseries'">
                    <mat-select placeholder="Select TestSeries" required formControlName="TypeID" [(ngModel)]="TypeID"
                        class="m-0" (selectionChange)="OnChangeType(TypeID)" floatLabel="always">
                        <mat-option *ngFor="let c of TypeList" [value]="c.TestSeriesID">{{c.Title}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="mb-24" fxFlex>
                    <mat-select placeholder="Select Exam" required formControlName="ExamID" [(ngModel)]="ExamID"
                        class="m-0" floatLabel="always">
                        <mat-option *ngFor="let c of ExamList" [value]="c.ExamID">{{c.Title}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="mb-24" fxFlex>
                    <input matInput type="email" required formControlName="Email" placeholder="Email"
                        [(ngModel)]="Email">
                </mat-form-field>
                <button mat-raised-button class="save-button mat-accent-bg" style="height: 40px;"
                    [disabled]="examForm.invalid" aria-label="Download" (click)="fetchResult()">
                    View
                </button>
            </form>

        </div>

    </div>
    <div class="content p-24" unisunPerfectScrollbar *ngIf="userRankStatistics && userRankStatistics.UserRank!=null">
        <h2>Rank Analysis - {{userRankStatistics.UserRank.Title}} -
            {{userRankStatistics.UserRank.SubTitle}}
        </h2>
        <div class="content" fxlayout="row wrap" fxlayoutalign="start start"
            style="flex-flow: row wrap; box-sizing: border-box; display: flex; place-content: flex-start; align-items: flex-start;">
            <div class="unisun-card m-16" style="max-width:30%">
                <div class="p-16">
                    <div class="h4 pr-16">Rank Classification</div>
                </div>
                <table class="simple clickable">
                    <thead>
                        <tr>
                            <th class="p-8">Category</th>
                            <th class="text-right p-8">Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="p-8">
                                <mat-icon>check_circle_outline</mat-icon>Correct
                            </td>
                            <td class="text-right p-8">
                                <span
                                    class="mat-chip lime-bg mat-standard-chip p-4">{{userRankStatistics.UserRank.Correct}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-8">
                                <mat-icon>highlight_off</mat-icon>Incorrect
                            </td>
                            <td class="text-right badge p-8">
                                <span
                                    class="mat-chip red-400-bg mat-standard-chip p-4">{{userRankStatistics.UserRank.Wrong}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-8">
                                <mat-icon>fiber_manual_record</mat-icon>Blank
                            </td>
                            <td class="text-right badge p-8">
                                <span
                                    class="mat-chip amber-400-bg mat-standard-chip p-4">{{userRankStatistics.UserRank.Blank}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-8">
                                <mat-icon>album</mat-icon>Score
                            </td>
                            <td class="text-right badge p-8">
                                <span
                                    class="mat-chip blue-400-bg mat-standard-chip p-4">{{userRankStatistics.UserRank.TotalScore}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="userRankStatistics.UserRank.ScoreType==2">
                            <td class="p-8">
                                <mat-icon>check_circle_outline</mat-icon>Actual Correct
                            </td>
                            <td class="text-right p-8">
                                <span
                                    class="mat-chip lime-bg mat-standard-chip p-4">{{userRankStatistics.UserRank.ActualCorrect}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="userRankStatistics.UserRank.ScoreType==2">
                            <td class="p-8">
                                <mat-icon>highlight_off</mat-icon>Actual Incorrect
                            </td>
                            <td class="text-right badge p-8">
                                <span
                                    class="mat-chip red-400-bg mat-standard-chip p-4">{{userRankStatistics.UserRank.ActualWrong}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-8">
                                <mat-icon>my_location</mat-icon>Rank
                            </td>
                            <td class="text-right badge p-8">
                                <span
                                    class="mat-chip blue-grey-300-bg mat-standard-chip p-4">{{userRankStatistics.UserRank.AllIndiaRank}}
                                    /
                                    {{userRankStatistics.UserRank.NoOfStudents}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="unisun-card m-16" style="max-width:55%;width:55%;height:370px;">
                <div class="p-16">
                    <div class="h4 pr-16">Correct vs Incorrect</div>
                    <div echarts [options]="myrwdata" style="height:250px;"></div>
                </div>
            </div>
            <div class="unisun-card m-16" style="max-width:100%;width: 100%;">
                <div class="p-16">
                    <div class="h4 pr-16">Average Ranking Across</div>
                </div>
                <table class="simple clickable">
                    <thead>
                        <tr>
                            <th class="p-8">Rank</th>
                            <th class="p-8">User</th>
                            <th class="p-8">Correct</th>
                            <th class="p-8">Wrong</th>
                            <th class="p-8">Blank</th>
                            <th class="text-right p-8">Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let toprank of userRankStatistics.TopRankList">
                            <td class="p-8">{{toprank.Title}}</td>
                            <td class="p-8" style="overflow: hidden; text-overflow: ellipsis;max-width: 15px;;"><span
                                    class="text-truncate" title="{{toprank.UserName}}">{{toprank.UserName}}</span>
                            </td>
                            <td class="p-8">{{toprank.Correct}}</td>
                            <td class="p-8">{{toprank.Wrong}}</td>
                            <td class="p-8">{{toprank.Blank}}</td>
                            <td class="text-right p-8">
                                <span class="mat-chip blue-grey-300-bg mat-standard-chip p-4">{{toprank.Score}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="unisun-card m-16" style="max-width:100%;width:100%;height:447px;">
                <div class="p-16">
                    <div class="h4 pr-16">GTS Analysis</div>
                    <div echarts [options]="gtsanalysis" style="height:250px;"></div>
                    <div>
                        <table class="simple">
                            <thead>
                                <tr>
                                    <td>SCORE</td>
                                    <td>POSITION</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="p-4 m-0">{{userRankStatistics.GTSAnalysis.HighestScore}}
                                    </td>
                                    <td class="p-4 m-0">Highest</td>
                                </tr>
                                <tr>
                                    <td class="p-4 m-0">{{userRankStatistics.GTSAnalysis.AverageScore}}
                                    </td>
                                    <td class="p-4 m-0">Average</td>
                                </tr>
                                <tr>
                                    <td class="p-4 m-0">{{userRankStatistics.GTSAnalysis.YourRank}}</td>
                                    <td class="p-4 m-0">Your Rank</td>
                                </tr>
                                <tr>
                                    <td class="p-4 m-0">{{userRankStatistics.GTSAnalysis.LowestScore}}
                                    </td>
                                    <td class="p-4 m-0">Lowest</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="unisun-card m-16" style="max-width:100%;width:100%;height:400px">
                <div class="p-16">
                    <div class="h4 pr-16">Your Position</div>
                    <div echarts [options]="posanalysis" style="height:250px;"></div>
                </div>
            </div>
        </div>
    </div>
</div>