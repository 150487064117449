    <div id="note-detail-list" class="page-layout simple left-sidebar inner-sidebar inner-scroll">

        <!-- HEADER -->
        <div class="header mat-accent-bg p-16 p-sm-24 mat-elevation-z6" fxLayout="column"
            fxLayoutAlign="start start" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                        account_box
                    </mat-icon>
                    <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        NOTES LIST
                    </span>
                </div>

            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-input-wrapper mt-16 ml-8 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <label for="search" class="mr-8">
                    <mat-icon>search</mat-icon>
                </label>
                <mat-form-field mat-no-float class="m-0" floatLabel="never">
                    <input matInput id="search" placeholder="Search" [formControl]="searchInput">
                </mat-form-field>
            </div>
            <!-- / SEARCH -->
            <!-- ADD note BUTTON -->
            <button mat-raised-button id="add-note-button" (click)="newNoteDetail()" aria-label="add note"
                [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
                <mat-icon>add</mat-icon> ADD
            </button>
            <!-- / ADD note BUTTON -->
        </div>
        <!-- / HEADER -->

        <!-- CONTENT -->
        <div class="content">



            <!-- CENTER -->
            <div class="center p-24 pb-56" unisunPerfectScrollbar>

                <!-- CONTENT -->
                <div class="content mat-white-bg mat-elevation-z4">
                    <mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort
                        matSortActive="Note" matSortDirection="asc">

                        <!-- Name Column -->
                        <ng-container matColumnDef="Title">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <p class="text-truncate font-weight-300">{{row.Title}}</p>
                            </mat-cell>
                        </ng-container>
                        <!-- QueueID Column -->
                        <ng-container matColumnDef="QueueID">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Queue</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <p class="text-truncate font-weight-300">{{row.QueueID}}</p>
                            </mat-cell>
                        </ng-container>
                        <!-- Buttons Column -->
                        <ng-container matColumnDef="buttons">
                            <mat-header-cell *matHeaderCellDef class="cell-center">Action</mat-header-cell>
                            <mat-cell *matCellDef="let row" class="cell-center">
                                <span mat-menu-item aria-label="edit" (click)="editNotesDetails(row)">
                                    <mat-icon>edit</mat-icon>
                                </span>
                                <span mat-menu-item aria-label="remove" (click)="deleteNotesDetails(row)">
                                    <mat-icon>delete</mat-icon>
                                </span>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;" class="note"
                            [@animate]="{value:'*',params:{y:'100%'}}">
                        </mat-row>
                    </mat-table>
                    <mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0"
                        [pageSize]="5" (page)="getNext($event)"
                        [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>

                </div>
                <!-- / CONTENT -->

            </div>
            <!-- / CENTER -->

        </div>
        <!-- / CONTENT-->

    </div>