import { UnisunUtils } from '@unisun/utils';

export class Hippo {
    HipposID: number = 0;
    Title: string;
    IsActive: boolean;
    /**
     * Constructor
     *
     * @param hippo
     */
    constructor(hippo) {
        {
            this.HipposID = hippo.HipposID || 0;
            this.Title = hippo.Title || '';
            this.IsActive = hippo.IsActive || false
        }
    }
}

export class HipposDetails {
    HipposID: number = 0;
    HipposDetailID: number = 0;
    QueueID: number;
    Subject: string;
    SubTitle: string;
    Content: string;
    NoteContent: string;
    Title: string;
    /**
     * Constructor
     *
     * @param HipposDetails
     */
    constructor(value) {
        {
            this.HipposID = value.HipposID || 0;
            this.Title = value.Title || '';
            this.QueueID = value.QueueID || 0;
            this.Subject = value.Subject || '';
            this.SubTitle = value.SubTitle || '';
            this.Content = value.Content || '';
            this.NoteContent = value.NoteContent || '';
        }
    }
}