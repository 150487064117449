<div class="p-24 pb-56" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
    <div class="mr-sm-48">
        <input matInput [matDatepicker]="startDatePicker" [(ngModel)]="StartDate" placeholder="Start Date" name="start">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
    </div>

    <div class="mr-sm-48">
        <input matInput [matDatepicker]="endDatePicker" [(ngModel)]="EndDate" placeholder="End Date" name="EndDate">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
    </div>
    <button class="m-4" mat-raised-button aria-label="view" (click)="view()">
        View
    </button>
    <button class="m-4" *ngIf="dataSource.data" mat-raised-button aria-label="Download" (click)="GetDownloadReport()">
        <mat-icon class="m-4">cloud_download</mat-icon>Download
    </button>
</div>
<mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort matSortActive="Name"
    matSortDirection="asc">

    <!-- Email Column -->
    <ng-container matColumnDef="Email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate">{{row.Email}}</p>
        </mat-cell>
    </ng-container>
    <!-- Title Column -->
    <ng-container matColumnDef="Title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Question Set</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate mat-red-bg m-4 text-boxed">{{row.Title}}</p>
        </mat-cell>
    </ng-container>
    <!-- QuestionTitle Column -->
    <ng-container matColumnDef="QuestionTitle">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Question</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate" [innerHTML]="row.QuestionTitle"> </p>
        </mat-cell>
    </ng-container>
    <!-- CreatedOn Column -->
    <ng-container matColumnDef="CreatedOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header>CreatedOn</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate mat-yellow-bg m-4 text-boxed">
                {{(row.CreatedOn | amFromUnix) | amDateFormat:'DD/MM/YYYY hh:mm A'}}</p>
        </mat-cell>
    </ng-container>
    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef class="cell-center">Action</mat-header-cell>
        <mat-cell *matCellDef="let row" class="cell-center">
            <span mat-menu-item aria-label="edit" (click)="viewReport(row)">
                <mat-icon>launch</mat-icon>
            </span>

        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="state" [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>
<mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
    (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>