
<div class="Topheader">configuration</div>
<div class="m-24">
    <form [formGroup]="QuestionForm" #form="ngForm">
        <div formArrayName="Questionconf" *ngFor="let marks of ConfigurationList;let i = index;">
            <div [formGroupName]="i">
        <h4>{{marks.Name}}</h4>
        <mat-form-field appearance="fill">
            <mat-label>Marks</mat-label>
            <input matInput type="text" formControlName="configurationValue">
        </mat-form-field>
    </div>
    </div>
        <!-- <h4>Negative Marks</h4>
        <mat-form-field appearance="fill">
            <mat-label>Marks</mat-label>
            <input matInput type="number">
        </mat-form-field> -->
        <mat-error class="reponsemsg">{{responseMessage}}</mat-error>
        <button mat-button type="submit" (click)="onSubmit()" >Submit</button>
    </form>
</div>