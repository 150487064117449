<mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort
    matSortActive="Notification" matSortDirection="asc">

    <!-- Subject Column -->
    <ng-container matColumnDef="Subject">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Subject</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Subject}}</p>
        </mat-cell>
    </ng-container>

    <!-- Message Column -->
    <ng-container matColumnDef="Message">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Message</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Message}}</p>
        </mat-cell>
    </ng-container>

    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell class="cell-center" *matHeaderCellDef >Action</mat-header-cell>
        <mat-cell class="cell-center" *matCellDef="let row">
            <span mat-menu-item aria-label="edit" (click)="editNotification(row)">
                <mat-icon>edit</mat-icon>
            </span>
            <span mat-menu-item aria-label="remove" (click)="deleteNotification(row)">
                <mat-icon>delete</mat-icon>
            </span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="notification"
        [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>
<mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
    (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>