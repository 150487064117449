import { NgModule } from '@angular/core';

import { UnisunCountdownComponent } from '@unisun/components/countdown/countdown.component';

@NgModule({
    declarations: [
        UnisunCountdownComponent
    ],
    exports: [
        UnisunCountdownComponent
    ],
})
export class UnisunCountdownModule
{
}
