import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { UnisunDirectivesModule } from '@unisun/directives/directives';
import { UnisunPipesModule } from '@unisun/pipes/pipes.module';

import { NgxEchartsModule } from 'ngx-echarts';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,
        MatExpansionModule,
        MatButtonModule,

        NgxEchartsModule,

        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatStepperModule,

        UnisunDirectivesModule,
        UnisunPipesModule
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,
        MatExpansionModule,
        MatButtonModule,

        NgxEchartsModule,

        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatStepperModule,

        UnisunDirectivesModule,
        UnisunPipesModule
    ]
})
export class UnisunSharedModule
{
}
