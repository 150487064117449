import { NgModule } from '@angular/core';

import { UnisunWidgetComponent } from './widget.component';
import { UnisunWidgetToggleDirective } from './widget-toggle.directive';

@NgModule({
    declarations: [
        UnisunWidgetComponent,
        UnisunWidgetToggleDirective
    ],
    exports     : [
        UnisunWidgetComponent,
        UnisunWidgetToggleDirective
    ],
})
export class UnisunWidgetModule
{
}
