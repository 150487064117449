import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";

import { FormBuilder, FormControl, FormGroup,FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA , MatDialogRef } from '@angular/material/dialog';

import { levelofQuestions as levelofQuestionsModel } from 'app/main/admin/common/subject/common.model';


@Component({
    selector: 'addlevelofQuestion',
    templateUrl: './addlevelofQuestion.component.html',
    styleUrls: ['./addlevelofQuestion.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class  AddLevelOfQuestionComponent implements OnInit {
    action: string;
    levelofQuestions: levelofQuestionsModel;
    levelofquestionsForm: FormGroup;
    dialogTitle: string;
    ckeConfig: any;
    choiceType: any;
    scoreType: any;

    /**
     * Constructor
     *
     * @param {MatDialogRef<AddSubjectComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */

    constructor(
        public matDialogRef: MatDialogRef<AddLevelOfQuestionComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        var self =this;
        // Set the defaults
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
       };

     this.action = _data.action;

    if (this.action === 'edit') {
        this.dialogTitle = 'Edit Level of Question';
        this.levelofQuestions = _data.levelofquestions;
    }
    else {
        this.dialogTitle = 'New Level of Question';
        this.levelofQuestions = _data.levelofquestions;
    }
    self.levelofquestionsForm = self.createlevelofquestionsForm();
  }

    

 // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create exam form
     *
     * @returns {FormGroup}
     */

    createlevelofquestionsForm(): FormGroup {
        return this._formBuilder.group({
            Title: [this.levelofQuestions.Title],
            IsActive: [this.levelofQuestions.IsActive],
            OrderNo:[this.levelofQuestions.OrderNo]
           
        });
    }

    ngOnInit(): void {
    }   

}
    

   
