import { InMemoryDbService } from 'angular-in-memory-web-api';


import { AcademyFakeDb } from 'app/fake-db/academy';
import { AnalyticsDashboardDb } from './dashboard-analytics';
 

export class FakeDbService implements InMemoryDbService
{
    createDb(): any
    {
        return {
            // Academy
            'academy-scoretype': AcademyFakeDb.ScoreType,
            'academy-choicetype'   : AcademyFakeDb.ChoiceType,
            'academy-daily-scoretype': AcademyFakeDb.DailyExamScoreType,
            'analytics-dashboard-widgets': AnalyticsDashboardDb.widgets,
        };
    }
}
