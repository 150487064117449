import { UnisunUtils } from '@unisun/utils';

export class Exam {
    ExamID: number;
    Title: string;
    Subject: string;
    ChoiceType: number;
    ScoreType: number;
    Chapter: string;
    RewardsPoint: number;
    StartDate: string;
    EndDate: string;
    QuestionSetID: number;
    HasReview: boolean;
    HasClear: boolean;
    Price:number;
    HasCalculateRisk: boolean;
    IsActive: boolean;
    DurationByMinutes: number;
    TotalScore: number;
    NoOfQuestions: number;
    ChoiceTypeList: Array<QuestionChoiceType>;
    ScoreTypeList: Array<ScoreType>;
    ProgressPercentage: number;
    Status: number;
    Instruction: string;
    /**
     * Constructor
     *
     * @param exam
     */
    constructor(exam) {
        {
            this.ExamID = exam.ExamID; //|| UnisunUtils.generateGUID();
            this.Title = exam.Title || '';
            this.Subject = exam.Subject || '';
            this.Chapter = exam.Chapter || '';
            this.ChoiceType = exam.ChoiceType;
            this.ScoreType = exam.ScoreType;
            this.RewardsPoint = exam.RewardsPoint || '';
            this.StartDate = exam.StartDate || '';
            this.EndDate = exam.EndDate || '';
            this.QuestionSetID = exam.QuestionSetID || '';
            this.HasReview = exam.HasReview || false;
            this.HasCalculateRisk = exam.HasCalculateRisk || false;
            this.HasClear = exam.HasClear || false;
            this.DurationByMinutes = exam.DurationByMinutes || 0;
            this.NoOfQuestions = exam.NoOfQuestions || 0;
            this.Instruction = exam.Instruction||'';
        }
    }
}

export class ScoreType {
    key?: number;
    value: string;
}

export class QuestionChoiceType {
    key?: number;
    value: string;
}