import { PromotionalVideoModule } from './promotional-video/promotional-video.module';
import { CMCExamModule } from './chart-my-career/exam/exam.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UnisunSharedModule } from '@unisun/shared.module';
import { CountryModule } from './country/country.module';
import { StudentCategoryModule } from './student-category/student-category.module';
import { StateModule } from './state/state.module';
import { CollegeModule } from './college/college.module';
import { AuthGuard } from 'app/_guards/auth.guard';
import { HippoModule } from './hippo/hippo.module';
import { NoteModule } from './note/note.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TodosModule } from './todo/todo.module';
import { MediaModule } from './video/video.module';
import { QuestionSetModule } from './qbank/qbank.module';
import { ExamModule } from './exam/exam.module';
import { MatDialogModule } from '@angular/material/dialog';
import { CourseModule } from './course/course.module';
import { MomentModule } from 'ngx-moment';
import { TestSeriesModule } from './test-series/test-series.module';
import { ReportModule } from './report/report.module';
import { UserManagementModule } from './users/users.module';
import { ExamActivityReportModule } from './exam-activity/report.module';
import { QuestionBugReportModule } from './question-bug-report/report.module';
import { RankReportModule } from './rank-execution/report.module';
import { DailyExamModule } from './daily-exam/exam.module';
import { NotificationModule } from './notification/notification.module';
import { PaymentHistoryModule } from './payment-history/payment-history.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { LiveMediaModule } from './live-video/video.module';
import { CMCQuestionSetModule } from './chart-my-career/qbank/qbank.module';
import { PGiReportModule } from './pg-analysis/report.module';
import { UserNotificationModule } from './user-notification/report.module';
import { VirtualLearnBookModule } from './virtual-learn/book.module';
import { ARFilterExamModule } from './virtual-learn-ar-filter/exam/exam.module';
import { ARFilterQuestionSetModule } from './virtual-learn-ar-filter/qbank/qbank.module';
import { TrandingVideoModule } from './live-intract-video/tranding-video.module';
import { DailyQuestionModule } from './daily-question/exam.module';
import {MatStepperModule} from '@angular/material/stepper';
import { CommonModule } from './common/subject/common.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes = [
  {
    path: 'localization/country',
    canActivateChild: [AuthGuard],
    loadChildren: './country/country.module#CountryModule'
  },
  {
    path: 'localization/institute',
    canActivateChild: [AuthGuard],
    loadChildren: './college/college.module#CollegeModule'
  },
  {
    path: 'localization/state',
    canActivateChild: [AuthGuard],
    loadChildren: './state/state.module#StateModule'
  },
  {
    path: 'localization/student-category',
    canActivateChild: [AuthGuard],
    loadChildren: './student-category/student-category.module#StudentCategoryModule'
  },
  {
    path: 'collections/hippo',
    canActivateChild: [AuthGuard],
    loadChildren: './hippo/hippo.module#HippoModule'
  },
  {
    path: 'collections/note',
    canActivateChild: [AuthGuard],
    loadChildren: './note/note.module#NoteModule'
  },
  {
    path: 'collections/todo',
    canActivateChild: [AuthGuard],
    loadChildren: './todo/todo.module#TodosModule'
  },
  {
    path: 'collections/video',
    canActivateChild: [AuthGuard],
    loadChildren: './video/video.module#MediaModule'
  },
  {
    path: 'collections/live-video',
    canActivateChild: [AuthGuard],
    loadChildren: './live-video/video.module#LiveMediaModule'
  },
  {
    path: 'collections/qbank',
    canActivateChild: [AuthGuard],
    loadChildren: './qbank/qbank.module#QuestionSetModule'
  },
  {
    path: 'collections/chart-my-career/qbank',
    canActivateChild: [AuthGuard],
    loadChildren: './chart-my-career/qbank/qbank.module#CMCQuestionSetModule'
  },
  {
    path: 'collections/exams',
    canActivateChild: [AuthGuard],
    loadChildren: './exam/exam.module#ExamModule'
  },

  {
    path: 'product/daily-exams',
    canActivateChild: [AuthGuard],
    loadChildren: './daily-exam/exam.module#DailyExamModule'
  },
  {
    path: 'product/daily-quiz',
    canActivateChild: [AuthGuard],
    loadChildren: './daily-question/exam.module#DailyQuestionModule'
  },
  {
    path: 'product/courses',
    canActivateChild: [AuthGuard],
    loadChildren: './course/course.module#CourseModule'
  },
  {
    path: 'product/test-series',
    canActivateChild: [AuthGuard],
    loadChildren: './test-series/test-series.module#TestSeriesModule'
  },
  {
    path: 'product/virtual-learn',
    canActivateChild: [AuthGuard],
    loadChildren: './virtual-learn/book.module#VirtualLearnBookModule'
  },
  {
    path: 'collections/virtual-learn/ar-filter/qbank',
    canActivateChild: [AuthGuard],
    loadChildren: './virtual-learn-ar-filter/qbank/qbank.module#ARFilterQuestionSetModule'
  },
  {
    path: 'collections/reports',
    canActivateChild: [AuthGuard],
    loadChildren: './report/report.module#ReportModule'
  },
  {
    path: 'collections/exam-activity-reports/:type',
    canActivateChild: [AuthGuard],
    loadChildren: './exam-activity/report.module#ExamActivityReportModule'
  },
  {
    path: 'collections/users',
    canActivateChild: [AuthGuard],
    loadChildren: './users/users.module#UserManagementModule'
  },
  {
    path: 'report/question-bug-report',
    canActivateChild: [AuthGuard],
    loadChildren: './question-bug-report/report.module#QuestionBugReportModule'
  },
  {
    path: 'report/rank-execution/:type',
    canActivateChild: [AuthGuard],
    loadChildren: './rank-execution/report.module#RankReportModule'
  },
  {
    path: 'localization/notification',
    canActivateChild: [AuthGuard],
    loadChildren: './notification/notification.module#NotificationModule'
  },
  {
    path: 'report/payment-history',
    canActivateChild: [AuthGuard],
    loadChildren: './payment-history/payment-history.module#PaymentHistoryModule'
  },
  {
    path: 'dashboards/analytics',
    canActivateChild: [AuthGuard],
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'product/virtual-learn/ar-filter',
    canActivateChild: [AuthGuard],
    loadChildren: './virtual-learn-ar-filter/exam/exam.module#ARFilterExamModule'
  },
  {
    path: 'product/chart-my-career',
    canActivateChild: [AuthGuard],
    loadChildren: './chart-my-career/exam/exam.module#CMCExamModule'
  },
  {
    path: 'report/pgi-analysis/:type',
    canActivateChild: [AuthGuard],
    loadChildren: './pg-analysis/report.module#PGiReportModule'
  },
  {
    path: 'report/pgi-analysis/result/:type/:typeID/:ExamID/:UserID',
    canActivateChild: [AuthGuard],
    loadChildren: './pg-analysis/report.module#PGiReportModule'
  },
  {
    path: 'feature/messanger',
    canActivateChild: [AuthGuard],
    loadChildren: './user-notification/report.module#UserNotificationModule'
  },  {
    path: 'product/power-reading',
    canActivateChild: [AuthGuard],
    loadChildren: './power-reading/power-reading.module'
  },
  //by vansh garg
  {
    path: 'qbank/analytics',
    loadChildren: './Custom-Qbank-Management/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'QuestionCreator',
    loadChildren: './Custom-Qbank-Management/users-management/users.module#UsersModule'
  },
  {
    path: 'Question',
    loadChildren: './Custom-Qbank-Management/question-management/question-management.module#QuestionManagementModule'
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    MomentModule.forRoot({ relativeTimeThresholdOptions: { 'm': 59 } }),
    UnisunSharedModule,
    CountryModule,
    StudentCategoryModule,
    StateModule,
    CollegeModule,
    HippoModule,
    NoteModule,
    TodosModule,
    MediaModule,
    QuestionSetModule,
    CMCQuestionSetModule,
    ExamModule,
    CourseModule,
    TestSeriesModule,
    ReportModule,
    UserManagementModule,
    ExamActivityReportModule,
    QuestionBugReportModule,
    RankReportModule,
    DailyExamModule,
    NotificationModule,
    PaymentHistoryModule,
    DashboardModule,
    LiveMediaModule,
    CMCExamModule,
    PGiReportModule,
    UserNotificationModule,
    VirtualLearnBookModule,
    ARFilterExamModule,
    ARFilterQuestionSetModule,
    PromotionalVideoModule,
    TrandingVideoModule,
    DailyQuestionModule,
    MatStepperModule,
    MatDialogModule,
    CommonModule    
  ],
  declarations: [PageNotFoundComponent]
})
export class AdminModule { }