import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

export interface PeriodicElement {
  categorytype: string;
  customexamName: string;
  examname: string;
}

@Component({
  selector: 'app-test-list',
  templateUrl: './test-list.component.html',
  styleUrls: ['./test-list.component.scss']
})
export class TestListComponent implements OnInit {
  Adddata:FormGroup;
  tableData:any=[];
  dataSource = new MatTableDataSource<any>(this.tableData);
  displayedColumns: string[] = ['categorytype', 'customexamName', 'examname', 'delete'];
  constructor(
    private _formBuilder : FormBuilder
  ) {
    
    this.Adddata = this._formBuilder.group({
      CategoryType: ['',Validators.required],
      ExamName: ['', Validators.required],
      CustomExamName: ['', Validators.required]
    })
   }

  ngOnInit(): void {
  }
  addtest() {
    this.tableData.push(this.Adddata.value);
    this.dataSource.data = this.tableData;
    this.Adddata.reset();
  }
  deleteRow(row: any) {
    const index = this.tableData.indexOf(row);
    if (index > -1) {
      this.tableData.splice(index, 1);
      this.dataSource.data = this.tableData;
    }
  }
  
}
