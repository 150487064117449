import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { UnisunUtils } from '@unisun/utils';

import { UserManagement } from 'app/main/admin/users/users.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UserManagementService implements Resolve<any>
{
    onUserManagementChanged: BehaviorSubject<any>;

    report: UserManagement[];
    user: any;
    selectedUserManagement: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onUserManagementChanged = new BehaviorSubject([]);
    }


        // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getUserManagementForGrid(gridFilter)
            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
        });
    }


    /**
     * Get User details for grid with pagination
     *
     * @returns {Promise<any>}
     */
    getUserManagementForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/user/get-grid-users`, { ..._gridFilter });
    }

    

}
