import { DataGuardService } from 'app/_guards';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { QuestionSetService } from 'app/main/admin/qbank/qbank.service';
import { QuestionSetFormDialogComponent } from 'app/main/admin/qbank/qbank-form/form.component';
import { QuestionSet } from '../qbank.model';
import { GridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute, Router } from '@angular/router';
import { SitePreference } from 'app/config';

import { State } from 'app/main/admin/state/state.model';
import { StateService } from '../../state/state.service';

@Component({
    selector: 'qbank-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class QuestionSetListComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;

    qbank: any;
    paginationData: any;

    dataSource: QuestionSetDataSource;
    displayedColumns = ['Title','IsActive', 'buttons'];
    selectedQuestionSet: any[];
    checkboxes: {};
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;


    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {QuestionSetService} _qbankService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _qbankService: QuestionSetService,
        public _matDialog: MatDialog,
        private _stateService: StateService,
        private _router: Router,
        private _dataGuardService: DataGuardService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit() {

    }

    loadPage() {
        this._qbankService.onQuestionSetChanged.next(this.qbank);
    }


    getNext(event: PageEvent) {
        this._qbankService.onQuestionSetChanged.next(this.qbank);

    }


    onSortData(sort: Sort) {

        this._qbankService.onQuestionSetChanged.next(this.qbank);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.dataSource = new QuestionSetDataSource(this._qbankService);

        this._qbankService.onQuestionSetChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.qbank = search;
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == null ? "QuestionSet" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc'
                };
                this.dataSource.loadData(gridFilter);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Edit qbank
     *
     * @param qbank
     */
    editQuestionSet(qbank): void {

        this.dialogRef = this._matDialog.open(QuestionSetFormDialogComponent, {
            panelClass: 'qbank-form-dialog',
            data: {
                qbank: qbank,
                action: 'edit'
            }
        });
        this.editQuestionSetCallback(qbank);

    }

    editCollectionQuestionSet(qbank): void {
        this._router.navigate(['admin/collections/qbank/' + this._dataGuardService.valueEncryption(qbank.QuestionSetID)]);

    }

    editQuestionSetCallback(qbank: QuestionSet) {
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: QuestionSet = response[1];
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':

                        this._qbankService.updateQuestionSet(formData);

                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deleteQuestionSet(qbank);

                        break;
                }
            });
    }

    /**
     * Delete QuestionSet
     */
    deleteQuestionSet(qbank): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._qbankService.deleteQuestionSet(qbank);
            }
            this.confirmDialogRef = null;
        });

    }

}

export class QuestionSetDataSource extends DataSource<QuestionSet>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<QuestionSet>;
    /**
     * Constructor
     *
     * @param {QuestionSetService} _qbankService
     */
    constructor(
        private _qbankService: QuestionSetService
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._qbankService.onQuestionSetChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) {
        var self = this;

        this._qbankService.getQuestionSetForGrid(gridFilter)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);

                self.paginationData = {
                    count: response.Count,
                    pageNumber: response.CurrentFilter.PageNumber
                };


            });
    }
}
