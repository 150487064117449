<div class="p-12 p-sm-24" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">

    <mat-label class="mat-yellow-bg m-4 text-boxed">{{title.toUpperCase()}}</mat-label>
    <mat-select placeholder="Select Course" *ngIf="title=='course'" [(ngModel)]="TypeID" class="m-0"
        (selectionChange)="OnChangeType(TypeID)" floatLabel="always">
        <mat-option *ngFor="let c of TypeList" [value]="c.CourseID">{{c.Title}}</mat-option>
    </mat-select>
    <mat-select placeholder="Select TestSeries" *ngIf="title=='testseries'" [(ngModel)]="TypeID" class="m-0"
        (selectionChange)="OnChangeType(TypeID)" floatLabel="always">
        <mat-option *ngFor="let c of TypeList" [value]="c.TestSeriesID">{{c.Title}}</mat-option>
    </mat-select>
    <mat-label class="mat-yellow-bg m-4 text-boxed">Exam</mat-label>
    <mat-select placeholder="Select Exam" [(ngModel)]="ExamID" class="m-0" floatLabel="always"
        (selectionChange)="OnChangeExam()">
        <mat-option *ngFor="let c of ExamList" [value]="c.ExamID">{{c.Title}}</mat-option>
    </mat-select>

</div>
<div class="p-12">
    <div fxLayout="row" fxLayoutAlign="end end">
        <button class="m-4" mat-raised-button color="primary" (click)="onClick($event)">
            <mat-icon>add</mat-icon>
            {{chooseLabel}}
        </button>
        <input #fileUpload type="file" style="display: none" class="input_fileupload--hidden" (input)="onInput($event)"
            (change)="onFileSelected($event)" [(ngModel)]="inputFileName" [accept]="accept">

        <div *ngFor="let f of files">
            <span *ngIf="files.length>0">{{f.name}}</span>
            <button mat-icon-button color="primary" [attr.title]="deleteButtonLabel?deleteButtonLabel:null"
                (click)="removeFile($event,f)">
                <mat-icon>{{deleteButtonIcon}}</mat-icon>
            </button>
        </div>
        <button class="m-4" *ngIf="files.length>0 && Type>0 && TypeID>0 && ExamID>0" mat-raised-button
            id="add-qbank-button" (click)="OnBulkUpdateRank()" aria-label="add qbank">
            <mat-icon>add</mat-icon> Submit
        </button>
    </div>
</div>