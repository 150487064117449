import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { UnisunConfigService } from '@unisun/services/config.service';
import { UnisunNavigationService } from '@unisun/components/navigation/navigation.service';
import { UnisunPerfectScrollbarDirective } from '@unisun/directives/unisun-perfect-scrollbar/unisun-perfect-scrollbar.directive';
import { UnisunSidebarService } from '@unisun/components/sidebar/sidebar.service';
import { HelperService } from 'app/_helpers/helper.service';
import { User } from 'app/_models';
import { environment } from 'environments/environment';

@Component({
    selector: 'navbar-vertical-style-1',
    templateUrl: './style-1.component.html',
    styleUrls: ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    unisunConfig: any;
    unisunPerfectScrollbarUpdateTimeout: any;
    navigation: any;
    user: User;
    _env: any;

    // Private
    private _unisunPerfectScrollbar: UnisunPerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UnisunConfigService} _UnisunConfigService
     * @param {UnisunNavigationService} _unisunNavigationService
     * @param {UnisunSidebarService} _unisunSidebarService
     * @param {Router} _router
     */
    constructor(
        private _UnisunConfigService: UnisunConfigService,
        private _unisunNavigationService: UnisunNavigationService,
        private _unisunSidebarService: UnisunSidebarService,
        private _helperService: HelperService,
        private _router: Router
    ) {
        this._env = environment;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.user = this._helperService.getUserDetail();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(UnisunPerfectScrollbarDirective, { static: true })
    set directive(theDirective: UnisunPerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._unisunPerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._unisunNavigationService.onItemCollapseToggled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.unisunPerfectScrollbarUpdateTimeout = setTimeout(() => {
                    this._unisunPerfectScrollbar.update();
                }, 310);
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    const activeNavItem: any = document.querySelector('navbar .nav-link.active');

                    if (activeNavItem) {
                        const activeItemOffsetTop = activeNavItem.offsetTop,
                            activeItemOffsetParentTop = activeNavItem.offsetParent.offsetTop,
                            scrollDistance = activeItemOffsetTop - activeItemOffsetParentTop - (48 * 3) - 168;

                        this._unisunPerfectScrollbar.scrollToTop(scrollDistance);
                    }
                });
            }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._unisunSidebarService.getSidebar('navbar')) {
                    this._unisunSidebarService.getSidebar('navbar').close();
                }
            }
            );

        // Subscribe to the config changes
        this._UnisunConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.unisunConfig = config;
            });

        // Get current navigation
        this._unisunNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._unisunNavigationService.getCurrentNavigation();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        if (this.unisunPerfectScrollbarUpdateTimeout) {
            clearTimeout(this.unisunPerfectScrollbarUpdateTimeout);
        }

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._unisunSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._unisunSidebarService.getSidebar('navbar').toggleFold();
    }
}
