import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Todo } from 'app/main/admin/todo/todo.model';
import { State } from 'app/main/admin/state/state.model';

@Component({
    selector     : 'todo-form-dialog',
    templateUrl  : './form.component.html',
    styleUrls    : ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TodoFormDialogComponent
{
    action: string;
    todo: Todo;
    todoForm: FormGroup;
    dialogTitle: string;

    states: State[];
    /**
     * Constructor
     *
     * @param {MatDialogRef<TodoFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<TodoFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.action = _data.action;
        this.states = _data.states;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Edit Todo';
            this.todo = _data.todo;
        }
        else
        {
            this.dialogTitle = 'New Todo';
            this.todo = new Todo({});
        }

        this.todoForm = this.createTodoForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create todo form
     *
     * @returns {FormGroup}
     */
    createTodoForm(): FormGroup
    {
        return this._formBuilder.group({
            TodosID      : [this.todo.TodoID],
            Title    : [this.todo.Title],
            RewardsPoint    : [this.todo.RewardsPoint],
            IsActive    : [this.todo.IsActive]
        });
    }
}
