import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select'
import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';

import { PaymentHistoryComponent } from 'app/main/admin/payment-history/payment-history.component';
import { PaymentHistoryService } from 'app/main/admin/payment-history/payment-history.service';
import { PaymentHistoryListComponent } from 'app/main/admin/payment-history/list/list.component';
import { PaymentHistoryFormDialogComponent } from 'app/main/admin/payment-history/form/form.component';

const routes: Routes = [
    {
        path     : 'report/payment-history',
        component: PaymentHistoryComponent,
        resolve  : {
            contacts: PaymentHistoryService
        }
    }
];

@NgModule({
    declarations   : [
        PaymentHistoryComponent,
        PaymentHistoryListComponent,
        PaymentHistoryFormDialogComponent
    ],
    imports        : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule
    ],
    providers      : [
        PaymentHistoryService
    ],
    entryComponents: [
        PaymentHistoryFormDialogComponent
    ]
})
export class PaymentHistoryModule
{
}
