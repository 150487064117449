import { UnisunUtils } from '@unisun/utils';

export class Report {
    UserID: number;
    UserName: string;
    Email: string;
    Mobile: string;
    College: string;
    State: string;

    Title: string;
    TrnExamResultID: number;
    TrnExamActID: number;
    Correct: number;
    Wrong: number;
    ActualCorrect: number;
    ActualScore: number;
    ActualWrong: number;
    Risk: number;
    MarkedReview: number;
    NotAnswered: number;
    NotVisited: number;
    Answered: number;
    NoOfQuestions: number;
    TotalScore: number;
    Percentage: number;
    RiskPercentage: number;
    RiskScore: number;
    SubmittedOn: number;
    Rank: number;
    AllIndiaRank: number;
    TimeSpent: string;
    TimeLeft: string;
}

export class UserRankList {
    Type: number;
    TypeID: number;
    ExamID: number;
    Title: string;
    SubTitle: string;
    CenterName: string;
    RegisterNo: string;
    UserName: string;
    Email: string;
    Mobile: string;
    Correct: number;
    Wrong: number;
    Blank: number;
    ActualWrong: number;
    ActualCorrect: number;
    Percentage: number;
    NoOfQuestions: number;
    TotalScore: number;
    SubmittedOn: number;
    ActualScore: number;
    CenterRank: number;
    AllIndiaRank: number;
    NoOfStudents: number;
    Remark: string;
    ScoreType: number;
}

export class AppRankUpdateRequest {
    Type: number;
    TypeID: number;
    ExamID: number;
    UserRankList: Array<UserRankList>;
}