<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="noteForm">

            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input name="Title" formControlName="Title" placeholder="Name" matInput required
                        [(ngModel)]="note.Title">
                </mat-form-field>
            </div>

            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>

                    <input name="Subject" formControlName="Subject" placeholder="Subject" matInput required
                        [(ngModel)]="note.Subject">
                </mat-form-field>
            </div>


            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input name="SubTitle" formControlName="SubTitle" placeholder="Sub Title" matInput required
                        [(ngModel)]="note.SubTitle">
                </mat-form-field>
            </div>
            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <!-- <mat-form-field fxFlex>
                    <input name="Content" formControlName="Content" placeholder="Content" matInput required
                        [(ngModel)]="note.Content">
                </mat-form-field> -->
                <ckeditor [config]="ckeConfig" [(ngModel)]="note.Content" formControlName="Content" required>
                </ckeditor>
            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(note)"
            class="save-button mat-accent-bg" [disabled]="noteForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',note])"
            class="save-button mat-accent-bg" [disabled]="noteForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',note])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>