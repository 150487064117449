 
<mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort matSortActive="UserName"
    matSortDirection="asc">

    <!-- Name Column -->
    <ng-container matColumnDef="UserName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.UserName}}</p>
        </mat-cell>
    </ng-container>
    <!-- Email Column -->
    <ng-container matColumnDef="Email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-400">{{row.Email}}</p>
        </mat-cell>
    </ng-container>
    <!-- Mobile Column -->
    <ng-container matColumnDef="Mobile">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-400">{{row.Mobile}}</p>
        </mat-cell>
    </ng-container>
    <!-- IsActive Column -->
    <ng-container matColumnDef="IsActive">
        <mat-header-cell *matHeaderCellDef mat-sort-header>IsActive</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="mat-yellow-bg m-4 text-boxed" *ngIf="row.IsActive">{{row.IsActive}}</span>
            <span class="mat-red-bg m-4 text-boxed" *ngIf="!row.IsActive">{{row.IsActive}}</span>
        </mat-cell>
    </ng-container>
    <!-- State Column -->
    <ng-container matColumnDef="State">
        <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-400">{{row.State}}</p>
        </mat-cell>
    </ng-container>
    <!-- College Column -->
    <ng-container matColumnDef="College">
        <mat-header-cell *matHeaderCellDef mat-sort-header>College</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-400">{{row.College}}</p>
        </mat-cell>
    </ng-container>
     <!-- CreatedOn Column -->
     <ng-container matColumnDef="CreatedOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Created On</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{(row.CreatedOn | amFromUnix) | amDateFormat:'DD/MM/YYYY HH:mm:ss' }}</p>
        </mat-cell>
    </ng-container>
    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef class="cell-center">Action</mat-header-cell>
        <mat-cell *matCellDef="let row" class="cell-center">
            <span aria-label="edit" (click)="editUser(row)">
                <mat-icon>edit</mat-icon>
            </span>
            <span aria-label="edit" (click)="courseByUser(row)">
                <mat-icon>card_membership</mat-icon>
            </span>
        </mat-cell>
        
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="usermanagement"
        [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>
<mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
    (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>