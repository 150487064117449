<div class="layout-content">
    <div class="layout-dashboard">
        <div class="ui-g">
            <div class="ui-g-12" *ngIf="Overview">
                <div class="card card-w-title">
                    <h2><small>Overview of </small><strong>Smart Learning System</strong></h2>
                    <p-tabView>
                        <p-tabPanel header="Overview">
                            <div class="ui-g">
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box courses">
                                        <img class="image-icon" src="assets/images/012-graduate.png">
                                        <div class="overview-title">COURSES</div>
                                        <div class="overview-numbers">{{Overview.Course}}</div>
                                        <div class="overview-subinfo">
                                            <span class="ui-g-6"><a [routerLink]="['/admin/product/courses']">more...</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box users">
                                        <img class="image-icon" src="assets/images/031-user.png">
                                        <div class="overview-title">USERS</div>
                                        <div class="overview-numbers">{{Overview.User}}</div>
                                        <div class="overview-subinfo">
                                            <span class="ui-g-6"><a [routerLink]="['/admin/collections/users']">more...</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box qbanks">
                                        <img class="image-icon" src="assets/images/002-qbank.png">
                                        <div class="overview-title">QBANKS</div>
                                        <div class="overview-numbers">{{Overview.Exam}}</div>
                                        <div class="overview-subinfo user">
                                            <a [routerLink]="['/admin/collections/exams']">more...</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box videos">
                                        <img class="image-icon" src="assets/images/010-video file.png">
                                        <div class="overview-title">VIDEOS</div>
                                        <div class="overview-numbers"> {{Overview.Video}} </div>
                                        <div class="overview-subinfo checkin">
                                            <a [routerLink]="['/admin/collections/video']">more...</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box bookmarks p-pb-1">
                                        <img class="image-icon" src="assets/images/011-bookmark.png">
                                        <div class="overview-title">Hippo Bookmarks</div>
                                        <div class="overview-numbers">{{Overview.HippoBookmark}}</div>
                                        <div class="overview-subinfo checkin ng-tns-c540-22"></div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box bookmarks p-pb-1">
                                        <img class="image-icon" src="assets/images/011-bookmark.png">
                                        <div class="overview-title">Video Bookmarks</div>
                                        <div class="overview-numbers">{{Overview.VideoBookmark}}</div>
                                        <div class="overview-subinfo checkin ng-tns-c540-22"></div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box bookmarks p-pb-1">
                                        <img class="image-icon" src="assets/images/011-bookmark.png">
                                        <div class="overview-title">QBank Bookmarks</div>
                                        <div class="overview-numbers">{{Overview.QuestionBookmark}}</div>
                                        <div class="overview-subinfo checkin ng-tns-c540-22"></div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box bookmarks p-pb-1">
                                        <img class="image-icon" src="assets/images/011-bookmark.png">
                                        <div class="overview-title">Total Bookmarks</div>
                                        <div class="overview-numbers">{{Overview.TotalBookmark}}</div>
                                        <div class="overview-subinfo checkin ng-tns-c540-22"></div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box hippos">
                                        <img class="image-icon" src="assets/images/011-hippos.png">
                                        <div class="overview-title">Hippos</div>
                                        <div class="overview-numbers">{{Overview.Hippo}}</div>
                                        <div class="overview-subinfo checkin">
                                            <a [routerLink]="['/admin/collections/hippo']">more...</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box notes">
                                        <img class="image-icon" src="assets/images/011-notebook.png">
                                        <div class="overview-title">Notes</div>
                                        <div class="overview-numbers">{{Overview.Notes}}</div>
                                        <div class="overview-subinfo checkin">
                                            <a [routerLink]="['/admin/collections/note']">more...</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box todos">
                                        <img class="image-icon" src="assets/images/047-test.png">
                                        <div class="overview-title">ToDos</div>
                                        <div class="overview-numbers">{{Overview.Todo}}</div>
                                        <div class="overview-subinfo checkin">
                                            <a [routerLink]="['/admin/collections/todo']">more...</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box subscription">
                                        <img class="image-icon" src="assets/images/011-subscription.png">
                                        <div class="overview-title">Subscriptions</div>
                                        <div class="overview-numbers">{{Overview.Subscription}}</div>
                                        <div class="overview-subinfo checkin">
                                            <!-- <a [routerLink]="['/admin/collections/users']">more...</a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel header="Courses">
                            <div class="ui-g">
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box courses">
                                        <img class="image-icon" src="assets/images/012-graduate.png">
                                        <div class="overview-title">COURSES</div>
                                        <div class="overview-numbers">255</div>
                                        <div class="overview-subinfo">
                                            <span class="ui-g-6">
                                                <i class="fa fa-chevron-circle-up text-success"></i> 18 (22%) increase
                                                from last month
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box courses">
                                        <img class="image-icon" src="assets/images/012-graduate.png">
                                        <div class="overview-title">COURSES - EXPIRED</div>
                                        <div class="overview-numbers">255</div>
                                        <div class="overview-subinfo danger">
                                            <span class="ui-g-6">
                                                <i class="fa fa-chevron-circle-down"></i> 18 (22%) decrease from last
                                                month
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box courses">
                                        <img class="image-icon" src="assets/images/012-graduate.png">
                                        <div class="overview-title">COURSES - ACTIVE</div>
                                        <div class="overview-numbers">255</div>
                                        <div class="overview-subinfo">
                                            <span class="ui-g-6">
                                                <i class="fa fa-chevron-circle-up text-success"></i> 18 (22%) increase
                                                from last month
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6 ui-lg-3">
                                    <div class="overview-box courses">
                                        <img class="image-icon" src="assets/images/012-graduate.png">
                                        <div class="overview-title">COURSES - INACTIVE</div>
                                        <div class="overview-numbers">255</div>
                                        <div class="overview-subinfo">
                                            <span class="ui-g-6">
                                                <i class="fa fa-chevron-circle-up text-success"></i> 18 (22%) increase
                                                from last month
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ui-g">
                                <div class="ui-g-6 ui-md-6 ui-lg-6">
                                    <p-panel>
                                        <p-header>
                                            <h4 class="p-m-0">Course by Users</h4>
                                        </p-header>
                                        <div class="global-sales">
                                            <div class="ui-panel-content">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th class="p-text-left">Course</th>
                                                            <th class="p-text-right">Users</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let cuser of CourseByUsers">
                                                            <td>{{cuser.id}}</td>
                                                            <td class="p-text-left">
                                                                <span>{{cuser.name}}</span>
                                                            </td>
                                                            <td class="p-text-right"><span
                                                                    class="p-tag p-tag-info">{{cuser.users}}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                     
                                    </p-panel>
                                </div>
                                <div class="ui-g-6 ui-md-6 ui-lg-6">
                                    <p-panel>
                                        <p-header class="p-d-flex p-ai-center p-jc-between">
                                            <div class="ui-g-6">
                                                <h4 class="p-m-0">Course Details</h4>
                                            </div>
                                            <div class="ui-g-6">
                                                <span>
                                                    <p-dropdown (onChange)="courseOnChange($event)" [options]="Courses"
                                                        [(ngModel)]="selectCourse" optionLabel="name" [filter]="true"
                                                        [showClear]="true" placeholder="Select a Course">
                                                        <ng-template pTemplate="selectedItem">
                                                            <div *ngIf="selectCourse">
                                                                <div class="p-text-left">{{selectCourse}}</div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template let-country pTemplate="item">
                                                            <div class="p-text-left">
                                                                <div>{{country.value.name}}</div>
                                                            </div>
                                                        </ng-template>
                                                    </p-dropdown>
                                                </span>
                                            </div>
                                        </p-header>
                                        <div class="global-sales">
                                            <div class="ui-panel-content">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td class="p-text-left">Videos</td>
                                                            <td class="p-text-left">
                                                                <span
                                                                    class="p-tag p-tag-info">{{CourseDetail.detail[0].videos}}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-text-left">QBank</td>
                                                            <td class="p-text-left">
                                                                <span
                                                                    class="p-tag p-tag-info">{{CourseDetail.detail[0].qbank}}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-text-left">Hippos</td>
                                                            <td class="p-text-left">
                                                                <span
                                                                    class="p-tag p-tag-info">{{CourseDetail.detail[0].hippos}}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-text-left">Notes</td>
                                                            <td class="p-text-left">
                                                                <span
                                                                    class="p-tag p-tag-info">{{CourseDetail.detail[0].notes}}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-text-left">ToDo</td>
                                                            <td class="p-text-left">
                                                                <span
                                                                    class="p-tag p-tag-info">{{CourseDetail.detail[0].todo}}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-text-left">Users</td>
                                                            <td class="p-text-left">
                                                                <span
                                                                    class="p-tag p-tag-info">{{CourseDetail.detail[0].users}}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-text-left">Live Videos</td>
                                                            <td class="p-text-left">
                                                                <span
                                                                    class="p-tag p-tag-info">{{CourseDetail.detail[0].livevideos}}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </p-panel>
                                </div>
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </div>
            </div>
        </div>
    </div>
</div>