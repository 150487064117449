// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // appName:"ADrPlexus",
  // appLogo:"assets/images/logos/logo.png",
  // enableSocialLogin:true,

  // appName:"CARE NExT",
  // appLogo:"assets/care-next/images/logos/logo.png",
  // enableSocialLogin:false,

  appName: "Madha Medical College - NExT-PG",
  appLogo: "assets/mmcri/images/logos/logo.png",
  enableSocialLogin: false,

  hmr: false,
  googleClientID: '50249208194-aiur9oq48jlpjdgtf4evom9n4e7ret5q.apps.googleusercontent.com',
  // apiURL: "http://localhost:5043",
  // OAuthConfiguration: {
  //   ApiClientID: "a2db852e6f28416f925dddeece5bfa96",
  //   ApiUserName: "elms",
  //   ApiPassword: "elms@123",
  //   ApiGrantType: "password"
  // },
  apiURL: "https://api.adrplexus.com/dev",
  OAuthConfiguration: {
    ApiClientID: "a2db852e6f28416f925dddeece5bfa96",
    ApiUserName: "elms",
    ApiPassword: "elms@123",
    ApiGrantType: "password"
  },
  ZoomConfiguration: {
    ZOOM_JWT_API_KEY: "cgUjp1PiQbeoqNJ-6UYRsw",
    ZOOM_JWT_API_SECRET: "EjvCcoou8yF6JSjkLciVx2zNfajp5j5yimYb"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
