import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';

import { CollegeComponent } from 'app/main/admin/college/college.component';
import { CollegeService } from 'app/main/admin/college/college.service';
import { CollegeListComponent } from 'app/main/admin/college/college-list/list.component';
import { CollegeFormDialogComponent } from 'app/main/admin/college/college-form/form.component';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select'
;
const routes: Routes = [
    {
        path: 'localization/college',
        component: CollegeComponent,
        resolve: {
            contacts: CollegeService
        }
    }
];

@NgModule({
    declarations: [
        CollegeComponent,
        CollegeListComponent,
        CollegeFormDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSnackBarModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule
    ],
    providers: [
        CollegeService
    ],
    entryComponents: [
        CollegeFormDialogComponent
    ]
})
export class CollegeModule {
}
