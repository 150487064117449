import { Country } from './../../country/country.model';
import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { TestSeries } from 'app/main/admin/test-series/test-series.model';
import { UnisunSidebarService } from '@unisun/components/sidebar/sidebar.service';
import { DataSource } from '@angular/cdk/collections';
import { User } from 'app/_models/user';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { UserService } from 'app/_services';
import { GridFilter } from 'app/_models/GridFilter';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { SitePreference } from 'app/config';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';
import { Exam } from '../../exam/exam.model';
import { ExamService } from '../../exam/exam.service';
import { TestSeriesService } from '../test-series.service';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { DataGuardService } from 'app/_guards';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'test-series-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TestSeriesFormComponent {
    action: string;
    testseries: TestSeries;
    testseriesForm: FormGroup;
    dialogTitle: string;
    TestSeriesID: number;

    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;


    ckeConfig: any;
    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _unisunSidebarService: UnisunSidebarService,
        private _userService: UserService,
        public _matDialog: MatDialog,
        private _examService: ExamService,
        private _testseriesService: TestSeriesService,
        private _route: ActivatedRoute,
        private _dataGuardService: DataGuardService,
        private _router: Router,
    ) {
        var self = this;

        // Set the defaults
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        this.examSearch = new FormControl('');

        this.action = 'new';
        self.testseries = new TestSeries({});

        this._route.params.subscribe(function (parram) {

            self.TestSeriesID = self._dataGuardService.valueDecryption(parram.id);;

            if (self.TestSeriesID > 0) {
                self.action = "edit";
                self.dialogTitle = 'Edit TestSeries';
                self._testseriesService.getTestSeriesById(self.TestSeriesID).subscribe(function (response) {
                    self.testseries = response;
                });
            }
            else {
                self.dialogTitle = 'New TestSeries';
                self.testseries = new TestSeries({});

            }
        });
        self.testseriesForm = this.createTestSeriesForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create test-series form
     *
     * @returns {FormGroup}
     */
    createTestSeriesForm(): FormGroup {
        return this._formBuilder.group({
            TestSeriesID: [this.testseries.TestSeriesID],
            Title: [this.testseries.Title],
            SubTitle: [this.testseries.SubTitle],
            Description: [this.testseries.Description],
            Price: [this.testseries.Price],
            RewardsPoint: [this.testseries.RewardsPoint],
            StartDate: [this.testseries.StartDate],
            EndDate: [this.testseries.EndDate],
            VideoID: [this.testseries.VideoID],
            HipposID: [this.testseries.HipposID],
            NoteID: [this.testseries.NoteID],
            IsActive: [this.testseries.IsActive],
            TodoID: [this.testseries.TodoID],
            ExamID: [this.testseries.ExamID],
            Users: [this.testseries.Users],
        });
    }

    /**
 * Toggle sidebar
 *
 * @param name
 */
    toggleSidebar(name): void {
        this._unisunSidebarService.getSidebar(name).toggleOpen();
    }

    saveOrUpdate(testseriesFrom: any) {
        if (!testseriesFrom) {
            return;
        }
        var self = this;

        const actionType: string = testseriesFrom[0];
        const formData: TestSeries = testseriesFrom[1];

        switch (actionType) {
            case 'new':

                self._testseriesService.createTestSeries(formData).then(function (response) {
                    self._router.navigate(['admin/collections/test-series/' + self._dataGuardService.valueEncryption(response)]);
                });

                break;
            case 'update':

                this._testseriesService.updateTestSeries(formData);

                break;

            case 'delete':

                this._testseriesService.deleteTestSeries(formData);

                break;
        }
    }



    exams: Array<Exam>;
    examSelectedValue: string;

    examSearch: FormControl;

    selectedExam(examID: string) {
        this.examSelectedValue = examID;
    }


    getExamBySearch(data: any) {
        var self = this;
        if (this.examSearch.value != null && this.examSearch.value.length > 1)
            this._examService.getExam(this.examSearch.value).then(function (response) {
                self.exams = response;
            });
    }

    addExam() {
        var self = this;

        let exam = this.exams.find(a => a.Title == this.examSelectedValue);

        this._testseriesService.mapExamByTestSeries(exam, this.TestSeriesID).subscribe(response => {
            if (response) {
                this.examSelectedValue = '';
                this.examSearch.reset();
                self._testseriesService.getTestSeriesById(self.TestSeriesID).subscribe(function (response) {
                    self.testseries = response;
                });
                self._testseriesService.openSnackBar("Successfully updated.", "Close");
            }
            else {
                self._testseriesService.openSnackBar("Failed", "Close");
            }
        });
    }

    removeExam(exam: Exam) {
        var self = this;

        self._testseriesService.deleteExamByTestSeries(exam, this.TestSeriesID).subscribe(response => {
            if (response) {
                self._testseriesService.getTestSeriesById(self.TestSeriesID).subscribe(function (response) {
                    self.testseries = response;
                });
                self.examSelectedValue = '';
                self.examSearch.reset();
                self._testseriesService.openSnackBar("Successfully updated.", "Close");
            }
            else {
                self._testseriesService.openSnackBar("Failed", "Close");
            }
        });
    }

}