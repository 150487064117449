<!DOCTYPE html>
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
<link href="https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap" rel="stylesheet">
<style>
    body,
    html {
        font-family: 'Rajdhani', sans-serif
    }
</style>


<div class="container" id="ChartmyCareerResults" *ngIf="report.UserInfo && report.QuestionsReport">
    <h1>Chart My Career Results <button type="button" class="btn btn-primary btn-sm"
            (click)="fnExcelReport()">Download</button>
    </h1>
    <table class="table" id="UserDetails">
        <thead class="thead-dark">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>

                <th scope="col">State</th>
                <th scope="col">DOB</th>
                <th scope="col">School/College</th>
                <th scope="col">Completed On</th>
            </tr>

        </thead>
        <tbody>
            <tr>
                <td> {{report.UserInfo.UserName}} </td>
                <td> {{report.UserInfo.Email}} </td>
                <td> {{report.UserInfo.State}} </td>
                <td>{{report.UserInfo.DateOfBirth}}</td>
                <td> {{report.UserInfo.College}} </td>
                <td>{{report.SubmittedOn}}</td>
            </tr>
            <tr class="thead-light">
                <th scope="col">#</th>
                <th scope="col">SectionID</th>
                <th scope="col">QuestionNo.</th>
                <th scope="col">QuestionID</th>
                <th scope="col">Answer</th>
            </tr>

            <tr *ngFor="let item of report.QuestionsReport">
                <th>{{item.QueueID}} </th>
                <th> {{item.Subject}} </th>
                <th>{{item.QuestionDetailID}} </th>
                <th> {{item.QuestionBankID}} </th>
                <th> {{item.Choice}} </th>
            </tr>
        </tbody>
    </table>
</div>
<div *ngIf="!(report.UserInfo && report.QuestionsReport)">Loading..</div>
<script>
    function fnExcelReport() {
        var tab_text = "<table border='2px'><tr bgcolor='#87AFC6'>";
        var textRange;
        var j = 0;
        tab = document.getElementById('UserDetails'); // id of table

        for (j = 0; j < tab.rows.length; j++) {
            tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
            //tab_text=tab_text+"</tr>";
        }

        tab_text = tab_text + "</table>";
        tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
        tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
        tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) // If Internet Explorer
        {
            txtArea1.document.open("txt/html", "replace");
            txtArea1.document.write(tab_text);
            txtArea1.document.close();
            txtArea1.focus();
            sa = txtArea1.document.execCommand("SaveAs", true, "Say Thanks to Mai.xls");
        } else //other browser not tested on IE 11
            sa = window.open('data:application/vnd.ms-excel,' + encodeURIComponent(tab_text));

        return (sa);
    }
</script>