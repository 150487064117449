import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subject, Topic, VirtualLearnBook } from 'app/main/admin/virtual-learn/book.model';
import { State } from 'app/main/admin/state/state.model';
import { BookService } from '../book.service';

@Component({
    selector: 'virtual-learn-book-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class VLBookDetailFormDialogComponent {
    subjects: Array<Subject> = [];
    action: string;
    virtualLearnBook: VirtualLearnBook;
    virtualLearnBookForm: FormGroup;
    dialogTitle: string;
    topics: Array<Topic> = [];

    states: State[];
    /**
     * Constructor
     *
     * @param {MatDialogRef<VLPageDetailFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<VLBookDetailFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _bookservice: BookService
    ) {
        this._bookservice.getSubjects().subscribe((response: any) => {
            this.subjects = response.Data;
        })

        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Book';
            this.virtualLearnBook = _data.VirtualLearnBook;
            this.getTopic(_data.VirtualLearnBook.SubjectID);
        }
        else {
            this.dialogTitle = 'New Book';
   
            this.virtualLearnBook = new VirtualLearnBook({});
        }

        this.virtualLearnBookForm = this.createvirtualLearnBookForm();
    }
    getTopic(SubjectID: number) {
        if (SubjectID != 0) {
            this._bookservice.getsubjectTopics(SubjectID).subscribe((response: any) => {
                this.topics = response.Data;
            })
        }
        else {
            this.topics = [];
            this.virtualLearnBookForm?.get('Topic').setValue('');
        }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create virtualLearnBook form
     *
     * @returns {FormGroup}
     */
    createvirtualLearnBookForm(): FormGroup {
        return this._formBuilder.group({
            BookID: [this.virtualLearnBook.BookID],
            Authors: [this.virtualLearnBook.Authors],
            Banner: [this.virtualLearnBook.Banner],
            Edition: [this.virtualLearnBook.Edition],
            Publisher: [this.virtualLearnBook.Publisher],
            Subject: [this.virtualLearnBook.Subject],
            Topic: [this.virtualLearnBook.TopicID],
            Title: [this.virtualLearnBook.Title],
            IsActive: [this.virtualLearnBook.IsActive]
        });
    }
}
