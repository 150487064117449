import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UserManagement } from 'app/main/admin/users/users.model';
import { User } from 'app/_models';
import { Country } from '../../country/country.model';
import { StudentCategory } from '../../student-category/student-category.model';
import { State } from '../../state/state.model';
import { College } from '../../college/college.model';
import { confirmPasswordValidator } from 'app/main/authentication/register/register.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CountryService } from '../../country/country.service';
import { StudentCategoryService } from '../../student-category/student-category.service';
import { StateService } from '../../state/state.service';
import { CollegeService } from '../../college/college.service';
import { DataGuardService } from 'app/_guards';

@Component({
    selector: 'usermanagement-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UserManagementFormDialogComponent {
    registerForm: FormGroup;
    userModel: User;
    countryList: Array<Country>;
    stateList: Array<State>;
    categoryList: Array<StudentCategory>;
    instituteList: Array<College>;
    action: any;
    dialogTitle: string;
    roleOptions = [
        { id: 1, name: 'User' },
        { id: 2, name: 'Administrator' },
        { id: 3, name: 'SuperAdmin' },
    ];
    currentUserModel: User;

    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<UserManagementFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _countryService: CountryService,
        private _categoryService: StudentCategoryService,
        private _stateService: StateService,
        private _instituteService: CollegeService,
        private _dataGuardService: DataGuardService,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Set the defaults
        this.action = _data.action;

        // User Data if already logged in 
        let user = this._dataGuardService.getCookieData('lms-user');

        if (user) {
            this.currentUserModel = JSON.parse(user);

            if (this.currentUserModel.RoleID == 2) {
                this.roleOptions = [
                    { id: 1, name: 'User' },
                    { id: 2, name: 'Administrator' }
                ];
            }
            else {
                this.roleOptions = [
                    { id: 1, name: 'User' },
                    { id: 2, name: 'Administrator' },
                    { id: 3, name: 'SuperAdmin' },
                ];
            }
        }

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit User ';
            this.userModel = _data.user;
            //console.log(_data.user);
            this.onChangeCountry({ value: this.userModel.CountryID });
            this.onChangeState({ value: this.userModel.StateID });

        }
        else {
            this.dialogTitle = 'New User ';
            this.userModel = new User();
        }

        var self = this;

        this.registerForm = this._formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            role: ['', Validators.required],
            mobile: ['', Validators.required],
            country: ['', Validators.required],
            state: ['', Validators.required],
            institute: ['', Validators.required],
            category: ['', Validators.required],
            dateOfBirth: ['', Validators.required],
            IsActive: ['', Validators.required],
            UniqueID: ['', Validators.nullValidator],
        });

        this._countryService.getCountry().then(function (data) {
            self.countryList = data;
        });
        this._categoryService.getStudentCategory().then(function (data) {
            self.categoryList = data;
        });

    }

    /**
  * On Change Country
  * @param event 
  */
    onChangeCountry(event: any) {
        var self = this;

        self._stateService.getStateByCountry(event.value).then(function (data) {
            self.stateList = data;
        });
    }

    /**
     * On Change Country
     * @param event 
     */
    onChangeState(event: any) {
        var self = this;

        self._instituteService.getCollegeByState(event.value).then(function (data) {
            self.instituteList = data;
        });
    }

}
