import { UnisunUtils } from '@unisun/utils';

export class PaymentHistory {
    PaymentHistoryID: string;
    PaymentMethodID: number;
    SubscriptionTypeID: number;
    Attempt: number;
    Type: number;
    TypeID: number;
    BankReferenceID: string;
    OrderReferenceID: string;
    TempReferenceID: string;
    Currency: string;
    Remarks: string;
    Status: number;
    Amount: number;
    CreatedOn: Date;
    SubscriptionUser: SubscriptionUser;
    UserName : string;
    Email : string;
    Mobile : string;
    PaymentMethodName : string;
    ProductName : string;
    Plan : string;
}

export class SubscriptionUser {
    UserSubscriptionID: number;
    SubscriptionID: number;
    SubscriptionTypeID: number;
    PaymentHistoryID: number;
    UserID: number;
    Type: number;
    TypeID: number;
    IsFreeHippo: boolean;
    IsFreeTodo: boolean;
    IsFreeVideo: boolean;
    ValidUpto: number;
    DiscountPercentage: number;
    ActualPrice: number;
    Price: number;
    IsActive: boolean;
}
export class PaymentHistoryReport extends PaymentHistory {
    UserName: string;
    Email: string;
    Mobile: string;
    PaymentMethodName: string;
    ProductName: string;
    Plan: string;

}