<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="qbankForm">
            <mat-tab-group>
                <mat-tab label="Name">
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <ckeditor [config]="ckeConfig" placeholder="Title" [(ngModel)]="qbank.QuestionTitle"
                            formControlName="QuestionTitle" required>
                        </ckeditor>
                    </div>
                </mat-tab>
                <mat-tab label="Configuration">
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field class="mr-sm-24" fxFlex>
                            <input matInput placeholder="Chapter" [(ngModel)]="qbank.Chapter" required
                                formControlName="Chapter">
                        </mat-form-field>
                    </div>
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field class="mr-sm-24" fxFlex>
                                <input matInput placeholder="Subject" [(ngModel)]="qbank.Subject" required
                                    formControlName="Subject">
                            </mat-form-field>
                        </div>
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field class="mr-sm-24" fxFlex>
                            <input matInput placeholder="Bank ID" [(ngModel)]="qbank.QuestionBankID" required
                                formControlName="QuestionBankID">
                        </mat-form-field>
                    </div>
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">

                        <mat-form-field class="mr-sm-24" fxFlex>
                            <input type="number" matInput placeholder="Question ID" [(ngModel)]="qbank.QueueID" required
                                formControlName="QueueID">
                        </mat-form-field>
                    </div>
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field class="mr-sm-24" fxFlex>
                            <input type="number" matInput placeholder="Marks" [(ngModel)]="qbank.Mark" required
                                formControlName="Mark">
                        </mat-form-field>
                    </div>
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field class="mr-sm-24" fxFlex>
                            <input type="number" matInput placeholder="Negative" [(ngModel)]="qbank.Negative" required
                                formControlName="Negative">
                        </mat-form-field>
                    </div>
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field class="mr-sm-24" fxFlex>
                            <mat-select placeholder="Question Type" [(ngModel)]="qbank.QuestionType" required
                                formControlName="QuestionType">
                                <mat-option [value]="s.Key" *ngFor="let s of choiceType">{{s.Value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-tab>
                <mat-tab label="Additional Choice" *ngIf="qbank.QuestionType==3">
                    <h6>Additional Choices * <button mat-raised-button (click)="newQuestionAdditionalChoice()"
                            href="javascript:void(0);">
                            <i class="Medium material-icons">add</i>
                        </button></h6>
                    <mat-list>
                        <mat-list-item *ngFor="let item of qbank.AdditionalChoices" class="mb-4 " fxLayout="row"
                            fxLayoutAlign="start start">
                            <span mat-raised-button (click)="editQuestionAdditionalChoice(item)">
                                <mat-icon mat-list-icon>edit</mat-icon>
                            </span>
                            <span mat-raised-button (click)="deleteQuestionAdditionalChoice(item)">
                                <mat-icon mat-list-icon>delete</mat-icon>
                            </span>
                            <h4 mat-line>{{item.ChoiceText}}</h4>

                        </mat-list-item>
                    </mat-list>
                </mat-tab>
                <mat-tab label="Choice">
                    <h6>Choices * <button mat-raised-button (click)="newQuestionChoice(qbank.QuestionType)"
                            href="javascript:void(0);">
                            <i class="Medium material-icons">add</i>
                        </button></h6>
                    <mat-list>
                        <mat-list-item *ngFor="let item of qbank.Choices" class="mb-4 " fxLayout="row"
                            fxLayoutAlign="start start">
                            <span mat-raised-button (click)="editQuestionChoice(item,qbank.QuestionType)">
                                <mat-icon mat-list-icon>edit</mat-icon>
                            </span>
                            <span mat-raised-button (click)="deleteQuestionChoice(item)">
                                <mat-icon mat-list-icon>delete</mat-icon>
                            </span>
                            <h4 mat-line>{{item.ChoiceText}}</h4>

                        </mat-list-item>
                    </mat-list>

                </mat-tab>
                <mat-tab label="Content">
                    <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                        <ckeditor [config]="ckeConfig" [(ngModel)]="qbank.Content" formControlName="Content" required>
                        </ckeditor>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(qbank)"
            class="save-button mat-accent-bg" [disabled]="!qbankForm.valid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',qbank])"
            class="save-button mat-accent-bg" [disabled]="!qbankForm.valid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',qbank])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>