<mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort matSortActive="Title"
    matSortDirection="asc">
    <!-- QueueID Column -->
    <ng-container matColumnDef="QueueID">
        <mat-header-cell *matHeaderCellDef mat-sort-header>QueueID</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.QueueID}}</p>
        </mat-cell>
    </ng-container>
    <!-- Title Column -->
    <ng-container matColumnDef="Title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Title}}</p>
        </mat-cell>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="IsActive">
        <mat-header-cell *matHeaderCellDef mat-sort-header>IsActive</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="mat-yellow-bg m-4 text-boxed" *ngIf="row.IsActive">{{row.IsActive}}</span>
            <span class="mat-red-bg m-4 text-boxed" *ngIf="!row.IsActive">{{row.IsActive}}</span>
        </mat-cell>
    </ng-container>
    <!-- Url Column -->
    <ng-container matColumnDef="Url">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Url</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Url}}</p>
        </mat-cell>
    </ng-container>
    <!-- Description Column -->
    <ng-container matColumnDef="Description">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Description}}</p>
        </mat-cell>
    </ng-container>

    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell class="cell-center" *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell class="cell-center" *matCellDef="let row">
            <span mat-menu-item aria-label="edit" (click)="editPromotionalVideo(row)">
                <mat-icon>edit</mat-icon>
            </span>
            <span mat-menu-item aria-label="remove" (click)="deletePromotionalVideo(row)">
                <mat-icon>delete</mat-icon>
            </span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="promotional-video"
        [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>
<mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
    (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>