import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { College } from 'app/main/admin/college/college.model';
import { State } from 'app/main/admin/state/state.model';

@Component({
    selector: 'college-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CollegeFormDialogComponent {
    action: string;
    college: College;
    collegeForm: FormGroup;
    dialogTitle: string;

    states: State[];
    /**
     * Constructor
     *
     * @param {MatDialogRef<CollegeFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<CollegeFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        // Set the defaults
        this.action = _data.action;
        this.states = _data.states;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit College';
            this.college = _data.college;
        }
        else {
            this.dialogTitle = 'New College';
            this.college = new College({});
        }

        this.collegeForm = this.createCollegeForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create college form
     *
     * @returns {FormGroup}
     */
    createCollegeForm(): FormGroup {
        return this._formBuilder.group({
            CollegeID: [this.college.CollegeID],
            Name: [this.college.Name],
            StateID: [this.college.StateID]
        });
    }
}
