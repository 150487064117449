 
<mat-table #table [dataSource]="dataSource.data" (matSortChange)="onSortData($event)" matSort
    matSortActive="PaymentHistory" matSortDirection="asc">

    <!-- TempReferenceID Column -->
    <ng-container matColumnDef="TempReferenceID">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Temp ID</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.TempReferenceID}}</p>
        </mat-cell>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="Email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Email}}</p>
        </mat-cell>
    </ng-container>

    <!-- Mobile Column -->
    <ng-container matColumnDef="Mobile">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Mobile}}</p>
        </mat-cell>
    </ng-container>

    <!-- PaymentMethodName Column -->
    <ng-container matColumnDef="PaymentMethodName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Payment Name</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.PaymentMethodName}}</p>
        </mat-cell>
    </ng-container>

    <!-- ProductName Column -->
    <ng-container matColumnDef="ProductName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Product Name</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.ProductName}}</p>
        </mat-cell>
    </ng-container>

    <!-- Plan Column -->
    <ng-container matColumnDef="Plan">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Plan</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Plan}}</p>
        </mat-cell>
    </ng-container>
    <!-- Amount Column -->
    <ng-container matColumnDef="Amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Amount</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.Amount}}</p>
        </mat-cell>
    </ng-container>
    <!-- CreatedOn Column -->
    <ng-container matColumnDef="CreatedOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header>CreatedOn</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-300">{{row.CreatedOn}}</p>
        </mat-cell>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="Status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="mat-blue-bg m-4 text-boxed" *ngIf="row.Status==1">Initiated</span>
            <span class="mat-green-bg m-4 text-boxed" *ngIf="row.Status==2">Paid</span>
            <span class="mat-yello-bg m-4 text-boxed" *ngIf="row.Status==3">Refund</span>
            <span class="mat-red-bg m-4 text-boxed" *ngIf="row.Status==4">Cancelled</span>
            <span class="mat-red-bg m-4 text-boxed" *ngIf="row.Status==5">Payment Failed</span>
            <span class="mat-red-bg m-4 text-boxed" *ngIf="row.Status==6">Refund Failed</span>
        </mat-cell>
    </ng-container>

    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell class="cell-center" *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell class="cell-center" *matCellDef="let row">
            <span mat-menu-item aria-label="history" (click)="editPaymentHistory(row)">
                <mat-icon>history</mat-icon>
            </span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="paymenthistory"
        [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>
<mat-paginator [length]="dataSource.paginationData? dataSource.paginationData.count:0" [pageSize]="5"
    (page)="getNext($event)" [pageSizeOptions]="_sitePreference.PAGE.GridRowViewPageSizeOptions"></mat-paginator>