import { filter } from 'rxjs/operators';
import { AppQuestionsRequest, PGiQuestionsRequest } from './../../apps/academy/game-view.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'app/main/admin/course/course.service';
import { TestSeriesService } from 'app/main/admin/test-series/test-series.service';
import { Exam } from 'app/main/admin/exam/exam.model';
import { RankingFilter, UserRankStatistics, PositionAnalysis } from 'app/_models/UserRankStatistics';
import { DataGuardService } from 'app/_guards';
import { HelperService } from 'app/_helpers/helper.service';
import { User } from 'app/_models';
import { GameViewService } from 'app/main/apps/academy/game-view.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { UserService } from 'app/_services';
import { ReportService } from '../report/report.service';
import { Notification } from 'app/main/admin/notification/notification.model';
import { NotificationService } from '../notification/notification.service';

@Component({
    selector: 'app-user-notification',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class UserNotificationComponent implements OnInit {
    myrwdata: any;
    gtsanalysis: any;
    posanalysis: any;

    MessageType: number;
    SenderType: number;
    Type: number;
    TypeID: number;
    ExamID: number;
    StartDate: Date;
    EndDate: Date;
    title: string = '';
    notification: Notification;
    TypeList: any;
    ExamList: Array<Exam>;
    notificationForm: FormGroup;
    userData: User;

    ckeConfig: any;

    constructor(
        private _courseService: CourseService,
        private _testseriesService: TestSeriesService,
        private _helperService: HelperService,
        private _userService: UserService,
        private _formBuilder: FormBuilder,
        private _notificationService: NotificationService,
    ) {
        var self = this;
        this.userSearch = new FormControl('');
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };
        this.notification = new Notification({});
        this.notificationForm = this.createNotificationForm();
    }

    OnChangeMessageType(TypeID: number) {
        var self = this;
        self.MessageType = TypeID;
    }

    OnChangeRecieverType(TypeID: number) {
        var self = this;
        self.SenderType = TypeID;
    }

    createMessager() {
        var self = this;
        this.notification.Type = self.Type;
        this._notificationService.createMessager(this.notification).then(function (response) {
            if (response) {
                self.notificationForm.reset();
            }
        });

    }

    ngOnInit() {

        var self = this;


        self.title = 'course';
        self.Type = self.title == 'course' ? 1 : 2;

        if (self.Type == 1) {
            self._courseService.getCourse().subscribe(function (data) {
                self.TypeList = data;
            });
        }
        else if (self.Type == 2) {
            self._testseriesService.getTestSeries().subscribe(function (data) {
                self.TypeList = data;
            });
        }



    }

    users: Array<User>;
    userSelectedValue: string;
    userSearch: FormControl;

    selectedUser(userID: string) {
        this.userSelectedValue = userID;
    }


    getUserBySearch() {
        var self = this;
        if (this.userSearch.value != null && this.userSearch.value.length > 1)
            this._userService.getUserBySearch(this.userSearch.value).then(function (response) {
                self.users = response;
            });
    }

    /**
    * Create notification form
    *
    * @returns {FormGroup}
    */
    createNotificationForm(): FormGroup {
        return this._formBuilder.group({
            NotificationID: [this.notification.NotificationID],
            Subject: [this.notification.Subject],
            Message: [this.notification.Message],
            MessageType: [this.notification.Message],
            SenderType: [this.notification.Message],
            Type: [this.notification.Type],
            TypeID: [this.notification.TypeID],
        });
    }
}
