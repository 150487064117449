import { ReportGridFilter } from './../../../../_models/GridFilter';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { GridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute } from '@angular/router';
import { SitePreference } from 'app/config';
import { QuestionBugReport } from 'app/_models/QuestionBugReport';
import { QuestionBugReportService } from '../report.service';
import { QuestionBugReportFormDialogComponent } from '../form/form.component';
import { ExcelExportService } from 'app/_services/excel.export.service';

@Component({
    selector: 'QuestionBugReport-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class QuestionBugReportListComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;

    QuestionBugReport: QuestionBugReport[];

    paginationData: any;

    dataSource: QuestionBugReportDataSource;
    displayedColumns = ['Email', 'QuestionTitle', 'Title', 'CreatedOn', 'buttons'];
    selectedQuestionBugReport: any[];
    checkboxes: {};
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;

    StartDate: any;
    EndDate: any;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {QuestionBugReportService} _QuestionBugReportService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _QuestionBugReportService: QuestionBugReportService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _exportExcelService: ExcelExportService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

    }

    ngAfterViewInit() {

    }

    loadPage() {
        this._QuestionBugReportService.onQuestionBugReportChanged.next(this.QuestionBugReport);
    }


    getNext(event: PageEvent) {
        this._QuestionBugReportService.onQuestionBugReportChanged.next(this.QuestionBugReport);

    }


    onSortData(sort: Sort) {

        this._QuestionBugReportService.onQuestionBugReportChanged.next(this.QuestionBugReport);
    }

    view() {
        this.ngOnInit();
    }

    GetDownloadReport() {
        var self = this;

        let gridFilter: ReportGridFilter = {
            PageNumber: 1,
            PageSize: 1000000,
            Search: "",
            SortBy: "CreatedOn",
            SortOrder: 'desc',
            StartDate: this.StartDate.unix(),
            EndDate: this.EndDate.unix(),
            Type: 0,
            TypeID: 0,
            ExamID: 0
        };

        this._QuestionBugReportService.getQuestionBugReportForGrid(gridFilter)

            .subscribe(response => {
                self._exportExcelService.exportAsExcelFile(response.data, "testseries-exam-report-list_");

            });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.dataSource = new QuestionBugReportDataSource(this._QuestionBugReportService);

        this._QuestionBugReportService.onQuestionBugReportChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.QuestionBugReport = search;
                let gridFilter: ReportGridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == undefined ? "CreatedOn" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc',
                    StartDate: this.StartDate.unix(),
                    EndDate: this.EndDate.unix(),
                    Type: 0,
                    TypeID: 0,
                    ExamID: 0
                };
                this.dataSource.loadData(gridFilter);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    viewReport(row: QuestionBugReport) {
        this.dialogRef = this._matDialog.open(QuestionBugReportFormDialogComponent, {
            panelClass: 'question-report-bug-form-dialog',
            data: {
                QuestionBugReport: row
            }
        });
    }
}

export class QuestionBugReportDataSource extends DataSource<QuestionBugReport>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<QuestionBugReport>;
    /**
     * Constructor
     *
     * @param {QuestionBugReportService} _QuestionBugReportService
     */
    constructor(
        private _QuestionBugReportService: QuestionBugReportService
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._QuestionBugReportService.onQuestionBugReportChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: ReportGridFilter) {
        var self = this;

        this._QuestionBugReportService.getQuestionBugReportForGrid(gridFilter)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);

                self.paginationData = {
                    count: response.Count,
                    pageNumber: response.CurrentFilter.PageNumber
                };


            });
    }
}
