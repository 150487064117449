import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Todo, TodoDetails } from 'app/main/admin/todo/todo.model';
import { State } from 'app/main/admin/state/state.model';

@Component({
    selector: 'todo-detail-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TodosDetailsFormDialogComponent {
    ckeConfig: any;
    action: string;
    todo: TodoDetails;
    todoForm: FormGroup;
    dialogTitle: string;

    states: State[];
    /**
     * Constructor
     *
     * @param {MatDialogRef<TodosDetailsFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<TodosDetailsFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Todo';
            this.todo = _data.todo;
        }
        else {
            this.dialogTitle = 'New Todo';
            this.todo = new TodoDetails({});
        }

        this.todoForm = this.createTodoForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create todo form
     *
     * @returns {FormGroup}
     */
    createTodoForm(): FormGroup {
        return this._formBuilder.group({
            Title: [this.todo.Title],
            IsImportent: [this.todo.IsImportent],
            IsStarred: [this.todo.IsStarred],
            Note: [this.todo.Note],
            Subject: [this.todo.Subject],
            Chapter:[this.todo.Chapter]
        });
    }
}
