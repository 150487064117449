import { UnisunUtils } from '@unisun/utils';
import { Exam } from '../exam/exam.model';
import { User } from 'app/_models';

export class TestSeries {
    TestSeriesID: number;
    Title: string;
    SubTitle: string;
    Description: string;
    Price: number;
    RewardsPoint: number;
    StartDate: string;
    EndDate: string;
    VideoID: number;
    HipposID: number;
    NoteID: number;
    TodoID: number;
    IsActive: boolean;
    ExamID: Array<Exam>;
    NoOfExams: number;
    ProgressPercentage: number;
    NoOfEntrolled: number;
    NoOfQuestions: number;
    NoOfExamCompleted: number;
    TotalMinutes: number;
    TotalScore: number;

    VideoCollection: Array<KVP>;
    HippoCollection: Array<KVP>;
    NoteCollection: Array<KVP>;
    TodoCollection: Array<KVP>;
    ExamCollection: Array<Exam>;

    Users: Array<User>;
    /**
     * Constructor
     *
     * @param testseries
     */
    constructor(testseries) {
        {
            this.TestSeriesID = testseries.TestSeriesID; //|| UnisunUtils.generateGUID();
            this.Title = testseries.Title || '';
            this.SubTitle = testseries.SubTitle || '';
            this.Description = testseries.Description || '';
            this.Price = testseries.Price || '';
            this.RewardsPoint = testseries.RewardsPoint || '';
            this.StartDate = testseries.StartDate || '';
            this.EndDate = testseries.EndDate || '';
            this.VideoID = testseries.VideoID || 0;
            this.HipposID = testseries.HipposID || 0;
            this.NoteID = testseries.NoteID || 0;
            this.TodoID = testseries.TodoID || 0;
            this.ExamID = testseries.ExamID || new Array<Exam>();
            this.Users = testseries.Users || new Array<User>();

            this.VideoCollection = testseries.VideoCollection || new Array<KVP>();
            this.HippoCollection = testseries.HippoCollection || new Array<KVP>();
            this.NoteCollection = testseries.NoteCollection || new Array<KVP>();
            this.TodoCollection = testseries.TodoCollection || new Array<KVP>();
            this.ExamCollection = testseries.ExamCollection || new Array<KVP>();
        }
    }
}

export class KVP {
    key?: number;
    value: string;
}
