import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';
import { CKEditorModule } from 'ng2-ckeditor';

import { CMCQuestionSetComponent } from './qbank.component';
import { CMCQuestionSetService } from './qbank.service';
import { CMCQuestionSetListComponent } from './qbank-list/list.component';
import { CMCQuestionSetFormDialogComponent } from './qbank-form/form.component';
import { CMCQuestionDetailFormDialogComponent } from './qbank-detail-form/form.component';
import { CMCQuestionDetailComponent } from './qbank-detail/list.component';
import { CMCQuestionDetailService } from './qbank-detail.service';
import { CMCQuestionChoiceFormDialogComponent } from './qbank-choice-form/form.component';
import { CMCQuestionAdChoiceFormDialogComponent } from './qbank-ad-choice-form/form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

const routes: Routes = [
    {
        path: 'collections/chart-my-career/qbank',
        component: CMCQuestionSetComponent,
        resolve: {
            service: CMCQuestionSetService
        }
    },
    {
        path: 'collections/chart-my-career/qbank/:id',
        component: CMCQuestionDetailComponent,
        resolve: {
            service: CMCQuestionDetailService
        }
    }
];

@NgModule({
    declarations: [
        CMCQuestionSetComponent,
        CMCQuestionSetListComponent,
        CMCQuestionSetFormDialogComponent,
        CMCQuestionDetailFormDialogComponent,
        CMCQuestionDetailComponent,
        CMCQuestionChoiceFormDialogComponent,
        CMCQuestionAdChoiceFormDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatListModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule,

        CKEditorModule
    ],
    providers: [
        CMCQuestionSetService,
        CMCQuestionDetailService
    ],
    entryComponents: [
        CMCQuestionSetFormDialogComponent,
        CMCQuestionDetailFormDialogComponent,
        CMCQuestionChoiceFormDialogComponent,
        CMCQuestionAdChoiceFormDialogComponent
    ]
})
export class CMCQuestionSetModule {
}
