import { QuestionSetService } from 'app/main/admin/qbank/qbank.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';

import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';

import { ExamComponent } from 'app/main/admin/exam/exam.component';
import { ExamService } from 'app/main/admin/exam/exam.service';
import { ExamListComponent } from 'app/main/admin/exam/exam-list/list.component';
import { ExamFormDialogComponent } from 'app/main/admin/exam/exam-form/form.component';
import { MomentModule } from 'ngx-moment';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
    {
        path: 'collections/exams',
        component: ExamComponent,
        resolve: {
            contacts: ExamService
        }
    }
];

@NgModule({
    declarations: [
        ExamComponent,
        ExamListComponent,
        ExamFormDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MomentModule.forRoot({ relativeTimeThresholdOptions: { 'm': 59 } }),
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatStepperModule,
        MatSlideToggleModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule
    ],
    providers: [
        ExamService,
        QuestionSetService
    ],
    entryComponents: [
        ExamFormDialogComponent
    ]
})
export class ExamModule {
}
