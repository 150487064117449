import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Video, VideoDetails,VideoResolution } from 'app/main/admin/video/video.model';
import { State } from 'app/main/admin/state/state.model';
import { CommonService } from '../../common/subject/common.service';

@Component({
    selector: 'video-detail-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class VideoDetailsFormDialogComponent {
    subjects:any;    
    topics:any=[];
    ckeConfig: any;
    action: string;
    video: VideoDetails;
    videoForm: FormGroup;
    dialogTitle: string;
    states: State[];
    resolutions:any[]=[{resolution:"360",disabled:false},
                    {resolution:"540",disabled:false},
                    {resolution:"720",disabled:false},
					{resolution:"480",disabled:false},
                    {resolution:"1080",disabled:false}
                ];
    /**
     * Constructor
     *
     * @param {MatDialogRef<VideoDetailsFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<VideoDetailsFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _commonService: CommonService
    ) {
        var self =this;
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };        
        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Video';
            this.video = _data.video;
        }
        else {
            this.dialogTitle = 'New Video';
            this.video = new VideoDetails({});
        }
        this._commonService.getSubject().then(function (response) {

            self.subjects = response.Data;
            console.log(this.subjects)
        });
        this.videoForm = this.createVideoForm();
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create video form
     *
     * @returns {FormGroup}
     */
    createVideoForm(): FormGroup {
        this.manageResolutions();
        return this._formBuilder.group({
            Title: [this.video.Title],
            MaxViewPermission: [this.video.MaxViewPermission],
            Content: [this.video.Content],
            Subject: [this.video.Subject],
            Topic: [this.video.Topic],
            Category:[this.video.Category],
            Price: [this.video.Price],
            Url: [this.video.Url],
            VideoLength: [this.video.VideoLength],
            VideoType: [this.video.VideoType],
            NotesLink: [this.video.NotesLink],
            VideoResolutionCollection:[this.video.VideoResolutionCollection]
        });
    }

    manageResolutions():void{
        if(this.video.VideoResolutionCollection!=null){
            this.video.VideoResolutionCollection.forEach(_resolutionObj => {
                this.resolutions.forEach(_resolution => {
                    if(_resolutionObj.ResolutionType==_resolution.resolution){
                        _resolution.disabled=true;
                          return;
                    }
                })
            });
        }
        else
        {
            this.video.VideoResolutionCollection=[];
        }
    }
    // Add Video Reo

    deleteResolutions(index:number):void{
        if (index > -1) { // only splice array when item is found
            this.video.VideoResolutionCollection.splice(index, 1); // 2nd parameter means remove one item only
          }
          this.manageResolutions();
    }
    addResolution():void{
        if(this.video.VideoResolutionCollection==null){
            this.video.VideoResolutionCollection=[];
        }
            var obj=new VideoResolution();
           this.video.VideoResolutionCollection.push(obj);
    }
    gettopics(SubjectID){
        this._commonService.getTopicsByEntityType(SubjectID,"Custom QBank").then((response:any)=> {
            this.topics = response;
            console.log(this.topics);
        });     
    }

}
