import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { UnisunUtils } from '@unisun/utils';

import { Country } from 'app/main/admin/country/country.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class CountryService implements Resolve<any>
{
    onCountryChanged: BehaviorSubject<any>;

    country: Country[];
    user: any;
    selectedCountry: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onCountryChanged = new BehaviorSubject([]);
    }


        // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getCountryForGrid(gridFilter)
            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
        });
    }


    /**
     * Get country
     *
     * @returns {Promise<any>}
     */
    getCountry(): Promise<Country[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/localization/get-countries`)
                .subscribe((response: any) => {

                    this.country = response;;
                    this.onCountryChanged.next(this.country);
                    resolve(this.country);
                }, reject);
        }
        );
    }

    /**
     * Get country
     *
     * @returns {Promise<any>}
     */
    getCountryForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/localization/get-grid-countries`, { ..._gridFilter });
    }

    /**
     * Update country
     *
     * @param country
     * @returns {Promise<any>}
     */
    createCountry(country: Country): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/localization/create-country/`, { ...country })
                .subscribe(response => {
                    if (response) {
                        this.onCountryChanged.next(this.country);
                        this.openSnackBar("Successfully added.", "Close");
                    }
                    else{
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Update country
     *
     * @param country
     * @returns {Promise<any>}
     */
    updateCountry(country: Country): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/localization/update-country/`, { ...country })
                .subscribe(response => {
                    if (response) {
                        this.onCountryChanged.next(this.country);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else{
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete country
     *
     * @param country
     */
    deleteCountry(country): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.delete(`${environment.apiURL}/localization/delete-country/` + country.CountryID,{})
                .subscribe(response => {
                    if (response) {
                        this.onCountryChanged.next(this.country);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else{
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

}
