import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule} from '@angular/material/select';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';

import { UnisunSharedModule } from '@unisun/shared.module';
import { UnisunConfirmDialogModule, UnisunSidebarModule } from '@unisun/components';
import { CKEditorModule } from 'ng2-ckeditor';

import { NoteComponent } from 'app/main/admin/note/note.component';
import { NoteService } from 'app/main/admin/note/note.service';
import { NoteListComponent } from 'app/main/admin/note/note-list/list.component';
import { NoteFormDialogComponent } from 'app/main/admin/note/note-form/form.component';
import { NotesDetailsFormDialogComponent } from './note-detail-form/form.component';
import { NotesDetailsComponent } from './note-detail/list.component';
import { NotesDetailsService } from './note-detail.service';

const routes: Routes = [
    {
        path: 'collections/note',
        component: NoteComponent,
        resolve: {
            service: NoteService
        }
    },
    {
        path: 'collections/note/:id',
        component: NotesDetailsComponent,
        resolve: {
            service: NotesDetailsService
        }
    }
];

@NgModule({
    declarations: [
        NoteComponent,
        NoteListComponent,
        NoteFormDialogComponent,
        NotesDetailsFormDialogComponent,
        NotesDetailsComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSlideToggleModule,

        UnisunSharedModule,
        UnisunConfirmDialogModule,
        UnisunSidebarModule,

        CKEditorModule
    ],
    providers: [
        NoteService,
        NotesDetailsService
    ],
    entryComponents: [
        NoteFormDialogComponent,
        NotesDetailsFormDialogComponent
    ]
})
export class NoteModule {
}
