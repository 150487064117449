<div class="layout-content">
    <div class="layout-dashboard">
        <div class="ui-g">
            <div class="position-relative p-24 bg-accent-bg" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxLayoutAlign="start start">
                    <span class="h2">
                        <mat-label class="mat-yellow-bg m-4 text-boxed">Course</mat-label>
                    </span>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-select placeholder="Select Course" style="width: 250px" [(ngModel)]="TypeID"
                        (selectionChange)="OnChangeType(TypeID)" class="m-0" floatLabel="always">
                        <mat-option *ngFor="let c of TypeList" [value]="c.CourseID">{{c.Title}}</mat-option>
                    </mat-select>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start">
                    <span class="h2">
                        <mat-label class="mat-yellow-bg m-4 text-boxed">Exam</mat-label>
                    </span>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-select style="width: 250px" placeholder="Select Exam" [(ngModel)]="ExamID" class="m-0"
                        floatLabel="always" (selectionChange)="OnChangeExam(ExamID)">
                        <mat-option *ngFor="let c of ExamList" [value]="c.ExamID">{{c.Title}}</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
        <div *ngIf="Type && TypeID && ExamID">
            <div class="ui-g">
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                    <div class="overview-box courses">
                        <img class="image-icon" src="assets/images/012-graduate.png">
                        <div class="overview-numbers">Entrolled Students</div>
                        <div class="overview-title">255</div>
                        <div class="overview-subinfo">
                            <span class="ui-g-6">

                            </span>
                        </div>
                    </div>
                </div>
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                    <div class="overview-box courses">
                        <img class="image-icon" src="assets/images/012-graduate.png">
                        <div class="overview-numbers">6.8(+ 12%)</div>
                        <div class="overview-title">Average Mark</div>
                        <div class="overview-subinfo danger">
                            <span class="ui-g-6">
                                <span class="ui-g-6"><a [routerLink]="['/admin/collections/users']">more...</a></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                    <div class="overview-box courses">
                        <img class="image-icon" src="assets/images/012-graduate.png">
                        <div class="overview-numbers">12 (10%)</div>
                        <div class="overview-title">Underperforming Students</div>
                        <div class="overview-subinfo">
                            <span class="ui-g-6">
                                <span class="ui-g-6"><a [routerLink]="['/admin/collections/users']">more...</a></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                    <div class="overview-box courses">
                        <img class="image-icon" src="assets/images/012-graduate.png">
                        <div class="overview-numbers">83%</div>
                        <div class="overview-title">Last Assessment Submitted</div>
                        <div class="overview-subinfo">
                            <span class="ui-g-6">
                                <span class="ui-g-6"><a [routerLink]="['/admin/collections/users']">more...</a></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!--- <div class="ui-g">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
                <div class="position-relative p-24 bg-accent-bg" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <span class="h2">Students</span>
                        <span class="h5 secondary-text">Performing completed QBank by month</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer"
                            (click)="widget1SelectedYear = 'June'"
                            [ngClass]="{'dark-pink-300-bg': widget1SelectedYear === 'June'}">
                            June
                        </div>
                        <div class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer"
                            (click)="widget1SelectedYear = 'July'"
                            [ngClass]="{'dark-pink-300-bg': widget1SelectedYear === 'July'}">
                            July
                        </div>
                        <div class="py-8 px-12 border-radius-2 line-height-1 cursor-pointer"
                            (click)="widget1SelectedYear = 'Augest'"
                            [ngClass]="{'dark-pink-300-bg': widget1SelectedYear === 'Augest'}">
                            Augest
                        </div>
                    </div>
                </div>
                <div class="position-relative h-256 pb-16 mat-blue-600-bg">
                    <canvas baseChart [datasets]="widgets.widget1.datasets[widget1SelectedYear]"
                        [labels]="widgets.widget1.labels" [colors]="widgets.widget1.colors"
                        [options]="widgets.widget1.options" [chartType]="widgets.widget1.chartType">
                    </canvas>
                </div>
            </div>


        </div>-->
            <div class="ui-g">
                <div class="ui-g-6 ui-md-6 ui-lg-6">
                    <div class="position-relative p-24 overview-box todos" fxLayout="row"
                        fxLayoutAlign="space-between center">

                        <div fxLayout="column" fxLayoutAlign="start start">
                            <span class="h2">Under Perfomar</span>
                            <span class="h5 secondary-text">Unique student by exam</span>
                        </div>


                    </div>
                    <div class="global-sales">
                        <div class="ui-panel-content" *ngIf="Underperformars">

                            <!-- <canvas baseChart [datasets]="widgets.widget4.datasets" [labels]="widgets.widget4.labels"
                            [colors]="widgets.widget4.colors" [options]="widgets.widget4.options"
                            [chartType]="widgets.widget4.chartType">
                        </canvas> -->
                            <div class="chat-list" fxLayout="column">
                                <button mat-button class="contact" *ngFor="let c of Underperformars">

                                    <div fxLayout="row" fxLayoutAlign="start center">

                                        <div class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                                            <img class="avatar" alt=""
                                                src="../../../../../assets/images/031-user.png" />
                                            <mat-icon class="s-16 status"></mat-icon>
                                        </div>

                                        <div fxLayout="row" fxFlex>

                                            <div class="" fxFlex fxLayout="column" fxLayoutAlign="center start">
                                                <div class="contact-name"> {{c.Name}}</div>
                                            </div>

                                            <div fxLayout="column" fxLayoutAlign="center end">
                                                <!-- <div class="contact-last-message-time">
                                                    Test
                                                </div> -->
                                                <div class="unread-message-count mat-accent-bg">{{c.TotalScore}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="ui-g-6 ui-md-6 ui-lg-6">
                        <div class="position-relative p-24 overview-box subscription" fxLayout="row"
                            fxLayoutAlign="space-between center">

                            <div fxLayout="column" fxLayoutAlign="start start">
                                <span class="h2">Topper</span>
                                <span class="h5 secondary-text">Unique student by exam</span>
                            </div>

                        </div>
                        <div class="global-sales">
                            <div class="ui-panel-content">
                                <div class="chat-list" fxLayout="column">
                                    <button mat-button class="contact" *ngFor="let c of Toppers">
    
                                        <div fxLayout="row" fxLayoutAlign="start center">
    
                                            <div class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                                                <img class="avatar" alt=""
                                                    src="../../../../../assets/images/031-user.png" />
                                                <mat-icon class="s-16 status"></mat-icon>
                                            </div>
    
                                            <div fxLayout="row" fxFlex>
    
                                                <div class="" fxFlex fxLayout="column" fxLayoutAlign="center start">
                                                    <div class="contact-name"> {{c.Name}}</div>
                                                </div>
    
                                                <div fxLayout="column" fxLayoutAlign="center end">
                                                    <div class="contact-last-message-time">
                                                        {{c.TotalScore}}
                                                    </div>
                                                    <div class="unread-message-count mat-accent-bg">{{c.RankPosition}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                    <!-- <canvas baseChart [datasets]="widgets.widget4.datasets" [labels]="widgets.widget4.labels"
                            [colors]="widgets.widget4.colors" [options]="widgets.widget4.options"
                            [chartType]="widgets.widget4.chartType">
                        </canvas> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>