import { College } from './../college/college.model';
import { User } from 'app/_models';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunSidebarService } from '@unisun/components/sidebar/sidebar.service';

import { UserManagementService } from 'app/main/admin/users/users.service';
import { UserManagementFormDialogComponent } from 'app/main/admin/users/users-form/form.component';
import { AuthService } from 'app/main/authentication/auth.service';
import { GridFilter } from 'app/_models/GridFilter';
import { ExcelExportService } from 'app/_services/excel.export.service';

@Component({
    selector: 'usermanagement',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class UserManagementComponent implements OnInit, OnDestroy {
    dialogRef: any;
    hasSelectedUserManagement: boolean;
    searchInput: FormControl;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UserManagementService} _usermanagementService
     * @param {UnisunSidebarService} _unisunSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _usermanagementService: UserManagementService,
        private _unisunSidebarService: UnisunSidebarService,
        private _matDialog: MatDialog,
        private _authService: AuthService,
        private _exportExcelService: ExcelExportService
    ) {
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._usermanagementService.onUserManagementChanged.next(searchText);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._unisunSidebarService.getSidebar(name).toggleOpen();
    }


    /**
     * New User
     */
    newUser(): void {
        var self = this;
        this.dialogRef = this._matDialog.open(UserManagementFormDialogComponent, {
            panelClass: 'user-form-dialog',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: User) => {
                if (!response) {
                    return;
                }

                this._authService.createUser(response).then(function (data) {
                    if (data.UserStatus == 1) {
                        self._usermanagementService.openSnackBar(data.Message, 'Close');
                        return false;
                    }
                    self._usermanagementService.onUserManagementChanged.next('');
                });
            });
    }

    GetDownloadReport() {
        var self = this;

        let gridFilter: GridFilter = {
            PageNumber: 1,
            PageSize: 7000000,
            Search:  "",
            SortBy: "UserName",
            SortOrder:'asc'
        };

        this._usermanagementService.getUserManagementForGrid(gridFilter).subscribe(response => {

            let data = [];

            response.data.forEach(element => {
                let user= {
                    UserName:element.UserName,
                    Email:element.Email,
                    Mobile:element.Mobile,
                    College:element.College,
                    State:element.state,
                    RegisteredOn: new Date(element.CreatedOn*1000),
                    Active : element.IsActive
                };
                data.push(user);
            });

            self._exportExcelService.exportAsExcelFile(data, "User-report-list-");

        });

    }
}
