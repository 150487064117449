<div id="notification" class="page-layout simple left-sidebar inner-sidebar inner-scroll">

    <!-- HEADER -->
    <div class="header mat-accent-bg p-16 p-sm-24 mat-elevation-z6" fxLayout="column" fxLayoutAlign="start start"
        fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

        <!-- APP TITLE -->
        <div fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="sidebar-toggle mr-12" fxHide.gt-md
                (click)="toggleSidebar('notification-main-sidebar')">
                <mat-icon>menu</mat-icon>
            </button>

            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">account_box
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Thought of the day
                </span>
            </div>

        </div>
        <!-- / APP TITLE -->

        <!-- SEARCH -->
        <div class="search-input-wrapper mt-16 ml-8 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
            <label for="search" class="mr-8">
                <mat-icon>search</mat-icon>
            </label>
            <mat-form-field mat-no-float class="m-0" floatLabel="never">
                <input matInput id="search" placeholder="Search" [formControl]="searchInput">
            </mat-form-field>
        </div>
        <!-- / SEARCH -->
        <!-- ADD notification BUTTON -->
        <button mat-raised-button id="add-notification-button" (click)="newNotification()" aria-label="add notification"
            [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
            <mat-icon>add</mat-icon> ADD
        </button>
        <!-- / ADD notification BUTTON -->
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">



        <!-- CENTER -->
        <div class="center p-24 pb-56" unisunPerfectScrollbar>

            <!-- CONTENT -->
            <div class="content mat-white-bg mat-elevation-z4">
                <notification-list></notification-list>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CENTER -->

    </div>
    <!-- / CONTENT-->

</div>