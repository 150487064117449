import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataGuardService } from 'app/_guards';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private _dataGuardService: DataGuardService
    ) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        var self = this;
        let currentUser = JSON.parse(localStorage.getItem('Access-Token'));
        // User Data if already logged in 
        let user = this._dataGuardService.getCookieData('lms-user');

        if (user)
            user = JSON.parse(user);
        if (currentUser && currentUser.access_token) {
            request = request.clone({
                setHeaders: {
                    'X-Device-ID': "web",
                    'X-Unique-ID': user != null && user != '' ? `${user.UserID}` : '0',
                    'X-User-ID': user != null && user != '' ? `${user.UserID}` : '0',
                    'X-App-Source': '1',
                    'X-App-Version': '1',
                    'Authorization': `Bearer ${currentUser.access_token}`
                }
            });
        }

        return next.handle(request);
    }


}