import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


import { Transcription } from 'app/main/admin/video/video.model';
import { environment } from 'environments/environment';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TranscriptionService implements Resolve<any>
{
    onTranscriptionChanged: BehaviorSubject<any>;

    transcription: Transcription[];
    user: any;
    selectedTranscription: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onTranscriptionChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterTranscriptionSnapshot} transcription
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, transcription: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                //this.getTranscriptionForGrid(gridFilter)
            ]).then(
                ([]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get transcription by Id
     *
     * @returns {Promise<any>}
     */
    getTranscriptionById(id): Promise<Transcription[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/fundamental/get-transcription-by-id/` + id)
                .subscribe((response: any) => {

                    this.transcription = response;

                    this.onTranscriptionChanged.next(this.transcription);
                    resolve(this.transcription);
                }, reject);
        }
        );
    }
    /**
     * Get transcription
     *
     * @returns {Promise<any>}
     */
    getTranscription(): Promise<Transcription[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/fundamental/get-transcription`)
                .subscribe((response: any) => {

                    this.transcription = response;

                    this.onTranscriptionChanged.next(this.transcription);
                    resolve(this.transcription);
                }, reject);
        }
        );
    }

    /**
     * Get transcription
     *
     * @returns {Promise<any>}
     */
    getTranscriptionForGrid(_gridFilter: GridFilter, videoId: number, videoDetailId: number): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/fundamental/get-grid-transcription/` + videoId + `/` + videoDetailId, { ..._gridFilter });
    }

    /**
     * Update transcription
     *
     * @param transcription
     * @returns {Promise<any>}
     */
    createTranscription(transcription: Transcription): Promise<any> {
        return new Promise((resolve) => {

            this._httpClient.post(`${environment.apiURL}/fundamental/create-transcription/`, { ...transcription })
                .subscribe(response => {
                    if (response) {
                        this.onTranscriptionChanged.next(this.transcription);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                    resolve(response);
                });
        });
    }

    /**
     * Update transcription
     *
     * @param transcription
     * @returns {Promise<any>}
     */
    updateTranscription(transcription: Transcription): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/fundamental/update-transcription/`, { ...transcription })
                .subscribe(response => {
                    if (response) {
                        this.onTranscriptionChanged.next(this.transcription);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete transcription
     *
     * @param transcription
     */
    deleteTranscription(transcription: Transcription): Promise<any> {
        return new Promise(() => {

            this._httpClient.delete(`${environment.apiURL}/fundamental/delete-transcription/` + transcription.TranscriptionID, {})
                .subscribe(response => {
                    if (response) {
                        this.onTranscriptionChanged.next(this.transcription);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

}
