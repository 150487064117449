<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="reportForm">

            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <mat-icon matPrefix class="mr-12 s-20 secondary-text">timer</mat-icon>
                    <input name="name" formControlName="RemainingDurationTime" placeholder="Balance(Mint's)" matInput
                        required [(ngModel)]="report.RemainingDurationTime">
                </mat-form-field>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field>
                    <mat-icon matPrefix class="mr-12 s-20 secondary-text">history</mat-icon>
                    <mat-select placeholder="Status" required formControlName="Status" [(ngModel)]="report.Status">
                        <mat-option value="1">STARTED</mat-option>
                        <mat-option value="2">INPROGRESS</mat-option>
                        <mat-option value="3">COMPLETED</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button mat-raised-button (click)="matDialogRef.close(report)" class="save-button mat-accent-bg"
            [disabled]="reportForm.invalid" aria-label="SAVE">
            SAVE
        </button>


    </div>
</div>