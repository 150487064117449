import { Component, OnDestroy, OnInit,  TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { CommonService } from "../common.service";
import { Subject as SubjectModel,Module as  ModuleModel } from '../common.model'
import { GridFilter, subjectgrid } from 'app/_models/GridFilter';
import { ActivatedRoute } from '@angular/router';
import { SitePreference } from 'app/config';
import { DataGuardService } from 'app/_guards';
import { AddSubjectComponent } from "../addSubject/addSubject.component";

@Component({
    selector: 'subjectList',
    templateUrl: './subjectList.component.html',
    styleUrls: ['./subjectList.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})

export class SubjectListComponent implements OnInit, OnDestroy {
    Modules:Array<ModuleModel>;
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;
subjects:SubjectModel
    subject: any;
    Qbanktypeid:number;
    paginationData: any;

    dataSource: SubjectDataSource;
    displayedColumns = ['SubjectName', 'IsActive', 'buttons','Topic','horizontalintegration','verticalintegration'];
    selectedExam: any[];
    checkboxes: {};
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;
    


    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    topicID:number;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DailyQuestionService} _examService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _commonService: CommonService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _router: Router,
        private _dataGuardService: DataGuardService
    ) {
        var self = this;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.route.params.subscribe( function (parram) {
            self.Qbanktypeid = self._dataGuardService.valueDecryption(parram.id);
            
        })

        if(self.Qbanktypeid==null){
            self.Qbanktypeid=0;
        }
    }

    ngAfterViewInit() {

    }



        
    

    loadPage() {
        
        this._commonService.onSubjectChanged.next(this.subject);
    }

    getNext(event: PageEvent) {
       
        this._commonService.onSubjectChanged.next(this.subject);

    }

    onSortData(sort: Sort) {

        this._commonService.onSubjectChanged.next(this.subject);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    ngOnInit(): void {
        
        this.dataSource = new SubjectDataSource(this._commonService);
  
        this._commonService.onSubjectChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.subject = search;
                let gridFilter: subjectgrid = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == undefined ? "SubjectID" : "SubjectID",
                    SortOrder: this.sort.direction == 'desc' ? this.sort.direction : 'desc',
                    QbankTypeID:this.Qbanktypeid
                };
                this.dataSource.loadData(gridFilter);

               
            });
          
    }

    /**
     * On destroy
     */

    ngOnDestroy(): void {
         // Unsubscribe from all subscriptions
         this._unsubscribeAll.next();
         this._unsubscribeAll.complete();
        
    }

    // Sandeep kumar sahoo--02/01/2023 workin on edit and funtion of sabject start

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Edit exam
     *
     * @param subject
     */

    editsubject(subject): void {
        var self = this;
        this._commonService.getSubjectById(subject.SubjectID).then(response=>{
          this._commonService.getModules().then(modules => {
            self.Modules=modules;
             self.dialogRef = self._matDialog.open(AddSubjectComponent, {
                    panelClass: 'subject-form-dialog',
                    disableClose: true,
                    data: {
                        subject: response,
                        modules:modules,
                        action: 'edit'
                    }
                });
                self.editsubjectCallback(subject);
                });
       })
     }

    editsubjectCallback(subject) {

        this.dialogRef.afterClosed()
            .subscribe(response => {
               if (!response) {
                    return;
                }
                const actionType: string = response.actionType;
                const subject: SubjectModel = response.subject;
                // subject.QbankTypeID=this.Qbanktypeid; 
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':
                        let modules=[];
                        var i=0;
                        response.Modules.forEach(module => {
                         if(module==true){
                             modules.push(this.Modules[i]);
                         }
                         i++;                    
                        });
                                         
                         subject.Modules=modules;
                        this._commonService.updateSubject(subject);

                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deleteSubject(subject);

                        break;
                }
            });

    }

    /**
     * Delete Subject
     */
    deleteSubject(subject): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._commonService.deleteSubject(subject);
            }
            this.confirmDialogRef = null;
        });

    }



    // Sandeep kumar sahoo--02/01/2023 workin on edit and funtion of sabject end

    topic(Subject) {
        this._router.navigate(['admin/collection/subject/' + this._dataGuardService.valueEncryption(Subject.SubjectID)]);
        
    }
}

export class SubjectDataSource extends DataSource<SubjectModel>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<SubjectModel>;
    /**
     * Constructor
     *
     * @param {DailyQuestionService} _examService
     */
    constructor(
        private _commonService: CommonService
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._commonService.onSubjectChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: subjectgrid) {
        var self = this;

        this._commonService.getSubjectForGrid(gridFilter)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);
                self.paginationData = {
                    count: response.Count,
                    pageNumber: response.CurrentFilter.PageNumber
                };


            });
    }
}