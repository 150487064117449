import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';
import { Subscription, SubscriptionResponse, SubscriptionPlan } from 'app/main/admin/course/course.model';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import { Exam } from '../exam.model';
import { QuestionSet } from '../../qbank/qbank.model';
import { CMCExamService } from '../exam.service';
import { CMCAssetFormDialogComponent } from '../../subcription-form/form.component';

@Component({
    selector: 'cmc-exam-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CMCExamFormDialogComponent {
    action: string;
    exam: Exam;
    examForm: FormGroup;
    dialogTitle: string;
    questionSet: Array<QuestionSet>;
    ckeConfig: any;
    choiceType: any;
    scoreType: any;
    Subscription: Subscription;
    SubscriptionResponse: SubscriptionResponse;
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<ExamFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<CMCExamFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _examService: CMCExamService,
        public _matDialog: MatDialog,
    ) {
        var self = this;
        // Set the defaults
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        this._examService.getChoiceType().then(function (response) {

            self.choiceType = response;
        });

        this.scoreType = this._examService.getScoreType().then(function (response) {

            self.scoreType = response;
        });

        this.action = _data.action;
        this.questionSet = _data.questionSet;
        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Exam';
            this.exam = _data.exam;
        }
        else {
            this.dialogTitle = 'New Exam';
            this.exam = new Exam({});
        }


        this.examForm = this.createExamForm();
        if (self.exam.ExamID > 0) {
            self.getSubscription();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create exam form
     *
     * @returns {FormGroup}
     */
    createExamForm(): FormGroup {
        return this._formBuilder.group({
            ExamID: [this.exam.ExamID],
            Title: [this.exam.Title],
            Subject: [this.exam.Subject],
            ScoreType: [this.exam.ScoreType],
            ChoiceType: [this.exam.ChoiceType],
            RewardsPoint: [this.exam.RewardsPoint],
            StartDate: [this.exam.StartDate],
            EndDate: [this.exam.EndDate],
            QuestionSetID: [this.exam.QuestionSetID],
            HasReview: [this.exam.HasReview],
            HasClear: [this.exam.HasClear],
            IsActive: [this.exam.IsActive],
            HasCalculateRisk: [this.exam.HasCalculateRisk],
            DurationByMinutes: [this.exam.DurationByMinutes],
            Instruction: [this.exam.Instruction],
            Price: [this.exam.Price]
        });
    }

    ngOnInit(): void {

        var self = this;
        if (self.exam.ExamID > 0) {
            self.getSubscription();
        }
    }

    getSubscription() {
        var self = this;

        self._examService.getSubscription(4, this.exam.ExamID).then(response => {
            if (response) {
                self.SubscriptionResponse = response;

            }
            else {

                self.Subscription = new Subscription({});
                self.Subscription.Type = 4;
                self.Subscription.TypeID = this.exam.ExamID;
            }
        });

    }
    updateSubscription(plan: Subscription) {
        var self = this;

        plan.Type = 4;
        plan.TypeID = this.exam.ExamID;
        self._examService.createAssetSubscription(plan).then(response => {
            if (response) {
                self.SubscriptionResponse = response;
                self._examService.openSnackBar("Successfully updated.", "Close");
            }
            else {
                self._examService.openSnackBar("Failed", "Close");
            }
        });
    }

    newAsset(plan: Subscription): void {

        let asset = new SubscriptionPlan({
            AssetType: 1,
            SubscriptionTypeID: plan.SubscriptionTypeID

        });

        asset.AssetType = 1;
        asset.Type = 4;
        asset.TypeID = this.exam.ExamID;
        asset.SubscriptionTypeID = plan.SubscriptionTypeID;
        plan.Plan = asset;

        this.dialogRef = this._matDialog.open(CMCAssetFormDialogComponent, {
            panelClass: 'asset-form-dialog',
            data: {
                action: 'new',
                Asset: plan
            }
        });
        this.newAssetCallback();


    }

    newAssetCallback() {
        var self = this;

        this.dialogRef.afterClosed()
            .subscribe((response: Subscription) => {
                if (!response) {
                    return;
                }

                self._examService.createAssetSubscription(response).then(response => {
                    if (response) {
                        self.Subscription = response;
                        self._examService.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        self._examService.openSnackBar("Failed", "Close");
                    }
                });
            });
    }

    editAsset(asset, plan: Subscription): void {

        plan.Plan = asset;

        this.dialogRef = this._matDialog.open(CMCAssetFormDialogComponent, {
            panelClass: 'asset-form-dialog',
            data: {
                Asset: plan,
                action: 'edit'
            }
        });
        this.editAssetCallback(asset);
    }

    editAssetCallback(plan: Subscription) {
        var self = this;
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: Subscription = response[1];
                formData.Plan.Type = 4;
                formData.Plan.TypeID = this.exam.ExamID;
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':


                        self._examService.createAssetSubscription(formData).then(response => {
                            if (response) {
                                self.SubscriptionResponse = response;
                                self._examService.openSnackBar("Successfully updated.", "Close");
                            }
                            else {
                                self._examService.openSnackBar("Failed", "Close");
                            }
                        });


                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        self._examService.deleteAssetSubscription(formData).then(response => {
                            if (response) {
                                self.SubscriptionResponse = response;
                                self._examService.openSnackBar("Successfully updated.", "Close");
                            }
                            else {
                                self._examService.openSnackBar("Failed", "Close");
                            }
                        });

                        break;
                }
            });
    }

    /**
     * Delete Asset
     */
    deleteAsset(asset): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
            this.confirmDialogRef = null;
        });

    }
}
