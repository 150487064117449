import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { UnisunUtils } from '@unisun/utils';

import { State } from 'app/main/admin/state/state.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class StateService implements Resolve<any>
{
    onStateChanged: BehaviorSubject<any>;

    state: State[];
    user: any;
    selectedState: string[] = [];

    searchText: string;
    filterBy: string;
    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onStateChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                this.getStateForGrid(gridFilter)
            ]).then(
                ([files]) => {

                    resolve(null);

                },
                reject
            );
        });
    }


    /**
     * Get state
     *
     * @returns {Promise<any>}
     */
    getStateByCountry(id: number): Promise<State[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/localization/get-state-by-country/`+id)
                .subscribe((response: any) => {

                    this.state = response;
                    resolve(this.state);
                }, reject);
        }
        );
    }
    /**
     * Get state
     *
     * @returns {Promise<any>}
     */
    getState(): Promise<State[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/localization/get-states`)
                .subscribe((response: any) => {

                    this.state = response;

                    this.onStateChanged.next(this.state);
                    resolve(this.state);
                }, reject);
        }
        );
    }
    /**
     * Get state
     *
     * @returns {Promise<any>}
     */
    getStateForGrid(_gridFilter: GridFilter): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/localization/get-grid-states`, { ..._gridFilter });
    }

    /**
     * Update state
     *
     * @param state
     * @returns {Promise<any>}
     */
    createState(state: State): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/localization/create-state/`, { ...state })
                .subscribe(response => {
                    if (response) {
                        this.onStateChanged.next(this.state);
                        this.openSnackBar("Successfully added.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Update state
     *
     * @param state
     * @returns {Promise<any>}
     */
    updateState(state: State): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(`${environment.apiURL}/localization/update-state/`, { ...state })
                .subscribe(response => {
                    if (response) {
                        this.onStateChanged.next(this.state);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete state
     *
     * @param state
     */
    deleteState(state): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.delete(`${environment.apiURL}/localization/delete-state/` + state.StateID, {})
                .subscribe(response => {
                    if (response) {
                        this.onStateChanged.next(this.state);
                        this.openSnackBar("Successfully removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

}
