import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


import { QuestionDetail } from 'app/main/admin/qbank/qbank.model';
import { environment } from 'environments/environment';
import { GridFilter } from 'app/_models/GridFilter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class QuestionDetailService implements Resolve<any>
{
    onQuestionDetailChanged: BehaviorSubject<any>;

    qbank: QuestionDetail[];
    user: any;
    selectedQuestionDetail: string[] = [];

    searchText: string;
    filterBy: string;

    openSnackBar(message: string, action: string) {
        this._matSnockbar.open(message, action, {
            duration: 2000,
        });
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _matSnockbar: MatSnackBar
    ) {
        // Set the defaults
        this.onQuestionDetailChanged = new BehaviorSubject([]);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterQuestionDetailSnapshot} qbank
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, qbank: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            let gridFilter: GridFilter = {
                PageNumber: 0,
                PageSize: 5,
                Search: '',
                SortBy: '',
                SortOrder: ''
            };
            Promise.all([
                // this.getQuestionDetailForGrid(gridFilter)
            ]).then(
                ([]) => {

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get qbank by Id
     *
     * @returns {Promise<any>}
     */
    getQuestionDetailById(id): Observable<QuestionDetail> {

        return this._httpClient.get<QuestionDetail>(`${environment.apiURL}/fundamental/get-qbank-detail/` + id);

    }
    /**
     * Get qbank
     *
     * @returns {Promise<any>}
     */
    getQuestionDetail(): Promise<QuestionDetail[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/fundamental/get-qbank-details`)
                .subscribe((response: any) => {

                    this.qbank = response;

                    this.onQuestionDetailChanged.next(this.qbank);
                    resolve(this.qbank);
                }, reject);
        }
        );
    }

    /**
     * Get qbank
     *
     * @returns {Promise<any>}
     */
    getQuestionDetailForGrid(_gridFilter: GridFilter, id: number): Observable<any> {

        return this._httpClient.post(`${environment.apiURL}/fundamental/get-grid-qbank-details/` + id, { ..._gridFilter });
    }

    /**
     * Update qbank
     *
     * @param qbank
     * @returns {Promise<any>}
     */
    createQuestionDetail(qbank: QuestionDetail): Promise<any> {
        return new Promise((resolve) => {

            this._httpClient.post(`${environment.apiURL}/fundamental/create-qbank-detail/`, { ...qbank })
                .subscribe(response => {
                    if (response) {
                        this.onQuestionDetailChanged.next(this.qbank);
                        this.openSnackBar("Successfully added..", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }

                    resolve(response);
                });
        });
    }

    /**
     * Update qbank
     *
     * @param qbank
     * @returns {Promise<any>}
     */
    updateQuestionDetail(qbank: QuestionDetail): Promise<any> {
        return new Promise(() => {

            this._httpClient.post(`${environment.apiURL}/fundamental/update-qbank-detail/`, { ...qbank })
                .subscribe(response => {
                    if (response) {
                        this.onQuestionDetailChanged.next(this.qbank);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    /**
     * Delete qbank
     *
     * @param qbank
     */
    deleteQuestionDetail(qbank: QuestionDetail): Promise<any> {
        return new Promise(() => {

            this._httpClient.delete(`${environment.apiURL}/fundamental/delete-qbank-detail/` + qbank.QuestionDetailID, {})
                .subscribe(response => {
                    if (response) {
                        this.onQuestionDetailChanged.next(this.qbank);
                        this.openSnackBar("Successfully Removed.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }

    BulkUploadQuestions(questions: Array<QuestionDetail>, parentID: number): Promise<void> {
        let request = {
            QuestionDetails: questions
        };


        return new Promise<void>((resolve, reject) => {
            this._httpClient.post(`${environment.apiURL}/fundamental/upload-qbank-detail/`, { ...request })
                .subscribe(response => {
                    if (response) {
                        this.onQuestionDetailChanged.next(this.qbank);
                        this.openSnackBar("Successfully updated.", "Close");
                    }
                    else {
                        this.openSnackBar("Failed", "Close");
                    }
                });
        });
    }
   

    getAllQbankDetails(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiURL}/fundamental/get-all-qbank-details/` + id)
                .subscribe((response: any) => {
                   resolve(response.Data);
                }, reject);
        }
        );
    }
}
