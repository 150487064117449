import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';

import { Video, Transcription } from '../video.model';
import { GridFilter } from 'app/_models/GridFilter';
import { ActivatedRoute } from '@angular/router';
import { SitePreference } from 'app/config';

import { TranscriptionFormDialogComponent } from '../transcription-form/form.component';
import { DataGuardService } from 'app/_guards/data.guard';
import { TranscriptionService } from '../transcription.service';

@Component({
    selector: 'transcription-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})
export class TranscriptionComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: true })
    dialogContent: TemplateRef<any>;
    _sitePreference: any = SitePreference;
    videoID: number;
    videoDetailID: number;
    video: any;
    paginationData: any;

    dataSource: TranscriptionDataSource;
    displayedColumns = [ 'Topic', 'TrimStart', 'TrimEnd','VideoLength', 'buttons'];
    selectedTranscription: any[];
    checkboxes: {};
    dialogRef: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;


    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    hasSelectedVideo: boolean;
    searchInput: FormControl;

    // Private

    /**
     * Constructor
     *
     * @param {TranscriptionService} _videoService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _videoService: TranscriptionService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _dataGuardService: DataGuardService
    ) {
        var self = this;
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.route.params.subscribe(function (parram) {

            self.videoID = self._dataGuardService.valueDecryption(parram.videoId);
            self.videoDetailID = self._dataGuardService.valueDecryption(parram.videoDetailID);
        });
    }

    ngAfterViewInit() {

    }

    loadPage() {
        this._videoService.onTranscriptionChanged.next(this.video);
    }


    getNext(event: PageEvent) {
        this._videoService.onTranscriptionChanged.next(this.video);

    }


    onSortData(sort: Sort) {

        this._videoService.onTranscriptionChanged.next(this.video);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.dataSource = new TranscriptionDataSource(this._videoService, this.videoID, this.videoDetailID);

        this._videoService.onTranscriptionChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                this.video = search;
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort.active == null ? "Title" : this.sort.active,
                    SortOrder: this.sort.direction == 'asc' ? this.sort.direction : 'desc'
                };
                this.dataSource.loadData(gridFilter);
            });

        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._videoService.onTranscriptionChanged.next(searchText);
            });
    }

    /**
    * New video
    */
    newVideoDetail(): void {

        this.dialogRef = this._matDialog.open(TranscriptionFormDialogComponent, {
            panelClass: 'transcription-form-dialog',
            data: {
                action: 'new'
            }
        });
        this.newVideoDetailCallback();

    }

    newVideoDetailCallback() {
        this.dialogRef.afterClosed()
            .subscribe((response: Transcription) => {
                if (!response) {
                    return;
                }
                response.VideoDetailID = this.videoDetailID;
                this._videoService.createTranscription(response);
            });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Edit video
     *
     * @param video
     */
    editTranscription(video): void {

        this.dialogRef = this._matDialog.open(TranscriptionFormDialogComponent, {
            panelClass: 'transcription-form-dialog',
            data: {
                video: video,
                action: 'edit'
            }
        });
        this.editTranscriptionCallback(video);

    }

    editTranscriptionCallback(video: Transcription) {
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: Transcription = response[1];
                formData.VideoDetailID= this.videoDetailID;
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':

                        this._videoService.updateTranscription(formData);

                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deleteTranscription(video);

                        break;
                }
            });
    }

    /**
     * Delete Transcription
     */
    deleteTranscription(video): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._videoService.deleteTranscription(video);
            }
            this.confirmDialogRef = null;
        });

    }

}

export class TranscriptionDataSource extends DataSource<Transcription>
{

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingSubject.asObservable();
    public data: MatTableDataSource<Transcription>;
    /**
     * Constructor
     *
     * @param {TranscriptionService} _videoService
     */
    constructor(
        private _videoService: TranscriptionService,
        private _videoId: number,
        private _videoDetailId: number
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._videoService.onTranscriptionChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) {
        var self = this;

        this._videoService.getTranscriptionForGrid(gridFilter, this._videoId, this._videoDetailId)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);


                self.paginationData = {
                    count: response.Count || 0,
                    pageNumber: response.CurrentFilter == undefined ? 0 : response.CurrentFilter.PageNumber
                };


            });
    }
}
