import { Country } from './../../country/country.model';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { State } from 'app/main/admin/state/state.model';

@Component({
    selector: 'state-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class StateFormDialogComponent {
    action: string;
    state: State;
    country: Country[];
    stateForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<StateFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<StateFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        // Set the defaults
        this.action = _data.action;
        this.country = _data.country;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit State';
            this.state = _data.state;
        }
        else {
            this.dialogTitle = 'New State';
            this.state = new State({});
        }

        this.stateForm = this.createStateForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create state form
     *
     * @returns {FormGroup}
     */
    createStateForm(): FormGroup {
        return this._formBuilder.group({
            StateID: [this.state.StateID],
            Name: [this.state.Name],
            CountryID: [this.state.CountryID],
        });
    }

    /**
     * Selected change event Country Dropdown
     */
    onChangeCountry(country: Country) {

    }

}
