import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Report } from 'app/main/admin/report/report.model';

@Component({
    selector     : 'report-form-dialog',
    templateUrl  : './form.component.html',
    styleUrls    : ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ReportFormDialogComponent
{
    action: string;
    report: Report;
    reportForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ReportFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.action = _data.action;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Edit Report';
            this.report = _data.report;
        }
        else
        {
            this.dialogTitle = 'New Report';
            this.report = new Report();
        }

        this.reportForm = this.createReportForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create report form
     *
     * @returns {FormGroup}
     */
    createReportForm(): FormGroup
    {
        return this._formBuilder.group({
           
        });
    }
}
