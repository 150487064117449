import { NgModule } from '@angular/core';

import { UnisunSidebarComponent } from './sidebar.component';

@NgModule({
    declarations: [
        UnisunSidebarComponent
    ],
    exports     : [
        UnisunSidebarComponent
    ]
})
export class UnisunSidebarModule
{
}
