import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { QuestionSet, Choice, AdditionalChoice } from '../qbank.model';
import { CommonType, SitePreference } from 'app/config';

@Component({
    selector: 'cmc-qbank-choice-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CMCQuestionChoiceFormDialogComponent {
    action: string;
    qbank: Choice;
    qbankForm: FormGroup;
    dialogTitle: string;
    questionType: number;
    additionalChoices: Array<AdditionalChoice>;
    _commonType: any = CommonType;

    /**
     * Constructor
     *
     * @param {MatDialogRef<CMCQuestionChoiceFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<CMCQuestionChoiceFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        // Set the defaults
        this.action = _data.action;
        this.questionType = _data.questionType;
        this.additionalChoices = _data.additionalChoice;
        
        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Choice';
            this.qbank = _data.qbank;
        }
        else {
            this.dialogTitle = 'New Choice';
            this.qbank = new Choice({});
        }

        this.qbankForm = this.createQuestionChoiceForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create qbank form
     *
     * @returns {FormGroup}
     */
    createQuestionChoiceForm(): FormGroup {
        return this._formBuilder.group({
            QuestionSetID: [this.qbank.QuestionSetID],
            QuestionDetailID: [this.qbank.QuestionDetailID],
            ChoiceID: [this.qbank.ChoiceID],
            QueueID: [this.qbank.QueueID],
            ChoiceText: [this.qbank.ChoiceText],
            IsCorrect: [this.qbank.IsCorrect],
            ValidAdditionalValue: [this.qbank.ValidAdditionalValue]
        });
    }
}
