<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent-bg m-0 mat-elevation-z6">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" unisunPerfectScrollbar>

        <form [formGroup]="videoForm">

            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input name="Title" formControlName="Title" placeholder="Name" matInput required
                        [(ngModel)]="video.Title">
                </mat-form-field>
            </div>
            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input matInput name="NotesLink" required formControlName="NotesLink" [(ngModel)]="video.NotesLink"
                        placeholder="NotesLink">
                </mat-form-field>
            </div>
            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field class="mr-sm-24" fxFlex>
                    <mat-select placeholder="Subject"  [(ngModel)]="video.Subject" required
                        formControlName="Subject">
                        <mat-option *ngFor="let s of subjects" [value]="s.SubjectName"  (click)="gettopics(s.SubjectID)">{{s.SubjectName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="mr-sm-24" fxFlex>
                    <mat-select placeholder="Topic"  [(ngModel)]="video.Topic" required
                        formControlName="Topic">
                        <mat-option *ngFor="let s of topics" [value]="s.TopicID" >{{s.TopicName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>

                    <input name ="Subject" formControlName="Subject" placeholder="Subject" matInput required
                        [(ngModel)]="video.Subject">
                </mat-form-field>
            </div> -->
            
            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>

                    <input name ="Subject" formControlName="Category" placeholder="category" matInput required
                        [(ngModel)]="video.Category">
                </mat-form-field>
            </div>

            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <textarea name="Url" formControlName="Url" placeholder="Url" matInput required
                        [(ngModel)]="video.Url"></textarea>
                </mat-form-field>
            </div>
           <div class="">
            <mat-label class="label">Video Resolutions</mat-label>
                <table class="width-100">
                  <tr  *ngFor="let videoResolution of  video.VideoResolutionCollection;index as i;">
                     <td>
                        <mat-form-field >
     <mat-select name="ResolutionType"  [(ngModel)]="videoResolution.ResolutionType" [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let _resolution of resolutions" [value]="_resolution.resolution">
                              {{_resolution.resolution}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                     </td>
                     <td>
                        <mat-form-field fxFlex>
                            <textarea name="vrUrl"  placeholder="Url"
                             matInput required
                                [(ngModel)]="videoResolution.Url" [ngModelOptions]="{standalone: true}"></textarea>
                        </mat-form-field>
                     </td>
                     <td style="width:100px;">
                        <span mat-menu-item aria-label="remove" (click)="deleteResolutions(i)">
                            <mat-icon>delete</mat-icon>
                        </span>
                     </td>
                    </tr>
                </table>
                <div class="width-100">
                    <span mat-menu-item aria-label="edit" (click)="addResolution()"
                                    matTooltip="Add Transcription"  class="text-right">
                                    <mat-icon>add</mat-icon>
                                </span>
                </div>
           </div>            
            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input name="VideoLength" formControlName="VideoLength" placeholder="VideoLength" matInput required
                        [(ngModel)]="video.VideoLength">
                </mat-form-field>
            </div>
            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input type="number" name="Price" formControlName="Price" placeholder="Price" matInput required
                        [(ngModel)]="video.Price">
                </mat-form-field>
            </div>
            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input type="number" name="MaxViewPermission" formControlName="MaxViewPermission"
                        placeholder="View Permission" matInput [(ngModel)]="video.MaxViewPermission">
                </mat-form-field>
            </div>

            <div class="mb-24 " fxLayout="row" fxLayoutAlign="start start">
                <ckeditor [config]="ckeConfig" [(ngModel)]="video.Content" formControlName="Content">
                </ckeditor>
            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button *ngIf="action !=='edit'" mat-raised-button (click)="matDialogRef.close(video)"
            class="save-button mat-accent-bg" [disabled]="videoForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-raised-button (click)="matDialogRef.close(['save',video])"
            class="save-button mat-accent-bg" [disabled]="videoForm.invalid" aria-label="SAVE">
            SAVE
        </button>

        <button *ngIf="action ==='edit'" mat-icon-button (click)="matDialogRef.close(['delete',video])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>