import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation , Input} from "@angular/core";
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, tap, catchError, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { unisunAnimations } from '@unisun/animations';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from "../../common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DataGuardService } from "app/_guards";
import { QbankType as qbankTypeModel,Module as ModuleModel } from "../../common.model";
import { GridFilter } from "app/_models/GridFilter";
import { SitePreference } from "app/config";
import { AddQBankTypeComponent } from "../addQBankType/addQBankType.component";
import {Location} from '@angular/common';


@Component({
    selector: 'qbankType',
    templateUrl: './qbankTypeList.component.html',
    styleUrls: ['./qbankTypeList.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: unisunAnimations
})

export class QBankTypeListComponent implements OnInit, OnDestroy  {
    @ViewChild('dialogContent', { static: true })
    Modules:Array<ModuleModel>;
    qbankType:any;
    dialogContent: TemplateRef<any>;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;
    dialogRef:any;
    _sitePreference: any = SitePreference;
    paginationData: any;
    dataSource: QBankTypeDetailsDataSource;
    displayedColumns = ['Title', 'IsActive', 'buttons'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
   
    
    // Private
    private _unsubscribeAll: Subject<any>;
    searchInput: FormControl;
    constructor(
        private _commonService: CommonService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _router: Router,
        private _dataGuardService: DataGuardService,
        private _location: Location
        
    ) { 
        var self = this;
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaultsSubject
        this._unsubscribeAll = new Subject();
        

     }
    loadPage() {
        this._commonService.onQBankTypeChanged.next(this.qbankType);
    }

    ngOnInit(): void {
        var self=this;
        this.dataSource = new QBankTypeDetailsDataSource(this._commonService);
     
        this.searchInput.valueChanges
        .pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(300),
            distinctUntilChanged()
        )
        .subscribe(searchText => {
            this._commonService.onQBankTypeChanged.next(searchText);
        });
    
        
        this._commonService.onQBankTypeChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(search => {
                
                let gridFilter: GridFilter = {
                    PageNumber: this.paginator.pageIndex + 1,
                    PageSize: this.paginator.pageSize == undefined ? SitePreference.PAGE.GridRowViewCount : this.paginator.pageSize,
                    Search: typeof search === "string" ? search : "",
                    SortBy: this.sort?.active == null ? "QbankTypeID" : "QbankTypeID",
                    SortOrder: this.sort?.direction == 'desc' ? this.sort.direction : 'desc',
                  };
                this.dataSource.loadData(gridFilter);

            });
     }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    getNext(event: PageEvent) {
        this._commonService.onQBankTypeChanged.next(this.qbankType);

    }

    onSortData(sort: Sort) {

        this._commonService.onQBankTypeChanged.next(this.qbankType);
    }

    addSubTopic() :void {
        var self=this;
        var qbankType = new qbankTypeModel({});
        this.dialogRef = this._matDialog.open(AddQBankTypeComponent, {
            panelClass: 'addSubTopic',
            disableClose: true,
            data: {
                action: 'new',
                qbankType:qbankType
            }     
        });        
        
        this.qbankTypeCrudOp(qbankType);
           

    }

    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Edit topic
     *
     * @param subject
     */

    editQbankType(qbankType): void {
       var self = this;
        this._commonService.getQbankTypeById(qbankType.QbankTypeID).then(response=>{
             self.dialogRef = self._matDialog.open(AddQBankTypeComponent, {
                    panelClass: 'qbanktype-form-dialog',
                    disableClose: true,
                    data: {
                        qbankType: response,
                        action: 'edit'
                    }
                });
                self.qbankTypeCrudOp(qbankType);
       })
    }
        
    qbankTypeCrudOp(qbankType: qbankTypeModel) {
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response.actionType;
                const _qbanktype: qbankTypeModel = response.qbankType;  
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'add':
                        if(_qbanktype) {  
                            this._commonService.createQbanktype(_qbanktype);
                        }
                         
                         break;

                    case 'update':

                    if(_qbanktype) {                       
                         this._commonService.updateQbankType(_qbanktype);
                      }
                      break;


                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deleteQbankType(qbankType);

                        break;
                }
                              
            });
                  

    }



    /**
     * Delete Topic
     */
    deleteQbankType(qbankType): void {
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._commonService.deleteQbankType(qbankType);
            }
            this.confirmDialogRef = null;
        });

    }
    // route to
    subjects(Subject) {
        this._router.navigate(['admin/collection/qbanktype/' + this._dataGuardService.valueEncryption(Subject.QbankTypeID)+'/subjects']);
        
    }

    
}

export class QBankTypeDetailsDataSource extends DataSource<qbankTypeModel>
{

    private loadingTopic = new BehaviorSubject<boolean>(false);
    public paginationData: any;
    public loading$ = this.loadingTopic.asObservable();
    public data: MatTableDataSource<qbankTypeModel>;
    
   
    
    /**
     * Constructor
     *
     * @param {TopicService} _topicService
     */
    constructor(
        private _commonService: CommonService
 
    ) {
        super();
    }
    

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._commonService.onSubtopicChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    loadData(gridFilter: GridFilter) { 
        var self = this;
        this._commonService.getQbankTypeForGrid(gridFilter)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                    this.loadingTopic.next(false)
                })
            )
            .subscribe(response => {
                this.data = new MatTableDataSource(response.data);
                self.paginationData = {
                    count: response.Count,
                    pageNumber: response.CurrentFilter.PageNumber
                };

            });
    }
}