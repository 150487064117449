import { filter } from 'rxjs/operators';
import { CommonType, SitePreference } from 'app/config';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import { QuestionSet, QuestionDetail, Choice, AdditionalChoice } from 'app/main/admin/qbank/qbank.model';
import { State } from 'app/main/admin/state/state.model';
import { CMCQuestionChoiceFormDialogComponent } from '../qbank-choice-form/form.component';
import { CMCQuestionAdChoiceFormDialogComponent } from '../qbank-ad-choice-form/form.component';
import { CMCExamService } from '../../exam/exam.service';
import { UnisunConfirmDialogComponent } from '@unisun/components/confirm-dialog/confirm-dialog.component';
import { CMCQuestionSetService } from '../qbank.service';

@Component({
    selector: 'cmc-qbank-detail-form-dialog',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CMCQuestionDetailFormDialogComponent {
    ckeConfig: any;
    action: string;
    qbank: QuestionDetail;
    qbankForm: FormGroup;
    dialogTitle: string;
    dialogRef: any;
    _commonType: any = CommonType;
    choiceType: any;
    confirmDialogRef: MatDialogRef<UnisunConfirmDialogComponent>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<QuestionDetailFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<CMCQuestionDetailFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        public _matDialog: MatDialog,
        private _examService: CMCExamService,
        private _qbankService: CMCQuestionSetService
    ) {
        var self = this;

        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        this._examService.getChoiceType().then(function (response) {

            self.choiceType = response;
        });
        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Question';
            this.qbank = _data.qbank;
        }
        else {
            this.dialogTitle = 'New Question';
            this.qbank = new QuestionDetail({});
        }

        this.qbankForm = this.createQuestionSetForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create qbank form
     *
     * @returns {FormGroup}
     */
    createQuestionSetForm(): FormGroup {
        return this._formBuilder.group({
            QuestionTitle: [this.qbank.QuestionTitle],
            Mark: [this.qbank.Mark],
            Negative: [this.qbank.Negative],
            QuestionType: [this.qbank.QuestionType],
            QuestionBankID: [this.qbank.QuestionBankID],
            Choice: [this.qbank.Choices],
            AdditionalChoice: [this.qbank.AdditionalChoices],
            Content: [this.qbank.Content],
            Subject: [this.qbank.Subject],
            Chapter: [this.qbank.Chapter],
            QueueID: [this.qbank.QueueID],
        });
    }

    newQuestionChoice(questionType: number): void {
        let qbank: Choice;

        this.dialogRef = this._matDialog.open(CMCQuestionChoiceFormDialogComponent, {
            panelClass: 'qbank-choice-form-dialog',
            disableClose: true,
            data: {
                qbank: qbank,
                action: 'new',
                questionType: questionType,
                additionalChoice: this.qbank.AdditionalChoices
            }
        });
        this.QuestionChoiceCallback(qbank);

    }

    editQuestionChoice(qbank: Choice, questionType: number): void {

        this.dialogRef = this._matDialog.open(CMCQuestionChoiceFormDialogComponent, {
            panelClass: 'qbank-choice-form-dialog',
            disableClose: true,
            data: {
                qbank: qbank,
                action: 'edit',
                questionType: questionType,
                additionalChoice: this.qbank.AdditionalChoices
            }
        });
        this.QuestionChoiceCallback(qbank);

    }

    QuestionChoiceCallback(qbank: Choice) {
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: Choice = response[1];
                var self = this;
                switch (actionType) {
                    case 'update':
                        {
                            let itemIndex = self.qbank.Choices.findIndex(a => a.AutoId == formData.AutoId);
                            self.qbank.Choices[itemIndex] = formData;
                            break;
                        }
                    case 'save':
                        {

                            if (self.qbank.Choices != null && self.qbank.Choices.length > 0) {
                                let id = Math.max.apply(Math, self.qbank.Choices.map(function (o) { return o.QueueID; }))
                                formData.QueueID = id + 1;
                            }
                            else {
                                formData.QueueID = 1;
                                self.qbank.Choices = [];
                            }
                            if (self.qbank.Choices) {
                                self.qbank.Choices.push(formData);
                            }
                            else {
                                self.qbank.Choices = [];
                                self.qbank.Choices.push(formData);
                            }

                            break;
                        }

                    case 'delete':
                        {

                            break;
                        }
                }
            });
    }

    newQuestionAdditionalChoice(): void {

        this.dialogRef = this._matDialog.open(CMCQuestionAdChoiceFormDialogComponent, {
            panelClass: 'qbank-ad-choice-form-dialog',
            disableClose: true,
            data: {
                qbank: new AdditionalChoice({}),
                action: 'new'
            }
        });
        this.QuestionAdditionalChoiceCallback();

    }

    editQuestionAdditionalChoice(qbank: AdditionalChoice): void {

        this.dialogRef = this._matDialog.open(CMCQuestionAdChoiceFormDialogComponent, {
            panelClass: 'qbank-ad-choice-form-dialog',
            disableClose: true,
            data: {
                qbank: qbank,
                action: 'edit'
            }
        });
        this.QuestionAdditionalChoiceCallback();

    }

    QuestionAdditionalChoiceCallback() {
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: AdditionalChoice = response[1];
                var self = this;
                switch (actionType) {
                    case 'update':
                        {
                            let itemIndex = self.qbank.AdditionalChoices.findIndex(a => a.AutoId == formData.AutoId);
                            self.qbank.AdditionalChoices[itemIndex] = formData;
                            break;
                        }
                    case 'save':
                        {

                            if (self.qbank.AdditionalChoices != null && self.qbank.AdditionalChoices.length > 0) {
                                let id = Math.max.apply(Math, self.qbank.AdditionalChoices.map(function (o) { return o.QueueID; }))
                                formData.QueueID = id + 1;
                            }
                            else {
                                formData.AdditionalChoiceID = 1;
                                self.qbank.AdditionalChoices = [];
                            }
                            if (self.qbank.AdditionalChoices) {
                                self.qbank.AdditionalChoices.push(formData);
                            }
                            else {
                                self.qbank.AdditionalChoices = [];
                                self.qbank.AdditionalChoices.push(formData);
                            }

                            break;
                        }

                    case 'delete':

                        break;
                }
            });
    }


    /**
     * Delete Choice
     */
    deleteQuestionChoice(_choice: Choice): void {
        var self = this;
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false,
            data: {
                choice: _choice
            }
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (_choice.ChoiceID > 0) {
                    self._qbankService.deleteQuestionChoice(_choice);
                }

                let item = self.qbank.Choices.filter(a => a.ChoiceID != _choice.ChoiceID);
                self.qbank.Choices = item;
            }

            this.confirmDialogRef = null;
        });

    }

    /**
     * Delete Add Choice
     */
    deleteQuestionAdditionalChoice(_choice: AdditionalChoice): void {
        var self = this;
        this.confirmDialogRef = this._matDialog.open(UnisunConfirmDialogComponent, {
            disableClose: false,
            data: {
                choice: _choice
            }
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (_choice.ChoiceID > 0) {
                    self._qbankService.deleteQuestionAdditionalChoice(_choice);
                }

                let item = self.qbank.AdditionalChoices.filter(a => a.ChoiceID != _choice.ChoiceID);
                self.qbank.AdditionalChoices = item;
            }

            this.confirmDialogRef = null;
        });

    }
}
