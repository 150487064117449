

export class TrandingVideo {
    PromoID: number;
    Title: number;
    Description: string;
    IsActive: string;
    Url: string;
    QueueID:number;
    VideoType:number;

    /**
     * Constructor
     *
     * @param Trandingvideo
     */
    constructor(Trandingvideo) {
        {
            this.PromoID = Trandingvideo.PromoID; //|| UnisunUtils.generateGUID();
            this.Title = Trandingvideo.Title || '';
            this.Description = Trandingvideo.Description || '';
            this.IsActive = Trandingvideo.Message || false;
            this.Url = Trandingvideo.Url || '';
            this.QueueID = Trandingvideo.QueueID || '';
            this.VideoType = Trandingvideo.VideoType||1;
        }
    }
}
