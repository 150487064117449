<div id="myranking" class="page-layout carded fullwidth inner-scroll">
    <button id="print" mat-icon-button aria-label="Delete" matTooltip="Delete">
        <mat-icon>print</mat-icon>
    </button>
    <!-- CONTENT -->
    <div class="content grey-100-bg" unisunPerfectScrollbar>

        <div class="content mat-elevation-z4" unisunPerfectScrollbar>
            <div class="content-card" *ngIf="pGiQuestionAnalysisReport">

                <div class="content p-24" unisunPerfectScrollbar>
                    <div class="content" fxlayout="row wrap" fxlayoutalign="start start"
                        style="flex-flow: row wrap; box-sizing: border-box; display: flex; place-content: flex-start; align-items: flex-start;">
                        <div class="unisun-card m-16" style="min-width:100%">
                            <div class="p-16">
                                <div class="h4 pr-16 center">ADrPlexus</div>
                                <div class="h4 pr-16 center">OPTION WISE RESPONSE </div>
                                <table class="simple clickable">
                                    <tbody>
                                        <tr>
                                            <td class="p-8">
                                                <b>NAME :</b> {{pGiQuestionAnalysisReport.UserName}}
                                            </td>
                                            <td class="p-8">

                                            </td>
                                            <td class="p-8">
                                                <b>TEST :</b> {{pGiQuestionAnalysisReport.TestName}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-8">

                                            </td>
                                            <td class="p-8">

                                            </td>
                                            <td class="p-8">
                                                <b>TEST ID:</b>{{pGiQuestionAnalysisReport.TestID}} <b>TEST
                                                    ID:</b>{{pGiQuestionAnalysisReport.TestDate}}
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            <table class="simple clickable">
                                <thead>
                                    <tr>
                                        <th class="p-8">QNo</th>
                                        <th class="p-8">CORROPT</th>
                                        <th class="p-8">INCOPT</th>
                                        <th class="p-8"> </th>
                                        <th class="p-8">CORRECT</th>
                                        <th class="p-8">INCORR</th>
                                        <th class="text-right p-8"> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of pGiQuestionAnalysisReport.QuestionsAnalysisReport">
                                        <td class="p-8">
                                            {{r.QNo}}
                                        </td>
                                        <td class="p-8">
                                            {{r.ActualCorrectOption}}
                                        </td>
                                        <td class="p-8">
                                            {{r.ActualInCorrectOption}}
                                        </td>
                                        <td class="p-8">

                                        </td>
                                        <td class="p-8">
                                            {{r.CorrectOption}}
                                        </td>
                                        <td class="p-8">
                                            {{r.InCorrectOption}}
                                        </td>
                                        <td class="text-right p-8"></td>

                                    </tr>
                                    <tr *ngIf="pGiQuestionAnalysisReport.QuestionAnalysisStatisticReport">
                                        <td class="p-8">

                                        </td>
                                        <td class="p-8">
                                            {{pGiQuestionAnalysisReport.QuestionAnalysisStatisticReport.ActualCorrectOptionValue}}
                                        </td>
                                        <td class="p-8">
                                            {{pGiQuestionAnalysisReport.QuestionAnalysisStatisticReport.ActualInCorrectOptionValue}}
                                        </td>
                                        <td class="p-8">

                                        </td>
                                        <td class="p-8">
                                            {{pGiQuestionAnalysisReport.QuestionAnalysisStatisticReport.CorrectOptionValue}}
                                        </td>
                                        <td class="p-8">
                                            {{pGiQuestionAnalysisReport.QuestionAnalysisStatisticReport.InCorrectOptionValue}}
                                        </td>
                                        <td class="text-right p-8"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- / CONTENT -->
    </div>
    <!-- / CENTER -->
</div>