import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SubjectDetails, Topic as TopicModel, Module as ModuleModel } from 'app/main/admin/common/subject/common.model'
import { CommonService } from "../../common.service";
import { State } from 'app/main/admin/state/state.model'

@Component({
    selector: 'addTopic',
    templateUrl: './addTopic.component.html',
    styleUrls: ['./addTopic.component.scss'],
    encapsulation: ViewEncapsulation.None

})

export class AddTopicComponent {

    ckeConfig: any;
    action: string;
    dialogTitle: string;
    topic: TopicModel;
    topicForm: FormGroup;
    SubjectID: any;
    _Modules: Array<ModuleModel>;

    states: State[];
    /**
    * Constructor
    *
    * @param {MatDialogRef<AddTopicComponent>} matDialogRef
    * @param _data
    * @param {FormBuilder} _formBuilder
    */

    constructor(
        public matDialogRef: MatDialogRef<AddTopicComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _commonService: CommonService
    ) {
        var self = this;
        // Set the defaults
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Topic';
            this.topic = _data.topic;
            this._Modules = _data.modules;
        }
        else {
            this.dialogTitle = 'New Topic';
            this.topic = _data.topic;
            this._Modules = _data.modules;
        }

        this.topicForm = this.createtopicForm();
    }

    ngOnInit(): void {

    }

    /**
    * Create topic form
    *
    * @returns {FormGroup}
    */
    createtopicForm(): FormGroup {
        return this._formBuilder.group({
            TopicName: [this.topic.TopicName],
            IsActive: [this.topic.IsActive],
            TopicID: [this.topic.TopicID],
            OrderNo: [this.topic.OrderNo],
            Modules: this.buildModules(),
        })
    }
    buildModules() {
        const arr = this._Modules.map(module => {
            if (this.topic.Modules) {
                var isSelected = false;
                this.topic.Modules.forEach(_module => {
                    if (module.ModuleID == _module.ModuleID) {
                        isSelected = true;
                    }
                });
                return this._formBuilder.control(isSelected);
            }
            else {
                return this._formBuilder.control(false);
            }
        });
        return this._formBuilder.array(arr);
    }

    get Modules() {
        return this.topicForm.get('Modules') as FormArray;
    };


}